import React, { Component } from 'react';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Tooltip from '@material-ui/core/Tooltip';
import TaglineLogo from 'assets/img/Cognisun-tagline.png';
import TextField from "@material-ui/core/TextField";
import cogoToast from 'cogo-toast';
import Button from "components/CustomButtons/Button.js";
import SimpleBackdrop from "../../utils/general";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(styles);
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailID: "",
      emailIDErr: false,
      emailIDHelperText: "",
      passwordHelperText: "",
      passwordErr: false,
      Password: "",   
      confirmPassword: "",
      confirmPasswordHelperText:"",
      confirmPasswordErr:false,
      OTP: "",
      OTPHelperText: "",
      OTPErr: "",
      // isChecked: false,  
      email: "",
      hashOTP: ""
    };
  }

  componentWillUnmount() {
    document.body.classList.remove('login-page');

  }

  componentDidMount() {
    document.body.classList.add('login-page');

  }


  validate = () => {
    debugger;
    let IsValid = true;

    if (CommonConfig.isEmpty(this.state.emailID)) {
      IsValid = false;
      this.setState({ emailIDHelperText: "Please Enter Your Email", emailIDErr: true });
    }
    else if (!CommonConfig.isEmpty(this.state.emailID)) {
      const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      const result = pattern.test(this.state.emailID);
      if (result === false) {
        this.setState({ emailIDErr: true, emailIDHelperText: "Please Enter Valid Email" });
      } else {
        this.setState({ emailIDErr: false, emailIDHelperText: "" });
      }

    }
    else {
      IsValid = true;
      this.setState({ passwordErr: false, passwordHelperText: "" });
    }
    return IsValid;
  }


  onBack = () => {
    this.props.history.push("/auth/login-page")
  }


  onSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.showLoader();

      let data = {
        Email: this.state.emailID,
        // Password: this.state.Password,
      }
      debugger;
      try {
        api.post("login/forgotPassword", data).then(res => {
          console.log("res", res.email);
          this.hideLoader();
          if (res.success) {
            cogoToast.success("Your OTP will be sent to your email address. Please check.");
            this.setState({ email: res.email });
            this.setState({ hashOTP: res.Data.path })

          }
          else {
            cogoToast.error(res.message);
          }
        }).catch(err => {
        });
      }
      catch (error) {
      }

    }

    else {
      cogoToast.error("Please Enter Valid Email");
    }

  }



  validateOtp = () => {
    debugger;
    let IsValid = true;

    if (CommonConfig.isEmpty(this.state.OTP)) {
      IsValid = false;
      this.setState({ OTPHelperText: "Please enter OTP.", OTPErr: true });
    }
    else {
      IsValid = true;
      this.setState({ OTPErr: false, OTPHelperText: "" });
    }

    if (CommonConfig.isEmpty(this.state.Password)) {
      IsValid = false;
      this.setState({ passwordHelperText: "Please create new password.", passwordErr: true });
    }
    else {
      IsValid = true;
      this.setState({ passwordErr: false, passwordHelperText: "" });
    }
    return IsValid;
  }


  onOtpSubmit = (e) => {
    e.preventDefault();
    if (this.validateOtp()) {
      this.showLoader();

      let data = {
        OTP: this.state.OTP,
        Password: this.state.Password,
        EmailID: this.state.email,
        hash: this.state.hashOTP
      }
      console.log("data", data);
      debugger;
      try {
        api.post("login/verifyOTPAndResetPassword", data).then(res => {
          this.hideLoader();
          if (res.success === true) {
            cogoToast.success("Password Updated Successfully.");
            this.props.history.push("/auth/login-page");
          } else if (res.success === false && res.message === 'Time Expired') {
            cogoToast.error(res.message);
            this.props.history.push("/auth/login-page");
          } else {
            cogoToast.error(res.message);
          }
        }).catch(err => {
        });
      }
      catch (error) {
      }

    }

    else {
      cogoToast.error("There were errors found in form.Please resolve and resubmit form");
    }

  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    if (this.state.Password !== this.state.confirmPassword) {
      return;
    }
    
  };
  handleChange = (e, field) => {
    const value = e.target.value;
    this.setState({ [field]: value });
    if (field === 'confirmPassword') {
      if (value !== this.state.Password) {
        this.setState({
          confirmPasswordErr: true,
          confirmPasswordHelperText: 'Passwords not matched'
        });
      } else {
        this.setState({
          confirmPasswordErr: false,
          confirmPasswordHelperText: ''
        });
      }
    }
  };
 


  onChange = (e, type) => {
    if (type === "emailID") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ emailID: e.target.value, emailIDErr: true, emailIDHelperText: "Please Enter Your Email" });
      }
      else if (type === "emailID") {

        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
        const result = pattern.test(e.target.value);
        if (result === true) {
          this.setState({
            emailID: e.target.value,
            emailIDErr: false,
            EmailHelperText: "",
          });
        } else {
          this.setState({
            emailID: e.target.value,
            emailIDErr: true,
            EmailHelperext: "Please enter valid Email",
          });
        }
      }
    }
    else if (type === "OTP") {
      console.log("otp", e.target.value)
      this.setState({ OTP: e.target.value });
    }

    else if (type === "password") {
      console.log("password", e.target.value)
      this.setState({ Password: e.target.value });
    }
  }
  


  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }

  render() {
    const { OTP, OTPErr, OTPHelperText, confirmPassword, confirmPasswordHelperText, confirmPasswordErr, emailID, Password, emailIDErr, emailIDHelperText, passwordErr, passwordHelperText, isChecked } = this.state;
    
    const canSubmit = Password === 
    confirmPassword && Password !== '' && confirmPassword !== '';
    
    return (

      <div className="login-outer">
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null
        }

        {!this.state.email ? (
          <form>
            <div className="login-box">
              <div className="login-welcome">
                <img src={TaglineLogo} />
                <p>Forgot Your password</p>
              </div>
              <div className="login-input">
                <FormControl fullWidth>
                  <TextField
                    value={emailID}
                    label="Email Id"
                    helperText={emailIDHelperText}
                    error={emailIDErr}
                    onChange={(e) => this.onChange(e, "emailID")}
                  />
                </FormControl>
              </div>

              <div className="login-submit">
                <Tooltip title="Back" placement="bottom">
                  <Button type="Submit" color="danger" onClick={() => this.onBack()}>BACK</Button>
                </Tooltip>
                <Tooltip title="Submit" placement="bottom">
                  <Button type="Submit" color="primary" onClick={(e) => this.onSubmit(e)}>SUBMIT</Button>
                </Tooltip>
              </div>
            </div>
          </form>
        ) : ""

        }
        {this.state.email ? (
          <form onSubmit={this.handleSubmit} >
            <div className="login-box">
              <div className="login-welcome">
                <img src={TaglineLogo} />
                <p>Verify OTP</p>
              </div>

              <div className="login-input">
                <FormControl fullWidth>
                  <TextField
                    value={OTP}
                    label="Verify OTP"
                    helperText={OTPHelperText}
                    error={OTPErr}
                    onChange={(e) => this.onChange(e, "OTP")}
                  />
                </FormControl>
              </div>
              <div className="login-input">
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    label="Create New Password"
                    helperText={passwordHelperText}
                    error={passwordErr}
                    value={Password}
                    onChange={(e) => this.onChange(e, "password")}
                  />
                </FormControl>
              </div>

              <div className="login-input">
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    label="Confirm Password"
                    helperText={confirmPasswordHelperText}
                    error={confirmPasswordErr}
                    value={confirmPassword}
                    onChange={(e) => this.handleChange(e, "confirmPassword")}
                  />
                </FormControl>
              </div>

              <div className="login-submit">
                <Tooltip title="Back" placement="bottom">
                  <Button type="Submit" color="danger" onClick={() => this.onBack()}>BACK</Button>
                </Tooltip>
                <Tooltip title="Submit" placement="bottom">
             
                <Button type="Submit" color="primary" disabled={!canSubmit} onClick={(e) => this.onOtpSubmit(e)}>SUBMIT</Button>


                </Tooltip>
              </div>

            </div>

          </form>
        ) : ""

        }


      </div>

    )
  }
}

export default ForgotPassword;