import React, { Component } from 'react';
// core components
import ReactTable from "react-table";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import TextField from "@material-ui/core/TextField";
import SimpleBackdrop from "../../utils/general";


class ProjectManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {

      projectmanagementList: [],
      projectmanagementList2: [],
      loggedUserID: 0,
      searchInput: "",
    };
  }

  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }

  componentDidMount() {

    this.getProjectList();
    this.setState({ loggedUserID: CommonConfig.loginData().PersonID });

  }

  redirectAdd = () => {
    this.props.history.push("/admin/addProjectManagement");
  }

  redirectEdit = (record) => {
    this.props.history.push({
      pathname: "/admin/editProjectManagement",
      state: { id: record.original.ProjectID }
    })
  }

  getProjectList = () => {
    this.showLoader()
    debugger;
    try {
      api.get("projectManagement/getProjectList").then(res => {
        this.hideLoader()
        console.log("dekho.........", res.data)
        this.setState({ projectmanagementList: res.data });
        this.setState({ projectmanagementList2: res.data });
      }).catch(error => {
        console.log("error", error);
      })
    }
    catch (err) {
      console.log("err", err);
    }
  }


  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData;
    if (searchInput.length === 0) {
      filteredData = this.state.projectmanagementList2.filter(value => {
        return (
          value.ProjectName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    else {
      filteredData = this.state.projectmanagementList2.filter(value => {
        return (
          value.ProjectName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }

    this.setState({ projectmanagementList: filteredData });
  };

  render() {
    const { projectmanagementList } = this.state;

    const tableColumns = [
      {
        Header: 'Project',
        accessor: 'ProjectName',
        id: 'ProjectName',
        sortable: true,

        filterMethod: (filter, row) => {
          let ProjectName = row._original.ProjectName;
          return (
            row._original ? String(ProjectName).toLowerCase().includes(filter.value.toLowerCase()) : row
          )
        },

      },
      {
        Header: 'PM',
        accessor: data => {
          return data.FirstName + " " + data.LastName
        },
        id: 'ProjectManager',
        sortable: true,
        filterMethod: (filter, row) => {
          let FirstName = row._original.FirstName;
          return (
            row._original ? String(FirstName).toLowerCase().includes(filter.value.toLowerCase()) : row
          )
        },
      },
      {
        Header: 'Client',
        accessor: data => {
          return data.ClientName
        },
        id: 'Client',
        sortable: true,

        filterMethod: (filter, row) => {
          let ClientName = row._original.ClientName;
          return (
            row._original ? String(ClientName).toLowerCase().includes(filter.value.toLowerCase()) : row
          )
        },

      },
      {
        Header: 'Start Date',
        accessor: data => {
          if (CommonConfig.isEmpty(data.ActualStartDate)) {
            return moment().format("DD/MM/YYYY")
          }
          else {
            return moment(data.ActualStartDate).format(CommonConfig.dateFormat.dateOnly);
          }
        },
        id: 'ActualStartDate',
        sortable: true,
      },
      {
        Header: 'Tech Stack',
        Cell: record => {
          return record.original.techStackList.map((content, idx) => {
            return (
              record.original.techStackList.length === idx + 1 ?
                <span>
                  {content.TechStack}
                </span>
                :
                <span>
                  {content.TechStack + " , "}
                </span>
            )
          })
        },
        id: 'TechStack',
        sortable: true,


        filterMethod: (filter, row) => {
          let TechStack = row._original.TechStack;
          return (
            row._original ? String(TechStack).toLowerCase().includes(filter.value.toLowerCase()) : row
          )
        },

      },
      {
        Header: 'Devlopers',
        Cell: record => {
          return record.original.AssigneeList.map((content, idx) => {
            return (
              record.original.AssigneeList.length === idx + 1 ?
                <span>
                  {content.FirstName}
                </span>
                :
                <span>
                  {content.FirstName + " , "}
                </span>
            )
          })
        },
        accessor: 'Devlopers',
        id: 'Devlopers',
        sortable: true,

        filterMethod: (filter, row) => {
          let FirstName = row._original.FirstName;
          return (
            row._original ? String(FirstName).toLowerCase().includes(filter.value.toLowerCase()) : row
          )
        },

      },
      {
        Header: 'Status',
        id: 'Status',
        accessor: 'Status',
        sortable: true,




      },
      {
        Header: 'Action',
        id: 'action',
        className: "text-align-right",

        Cell: record => {
          return (
            <span>
              <Tooltip title="Edit" placement="bottom">
                <Button className="btn-icon" justIcon color="primary" onClick={() => this.redirectEdit(record)}><CreateIcon /></Button>
              </Tooltip>
            </span>
          )
        },
        filterable: false,
        // sortable: false,
      },
    ]

    let { searchInput } = this.state;
    return (
      <GridContainer className="UserList-outer">
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <div class="project-header">
                <Tooltip title="Add" placement="bottom">
                  <Button color="primary" onClick={() => this.redirectAdd()}>Add Project</Button>
                </Tooltip>
                <div className="search-field">
                  <TextField
                    size="large"
                    name="searchInput"
                    value={searchInput || ""}
                    onChange={this.handleChange}
                    label="Search Project"
                  />
                </div>
              </div>

              <ReactTable
                data={projectmanagementList}
                minRows={5}
                resizable={false}
                filterable={true}
                columns={tableColumns}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
export default ProjectManagement;
