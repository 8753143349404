import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CreateIcon from "@material-ui/icons/Create";
import api from "../../utils/apiClient";
import Tooltip from "@material-ui/core/Tooltip";
import ReactTable from "react-table";
import { CommonConfig } from "../../utils/constant";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import cogoToast from 'cogo-toast';
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";
import SimpleBackdrop from "../../utils/general";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LeaveList: [],
      ApproveAccess: false,
      IsSelectAll: false,
      LeaveBalance: 12,
      DailyStatusList: [],
      filterVal: "",
      Resoource: "",
      StartDate: "",
      EndDate: "",
      selectedDate: new Date(),
      ResourceID: "",
      FirstName: "",
      LastName: "",
      LeaveBalance: "",
      ResourceErr: false,
      ResourceHelperText: "",
      apiCalled: false

    };
  }

  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }


  componentDidMount() {
    let checkAccess = CommonConfig.getUserAccess("Leave");
    if (checkAccess) {
      if (checkAccess.AllAccess == 1) {
        this.setState({ ApproveAccess: true });
      } else {
        this.setState({ ApproveAccess: false });
      }
    }
    this.getLeaveList();
    this.getLeaveBalance();
    this.getDailyStatusList();
  }

  sendState() {
    return this.state;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  isValidated() {
    return true;
  }

  getDailyStatusList = () => {
    try {
      api
        .get("dailystatus/getDailyStatusList")
        .then((res) => {
          if (res.success) {
            let obj = {
              FullName: "Select All",
              ResourceID: "",
            };
            var finalResourceList = [];

            res.data.map((r, i) => {
              res.data[i].FullName = `${r.FirstName} ${r.LastName}`;
            });
            finalResourceList.push(obj);
            for (let i = 0; i < res.data.length; i++) {
              finalResourceList.push(res.data[i]);
            }
            this.setState({
              DailyStatusList: finalResourceList,
              filterVal: this.state.ApproveAccess
                ? { value: "", label: "Select All" }
                : {
                  value: CommonConfig.loginData().PersonID,
                  label:
                    CommonConfig.loginData().FirstName +
                    " " +
                    CommonConfig.loginData().LastName,
                },
            });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  getLeaveList = () => {
    this.showLoader()
    var today = new Date();
    let data;
    let checkAccess = CommonConfig.getUserAccess("Leave");
    if (checkAccess) {
      if (checkAccess.AllAccess == 1) {
        data = {
          ResourceID: "",
          StartDate: startOfMonth(today),
          EndDate: endOfMonth(today),
        };
      } else {
        data = {
          ResourceID: CommonConfig.loginData().PersonID,
          StartDate: this.state.StartDate
            ? this.state.StartDate
            : startOfMonth(today),
          EndDate: this.state.EndDate ? this.state.EndDate : endOfMonth(today),
        };
      }
    }


    try {
      api
        .post("leave/getLeaveListByResource", data)
        .then((res) => {
          this.hideLoader()
          if (res.success === true) {

            let current = 0;
            let balance = 12;

            //Reverse the array to count the balance from the earlier date
            res.data = res.data.reverse();
            res.data.map((r) => {
              r.leaveDays =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              r.leaveDays2 =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              current =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              balance = balance - current;
              r.leaveBalance = balance;
              return r;
            });
            //Now again reverse the list to it's original state
            let data = res.data.reverse();

            this.setState({ LeaveList: data });

          } else {
            this.setState({ LeaveList: [] });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  getLeaveBalance = () => {
    try {
      let data = {};
      if (this.state.filterVal && this.state.filterVal.value) {
        data.ResourceID = this.state.filterVal.value;
      } else {
        return;
      }
      api
        .post("leave/getLeaveBalance",data)
        .then((res) => {
          if (res.success === true) {
            this.setState({ LeaveBalance: res.data,apiCalled: true });
          } else {
            this.setState({ LeaveList: 'Not Calculated' });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };
  edit = (record) => {
    this.props.history.push({
      pathname: "/admin/AddLeave",
      state: { id: record.original.LeaveID },
    });
  };
  Delete = (record) => {
    let data = {
      ResourceID: CommonConfig.loginData().PersonID,
      LeaveID: record.original.LeaveID,
    };
    try {
      api
        .post("leave/deleteLeave", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success(`Leave deleted successfully`);
            this.getLeaveList();
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };
  redirectAdd = () => {
    this.props.history.push("/admin/AddLeave");
  };

  handleApprovedLeave = (record, type) => {
    let data = {
      ResourceID: CommonConfig.loginData().PersonID,
      Status: type,
      LeaveID: record.original.LeaveID,
    };
    try {
      api
        .post("leave/updateLeaveStatus", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success(`Leave ` + type + ` successfully`);
            this.search();
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  handleResoureOnChange = (value) => {
    if (value !== null) {
      this.setState({ filterVal: value });
    }
  };

  handleMonthChange = (date) => {

    this.setState({
      selectedDate: startOfMonth(date),
      StartDate: startOfMonth(date),
      EndDate: endOfMonth(date),
    });

  };

  search = () => {
    this.showLoader()
    var today = new Date();


    let data;
    data = {
      ResourceID: this.state.filterVal.value,
      StartDate: this.state.StartDate
        ? this.state.StartDate
        : startOfMonth(today),
      EndDate: this.state.EndDate ? this.state.EndDate : endOfMonth(today),
    };
    try {
      api
        .post("leave/getLeaveListByResource", data)
        .then((res) => {
          this.hideLoader()
          if (res.success === true && res.data.length != 0) {
            let current = 0;
            let balance = 12;

            //Reverse the array to count the balance from the earlier date
            res.data = res.data.reverse();
            res.data.map((r) => {
              r.leaveDays =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              r.leaveDays2 =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              current =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              balance = balance - current;
              r.leaveBalance = balance;
              return r;
            });
            //Now again reverse the list to it's original state
            let data = res.data.reverse();
            this.setState({ LeaveList: data });

            this.setState({
              LeaveList: res.data,
              IsSelectAll: data.ResourceID == "" ? true : false,
            });
          } else {
            this.setState({ LeaveList: [] });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  days_passed = (startdate, enddate) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(startdate);
    const secondDate = new Date(enddate);
    return Math.round(Math.abs((firstDate - secondDate) / oneDay) + 1);
  };

  render() {
    const {
      LeaveList,
      ApproveAccess,
      selectedDate,
      IsSelectAll,
      apiCalled

    } = this.state;
    const Resource = this.state.DailyStatusList
      ? this.state.DailyStatusList.map((DailyStatus) => {
        return { value: DailyStatus.ResourceID, label: DailyStatus.FullName };
      })
      : [];
    var AlltableColumns = [
      {
        Header: "Leave Date",
        accessor: (data) => {
          return (
            <span>
              {Date.parse(data.LeaveStatDate) === Date.parse(data.LeaveEndDate)
                ? moment(data.LeaveStartDate).format("DD/MM/YYYY")
                : moment(data.LeaveStartDate).format("DD/MM/YYYY") +
                "-" +
                moment(data.LeaveEndDate).format("DD/MM/YYYY")}
            </span>
          );
        },
        id: "LeaveDate",
        width: 100,
        sortable: true,
      },

      {
        Header: "Day",
        accessor: (data) => {
          return <span>{data.LeaveDay}</span>;
        },
        id: "Day",
        width: 100,
        sortable: false,
      },
      {
        Header: "Resource",
        accessor: (data) => {
          return (
            <span>
              {data.FirstName} {data.LastName}
            </span>
          );
        },
        id: "Day2",
        width: 150,
        sortable: true,
      },
      {
        Header: "Leave Reason",
        width: 130,
        accessor: (data) => {
          return (
            <Tooltip title={data.LeaveReason} arrow>
              <span> {data.LeaveReason} </span>
            </Tooltip>
          );
        },
        id: "LeaveReason",
        sortable: true,
        className: "nowrap",
      },
      {
        Header: "Leave Type",
        width: 100,
        accessor: (data) => {
          return (
            <span>
              {Date.parse(data.LeaveStartDate) ==
                Date.parse(data.LeaveEndDate) && data.HalfLeave.data[0] == 1
                ? "Half Day"
                : Date.parse(data.LeaveStartDate) ==
                  Date.parse(data.LeaveEndDate)
                  ? "Full Day"
                  : "Multi Day"}
            </span>
          );
        },
        id: "LeaveType",
        sortable: true,
      },
      {
        Header: "Status",
        width: 90,
        accessor: "LeaveStatus",

        id: "Status",
        sortable: true,
      },


      {
        Header: "Used Leave",
        accessor: (data) => {
          return <span>{data.TotalLeaveUsed}</span>;//`${data.TotalLeaveUsed} `;
        },
        id: "TotalLeaveUsed",
        width: 100,
        sortable: true,
      },
      // {
      //   Header: "CurrentMonthLeaveUsed",
      //   accessor: (data) => {
      //     return <span>{data.CurrentMonthLeaveUsed}</span>;
      //   },
      //   id: "CurrentMonthLeaveUsed",
      //   width: 170,
      //   sortable: true,
      // },
      {
        Header: "Remaining Leave Balance",
        accessor: (data) => {
          return <span>{data.FinalBalance}</span>;
        },
        id: "FinalBalance",
        width: 170,
        sortable: true,
      },
      {
        Header: "Action",
        width: 75,
        id: "action",
        sortable: false,
        className: "text-align-right",
        Cell: (record) => {
          return (
            <span>

              {record.original.LeaveStatus === "Pending" ? (
                <span>
                  <DoneIcon
                    onClick={() => this.handleApprovedLeave(record, "Approved")}
                  />
                  <CloseIcon
                    onClick={() => this.handleApprovedLeave(record, "Rejected")}
                  />
                </span>
              ) : null}
              {record.original.LeaveStatus === "Approved" ||
                record.original.LeaveStatus === "Rejected" ||
                Date.parse(record.original.LeaveStartDate) <=
                Date.parse(new Date()) ? null : (
                <Tooltip title="Edit" placement="bottom">
                  <Link
                    to={{
                      pathname: "/admin/AddLeave",
                      state: { id: record.original.LeaveID },
                    }}
                  >
                    <Button className="btn-icon" color="primary">
                      <CreateIcon />
                    </Button>
                  </Link>
                </Tooltip>
              )}
              {Date.parse(record.original.LeaveStartDate) <=
                Date.parse(new Date()) ? null : (
                <Tooltip title="Delete" placement="bottom">
                  <Button
                    className="btn-icon"
                    color="primary"
                    onClick={() => this.Delete(record)}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              )}
            </span>
          );
        },
        filterable: false,
      },
    ];

    const tableColumns = [
      {
        Header: "Leave Date",
        accessor: (data) => {
          return (
            <span>
              {Date.parse(data.LeaveStartDate) == Date.parse(data.LeaveEndDate)
                ? moment(data.LeaveStartDate).format("DD/MM/YYYY")
                : moment(data.LeaveStartDate).format("DD/MM/YYYY") +
                "-" +
                moment(data.LeaveEndDate).format("DD/MM/YYYY")}
            </span>
          );
        },
        width: 115,
        id: "LeaveDate",
        sortable: true,
      },
      {
        Header: "Day",
        accessor: "Day",
        id: "Day",
        sortable: false,
      },
      {
        Header: "Leave Reason",
        width: 130,
        accessor: (data) => {
          return (
            <Tooltip title={data.LeaveReason} arrow>
              <span> {data.LeaveReason} </span>
            </Tooltip>
          );
        },
        id: "LeaveReason",
        sortable: true,
        className: "nowrap",
      },
      {
        Header: "Leave Type",
        accessor: (data) => {
          return (
            <span>
              {Date.parse(data.LeaveStartDate) ==
                Date.parse(data.LeaveEndDate) && data.HalfLeave.data[0] == 1
                ? "Half Day"
                : Date.parse(data.LeaveStartDate) ==
                  Date.parse(data.LeaveEndDate)
                  ? "Full Day"
                  : "Multi Day"}
            </span>
          );
        },
        id: "LeaveType",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "LeaveStatus",

        id: "Status",
        sortable: true,
      },

      {
        Header: "Used Leave",
        accessor: (data) => {
          return <span>{data.TotalLeaveUsed}</span>;
        },
        id: "TotalLeaveUsed",
        width: 150,
        sortable: true,
      },
      // {
      //   Header: "CurrentMonthLeaveUsed",
      //   accessor: (data) => {
      //     return <span>{data.CurrentMonthLeaveUsed}</span>;
      //   },
      //   id: "CurrentMonthLeaveUsed",
      //   width: 170,
      //   sortable: true,
      // },
      {
        Header: "Remaining Leave Balance",
        accessor: (data) => {
          return <span>{data.FinalBalance}</span>;
        },
        id: "FinalBalance",
        width: 170,
        sortable: true,
      },
      {
        Header: "Action",
        id: "action",
        sortable: false,
        className: "text-align-right",
        Cell: (record) => {
          return (
            <span>
              {record.original.LeaveStatus === "Approved" ||
                record.original.LeaveStatus === "Rejected" ||
                Date.parse(record.original.LeaveStartDate) <=
                Date.parse(new Date()) ? null : (
                <Tooltip title="Edit" placement="bottom">
                  <Link
                    to={{
                      pathname: "/admin/AddLeave",
                      state: { id: record.original.LeaveID },
                    }}
                  >
                    <Button className="btn-icon" color="primary">
                      <CreateIcon />
                    </Button>
                  </Link>
                </Tooltip>
              )}
              {Date.parse(record.original.LeaveStartDate) <=
                Date.parse(new Date()) ? null : (
                <Tooltip title="Delete" placement="bottom">
                  <Button
                    className="btn-icon"
                    color="primary"
                    onClick={() => this.Delete(record)}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              )}
            </span>
          );
        },
        filterable: false,
      },
    ];

    if (!IsSelectAll) {
      var index = AlltableColumns.findIndex((x) => x.id == "Resource");
      if (index > -1) {
        AlltableColumns.splice(index, 1);
      }
    }

    return (
      <div>
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}

        {/* <Card>
        <CardHeader> */}
        <GridContainer className="UserList-outer">
          <GridItem xs={12} sm={4} md={4}>
            <Tooltip title="Add" placement="bottom">
              <Link to="/admin/AddLeave">
                <Button type="button" color="primary">
                  Add Leave
                </Button>
              </Link>
            </Tooltip>
          </GridItem>
        {/*  {apiCalled && (
            <GridItem xs={12} sm={3} md={6} alignItems="flex-end">
              <GridItem xs={12} sm={4} md={4} className="float-right date-filter">
                <div className="submitted-on">
                  <span>{`Leave Balance: ${this.state.LeaveBalance ? this.state.LeaveBalance : "N/A"}`}</span>
                </div>
              </GridItem>
            </GridItem>
        )}  */}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <div className="search-resource">
              <div className="with-icon">
                <Autocomplete
                  disabled={ApproveAccess ? false : true}
                  options={Resource}
                  id="Resource"
                  value={this.state.filterVal}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => this.handleResoureOnChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={this.state.ResourceErr}
                      helperText={this.state.ResourceHelperText}
                      label="Search Resource"
                    />
                  )}
                />

                <Tooltip title="Search">
                  <Button
                    color="primary"
                    justIcon
                    onClick={() => {
                      this.search();
                      this.getLeaveBalance();
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </GridItem>
          {/* {ApproveAccess ? ( */}
          <GridItem xs={12} sm={4} md={4}>
            <div className="search-resource">
              <div className="with-icon">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk={true}
                    variant="inline"
                    openTo="year"
                    views={["year", "month"]}
                    label="Select Year and Month"
                    value={selectedDate}
                    onChange={(e) => this.handleMonthChange(e)}
                  />
                </MuiPickersUtilsProvider>
                <Tooltip title="Search">
                  <Button
                    color="primary"
                    justIcon
                    onClick={() => this.search()}
                  >
                    <SearchIcon />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <ReactTable
          data={LeaveList}
          minRows={2}
          resizable={false}
          columns={ApproveAccess ? AlltableColumns : tableColumns}
          defaultPageSize={10}
          style={{ cursor: "pointer" }}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

ListView.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(ListView);
