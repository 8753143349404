import React, { Component } from 'react';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import Tooltip from '@material-ui/core/Tooltip';
import cogoToast from 'cogo-toast';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageUploader from 'components/CustomUpload/PictureUpload';
import PropTypes from "prop-types";
// @material-ui/icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Bank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
        Email: "",
        EmailErr: false,
        EmailHelperText: "",
    
        Salt:'',
        SaltErr:false,
        SaltHelperText:'',
  
        BankName:'',
        BankNameErr:false,
        BankNameHelperText:'',
  
        AccountNo:'',
        AccountNoErr:false,
        AccountNoHelperText:'',
  
        IFSCCode:'',
        IFSCCodeErr:false,
        IFSCCodeHelperText:'',
        
        SuffixList: [],
        AddressList: [],
        TiltleList: [],      
          
        cubiclepositionList:[],
        viewBankList: [],
    };
  }

  componentDidMount() {
       this.getEditData();
  }

  getEditData() {
    let data = {
      ResourceID: this.props.editprops.editid
    };

    try {
      api.post("resource/getResourceByID", data).then(res => {
        if (res.success) {
          
          if (res.data.BankDetail != null && res.data.BankDetail.length > 0) {
            var i = 0;
            res.data.BankDetail.map(Obj => {
              Obj.Index = i;
              i++;
              return Obj;
            })
            this.setState({ viewBankList: res.data.BankDetail });
          }

          this.setState({

            FirstName: res.data.FirstName ? res.data.FirstName : '',
            MiddleName: res.data.MiddleName ? res.data.MiddleName : '',
            LastName: res.data.LastName ? res.data.LastName : '',
            Title: res.data.Title ? res.data.Title : '',
            Suffix: res.data.Suffix ? res.data.Suffix : '',
            Designation: res.data.Designation ? res.data.Designation : '',
            AddressType: res.data.AddressType ? res.data.AddressType : '',
            ResourceCode: res.data.ResourceCode ? res.data.ResourceCode : '',
            Line1: res.data.Line1 ? res.data.Line1 : '',
            Line2: res.data.Line2 ? res.data.Line2 : '',
            Line3: res.data.Line3 ? res.data.Line3 : '',
            PAN: res.data.PAN ? res.data.PAN : '',
            Passport: res.data.Passport ? res.data.Passport : '',
            ContactNumber: res.data.ContactNumber ? res.data.ContactNumber : '',
            AdharNumber: res.data.AadharNumber ? res.data.AadharNumber : '',
            Gender: res.data.Gender ? res.data.Gender : '',
            DOB: res.data.DOB? moment(res.data.DOB).format("DD/MM/YYYY"): '',
            Email: res.data.Email ? res.data.Email : '',
            CountryID: res.data.CountryID ? res.data.CountryID: '',
            Password: res.data.Password ? res.data.Password : '',
            Department: res.data.Department ? res.data.Department : '',
            ResourceID: res.data.ResourceID ? res.data.ResourceID : '',
            JoiningDate: res.data.JoiningDate ? moment(res.data.JoiningDate).format("DD/MM/YYYY") : '',
            YearlyLeave: res.data.LeaveBalance ? res.data.LeaveBalance : '',
            ComputerName: res.data.ComputerName ? res.data.ComputerName : '',
            Salt: res.data.Salt ? res.data.Salt : '',
            CubiclePosition: res.data.CubiclePosition ? res.data.CubiclePosition : '',
            IPAddress: res.data.IPAddress ? res.data.IPAddress : '',
          });
          if(res.data.CountryID){
            try {
              api.get("/country/getCountry").then(country => {
                if (country.success) {
                  this.setState({ CountryList: country.data,Country:{
                    value:country.data.filter(x=>x.CountryID == res.data.CountryID)[0].CountryID,
                    label : country.data.filter(x=>x.CountryID == res.data.CountryID)[0].CountryName
                  } });
                }
                else {
                  cogoToast.error("Something went wrong");
                }
              }).catch(err => {
                console.log(err)
              })
            } catch (err) {
            }
          }
        } else {
          cogoToast.error( 'Something went wrong')
        }

      }).catch(error => {
      })

    }
    catch (err) {
    }
}

  
  submit = (e) => {
    const {
      FirstName, MiddleName,LastName,Designation,AddressType,ResourceCode,AdharNumber,PAN,Passport,Gender,Line1,Line2,
      Line3,DOB,Email,Password,Department,Country,IPAddress,JoiningDate,Salt,AppraisalDate,Title,Suffix,ComputerName,CubiclePosition,YearlyLeave
    } = this.state;
    
    if (this.validate()) {
      let data = {
        UserID: CommonConfig.loginData().PersonID,
        FirstName: FirstName,
        MiddleName: MiddleName,
        LastName: LastName,
        Title : Title,
        Suffix: Suffix,
        Designation: Designation,
        AddressType: AddressType,
        ResourceCode: ResourceCode,
        Line1: Line1,
        Line2: Line2,
        Line3: Line3,
        PAN: PAN,
        Passport: Passport,
        AdharNumber: AdharNumber,
        Gender: Gender,
        DOB:
          CommonConfig.isEmpty(DOB) != true
            ? moment(DOB).format("YYYY-MM-DD").toString()
            : null,
        Email: Email,
        Password: Password,
        Department: Department,
        CountryID: Country.value,
        ResourceID: 0,
        JoiningDate: moment(JoiningDate).format("YYYY-MM-DD").toString(),
        AppraisalDate: moment(AppraisalDate).format("YYYY-MM-DD").toString(),
        YearlyLeave: YearlyLeave,
        ComputerName: ComputerName,
        Salt: Salt,
        CubiclePosition: CubiclePosition,
        IPAddress: IPAddress,
        BankDetail: this.state.viewBankList.filter(x => x.Status === 'Active')
      };

      try {
        api
          .post("resource/addResourceBasic", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success("Resource added successfully");
              this.props.history.push("/admin/resourceListing");
            } else {
              cogoToast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (err) {
        console.log("error", err);
      }
    }

  };
  onChange = (e,type) => {
    if (type === "BankName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          BankName: e.target.value,
          BankNameErr: true,
          BankNameHelpertext: "Please Enter BankName",
        });
      } else {
        this.setState({
          BankName: e.target.value,
          BankNameErr: false,
          BankNameHelpertext: "",
        });
      }
    }
  }

  addCancel = () => {
    this.props.history.push("/admin/resourceListing");
  };

  addBankDetails = (e) => {
    const { BankName,AccountNo,IFSCCode } = this.state;
    if(BankName && AccountNo && IFSCCode){
      var addBankDetail = this.state.viewBankList.filter(x => (x.Status === "Active" && (x.BankName === BankName) && (x.AccountNo === AccountNo) && (x.IFSCCode === IFSCCode)))
      
      if (addBankDetail.length === 0) {
        let data = {
          CreatedBy: CommonConfig.loginData().PersonID,
          BankName: this.state.BankName,
          AccountNo: this.state.AccountNo,
          IFSCCode: this.state.IFSCCode,
          Index: this.state.viewBankList.length + 1,
          IsEdit: false,
          Status: 'Active'
        }
        this.setState({ viewBankList: [...this.state.viewBankList, data] ,BankName:'', BankNameErr:false, BankNameHelperText:'', AccountNo:'', AccountNoErr:false, AccountNoHelperText:'', IFSCCode:'', IFSCCodeErr:false, IFSCCodeHelperText:'',});
      }
      else {
        cogoToast.error("Bank Details Already exist");
      }
    }
    else{
      if(CommonConfig.isEmpty(BankName)){
        this.setState({BankNameErr:true,BankNameHelperText:'Please Enter Bank Name'})
      }
      if(CommonConfig.isEmpty(AccountNo)){
        this.setState({AccountNoErr:true,AccountNoHelperText:'Please Enter Account No'})
      }
      if(CommonConfig.isEmpty(IFSCCode)){
        this.setState({IFSCCodeErr:true,IFSCCodeHelperText:'Please Enter IFSC Code'})
      }
    }
  }

  editBankDetails = (idx) => {
    const editBank = [...this.state.viewBankList]
    var Index = this.state.viewBankList.findIndex(x => x.Index === idx);
    if (Index !== -1) {
      editBank[Index]["IsEdit"] = true;
      this.setState({ viewBankList: editBank })
    }
  }

  removeBankDetails = (idx) => {
    const removeBank = [...this.state.viewBankList]
    var Index = this.state.viewBankList.findIndex(x => x.Index === idx);
    if (Index !== -1) {
      removeBank[Index]["Status"] = "Inactive";
      this.setState({ viewBankList: removeBank })
    }
  }

  viewBankList = () => {
    return this.state.viewBankList.filter(x => x.Status === 'Active').map((content,index) => {
      return (
        content.IsEdit == false?
        <GridContainer className="ml-auto">
          <GridItem xs={4}>
            <div className="payment-card">
              <div className="card-base border-primary">
                  <div className="d-flex justify-content-between">
                      <h5 className="fw-bold">Account No.{content.AccountNo}</h5>
                      <div className="list-action">
                          <button type="button" className="userProfile-wrapper" onClick={()=> this.editBankDetails(content.Index)}>
                              <i className="fa fa-pencil-alt"></i>
                          </button>
                          <button type="button" className="userProfile-wrapper" onClick={() => this.removeBankDetails(content.Index)}>
                              <i className="fa fa-trash"></i>
                          </button>
                      </div>
                  </div>
                  <address>
                      <span></span>
                      <span><strong>BANK NAME - </strong> {content.BankName}</span>
                      {/* <span>BRANCH NAME</span> */}
                      <span><strong>IFSC CODE - </strong> {content.IFSCCode}</span>
                  </address>
              </div>
            </div>
            </GridItem>
            {/* <GridItem xs={3}>
            <Button onClick={() => this.removeBankDetails(content.Index)} color="primary"><DeleteIcon/></Button>
          </GridItem> */}
        </GridContainer>
        :
        <GridContainer className="ml-auto">
        <GridItem xs={3}>
          <FormControl fullWidth label="Bank Name">
            <TextField
              value={content.BankName}
              prefix={
                <Icon
                  type="user"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              label="Bank Name"
              onChange={(e) => {
                let editBank = [...this.state.viewBankList];
                var Index = this.state.viewBankList.findIndex(x => x.Index === content.Index);
                if (Index !== -1) {
                  editBank[Index]["BankName"] = e.target.value;
                  this.setState({ viewBankList: editBank })
                }
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth label="Account Number">
            <TextField
              value={content.AccountNo}
              prefix={
                <Icon
                  type="user"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              label="Account Number"
              onChange={(e) => {
                const editBank = [...this.state.viewBankList]
                var Index = this.state.viewBankList.findIndex(x => x.Index === content.Index);
                if (Index !== -1) {
                  editBank[Index]["AccountNo"] = e.target.value;
                  this.setState({ viewBankList: editBank })
                }
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth label="IFSC Code">
            <TextField
              value={content.IFSCCode}
              prefix={
                <Icon
                  type="user"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              }
              label="IFSC Code"
              onChange={(e) => {
                const editBank = [...this.state.viewBankList]
                var Index = this.state.viewBankList.findIndex(x => x.Index === content.Index);
                if (Index !== -1) {
                  editBank[Index]["IFSCCode"] = e.target.value;
                  this.setState({ viewBankList: editBank })
                }
              }}
            />
          </FormControl>
        </GridItem>
        
        <GridItem xs={3}>
        <Tooltip title="Update" placement="bottom">
          <Button
            color="primary"
            onClick={(e) => {
              
                let editBank = [...this.state.viewBankList]
                var Index = this.state.viewBankList.findIndex(x => x.Index === content.Index);
                if (Index !== -1) {
                  if(CommonConfig.isEmpty(content.BankName) && CommonConfig.isEmpty(content.AccountNo) && CommonConfig.isEmpty(content.IFSCCode)){
                    cogoToast.error('Fields cannot be empty.')
                  }else{
                    editBank[Index]["IsEdit"] = false;
                    this.setState({ viewBankList: editBank })
                  }
                }
              }
            }
          >
            Update
          </Button>
         
        </Tooltip>
        <Tooltip title="Cancel" placement="bottom">
        <Button
            color="primary"
            onClick={(e) => {
              
                let editBank = [...this.state.viewBankList]
                var Index = this.state.viewBankList.findIndex(x => x.Index === content.Index);
                if (Index !== -1) {
                    editBank[Index]["IsEdit"] = false;
                    this.setState({ viewBankList: editBank })
                }
              }
            }
          >
            Cancel
          </Button>
          </Tooltip>
        </GridItem>
      
      </GridContainer>
      )
    })
  }


  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    return true;
  }
  render() {
    const { classes } = this.props;
    // const countryList = this.state.CountryList.map(country => { return { value:country.CountryID , label:country.CountryName }})

    const {BankName,AccountNo,IFSCCode,IFSCCodeErr,AccountNoErr,BankNameErr,AccountNoHelperText,BankNameHelperText,IFSCCodeHelperText} = this.state;
    return (
      <GridContainer className="UserList-outer">
      <GridItem xs={12} sm={12} md={12}>
      <GridContainer className="ml-auto">
              <GridItem xs={3}>
                <FormControl fullWidth label="Bank Name">
                  <TextField
                    helperText={BankNameHelperText}
                    error={BankNameErr}
                    value={BankName}
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="Bank Name"
                    onChange={(e) => this.setState({BankName: e.target.value})}
                    onFocus={() => this.setState({BankNameHelperText:"", BankNameErr:false})}
                    onBlur={(e) => this.onChange(e, "BankName")}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth label="Account Number">
                  <TextField
                    helperText={AccountNoHelperText}
                    error={AccountNoErr}
                    value={AccountNo}
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="Account Number"
                    onChange={(e) => this.setState({AccountNo: e.target.value})}
                    onFocus={() => this.setState({AccountNoHelperText:"", AccountNoErr:false})}
                    onBlur={(e) => this.onChange(e, "AccountNo")}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth label="IFSC Code">
                  <TextField
                    helperText={IFSCCodeHelperText}
                    error={IFSCCodeErr}
                    value={IFSCCode}
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="IFSC Code"
                    onChange={(e) => this.setState({IFSCCode: e.target.value})}
                    onFocus={() => this.setState({IFSCCodeHelperText:"", IFSCCodeErr:false})}
                    onBlur={(e) => this.onChange(e, "IFSCCode")}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={3}>
              <Tooltip title="Add" placement="bottom">
                <Button
                  color="primary"
                  onClick={(e) => this.addBankDetails(e)}
                >
                  Add Bank Details
                </Button>
              </Tooltip>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {this.viewBankList()}
            </GridContainer>
            
      </GridItem>
    </GridContainer>
    );
  }
}

Bank.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Bank);
