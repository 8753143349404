import React, { Component } from 'react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import api from '../../utils/apiClient';
import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
class projectdomainconfiglisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectdomainconfigList: [],
      searchText: "",
      searchedColumn: ""
    }
  }

  componentDidMount() {
    this.getProjectDomainConfigList();
  }

  getProjectDomainConfigList = () => {
    try {
      api.get("projectdomainconfig/getProjectDomainConfigList").then(res => {
        this.setState({ projectdomainconfigList: res.data });
      }).catch(error => {
      })

    }
    catch (err) {
    }
  }

//   edit = (record) => {
//     this.props.history.push({
//       pathname: "/admin/EditProjectInstance",
//       state: { id: record.original.ProjectInstanceID }
//     })
//   }

  redirectAdd = () => {
    this.props.history.push("/admin/addProjectDomainConfig");
  }

  render() {
    const { projectdomainconfigList } = this.state;
    const tableColumns = [
      {
        Header: 'Server Name',
        accessor: 'PanelName',
        id: 'PanelName',
        sortable: true,
      },
      {
        Header: 'Project Name',
        accessor: 'ProjectName',
        id: 'ProjectName',
        sortable: true,
      },
      {
        Header: 'cPanel UserName',
        accessor: 'UserName',
        id: 'UserName',
        sortable: true,
      },
      {
        Header: 'cPanel Password',
        accessor: 'Password',
        id: 'Password',
        sortable: true,
      },
      {
        Header: 'Domain & SubDomain',
        accessor: 'DomainName',
        id: 'DomainName',
        sortable: true,
      },
      {
        Header: 'Comment',
        accessor: 'Comment',
        id: 'Comment',
        sortable: true,
      },
    ]
    return (
      <Card>
        <CardHeader>
        <Tooltip title="Add" placement="bottom">
          <Button type="button" color="primary" onClick={() => this.redirectAdd()}>Add Project Domain Configuration</Button></Tooltip>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={projectdomainconfigList}
            filterable
            minRows={2}
            resizable={false}
            columns={tableColumns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
    )
  }
}
export default projectdomainconfiglisting;