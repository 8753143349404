import React, { Component } from 'react'
import { AddFederalLeave } from './AddFederalLeave'
import Wizard from "components/Wizard/Wizard.js";
import FederalListView from './FederalListView';

export class FederalLeaveList extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
        }
    }
    
    render() {
        return (
            <Wizard
              // validate
              steps={[
                { stepName: "Federal Leave List", stepComponent: FederalListView, stepId: "FederalLeaveList"},
                // { stepName: "Leave Calendar", stepComponent: LeaveCalendar, stepId: "LeaveCalendar" },
              ]}
              title=""
              color="primary"
              nextButtonVisible={false}
              // subtitle="This information will let us know more about you."
              finishButtonClick={e => this.submit(e)}
            />
        )
    }
}

export default FederalLeaveList
