import React, { Component } from 'react';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import cogoToast from 'cogo-toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import CardIcon from "components/Card/CardIcon.js";
import Datetime from "react-datetime";
import Card from "components/Card/Card.js";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CardBody from "components/Card/CardBody.js";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import Icon from "@material-ui/core/Icon";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import { FormHelperText, InputLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { truncate } from 'fs';

class addClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientCode: "",
      clientcodeErr: false,
      clientcodeHelperText: "",

      ClientName: "",
      clientnameErr: false,
      clientnameHelperText: "",

      ClientManager: [],
      clientmanagerErr: false,
      clientmanagerHelperText: "",
      ClientManagerList: [],

      Country: "",
      countryErr: false,
      countryHelperText: "",
      CountryList: [],

      Industry: "",
      industryErr: false,
      industryHelperText: "",

      Description: "",
      descriptionErr: false,
      descriptionHelperText: "",

      Email: '',
      emailErr: false,
      emailHelperText: '',

      ContactNo: '',
      contactNoErr: false,
      contactNoHelperText: '',

      startdate: '',
      startdateErr: false,
      startdateHelperText: '',

      loggedUserID: 0,

      TypeList: [],
    };
  }

  componentDidMount() {
    this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
    this.getCountry();
    this.getManagerList();
    this.getTypeList();
  }

  getTypeList = () => {
    let data = {
      StringMapType: "INDUSTRY"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ TypeList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getManagerList = () => {
    try {
      api.get("/client/getProjectManagerList").then(res => {
        if (res.success) {
          this.setState({ ClientManagerList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {

    }
  }
  getCountry = () => {
    try {
      api.get("/country/getCountry").then(response => {
        if (response.success) {
          this.setState({ CountryList: response.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    }
    catch (err) {
      console.log("error", err);
    }
  }

  onChange = (e, type) => {
    let val = e.target.value
    if (type === "clientcode") {

      if (CommonConfig.isEmpty(val)) {
        this.setState({ ClientCode: val, clientcodeHelperText: "Please enter Client Code", clientcodeErr: true });
      } else {
        this.setState({ ClientCode: val, clientcodeHelperText: "", clientcodeErr: false });
      }
    }
    else if (type === "clientname") {

      if (CommonConfig.isEmpty(val)) {
        this.setState({ ClientName: val, clientnameHelperText: "Please enter Client Name", clientnameErr: true });
      } else {
        this.setState({ ClientName: val, clientnameHelperText: "", clientnameErr: false });
      }
    }
    else if (type === "description") {
      this.setState({ Description: val, descriptionHelperText: "", descriptionErr: false });
    }
    else if (type === "email") {
      if (CommonConfig.isEmpty(val)) {
        this.setState({ Email: e.target.value, emailErr: true, emailHelperText: 'Please enter Email OR Contact No' });
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({ Email: e.target.value, emailErr: true, emailHelperText: 'Please enter valid Email' });
      } else {
        this.setState({ Email: e.target.value, emailErr: false, emailHelperText: '' });
      }
    }
    else if (type === "contactNo") {
      if (CommonConfig.isEmpty(val)) {
        this.setState({ ContactNo: val, contactNoHelperText: "Please enter Email OR Contact No", contactNoErr: true });
      } else if (!val.match(CommonConfig.RegExp.phoneNumber)) {
        this.setState({ ContactNo: val, contactNoErr: true, contactNoHelperText: 'Please enter valid Contact Number' });
      } else {
        this.setState({ ContactNo: val, contactNoHelperText: "", contactNoErr: false });
      }
    }

  }

  validate = (e) => {
    let IsValid = true;
    const { ClientCode, Email, ContactNo, ClientName, ClientManager, Country, Industry } = this.state;
    if (CommonConfig.isEmpty(ClientCode)) {
      IsValid = false;
      this.setState({ clientcodeErr: true, clientcodeHelperText: "Please enter Client Code" });
    }
    if (CommonConfig.isEmpty(ClientName)) {
      IsValid = false;
      this.setState({ clientnameErr: true, clientnameHelperText: "Please enter Client Name" });
    }
    if (!ClientManager.length > 0) {
      IsValid = false;
      this.setState({ clientmanagerErr: true, clientmanagerHelperText: "Please select Client Manager" });
    }
    if (CommonConfig.isEmpty(Email || ContactNo)) {
      IsValid = false;
      this.setState({ emailErr: true , emailHelperText:"Please enter Email ID", contactNoErr:true, contactNoHelperText:"Please enter Contact No" });
    }
    // if (!Email.match(CommonConfig.RegExp.email)) {
    //   IsValid = false;
    //   this.setState({emailErr: true, emailHelperText: 'Please enter valid Email' });
    //  }

    //  if (!ContactNo.match(CommonConfig.RegExp.phone)) {
    //   IsValid = false;
    //   this.setState({contactNoErr: true, contactNoHelperText: 'Please enter valid Contact No' });
    //  }

    // if(CommonConfig.isEmpty(ContactNo)){
    //   IsValid = false;
    //   this.setState({contactNoErr:true , contactNoHelperText:"Please select Contact No"});
    // }
    if (CommonConfig.isEmpty(Industry)) {
      IsValid = false;
      this.setState({ industryErr: true, industryHelperText: "Please select Industry" });
    }
    if (CommonConfig.isEmpty(Country)) {
      IsValid = false;
      this.setState({ countryErr: true, countryHelperText: "Please select Country" });
    }

    return (IsValid);
  }

  submit = (e) => {
    const { ClientCode, Email, ContactNo, ClientName, ClientManager, loggedUserID, Country, Industry, Description, StartDate, EndDate } = this.state;
    console.log(this.state);
    //  if (!e.target.value.match(CommonConfig.RegExp.email)) {
    //   this.setState({ Email: e.target.value, emailErr: true, emailHelperText: 'Please enter valid Email' });
    //  }
    if (this.validate()) {
      let data = {
        ClientCode: ClientCode,
        ClientName: ClientName,
        ClientManager: ClientManager.map(x => { return x.ResourceID }),
        CountryID: Country.value,
        Industry: Industry,
        Description: Description,
        UserID: loggedUserID,
        ClientID: null,
        Email: Email,
        ContactNo: ContactNo,
        PhoneID: null,
        EmailID: null,
        StartClientDate: moment(StartDate).startOf('day').format("YYYY-MM-DD HH:mm:ss").toString(),

      }
      // if (!Email.match(CommonConfig.RegExp.email)) {
      //   this.setState({ Email: Email, emailErr: true, emailHelperText: 'Please enter valid Email' });
      // }
      try {
        api.post("client/addClient", data).then(res => {
          if (res.success) {
            cogoToast.success("Client added successfully");
            this.props.history.push("/admin/clientlisting");
          }
        }).catch(err => {
          console.log(err)
        });
      }
      catch (err) {
        console.log(err);
      }
    }
    else {
      
      if((Email === undefined || Email === null || Email === '')&&(ContactNo === undefined || ContactNo === null || ContactNo === '')&&
      ((!CommonConfig.isEmpty(ClientCode && ClientName &&  ClientManager && Country.value && Industry )))){
       
        cogoToast.error("Please Enter Anyone Of Below Field");
    }else{
        cogoToast.error("Please correct error and resubmit the form");
      }
    }
  }

    selectChange = (e, type) => {
      if (type === "industry") {
        this.setState({ Industry: e.target.value, industryErr: false, industryHelperText: "" });
      }
      else if (type === "clientmanager") {
        this.setState({ ClientManager: e, clientmanagerHelperText: "", clientmanagerErr: false });
      }
    }

    requestChange = (e, value, type) => {
      if (type === "country") {
        this.setState({ Country: value, countryErr: false, countryHelperText: "" });
      }
    }

    handleDate = (date, type) => {
      if (type === "startdate") {
        this.setState({ StartDate: date });
      }
    }

    typelist = () => {
      return this.state.TypeList.map(content => {
        return (
          <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
        )
      })
    }

    cancel = () => {
      this.props.history.push("/admin/clientlisting");
    }


    render(){
      const countryList = this.state.CountryList.map(country => { return { value: country.CountryID, label: country.CountryName } })

      const { ClientCode, clientcodeErr, clientcodeHelperText, ClientName, clientnameErr,
        clientnameHelperText, ClientManager, clientmanagerErr, clientmanagerHelperText,
        Country, countryErr, countryHelperText, Industry, industryErr, industryHelperText,
        Description, StartDate, startdateErr, startdateHelperText, Email, emailErr, emailHelperText,
        ContactNo, contactNoErr, contactNoHelperText, ClientManagerList } = this.state;
      return (
        <GridContainer className="UserList-outer">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="btn-right-outer" color="primary" icon>
                <CardIcon color="primary">
                  <HeadsetMic />
                </CardIcon>
                <h4>Client</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <TextField
                        value={ClientCode}
                        error={clientcodeErr}
                        helperText={clientcodeHelperText}
                        label="Client Code"
                        onChange={(e) => this.onChange(e, "clientcode")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <TextField
                        value={ClientName}
                        error={clientnameErr}
                        helperText={clientnameHelperText}
                        label="Client Name"
                        onChange={(e) => this.onChange(e, "clientname")}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <Autocomplete
                      multiple
                      options={ClientManagerList}
                      id="ClientManager"
                      value={ClientManager}
                      getOptionLabel={option => option.ResourceID ? option.FirstName + " " + option.LastName : option}
                      onChange={(e, value) => this.selectChange(value, "clientmanager")}
                      renderInput={params => (
                        <TextField {...params} error={clientmanagerErr} helperText={clientmanagerHelperText}
                          label="Client Manager" />)}
                    />

                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={countryList}
                        id="country"
                        getOptionLabel={option => option.label}
                        value={Country}
                        onChange={(event, value) => this.requestChange(event, value, "country")}
                        renderInput={params => (
                          <TextField {...params} error={countryErr} helperText={countryHelperText}
                            label="Country" margin="normal" />)} />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl fullWidth error={industryErr}>
                      <InputLabel>Industry</InputLabel>
                      <Select onChange={(e) => this.selectChange(e, "industry")} value={Industry} >
                        {this.typelist()}
                      </Select>
                      <FormHelperText>{industryHelperText}</FormHelperText>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <TextareaAutosize
                        value={Description}
                        rowsMin={5}
                        cols={90}
                        placeholder="Description"
                        // error={descriptionErr}
                        // helperText={descriptionHelperText}
                        label="Description"
                        onChange={(e) => this.onChange(e, "description")}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <Datetime
                        dateFormat={"MM/DD/YYYY"}
                        timeFormat={false}
                        className="input-group"
                        selected={moment(StartDate)}
                        inputProps={{ placeholder: "Start Date" }}
                        onChange={(date) => this.handleDate(date, "startdate")}
                        closeOnSelect={true}
                        renderInput={params => (
                          <TextField
                            error={startdateErr}
                            helperText={startdateHelperText}
                            {...params} label="Start Date" margin="normal" />)} />
                      <Icon className="date-icon tp-slam">date_range</Icon>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      value={Email}
                      error={emailErr}
                      helperText={emailHelperText}
                      label="Email"
                      onChange={(e) => this.onChange(e, "email")}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <TextField
                      value={ContactNo}
                      error={contactNoErr}
                      helperText={contactNoHelperText}
                      label="Contact No"
                      onChange={(e) => this.onChange(e, "contactNo")}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <div className="submit-btn">
            <Tooltip title="Save" placement="bottom">
              <Button color="primary" type="submit" onClick={(e) => this.submit(e)} className="width-150 mr-4 M-right-10" >
                Save
              </Button>
            </Tooltip>
            <Tooltip title="Cancel" placement="bottom">
              <Button color="secondary" type="submit" onClick={(e) => this.cancel(e)} className="width-150 mr-4" >
                Cancel
              </Button>
            </Tooltip>
          </div>
        </GridContainer>
      );
    }
  }
 
export default addClient;