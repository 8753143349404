import React, { Component } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import api from '../../utils/apiClient';
import Tooltip from '@material-ui/core/Tooltip';
import { CommonConfig } from '../../utils/constant';
import Card from "components/Card/Card.js";
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import SimpleBackdrop from "../../utils/general";
class clientlisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
    }
  }

  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }

  componentDidMount() {
    this.getClientList();
  }

  getClientList = () => {
    this.showLoader()
    try {
      api.get("client/getClientList").then(res => {
        if (res.success) {
          this.hideLoader()
          this.setState({ clientList: res.data });
        }
      }).catch(error => {
      })

    }
    catch (err) {
    }
  }

  edit = (record) => {
    this.props.history.push({
      pathname: "/admin/EditClient",
      state: { id: record.original.ClientID }
    })
  }

  redirectAdd = () => {
    this.props.history.push("/admin/addClient");
  }

  render() {
    const { clientList } = this.state;
    const tableColumns = [
      {
        Header: 'Client Code',
        accessor: 'ClientCode',
        id: 'ClientCode',
        sortable: true,
      },
      {
        Header: 'Client Name',
        accessor: 'ClientName',
        id: 'ClientName',
        sortable: true,
      },
      {
        Header: 'Country',
        accessor: 'CountryName',
        id: 'CountryName',
        sortable: true,
      },
      {
        Header: 'Status',
        accessor: 'Status',

        id: 'Status',
        sortable: true,
      },
      {
        Header: 'Action',
        id: 'action',
        sortable: false,
        className: "text-align-right",
        Cell: record => {
          return (
            <span>
              <Tooltip title="Edit" placement="bottom">
                <Button className="btn-icon" justIcon color="primary" onClick={() => this.edit(record)}><CreateIcon /></Button>
              </Tooltip>
            </span>
          )
        },
        filterable: false
      },
    ]
    return (
      <div class="page-outer">
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        <div className="page-actions">
          <Tooltip title="Add" placement="bottom">
            <Button type="button" size="sm" color="primary" onClick={() => this.redirectAdd()}>Add Client</Button>
          </Tooltip>
        </div>
        <div className="page-header">
          <h4>Client</h4>
        </div>
        <div className="page-content">
          <div className="common-datatable">
            <ReactTable
              data={clientList}
              filterable
              minRows={2}
              defaultFilterMethod={CommonConfig.filterCaseInsensitive}
              resizable={false}
              columns={tableColumns}

              defaultPageSize={10}
              className="-striped -highlight"
            />
          </div>
        </div>
      </div>
    )
  }
}
export default clientlisting;