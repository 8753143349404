import React, { Component } from 'react'
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// material ui icons
import Contacts from "@material-ui/icons/Contacts";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { CommonConfig } from '../../utils/constant';
import CardBody from "components/Card/CardBody.js";
import ImageUploader from 'components/CustomUpload/PictureUpload';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import cogoToast from 'cogo-toast';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import ReactTable from "react-table";
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
// style for this view
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Grid } from '@material-ui/core';
import { parse } from 'date-fns';

const style = {
    ...customSelectStyle
};

class editSalaryMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            EmployeeCode: "",
            EmployeeCodeErr: false,
            EmployeeCodeHelperText: "",

            EmployeeName: "",
            EmployeeNeErr: false,
            EmployeeNameHelperText: "",

            Designation: "",
            DesignationErr: false,
            DesignationHelperText: "",
            DesignationList: [],

            Amountlimit: "",
            AmountlimitErr: false,
            AmountlimitHelperText: "",

            AllowanceAmountlimit: "",
            AllowanceAmountlimitErr: false,
            AllowanceAmountlimitHelperText: "",

            BasicSalary: "",
            BasicSalaryErr: false,
            BasicSalaryHelperText: "",

            Reimbursement: "",
            ReimbursementErr: false,
            ReimbursementHelperText: "",

            BankName: "",
            BankNameErr: false,
            BankNameHelperText: "",

            PaymentMode: "",
            PaymentModeErr: false,
            PaymentModeHelperText: "",

            AllowanceType: "",
            AllowanceTypeErr: false,
            AllowanceTypeHelperText: "",
            AllowanceTypeList: [],

            PaymentModeList: [],
            AllowanceSummary: [],
            BankNameList: [],

            isTaxExempt: false,
            FixTDS: false,
            IsESICApplicable: false,
            isPFApplication: false,
            isTDSApplication: false,
            isLeaveNotApplicable: false,

            isChecked: false,
            allowanceList: [],
            AllowanceTotal:0
        }
    }

    componentDidMount() {
        this.getDesignationList();
        this.getAllowaneTypeList();
        this.getPaymentMode();
        this.getBankName();
        this.getPayrollData();
    }

    getPayrollData = () => {
        let data = {
            PayrollID: this.props.location.state.id
        };
            
        try {
            api.post("payroll/getPayrollByID", data).then(res => {
            if (res.success) {
                
                if (res.data.AllowanceList != null && res.data.AllowanceList.length > 0) {
                    var i = 0;
                    res.data.AllowanceList.map(Obj => {
                        Obj.Index = i;
                        i++;
                        return Obj;
                    })
                    this.setState({ AllowanceSummary: res.data.AllowanceList });
                }
    
                this.setState({
    
                EmployeeCode: res.data.EmployeeCode ? res.data.EmployeeCode : '',
                EmployeeName: res.data.EmployeeName ? res.data.EmployeeName : '',
                Amountlimit: res.data.AmountLimit ? res.data.AmountLimit : '',
                BasicSalary: res.data.BasicSalary ? res.data.BasicSalary : '',
                BankName: res.data.BankName ? res.data.BankName : '',
                PaymentMode: res.data.MethodOfPayment ? res.data.MethodOfPayment : '',
                FixTDS: res.data.IsTDSFixed ? res.data.IsTDSFixed : 0,
                IsESICApplicable: res.data.IsESICApplicable?res.data.IsESICApplicable:0,
                isPFApplication: res.data.IsPFApplicable ?data.IsPFApplicable:0,
                isTDSApplication:res.data.IsTDSApplicable?res.data.IsTDSApplicable:0,
                isLeaveNotApplicable:res.data.IsLeaveNotApplicable?res.data.IsLeaveNotApplicable:0,
                });
                
            } else {
                cogoToast.error('Something went wrong')
            }
    
            }).catch(error => {
            })
    
        }
        catch (err) {
        }
    }

    getDesignationList = () => {
        let data = {
            StringMapType: "DESIGNATION"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ DesignationList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getAllowaneTypeList = () => {
        let data = {
            StringMapType: "ALLOWANCETYPE"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ AllowanceTypeList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getPaymentMode = () => {
        let data = {
            StringMapType: "PAYMENTMODE"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ PaymentModeList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    };

    getBankName = () => {
        let data = {
            StringMapType: "BANKNAME"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ BankNameList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    };

    onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }

    selectChange = (e, type) => {
        if (type === "Designation") {
            this.setState({ Designation: e.target.value });
        }
        if (type === "AllowanceType") {
            this.setState({ AllowanceType: e.target.value });
        }
        if (type === "PaymentMode") {
            this.setState({ PaymentMode: e.target.value });
        }
        if (type === "BankName") {
            this.setState({ BankName: e.target.value });
        }
    }

    onChange = (e, type) => {
        if (type === "EmployeeCode") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ EmployeeCode: e.target.value, EmployeeCodeErr: true, EmployeeCodeHelperText: "Please enter EmployeeCode" });
            }
            else {
                this.setState({ EmployeeCode: e.target.value, EmployeeCodeErr: false, EmployeeCodeHelperText: "" });
            }
        }
        if (type === "EmployeeName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ EmployeeName: e.target.value, EmployeeNameErr: true, EmployeeNameHelperText: "Please enter EmployeeName" });
            }
            else {
                this.setState({ EmployeeName: e.target.value, EmployeeNameErr: false, EmployeeNameHelperText: "" });
            }
        }
        if (type === "Amountlimit") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Amountlimit: e.target.value, AmountlimitErr: true, AmountlimitHelperText: "Please enter Amountlimit" });
            }
            else {
                this.setState({ Amountlimit: e.target.value, AmountlimitErr: false, AmountlimitHelperText: "" });
            }
        }
        if (type === "AllowanceAmountlimit") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ AllowanceAmountlimit: e.target.value, AllowanceAmountlimitErr: true, AllowanceAmountlimitHelperText: "Please enter Amount limit" });
            }
            else {
                this.setState({ AllowanceAmountlimit: e.target.value, AllowanceAmountlimitErr: false, AllowanceAmountlimitHelperText: "" });
            }
        }
    }

    validate = () => {
        let Isvalid = true;
        const { EmployeeCode, EmployeeName, Amountlimit, BasicSalary, Reimbursement } = this.state;

        if (CommonConfig.isEmpty(EmployeeCode)) {
            Isvalid = false;
            this.setState({ EmployeeCodeErr: true, EmployeeCodeHelperText: "Employee Code is required" });
        }
        if (CommonConfig.isEmpty(EmployeeName)) {
            Isvalid = false;
            this.setState({ EmployeeNameErr: true, EmployeeNameHelperText: "Employee Name is required" });
        }
        if (CommonConfig.isEmpty(Amountlimit)) {
            Isvalid = false;
            this.setState({ AmountlimitErr: true, AmountlimitHelperText: "Amount Limit is required" });
        }
        // if (CommonConfig.isEmpty(BasicSalary)) {
        //     Isvalid = false;
        //     this.setState({ BasicSalaryErr: true, BasicSalaryHelperText: "Basic Salary is required" });
        // }
        // if (CommonConfig.isEmpty(Reimbursement)) {
        //     Isvalid = false;
        //     this.setState({ ReimbursementErr: true, ReimbursementHelperText: "Reimbursement is required" });

            return Isvalid;
        // }
    }

    designationlist = () => {
        return this.state.DesignationList.map((content) => {
            return (
                <MenuItem value={content.StringMapKey}>
                    {" "}
                    {content.StringMapName}{" "}
                </MenuItem>
            );
        });
    };

    allowanceTypelist = () => {
        return this.state.AllowanceTypeList.map((content) => {
            return (
                <MenuItem value={content.StringMapKey}>
                    {" "}
                    {content.StringMapName}{" "}
                </MenuItem>
            );
        });
    };

    paymentModelist = () => {
        return this.state.PaymentModeList.map((content) => {
            return (
                <MenuItem value={content.StringMapKey}>
                    {" "}
                    {content.StringMapName}{" "}
                </MenuItem>
            );
        });
    };

    bankNamelist = () => {
        return this.state.BankNameList.map((content) => {
            return (
                <MenuItem value={content.StringMapKey}>
                    {" "}
                    {content.StringMapName}{" "}
                </MenuItem>
            );
        });
    };

    addAllowance = (e) => {
        const { AllowanceType, AllowanceAmountlimit, isTaxExempt } = this.state;
        if(AllowanceType && AllowanceAmountlimit){
          var addAllowance = this.state.AllowanceSummary.filter(x => (x.Status === "Active" && (x.AllowanceType === AllowanceType)))
          
          if (addAllowance.length === 0) {
            let data = {
              CreatedBy: CommonConfig.loginData().PersonID,
              AllowanceType: this.state.AllowanceType,
              Amountlimit: this.state.AllowanceAmountlimit,
              isTaxExempt: this.state.isTaxExempt?1:0,
              Index: this.state.AllowanceSummary.length + 1,
              IsEdit: false,
              Status: 'Active'
            }
            this.setState({ AllowanceSummary: [...this.state.AllowanceSummary, data] ,AllowanceType:'', AllowanceTypeErr:false, AllowanceTypeHelperText:'', AllowanceAmountlimit:'', AllowanceAmountlimitErr:false, AllowanceAmountlimitHelperText:'', isTaxExempt:false});
          }
          else {
            cogoToast.error("Allowance Already exist");
          }
        }
        else{
          if(CommonConfig.isEmpty(AllowanceType)){
            this.setState({AllowanceTypeErr:true,AllowanceTypeHelperText:'Please select Allowance Type'})
          }
          if(CommonConfig.isEmpty(AllowanceAmountlimit)){
            this.setState({AllowanceAmountlimitErr:true,AllowanceAmountlimitHelperText:'Please Enter Amount limit'})
          }
        }
    }
    
    editAllowanceSummary = (idx) => {
        const editAllowanceSummary = [...this.state.AllowanceSummary]
        var Index = this.state.AllowanceSummary.findIndex(x => x.Index === idx);
        if (Index !== -1) {
            editAllowanceSummary[Index]["IsEdit"] = true;
            this.setState({ AllowanceSummary: editAllowanceSummary })
        }
    }

    removeAllowanceSummary = (idx) => {
        const removeAllowanceSummary = [...this.state.AllowanceSummary]
        var Index = this.state.AllowanceSummary.findIndex(x => x.Index === idx);
        if (Index !== -1) {
            removeAllowanceSummary[Index]["Status"] = "Inactive";
            this.setState({ AllowanceSummary: removeAllowanceSummary })
        }
    }

    searchTotalAllowance = (len,e) => {
        var sum = 0;
        for (let i = 0; i < len.length; i++) {
            sum = sum + Number(len[i]._original.Amountlimit)
        }
        this.setState({ AllowanceTotal: sum });
      
    }
    searchCheckProps = (e) => {
        
        if(e.sortedData.length !== 0){
            var sum = 0;
            for (let i = 0; i < e.sortedData.length; i++) {
                sum = sum + Number(e.sortedData[i]._original.Amountlimit) 
            }
            if (this.state.AllowanceTotal !== sum) {
                this.searchTotalAllowance(e.sortedData);
            }
        }
        return '';
    }

    Submit = () => {
        const { EmployeeCode, EmployeeName,AllowanceSummary, Amountlimit, Designation, FixTDS, PaymentMode, BankName, BasicSalary, Reimbursement,IsESICApplicable ,isPFApplication, isTDSApplication, isLeaveNotApplicable, } = this.state;
        if (this.validate()) {
            let data = {
                UserID: CommonConfig.loginData().PersonID,
                ResourceID: CommonConfig.loginData().PersonID,
                PayrollID:this.props.location.state.id,
                EmployeeCode: EmployeeCode,
                EmployeeName: EmployeeName,
                AmountLimit: Amountlimit,
                AllowanceList: AllowanceSummary.filter(x=>x.Status === "Active"),
                Designation: Designation,
                IsTDSFixed: FixTDS?1:0,
                PaymentMode: PaymentMode,
                BankName: BankName,
                BasicSalary: BasicSalary,
                Reimbursements: Reimbursement,
                IsESICApplicable: IsESICApplicable?1:0,
                IsPFApplicable: isPFApplication?1:0,
                IsTDSApplicable: isTDSApplication?1:0,
                IsLeaveNotApplicable: isLeaveNotApplicable?1:0,

            }
            try {
                api
                  .post("payroll/addUpdatePayRoll", data)
                  .then((res) => {
                    if (res.success) {
                      cogoToast.success("Salary updated successfully");
                      this.props.history.push("/admin/salaryListing");
                    } else {
                      cogoToast.error("Something went wrong");
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              } catch (err) {
                console.log("error", err);
              }
        }

    }

    BackAllowance = () => {
        this.props.history.push("/admin/Dashboard");
    }

    render() {
        
        const { classes } = this.props;
        const { isTaxExempt, BankName,EmployeeCode, EmployeeCodeErr, EmployeeCodeHelperText, EmployeeName, EmployeeNameErr, EmployeeNameHelperText, Designation, DesignationErr,
            DesignationHelperText, Amountlimit, AmountlimitErr, AmountlimitHelperText, BasicSalaryHelperText,AllowanceAmountlimit, AllowanceAmountlimitErr, AllowanceAmountlimitHelperText, BasicSalary, BasicSalaryErr, ReimbursementHelperText,
            ReimbursementErr, Reimbursement, AllowanceType, AllowanceTypeHelperText, AllowanceTypeErr, AllowanceSummary, PaymentMode, paymentModeHelperText,
            payMentModeErr, FixTDS, BankNameHelperText, BankNameErr, IsESICApplicable, IsLeaveNotAppicable, IsPFApplicable, IsTDSAppicable } = this.state;
            
        const tableColumns = [

            {
                Header: 'Name',
                accessor: data => {
                    return (
                            <span>{data.AllowanceType}</span>
                    )
                },
                id: 'Name',
                width: 700,
            },
            {
                Header: 'Amount',
                accessor: data => {
                    return (
                            <span>{data.Amountlimit}</span>
                    )
                },
                id: 'Amount',
            },
            {
                Header: 'Is Tax Exempt',
                accessor: data => {
                    return (
                            <span>{data.isTaxExempt? "Yes" : "No"}</span>
                    )
                },
                id: 'isTaxExempt',
            },
            {
                Header: 'Action',
                accessor: 'Action',
            },
        ]
        return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Contacts />
                            </CardIcon>
                            <h4 style={{ color: "black" }}>Salary Master</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                    <ImageUploader
                                        withIcon={true}
                                        buttonText="Choose images"
                                        onChange={this.onDrop}
                                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                        maxFileSize={5242880}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <TextField
                                                helperText={EmployeeCodeHelperText}
                                                error={EmployeeCodeErr}
                                                value={EmployeeCode}
                                                label="Employee Code"
                                                onChange={(e) => this.onChange(e, "EmployeeCode")}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <TextField
                                                label="Amount Limit"
                                                helperText={AmountlimitHelperText}
                                                error={AmountlimitErr}
                                                value={Amountlimit}
                                                onChange={(e) => this.onChange(e, "Amountlimit")}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <TextField
                                                helperText={EmployeeNameHelperText}
                                                label="Employee Name"
                                                error={EmployeeNameErr}
                                                value={EmployeeName}
                                                onChange={(e) => this.onChange(e, "EmployeeName")}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <TextField
                                                label="Basic Salary"
                                                disabled
                                                helperText={BasicSalaryHelperText}
                                                error={BasicSalaryErr}
                                                value={BasicSalary}
                                                onChange={(e) => this.onChange(e, "BasicSalary")}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <FormControl error={DesignationErr} fullWidth label="Designation">
                                                <InputLabel>Designation</InputLabel>
                                                <Select
                                                    onChange={(e) => this.selectChange(e, "Designation")}
                                                    value={Designation}
                                                >
                                                    {this.designationlist()}
                                                </Select>
                                                <FormHelperText>{DesignationHelperText}</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <TextField
                                                label="Reimbursement Amount"
                                                disabled
                                                helperText={ReimbursementHelperText}
                                                error={ReimbursementErr}
                                                value={Reimbursement}
                                                onChange={(e) => this.onChange(e, "ReimbursementAmount")}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <label> Fix TDS </label>
                                            <Checkbox checked={FixTDS} onChange={(e) => this.setState({ FixTDS: !this.state.FixTDS })} name="checked" />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Contacts />
                            </CardIcon>
                            <h4 style={{ color: "black" }}>Allowance</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} md={4}>
                                            <FormControl error={AllowanceTypeErr} fullWidth label="AllowanceType">
                                                <InputLabel>Allowance Type</InputLabel>
                                                <Select
                                                    onChange={(e) => this.selectChange(e, "AllowanceType")}
                                                    value={AllowanceType}
                                                >
                                                    {this.allowanceTypelist()}
                                                </Select>
                                                <FormHelperText>{AllowanceTypeHelperText}</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <TextField
                                                label="Amount Limit"
                                                type="number"
                                                helperText={AllowanceAmountlimitHelperText}
                                                error={AllowanceAmountlimitErr}
                                                value={AllowanceAmountlimit}
                                                onChange={(e) => this.onChange(e, "AllowanceAmountlimit")}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <Checkbox checked={isTaxExempt} onChange={(e) => this.setState({ isTaxExempt: !this.state.isTaxExempt })} name="checked" />
                                            <label> Is Tax Exempt </label>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4}>
                                            <Button variant="contained" color="rose" onClick={() => this.addAllowance()}>Add Allowance</Button>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardBody>
                                                <ReactTable
                                                    data={AllowanceSummary}
                                                    minRows={5}
                                                    resizable={false}
                                                    columns={tableColumns} defaultPageSize={10}
                                                    style={{ cursor: "pointer" }}
                                                    className="-striped -highlight"
                                                    pageText={"Allowance Total : " + parseFloat(this.state.AllowanceTotal).toFixed(2)} 
                                                    getPaginationProps={(record) => this.searchCheckProps(record)}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Contacts />
                            </CardIcon>
                            <h4 style={{ color: "black" }}>Payment Details</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <FormControl error={payMentModeErr} fullWidth label="PaymentMode">
                                                <InputLabel>Payment Mode</InputLabel>
                                                <Select
                                                    onChange={(e) => this.selectChange(e, "PaymentMode")}
                                                    value={PaymentMode}
                                                >
                                                    {this.paymentModelist()}
                                                </Select>
                                                <FormHelperText>{paymentModeHelperText}</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={6}>
                                            <FormControl error={BankNameErr} fullWidth label="BankName">
                                                <InputLabel>Bank  Name</InputLabel>
                                                <Select
                                                    onChange={(e) => this.selectChange(e, "BankName")}
                                                    value={BankName}
                                                >
                                                    {this.bankNamelist()}
                                                </Select>
                                                <FormHelperText>{BankNameHelperText}</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <Contacts />
                            </CardIcon>
                            <h4 style={{ color: "black" }}>Employee Configuration</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={3} md={3}>
                                    <label> Is ESIC Applicable</label>
                                    <Checkbox checked={IsESICApplicable} onChange={(e) => this.setState({ IsESICApplicable: !this.state.IsESICApplicable })} name="checked" />
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                    <label> Is PF Applicable</label>
                                    <Checkbox checked={IsPFApplicable} onChange={(e) => this.setState({ IsPFApplicable: !this.state.IsPFApplicable })} name="checked" />
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                    <label> Is TDS Applicable</label>
                                    <Checkbox checked={IsTDSAppicable} onChange={(e) => this.setState({ IsPFApplicable: !this.state.IsPFApplicable })} name="checked" />
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                    <label> Is Leave Not Applicable</label>
                                    <Checkbox checked={IsLeaveNotAppicable} onChange={(e) => this.setState({ IsLeaveNotAppicable: !this.state.IsLeaveNotAppicable })} name="checked" />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={6} md={6}>
                            <Button variant="contained" color="rose" onClick={() => this.Submit()}>Update</Button>
                            <Button variant="contained" color="rose" onClick={() => this.BackAllowance()}>Back</Button>
                        </GridItem>
                    </GridItem>
                </GridContainer>
            </GridContainer>

        </div>
        );
    }
}

editSalaryMaster.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(editSalaryMaster);