import React, { Component } from 'react';
import {CommonConfig} from '../../utils/constant';
import api from '../../utils/apiClient';
import cogoToast from 'cogo-toast';
import FormControl from '@material-ui/core/FormControl';
import CardIcon from "components/Card/CardIcon.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormHelperText, InputLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            Firstname:"",
            firstnameErr:false,
            firstnameHelperText:"",

            Lastname:"",
            lastnameErr:false,
            lastnameHelperText:"",

            Password:"",
            passwordErr:false,
            passwordHelperText:"",

            Mobile:"",
            mobileErr:false,
            mobileHelperText:"",

            Email:"",
            emailErr:false,
            emailHelperText:"",

            // OrganizationName:"",
            ConfirmPassword:"",
            confirmpasswordErr:false,
            confirmpasswordHelperText:"",

            DesignationList: [],
            Designation: "",

        };
    }

    componentDidMount() {
       this.getDesignationList();
   }

    getDesignationList = () => {
      let data = {
          StringMapType: "DESIGNATION"
      }
      try {
          api.post("/stringmap/getStringMap", data).then(res => {
              if (res.success) {
                  this.setState({ DesignationList: res.data });
              }
              else {
                  cogoToast.error("Something went wrong");
              }
          }).catch(err => {
              console.log(err)
          })
      } catch (err) {
      }
  }

    designationlist = () => {
      return this.state.DesignationList.map(content => {
          return (
              <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
          )
      })
  }

    validate = () => {
      const {Mobile , Email , ConfirmPassword , Password , Firstname , Lastname} = this.state;
      let IsValid = true;
      if(CommonConfig.isEmpty(Email)){
        IsValid = false
        this.setState({emailErr:true , emailHelperText:"Please enter email address"});
      }
      if(CommonConfig.isEmpty(Mobile)){
        IsValid = false
        this.setState({mobileErr:true , mobileHelperText:"Please enter mobile"});
      }
      if(CommonConfig.isEmpty(Firstname)){
        IsValid = false
        this.setState({firstnameErr:true , firstnameHelperText:"Please enter firstname"});
      }
      if(CommonConfig.isEmpty(Lastname)){
        IsValid = false
        this.setState({lastnameErr:true , lastnameHelperText:"Please enter lastname"});
      }
      if(CommonConfig.isEmpty(Password)){
        IsValid = false
        this.setState({passwordErr:true, passwordHelperText:"Please enter password"});
      }
      if(CommonConfig.isEmpty(ConfirmPassword)){
        IsValid = false
        this.setState({confirmpasswordErr:true , confirmpasswordHelperText:"Please enter confirm password"});
      }
      if(ConfirmPassword !== Password){
        IsValid = false
        this.setState({confirmpasswordErr:true , confirmpasswordHelperText:"password and confirm password do not match"});
      }
      return (IsValid);
    }

    onChange = (e,type) => {
      if(type === "email"){
        if(CommonConfig.isEmpty(e.target.value)){
          this.setState({Email:e.target.value , emailErr:true , emailHelperText:"Please enter email address"});
        }
        else{
          this.setState({Email:e.target.value , emailErr:false , emailHelperText:""});
        }
      }

      else if(type === "password"){
        if(CommonConfig.isEmpty(e.target.value)){
          this.setState({Password:e.target.value , passwordErr:true , passwordHelperText:"Please enter password"});
        }
        else{
          this.setState({Password:e.target.value , passwordErr:false , passwordHelperText:""});
        }
  
      }
      
      else if(type === "confirmpassword"){
        if(CommonConfig.isEmpty(e.target.value)){
          this.setState({ConfirmPassword:e.target.value , confirmpasswordErr:true , confirmpasswordHelperText:"Please enter confirm password"});
        }
        else{
          this.setState({ConfirmPassword:e.target.value , confirmpasswordErr:false , confirmpasswordHelperText:""});
        }
  
      }

      else if(type === "phone"){
        if(CommonConfig.isEmpty(e.target.value)){
          this.setState({Mobile:e.target.value , mobileErr:true , mobileHelperText:"Please enter mobile number"});
        }
        else{
          this.setState({Mobile:e.target.value , mobileErr:false , mobileHelperText:""});
        }
  
      }
      
      else if(type === "firstname"){
        if(CommonConfig.isEmpty(e.target.value)){
          this.setState({Firstname:e.target.value , firstnameErr:true , firstnameHelperText:"Please enter first name"});
        }
        else{
          this.setState({Firstname:e.target.value , firstnameErr:false , firstnameHelperText:""});
        }
  
      }
      
      else if(type === "lastname"){
        if(CommonConfig.isEmpty(e.target.value)){
          this.setState({Lastname:e.target.value , lastnameErr:true , lastnameHelperText:"Please enter last name"});
        }
        else{
          this.setState({Lastname:e.target.value , lastnameErr:false , lastnameHelperText:""});
        }
  
      }

    }

    selectChange = (e, type) => {
      if (type === "Designation") {
          
          this.setState({ Designation: e.target.value });
      }
  }

    submit = (e) => {

      if(this.validate()){
        
      let data = {
        FirstName:this.state.Firstname,
        LastName:this.state.Lastname,
        Password:this.state.Password,
        Email:this.state.Email,
        PhoneNumber:this.state.Mobile,
        Designation: this.state.Designation,
      }
     
      try{
        
          api.post("login/userRegister",data).then(res => {
            const {history} = this.props;
            if(res.success){
              history.push("/admin/viewDailyTasks");
              cogoToast.success("User Registration Successful");
            }
            else{
              cogoToast.error("User Already Registered");
            }
          });
      }

      catch(err){
      }
    }
    else{
      cogoToast.error("Please correct error and resubmit form");
    }

    }

    cancelRegister = () => {
      this.props.history.push("/admin/viewDailyTasks");
    }

    render(){
        const {Firstname , firstnameErr , firstnameHelperText , Lastname , lastnameErr , lastnameHelperText , 
              Email ,emailErr , emailHelperText ,Mobile , mobileErr , mobileHelperText , Designation ,
              Password ,passwordErr , passwordHelperText , ConfirmPassword , confirmpasswordHelperText ,confirmpasswordErr} = this.state;
        return(
          <GridContainer className="UserList-outer">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader className="btn-right-outer" color="primary" icon>
                  <CardIcon color="primary">
                      <HeadsetMic />
                  </CardIcon>
                  <h4 className="margin-right-auto text-color-black">
                    Register
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControl>
                        <TextField
                          value={Firstname}
                          error={firstnameErr}
                          helperText={firstnameHelperText}
                          label="First Name"
                          onChange={(e) => this.onChange(e,"firstname")}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControl>
                        <TextField
                          value={Lastname}
                          error={lastnameErr}
                          helperText={lastnameHelperText}
                          label="Last Name"
                          onChange={(e) => this.onChange(e,"lastname")}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControl>
                        <TextField
                          value={Email}
                          error={emailErr}
                          helperText={emailHelperText}
                          label="Email"
                          onChange={(e) => this.onChange(e,"email")}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControl>
                        <TextField
                          value={Mobile}
                          error={mobileErr}
                          helperText={mobileHelperText}
                          label="Phone Number"
                          onChange={(e) => this.onChange(e,"phone")}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControl>
                        <TextField
                          type="password"
                          error={passwordErr}
                          helperText={passwordHelperText}
                          value={Password}
                          label="Password"
                          onChange={(e) => this.onChange(e,"password")}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControl>
                        <TextField
                          type="password"
                          error={confirmpasswordErr}
                          helperText={confirmpasswordHelperText}
                          value={ConfirmPassword}
                          label="Confirm Password"
                          onChange={(e) => this.onChange(e,"confirmpassword")}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <FormControl fullWidth label="Designation">
                      <InputLabel>Designation</InputLabel>
                        <Select onChange={(e) => this.selectChange(e, "Designation")} value={Designation}>
                        {this.designationlist()}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={6}>
                      <Tooltip title="Register" placement="bottom">
                        <Button color="primary" onClick={(e)=>this.submit(e)} className="width-150 mr-4" htmlType="submit">
                          Register
                        </Button>
                      </Tooltip>
                      <Tooltip title="Cancel" placement="bottom">
                          <Button  onClick={()=>this.cancelRegister()}>
                            Cancel
                          </Button>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );
    }

}
export default Register;