import React, { Component } from "react";
// core components
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import CardHeader from "components/Card/CardHeader.js";
import { DialogActions, withStyles } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import api from '../../utils/apiClient';
import { CommonConfig } from '../../utils/constant';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import GridItem from "components/Grid/GridItem";
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import GridContainer from "components/Grid/GridContainer.js";
import startOfMonth from "date-fns/startOfMonth";
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { endOfMonth } from "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";


class timeSheetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSheetList: [],
      myData: "",
      Editvisible: false,
      isSubmit: true,
      IsSubmitted: null,
      ApproveAccess: false,
      enabledResource : false,
      DailyStatusList: [],
      selectedDate: new Date(),
      selectedStartDate : startOfMonth(new Date()),
      selectedEndDate : endOfMonth(new Date()),
      filterVal: "",
    }
  }
  componentDidMount() {
    this.getDailyStatusList();
    if (CommonConfig.getUserAccess("Timesheet").AllAccess === 1) {
      this.setState({ enabledResource : true})
    }
    else{
          this.setState({ enabledResource : false })
    }
    if(CommonConfig.getUserAccess("Timesheet").DeleteAccess === 1){
      this.setState({ ApproveAccess : true })
    }else{
      this.setState({ ApproveAccess : false })
    }
    this.getTimeSheetList();
  }

  getTimeSheetList = () => {
    let data;
    data = {
      ResourceID: CommonConfig.loginData().PersonID,

    }
    try {
      api.post("dailystatus/getTimeSheetList", data).then(res => {

        var List = [];
        var List1 = [];

        for (let i = 0; i < res.data.filter(x => x.LogID == "(NULL)").length; i++) {
          var IsExist = List.filter(x => x.filtertaskdate.includes(res.data[i].TaskStatusDate));
          if (!IsExist.length > 0) {
            let [start, end] = this.getWeekDates(res.data[i].TaskStatusDate);
            if (Date.parse(start) >= Date.parse(this.state.selectedStartDate) && Date.parse(start) <= Date.parse(this.state.selectedEndDate)) {
              var tempend = new Date(end);
              tempend.setDate(tempend.getDate()-1);
              var filter = res.data.filter(d => new Date(d.TaskStatusDate) >= new Date(start) && new Date(d.TaskStatusDate) <= new Date(end) && d.SubmittedStatus != "Rejected" && d.LogID == "(NULL)");
              List.push({
                TimeSheetPeriod: moment((start)).format('DD/MM/YYYY') + "-" + moment((tempend)).format('DD/MM/YYYY'),
                StartDate: start,
                EndDate: end,
                SubmittedDate: filter[0].SubmittedDate ? moment((filter[0].SubmittedDate)).format('DD/MM/YYYY') : null,
                SubmittedStatus: filter[0].SubmittedStatus,
                filtertaskdate: filter.map((x) => { return x.TaskStatusDate }),
                filtertotalhrs: filter.map((x) => { return x.HrsToday ? x.HrsToday : 0 }),
                DailyTaskID: filter.map((x) => { return x.DailyTaskID }),
                Comments: filter[0].Comments
              })
            }
          }
        }

        var Reject = res.data.filter(x => x.SubmittedStatus == "Rejected")

        for (let j = 0; j < Reject.length; j++) {
          var IsRejected = []
          IsRejected = List1.filter(x=>x.filtertasklog.includes(Reject[j].LogID))

          if (!IsRejected.length > 0) {
            let [start, end] = this.getWeekDates(Reject[j].TaskStatusDate);
            var tempend = new Date(end);
              tempend.setDate(tempend.getDate()-1);
            if (Date.parse(start) >= Date.parse(this.state.selectedStartDate) && Date.parse(start) <= Date.parse(this.state.selectedEndDate)) {
              var filter2 = Reject.filter((d)=> new Date(d.TaskStatusDate) >= new Date(start) && new Date(d.TaskStatusDate) <= new Date(end) && d.SubmittedStatus == "Rejected");

              List1.push({
                TimeSheetPeriod: moment((start)).format('DD/MM/YYYY') + "-" + moment((tempend)).format('DD/MM/YYYY'),
                StartDate: start,
                EndDate: end,
                SubmittedDate: filter2[0].SubmittedDate ? moment((filter2[0].SubmittedDate)).format('DD/MM/YYYY') : null,
                SubmittedStatus: filter2[0].SubmittedStatus,
                filtertaskdate: filter2.map((x) => { return x.TaskStatusDate }),
                filtertasklog: filter2.map((x) => { return x.LogID }),
                filtertotalhrs: filter2.map((x) => { return x.HrsToday ? x.HrsToday : 0 }),
                DailyTaskID: filter2.map((x) => { return x.DailyTaskID }),
                Comments: filter2[0].Comments
              })
            }
          }
        }

        List = List.concat(List1)

        for (let l = 0; l < List.length; l++) {
          var sum = 0
          if (List[l].filtertotalhrs[0] != "null") {
            for (let m = 0; m < List[l].filtertotalhrs.length; m++) {
              sum = parseFloat(Number(sum) + Number(List[l].filtertotalhrs[m])).toFixed(2)
            }
          } else {
            sum = 0
          }

          var total = {
            sum: sum
          }
          List[l] = Object.assign(List[l], total)
        }

        this.setState({ timeSheetList: List });
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }
  search = () => {
    
    let data;
    data = {
      ResourceID: this.state.filterVal.value
    }
    try {
      api.post("dailystatus/getTimeSheetList", data).then(res => {
        var List = [];
        var sum = 0
        for (let i = 0; i < res.data.length; i++) {
          var IsExist = List.filter(x => x.filtertaskdate.includes(res.data[i].TaskStatusDate));
          if (!IsExist.length > 0) {
            let [start, end] = this.getWeekDates(res.data[i].TaskStatusDate);
            var tempend = new Date(end);
            tempend.setDate(tempend.getDate()-1);
            if (Date.parse(start) >= Date.parse(this.state.selectedStartDate) && Date.parse(start) <= Date.parse(this.state.selectedEndDate)) {
              var filter = res.data.filter(d => new Date(d.TaskStatusDate) >= new Date(start) && new Date(d.TaskStatusDate) <= new Date(end));
              List.push({
                TimeSheetPeriod: moment((start)).format('DD/MM/YYYY') + "-" + moment((tempend)).format('DD/MM/YYYY'),
                StartDate: start,
                EndDate: end,
                SubmittedDate: filter[0].SubmittedDate ? moment((filter[0].SubmittedDate)).format('DD/MM/YYYY') : null,
                SubmittedStatus: filter[0].SubmittedStatus,
                filtertaskdate: filter.map((x) => { return x.TaskStatusDate }),
                filtertotalhrs: filter.map((x) => { return x.HrsToday })
              })
            }
          }
        }

        for (let l = 0; l < List.length; l++) {
          var sum = 0;
          for (let m = 0; m < List[l].filtertotalhrs.length; m++) {
            sum = parseFloat(Number(sum) + Number(List[l].filtertotalhrs[m]))
          }
          var total = {
            sum: sum
          }
          List[l] = Object.assign(List[l], total)
        }

        this.setState({ timeSheetList: List });
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  getDailyStatusList = () => {
    try {
      api.get("dailystatus/getDailyStatusList").then(res => {
        if (res.success) {
          let obj = {
            FirstName: "Select All",
            LastName: "",
            ResourceID: ""
          }
          res.data.push(obj);
          res.data.map((r, i) => {
            res.data[i].FullName = `${r.FirstName} ${r.LastName}`
          });
          this.setState({
            DailyStatusList: res.data,
            filterVal: {
              value: CommonConfig.loginData().PersonID, label: CommonConfig.loginData().FirstName + ' ' + CommonConfig.loginData().LastName
            }
          });
        }
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  getWeekDates = (date) => {
    let now = new Date(date);
    let dayOfWeek = now.getDay(); //0-6
    let numDay = now.getDate();

    let start = new Date(now); //copy
    start.setDate(numDay - dayOfWeek);
    start.setHours(0, 0, 0, 0);


    let end = new Date(now); //copy
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(0, 0, 0, 0);

    return [start, end];
  }

  redirectReports = () => {
    this.props.history.push("/admin/Reports");
  }
  SubmitTimeSheet = (record) => {

    let data = {
      FromDate: moment(record.original.StartDate).format(CommonConfig.dateFormat.dbDateTime),
      ToDate: moment(record.original.EndDate).format(CommonConfig.dateFormat.dbDateTime),
      ResourceID: CommonConfig.isEmpty(this.state.filterVal) ? CommonConfig.loginData().PersonID : this.state.filterVal.value,
      Status: 'Submitted'
    }
    try {
      api.post("dailystatus/updateTimeSheetStatus", data).then(res => {
        if (res.success) {
          cogoToast.success('Timesheet Submitted successfully')
          this.getTimeSheetList();

        }
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }
  viewTimeSheet = (record, type) => {
    this.props.history.push({
      pathname: "/admin/Reports",
      state: {
        startDate: record.original.StartDate,
        endDate: record.original.EndDate,
        viewOnly: type === "View" ? true : false
      }
    });
  }
  handleApprovedTimeSheet = (record, type) => {
    let data = {
      FromDate: moment(record.original.StartDate).format(CommonConfig.dateFormat.dbDateTime),
      ToDate: moment(record.original.EndDate).format(CommonConfig.dateFormat.dbDateTime),
      ResourceID: CommonConfig.isEmpty(this.state.filterVal) ? CommonConfig.loginData().PersonID : this.state.filterVal.value,
      Status: type,
      Comments: this.state.Notes,
      TaskStatusDate: record.original.filtertaskdate,
      DailyTaskID: record.original.DailyTaskID,
      SubmittedDate: moment(record.original.SubmittedDate).format(CommonConfig.dateFormat.dbDateTime)
    }

    try {
      api.post("dailystatus/updateTimeSheetStatus", data).then(res => {
        if (res.success) {
          cogoToast.success(`Timesheet ` + type + ` successfully`)
          this.setState({ openReject: false, Notes: '', NotesErr: false, NotesHelperText: '' })
          this.getTimeSheetList();

        }
      }).catch(error => {
      })
    }
    catch (err) {
    }

  }
  handleCancel = () => {
    this.setState({ Editvisible: false });
  }
  deleteTimeSheet = (record) => {
    let data = {
      ResourceID: CommonConfig.isEmpty(this.state.filterVal) ? CommonConfig.loginData().PersonID : this.state.filterVal.value,
      FromDate: moment(record.original.StartDate).format(CommonConfig.dateFormat.dbDateTime),
      ToDate: moment(record.original.EndDate).format(CommonConfig.dateFormat.dbDateTime),
      Status: "Draft"
    }
    try {
      api.post("dailystatus/deleteTimeSheetistById", data).then(res => {
        if (res.success) {
          cogoToast.success('Timesheet Deleted successfully')
          this.setState({ Editvisible: false });
          this.getTimeSheetList();

        }
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  handleResoureOnChange = (value) => {
    if (value !== null) {
      this.setState({ filterVal: value })
    }
  }
  requestChange = (e, value, type) => {
    if (type === "DailyStatus") {
      this.setState({ DailyStatus: value, DailyStatusErr: false, DailyStatusHelperText: "" });
    }
  }
  handleMonthChange = date => {
    this.setState({ selectedDate: startOfMonth(date), selectedStartDate: startOfMonth(date), selectedEndDate: endOfMonth(date) });
  };
  onChange = (e, type) => {
    if (type === "Notes") {
      this.setState({ Notes: e.target.value, NotesErr: false, NotesHelperText: "" });
    }
  }
  render() {
    const { Editvisible, ApproveAccess, timeSheetListErr, timeSheetListHelperText, selectedDate } = this.state;
    const DailyStatus = this.state.DailyStatusList.map(DailyStatus => {
      return { value: DailyStatus.ResourceID, label: DailyStatus.FullName }
    });
    const AlltableColumns = [
      {
        Header: 'TimeSheet Period',
        accessor: 'TimeSheetPeriod',
        id: 'TimeSheetPeriod',
        sortable: true,
      },
      {
        Header: 'Status',
        accessor: 'SubmittedStatus',
        id: 'Status',
        sortable: true,
      },
      {
        Header: 'Submit Date',
        accessor: 'SubmittedDate',
        Cell: record => {
          return (
            <span className="daily-total-hr">
              {record.original.SubmittedDate ? record.original.SubmittedDate : <button className="btn-icon-normal" onClick={() => this.SubmitTimeSheet(record)}>Submit</button>}
            </span>
          )
        },

        id: 'SubmittedDate',
        sortable: true,
      },
      {
        Header: 'Comments',
        className: "text-align-left",
        accessor: data => {
          return (
            <Tooltip title={data.Comments} arrow>
              <span>{data.Comments}</span>
            </Tooltip>
          )
        },
        id: 'Comments',
        sortable: false
      },
      {
        Header: 'TotalHrs',
        accessor: 'sum',
        id: 'TotalHrs',
        sortable: true,
      },
      {
        Header: 'Action',
        id: 'action',
        sortable: false,
        className: "text-align-right",
        Cell: record => {
          return (
            <span>
              {record.original.SubmittedStatus === "Submitted" ?
                <span>
                  <Tooltip title="Approved" placement="buttom">
                    <DoneIcon color="primary" onClick={() => this.handleApprovedTimeSheet(record, "Approved")} />
                  </Tooltip>
                  <Tooltip title="Rejected" placement="buttom">
                    <CloseIcon color="primary" onClick={() => this.setState({ record: record, openReject: true })} />
                  </Tooltip>
                </span>
                : null
              }
              <span>
                <Tooltip title="View" placement="buttom">
                  <VisibilityIcon color="primary" onClick={() => this.viewTimeSheet(record, "View")}><CreateIcon /></VisibilityIcon>
                </Tooltip>
              </span>
              <span>
                <Tooltip title="Edit" placement="bottom">
                  <EditIcon color="primary" onClick={() => this.viewTimeSheet(record, "Edit")}><CreateIcon /></EditIcon>
                </Tooltip>
              </span>
              <span>
                <Tooltip title="Delete" placement="buttom" >
                  <DeleteIcon color="primary" onClick={() => this.setState({ Editvisible: true, record: record })}><CreateIcon /></DeleteIcon >
                </Tooltip>
              </span>
            </span>
          )
        },
        filterable: false
      },
    ]

    const tableColumns = [
      {
        Header: 'TimeSheet Period',
        accessor: 'TimeSheetPeriod',
        id: 'TimeSheetPeriod',
        sortable: true,
      },
      {
        Header: 'Status',
        accessor: 'SubmittedStatus',
        id: 'Status',
        sortable: true,
      },
      {
        Header: 'Submit Date',
        accessor: 'SubmittedDate',
        Cell: record => {
          return (
            <span className="daily-total-hr">
              {record.original.SubmittedDate ? record.original.SubmittedDate : <button className="btn-icon-normal" onClick={() => this.SubmitTimeSheet(record)}>Submit</button>}
            </span>
          )
        },

        id: 'SubmittedDate',
        sortable: true,
      },
      {
        Header: 'Comments',
        className: "text-align-left",
        accessor: data => {
          return (
            <Tooltip title={data.Comments} arrow>
              <span>{data.Comments}</span>
            </Tooltip>
          )
        },
        id: 'Comments',
        sortable: false
      },
      {
        Header: 'TotalHrs',
        accessor: 'sum',
        id: 'TotalHrs',
        sortable: true,
      },
      {
        Header: 'Action',
        id: 'action',
        sortable: false,
        className: "text-align-right",
        Cell: record => {
          return (
            <span>
              <span>
                <Tooltip title="View" placement="buttom">
                  <VisibilityIcon color="primary" onClick={() => this.viewTimeSheet(record, "View")}><CreateIcon /></VisibilityIcon >
                </Tooltip>
              </span>
              <span>
                <Tooltip title="Edit" placement="bottom">
                  <EditIcon color="primary" onClick={() => this.viewTimeSheet(record, "Edit")}><CreateIcon /></EditIcon>
                </Tooltip>
              </span>
              <span>
                <Tooltip title="Delete" placement="buttom" >
                  <DeleteIcon color="primary" onClick={() => this.setState({ Editvisible: true, record: record })}><CreateIcon /></DeleteIcon >
                </Tooltip>
              </span>
            </span>
          )
        },
        filterable: false
      },
    ]

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={3} md={6}>
            
          </GridItem>
        </GridContainer>
          <Card>
        <CardHeader>
          <Tooltip title="Add" placement="bottom">
            <Button type="button" color="primary" onClick={() => this.redirectReports()}>Add New</Button>
          </Tooltip>
        </CardHeader>
        <GridContainer className="ml-auto">
          {this.state.enabledResource ?
            <GridItem xs={12} sm={4} md={4}>
              <div className="search-resource">
                <Autocomplete
                  options={DailyStatus}
                  id="DailyStatus"
                  value={this.state.filterVal}
                  getOptionLabel={option => option.label}
                  onChange={(event, value) => this.handleResoureOnChange(value)}
                  renderInput={params => (
                    <TextField {...params} error={timeSheetListErr} helperText={timeSheetListHelperText}
                      label="Search Resources Name" />)}
                />
              </div>
            </GridItem>
            : <GridItem xs={12} sm={4} md={4}>
            <div className="search-resource">
              <Autocomplete
                disabled
                options={DailyStatus}
                id="DailyStatus"
                value={this.state.filterVal}
                getOptionLabel={option => option.label}
                onChange={(event, value) => this.handleResoureOnChange(value)}
                renderInput={params => (
                  <TextField {...params} error={timeSheetListErr} helperText={timeSheetListHelperText}
                    label="Search Resources Name" />)}
              />
            </div>
          </GridItem>}
          <GridItem xs={12} sm={4} md={4}>
            <div className="search-resource">
              <div className="with-icon">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk={true}
                    variant="inline"
                    openTo="year"
                    views={["year", "month"]}
                    label="Select Year and Month"
                    value={selectedDate}
                    onChange={(e) => this.handleMonthChange(e)}
                  />
                </MuiPickersUtilsProvider>
                <Tooltip title="Search" placement="top">
                  <Button onClick={() => this.search()} justIcon color="primary"><SearchIcon /></Button>
                </Tooltip>
              </div>
            </div>
          </GridItem>

          <GridItem xs={12} sm={4} md={4}>
            <div className="top-search margin-top-16">
              <input placeholder="Search..." type="text" name="query"></input>
            </div>
          </GridItem>
        </GridContainer>

        <Dialog
          open={Editvisible}
          title="Credentials"
          onClose={() => this.handleCancel()}
        >
          <DialogTitle id="alert-dialog-title" > "Confirm Delete" </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to delete?
        </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Tooltip title="Cancel" placement="bottom">
              <Button type="submit" onClick={() => this.handleCancel()} color="primary">
                Cancel
        </Button>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <Button type="submit" color="primary" onClick={(e) => this.deleteTimeSheet(this.state.record)}>Delete</Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openReject}
          title="Credentials"
          onClose={() => this.setState({ openReject: false, Notes: "", NotesErr: false, NotesHelperText: "" })}
        >
          <FormControl fullWidth error={this.state.NotesErr}>
            <TextareaAutosize
              value={this.state.Notes}
              placeholder="Comments"
              rowsMin={5}
              cols={90}
              label="Comments"
              onChange={(e) => this.onChange(e, "Notes")}
            />
            <FormHelperText>{this.state.NotesHelperText}</FormHelperText>
          </FormControl>

          <DialogActions>
            <Tooltip title="Save" placement="bottom">
              <Button type="submit" color="primary" onClick={() => this.handleApprovedTimeSheet(this.state.record, "Draft")}>Save</Button>
            </Tooltip>
            <Tooltip title="Back" placement="bottom">
              <Button key="back" onClick={() => this.setState({ openReject: false, Notes: "", NotesErr: false, NotesHelperText: "" })}>Back</Button>
            </Tooltip>
          </DialogActions>
        </Dialog>

        <CardBody>
          <ReactTable
            data={this.state.timeSheetList}
            minRows={5}
            resizable={false}
            style={{ cursor: "pointer" }}
            columns={ApproveAccess ? AlltableColumns : tableColumns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
      </div>
    )
  };
}

export default withStyles()(timeSheetList);
