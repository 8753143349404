import React from "react";
import PropTypes from "prop-types";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { useState, useEffect, useRef } from "react";
import moment, { relativeTimeRounding } from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import api from "../../utils/apiClient";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import { CommonConfig } from "../../utils/constant";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);

function Event(event) {


  event.localizer.messages.previous = "Prev";
  const [showTooltip, setShowTooltip] = useState(false);

  const closeTooltip = () => {
    setShowTooltip(false);
  };

  const openTooltip = () => {
    setShowTooltip(true);
  };
  const ref = useRef(null);

  return showTooltip ? (
    <Tooltip id="test" title={event.event.body} placement="bottom" arrow>
      <div
        ref={ref}
        onMouseOver={openTooltip}
        onMouseOut={closeTooltip}
        className="notification-list__icon"
      >
        {/* <img src={Logo} className="image image--circle -x-large" /> */}
        <span>{event.title}</span>
      </div>
    </Tooltip>
  ) : (
    <div
      ref={ref}
      onMouseOver={openTooltip}
      onMouseOut={closeTooltip}
      className="notification-list__icon"
    >
      <span>{event.title}</span>
    </div>
  );
}

function LeaveCalendar(props) {
  var columns = [];
  const classes = useStyles();
  const [events, setEvents] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [editvisible, setEditvisible] = React.useState(false);



  const [TasksErr, setTasksErr] = React.useState(false);
  const [TasksHelperText, setTasksHelperText] = React.useState("");
  const [DailyStatusList, setDailyStatusList] = React.useState([]);
  const [filterVal, setfilterVal] = React.useState("");
  const selectedEvent = (event) => {
    window.alert(event.title);
  };

  useEffect(() => {

    getDailyStatusList();
    SearchClick();
  }, []);

  const handleCancel = () => {
    setEditvisible(false);
  };

  const HolidayList = () => {
    setEditvisible(true);
    debugger
    try {
      api
        .get("resource/getHolidayList")
        .then((res) => {

          setData(res.data)

        })
        .catch((error) => {

        });
    } catch (err) { }
  };


  const getDailyStatusList = () => {
    try {
      api
        .get("dailystatus/getDailyStatusList")
        .then((res) => {
          if (res.success) {
            let obj = {
              FullName: "Select All",
              ResourceID: "",
            };
            var finalResourceList = [];

            res.data.map((r, i) => {
              res.data[i].FullName = `${r.FirstName} ${r.LastName}`;
            });
            finalResourceList.push(obj);
            for (let i = 0; i < res.data.length; i++) {
              finalResourceList.push(res.data[i]);
            }
            setDailyStatusList(finalResourceList);
            setfilterVal({
              value: CommonConfig.loginData().PersonID,
              label:
                CommonConfig.loginData().FirstName +
                " " +
                CommonConfig.loginData().LastName,
            });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  const sendState = () => {
    return this.state;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const isValidated = () => {
    return true;
  };

  const addNewEvent = (e, slotInfo) => {
    var newEvents = events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    setAlert(null);
    setEvents(newEvents);
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  const handleResoureOnChange = (value) => {
    if (value !== null) {
      setfilterVal(value);
    }
  };
  const SearchClick = () => {
    let data;
    data = {
      ResourceID: filterVal
        ? filterVal.value
        : CommonConfig.loginData().PersonID,
      StartDate: "",
      EndDate: "",
    };
    try {
      api
        .post("leave/getLeaveListByResource", data)
        .then((res) => {
          const events = [];
          for (let i = 0; i < res.data.length; i++) {
            events.push({
              title: res.data[i].FirstName + " " + res.data[i].LastName,
              body: res.data[i].LeaveReason,
              allDay: true,
              start: new Date(res.data[i].LeaveStartDate),
              end: new Date(res.data[i].LeaveEndDate),
              color: "default",
            });
          }
          setEvents(events);
        })
        .catch((error) => { });
    } catch (err) { }
  };
  const DailyStatus = DailyStatusList
    ? DailyStatusList.map((DailyStatus) => {
      return { value: DailyStatus.ResourceID, label: DailyStatus.FullName };
    })
    : [];
  return (
    <div>
      <GridContainer className="UserList-outer ml-auto">
        <GridItem xs={6} sm={4} md={4}>
          <div className="search-resource">
            <div className="with-icon">
              <Autocomplete
                options={DailyStatus}
                id="DailyStatus"
                value={filterVal}
                style={{ widht: "250px" }}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => handleResoureOnChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={TasksErr}
                    helperText={TasksHelperText}
                    label="Search Resources Name"
                  />
                )}
              />
              <Tooltip title="Search">
                <Button
                  color="primary"
                  justIcon
                  onClick={(e) => SearchClick(e)}
                >
                  <SearchIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
        </GridItem>
        <GridItem xs={6} sm={4} md={4}>
          <div className="search-resource">
            <Button
              color="primary"
              title="Add OT"
              onClick={() => HolidayList()}
              style={{ marginLeft: "10px" }}
            >
              Holiday List-2023
            </Button>
          </div>
        </GridItem>

      </GridContainer>
      {alert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardBody calendar>
              <BigCalendar
                selectable
                tooltipAccessor={null}
                components={{ event: Event }}
                // events={this.state.events}
                localizer={localizer}
                events={events}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                eventPropGetter={eventColors}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        open={editvisible}
        title="Credentials"
        onClose={() => this.handleCancel()}
        fullWidth
      >
      <DialogContent>
      <h3 style={{ textAlign: "center" }}>Holiday List-2023</h3>
      <div style={{ overflowX: "auto" }}>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2" }}>HolidayID</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2" }}>Festival</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2" }}>Date</th>
              <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2" }}>Day</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((user, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.HolidayID}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.Festival}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{moment(user.Date).format("DD-MM-YYYY")}</td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{user.Day}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DialogContent> 
        <DialogActions>
          <Tooltip title="Ok" placement="bottom">
            <Button key="back" onClick={() => handleCancel()}>
              Ok
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LeaveCalendar.propTypes = {
  classes: PropTypes.object,
};

export default LeaveCalendar;
