import React, { Component } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import api from "../../utils/apiClient";
import Tooltip from "@material-ui/core/Tooltip";
import ReactTable from "react-table";
import { CommonConfig } from "../../utils/constant";
import CreateIcon from "@material-ui/icons/Create";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import SimpleBackdrop from "../../utils/general";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import startOfMonth from "date-fns/startOfMonth";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import cogoToast from 'cogo-toast';


const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class PayrollList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PayrollList: [],
      ResourceList: [],
      ApproveAccess: false,
      IsSelectAll: false,
      LeaveBalance: 12,
      BasicSalary: "",
      DailyStatusList: [],
      Loading: false,
      filterVal: "",
      Resoource: "",
      StartDate: "",
      EndDate: "",
      checked: false,
      FixTDS: "",
      selectedDate: moment().subtract(1, "month"),
      salaryMonth: moment(moment().subtract(1, "month")).format("MM"),
      salaryYear: moment(moment().subtract(1, "month")).format("YYYY"),
      // salaryMonth : moment().format('MM')
      // salaryYear : moment().format('YYYY')
      selectedStartDate: "",
      selectedSalaryMonthDate: "",
      isShowSalaryMaster: 0,
      PayrollStatus:true,
      isEditEnable: false,
      payrollList: [], // Holds the fetched payroll data
      filteredPayrollList: [], // Holds the filtered payroll data
      filterVal: {
        PayrollStatus: "", // Add the PayrollStatus filter state
      },
      selectedSalaryMonthDate: new Date(),
      isFilterApplied: false,
      DraftCount: 0,
    };
  }

  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }

  componentDidMount() {
    let checkAccess = CommonConfig.getUserAccess("payroll");
    if (checkAccess) {
      if (checkAccess.AllAccess == 1) {
        this.setState({ ApproveAccess: true });
      } else {
        this.setState({ ApproveAccess: false });
      }
    }
    this.getPayrollList();
    this.getDailyStatusList();
    this.getResourceList();
  }

  sendState() {
    return this.state;
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  isValidated() {
    return true;
  }

  handleMonthChange = (date) => {
    var dateNow = new Date();

    this.setState({
      salaryMonth: moment(date).format("MM"),
      salaryYear: moment(date).format("Y"),
      selectedDate: startOfMonth(date),
      selectedStartDate: new Date(date.getFullYear(), date.getMonth() - 1, 1),
      selectedSalaryMonthDate: date,
    }, () => {
      this.getPayrollList();
    });

  };

  getDailyStatusList = () => {
    try {
      api
        .get("dailystatus/getDailyStatusList")
        .then((res) => {
          if (res.success) {
            console.log("this.state.ApproveAccess", this.state.ApproveAccess);
            let obj = {
              FullName: "Select All",
              ResourceID: "",
            };
            var finalResourceList = [];

            res.data.map((r, i) => {
              res.data[i].FullName = `${r.FirstName} ${r.LastName}`;
            });
            finalResourceList.push(obj);
            for (let i = 0; i < res.data.length; i++) {
              finalResourceList.push(res.data[i]);
            }
            // this.setState({
            //   DailyStatusList: finalResourceList,
            //   Loading: false,
            //   filterVal: {
            //     value: CommonConfig.loginData().PersonID,
            //     label:
            //       CommonConfig.loginData().FirstName +
            //       " " +
            //       CommonConfig.loginData().LastName,
            //   },
            // });
            this.setState({
              DailyStatusList: finalResourceList,
              filterVal: !this.state.ApproveAccess
                ? { value: "", label: "Select All" }
                : {
                  value: CommonConfig.loginData().PersonID,
                  label:
                    CommonConfig.loginData().FirstName +
                    " " +
                    CommonConfig.loginData().LastName,
                },
            });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  getResourceList = () => {
    this.showLoader()
    try {
      api
        .get("resource/getResourceList")
        .then((res) => {
          this.hideLoader()
          this.setState({
            ResourceList: res.data,
          });
        })
        .catch((error) => { });
    } catch (err) { }
  };

  getPayrollList = (filter) => {
    let data;
    if (!CommonConfig.isEmpty(filter)) {
      data = {
        ResourceID: filter.value,
        SalaryMonth: this.state.salaryMonth,
        SalaryYear: this.state.salaryYear,
        isConfigurePayroll:1,
        Loading: false,
      };
    } else {
      data = {
        ResourceID: "",
        SalaryMonth: this.state.salaryMonth,
        SalaryYear: this.state.salaryYear,
        isConfigurePayroll:1,
        Loading: false,
      };
    }



    try {
      api
        .post("payroll/getPayrollList", data)
        .then((res) => {
          const payrollList = res.data;
          const draftCount = payrollList.reduce((count, item) => {
            if (item.PayrollStatus === "Draft") {
              count++;
            }
            return count;
          }, 0);

          this.setState({
            PayrollList: res.data,
            DraftCount: draftCount,
            Loading: false,
          });
        })
        .catch((error) => { });
    } catch (err) { }
  };


  getPayrollListForFilter = () => {
    const { filterVal, salaryMonth, salaryYear } = this.state;
  
    const data = {
      ResourceID: filterVal.value,
      SalaryMonth: salaryMonth,
      SalaryYear: salaryYear,
      isConfigurePayroll: 1,
      Loading: false,
    };
  
    try {
      api
        .post("payroll/getPayrollList", data)
        .then((res) => {
          this.setState({
            payrollList: res.data,
            Loading: false,
          }, () => {
            console.log("Payroll List:", res.data);
            this.filterPayrollList();
          });
        })
        .catch((error) => { });
    } catch (err) { }
  };

filterPayrollList = () => {
  const { payrollList, filterVal } = this.state;

  const filteredPayrollList = payrollList.filter(item => {
    console.log("Filter Payroll Value:", filterVal.PayrollStatus.value);
    console.log("Item Payroll Status:", item);
    return item.PayrollStatus === filterVal.PayrollStatus.value;
  });

  const isFilterApplied = filteredPayrollList.length > 0;

  this.setState({
    filteredPayrollList: isFilterApplied ? filteredPayrollList : [],
    isFilterApplied: isFilterApplied,
  });

  console.log("Filtered Payroll List:", filteredPayrollList ,"--" , filteredPayrollList);
};


  
  makeProcessed = () => {
    let data = {
      SalaryMonth: this.state.salaryMonth,
      SalaryYear: this.state.salaryYear,
    };
    try {
      api
        .post("payroll/makeProcessed", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success(`${this.state.DraftCount} Payroll processed successfully`);
      
            setTimeout(() => {
              window.location.reload();
            }, 2000); 
            // window.location.reload();
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) { }
  };

  requestChange = (e, value, type) => {
    if (type === "DailyStatus") {
      this.setState({
        DailyStatus: value,
        DailyStatusErr: false,
        DailyStatusHelperText: "",
      });
    }
  };

  handleResoureOnChange = (value, type) => {
    if (value !== null) {
      if (type == "ResourceID") {
        this.setState({ filterVal: value, Loading: true });
        this.getPayrollList(value);
        this.getResourceList();
      }
    } else {
      this.setState({ filterVal: "", Loading: true });
      this.getPayrollList();
      this.getResourceList();
    }
  };

  handleResoureOnChangeForFilter = (value, field) => {
    if (value === null) {
      value = ""; // Set empty string for null value

      this.getPayrollListForFilter("Select All");
    }
    this.setState(
      prevState => ({
        filterVal: {
          ...prevState.filterVal,
          [field]: value,
        },
      }),
      () => {
        this.filterPayrollList(); 
        this.getPayrollListForFilter();// Call the filtering function after updating the filter value
      }

      // () => {
      //   this.getPayrollList();
      // });
      
    );
  };
  
  
  render() {
    const {
      PayrollList,
      ResourceList,
      ApproveAccess,
      selectedDate,
      viewDailyTaskErr,
      viewDailyTaskHelperText,
      IsSelectAll,
      ResourceID,
      checked,
    } = this.state;

    const DailyStatus = this.state.DailyStatusList.map((DailyStatus) => {
      return { value: DailyStatus.ResourceID, label: DailyStatus.FullName };
    });

    const tableColumns = [

      {
        Header: "Employee Code",
        accessor: "ResourceCode",
        id: "ResourceCode",
        sortable: true,
        width: 120,
      },

      {
        Header: "Employee",
        accessor: "FirstName",
        width: 180,
        Cell: (record) => {
          return (
            <Tooltip
              title={
                record.original.FirstName +
                " " +
                record.original.LastName +
                " , " +
                record.original.Designation
              }
              arrow
            >
              <span>
                {record.original.FirstName +
                  " " +
                  record.original.LastName +
                  " , " +
                  record.original.Designation}
              </span>
            </Tooltip>
          );
        },
        id: "FirstName",
        sortable: true,
        filterMethod: (filter, row) => {
          let firstname =
            row._original.FirstName + " " + row._original.LastName;
          return row._original
            ? String(firstname)
              .toLowerCase()
              .includes(filter.value.toLowerCase())
            : row;
        },
      },
      // {
      //   Header: "Monthly Salary",
      //   accessor: (data) => {
      //     return <span>{"N/A"}</span>;
      //   },
      //   id: "prevSalary",
      //   sortable: true,
      // },

      {
        Header: "Salary",
        accessor: "BasicSalary",
        id: "BasicSalary",
        sortable: true,
        width: 100,
      },

      // {
      //   Header: "Diff from Last Mth",
      //   accessor: (data) => {
      //     return <span>{"N/A"}</span>;
      //   },
      //   id: "DiffLastMth",
      //   sortable: true,
      // },
      {
        Header: "Salary Date",
        accessor: (data) => {
          if (data.SalaryDate != null) {
            return moment(data.SalaryDate).format("DD/MM/YYYY")
          } else {
            return "N/A"
          }
        },
        id: "SalaryDate",
        sortable: true,
        width: 100,
      },
      {
        Header: "Status",
        width: 100,
        id: "PayrollStatus",
        accessor: "PayrollStatus",
        filterMethod: (filter, row) => {
          let status = row._original.PayrollStatus;
          return row._original
            ? String(status).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
      },
      {
        Header: "Action",
        id: "action",
        sortable: false,
        className: "text-align-right",
        width: 100,
        accessor: (record) => {
          return (
            <span>
                   <Link
                  to={{
                    pathname: "/admin/payroll",
                    state: {
                      id: record.ResourceID,
                      PayrollID: record.PayrollID,
                      isShowSalaryMaster: record.isShowSalaryMaster,
                      salaryMonth: this.state.salaryMonth,
                      salaryYear: this.state.salaryYear,
                      selectedStartDate: this.state.selectedStartDate,
                      PayrollStatus:record.PayrollStatus === 'Pending' ? true :false,
                      isEditEnable:record.PayrollStatus === 'Processed' ? false : true ,
                    },
                  }}
                >
                  <Button color="primary" title="Edit" style={{
                    padding: "14px 3px", height: "20px", width: "60px",
                    textAlign: "center"
                  }}>
                    <i className="fas fa-edit"></i>
                  </Button>
                </Link>
            </span>
          );
        },
        filterable: false,
      },
    ];
    const PayrollStatusOptions = [
      { label: 'Select All', value: 'Select All' },
      { label: 'Processed', value: 'Processed' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Draft', value: 'Draft' },
    ];
    
    return (
      <div>
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        <Card>
          <CardHeader>
            <GridContainer className="UserList-outer">
          <GridItem xs={12} sm={this.state.DraftCount > 0 ? 3 : 4} md={this.state.DraftCount > 0 ? 3 : 4}>
                <div className="search-resource margin-top-16">
                  <div className="with-icon">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk={true}
                        variant="inline"
                        openTo="year"
                        views={["year", "month"]}
                        label="Select Year and Month"
                        value={selectedDate}
                        onChange={(e) => this.handleMonthChange(e)}
                      />
                    </MuiPickersUtilsProvider>
                    {/*<Tooltip title="Search" placement="top">
                      <Button
                        onClick={() => this.getPayrollList()}
                        justIcon
                        color="primary"
                      >
                        <SearchIcon />
                      </Button>
        </Tooltip>*/}
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={this.state.DraftCount > 0 ? 3 : 4} md={this.state.DraftCount > 0 ? 3 : 4}>
                <div className="search-resource margin-top-16">
                  <div className="with-icon">
                    <Autocomplete
                      options={DailyStatus}
                      id="DailyStatus"
                      value={this.state.filterVal}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, value) =>
                        this.handleResoureOnChange(value, "ResourceID")
                      }
                      disabled={this.state.ApproveAccess}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={viewDailyTaskErr}
                          helperText={viewDailyTaskHelperText}
                          label="Search Resources Name"
                        />
                      )}
                    />
                  </div>
                </div>
              </GridItem>
                  <GridItem xs={12} sm={this.state.DraftCount > 0 ? 3 : 4} md={this.state.DraftCount > 0 ? 3 : 4}>
                    <div className="search-resource margin-top-16">
                      <div className="with-icon">
                        <Autocomplete
                          options={PayrollStatusOptions}
                          id="PayrollStatus"
                          value={this.state.filterVal.PayrollStatus}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) =>
                            this.handleResoureOnChangeForFilter(value, "PayrollStatus")
                          }
                          
                          renderInput={(params) => (
                            <div className="search-bar">
                              <TextField
                                {...params}
                                label="Status"
                              />
                            </div>
                            
                          )}
                        />
                      </div>
                    </div>
                          </GridItem>
                  {/*
                  <ul>
                    {this.state.filteredPayrollList.map((item) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                    
                    </ul>*/}
                     {this.state.DraftCount > 0 && (
        <GridItem xs={12} sm={this.state.DraftCount > 0 ? 3 : 4} md={this.state.DraftCount > 0 ? 3 : 4}>
          <div className="margin-top-80" style={{ textAlign: 'left'}}>
              <div className="with-icon">
                <Button onClick={() => this.makeProcessed()} color="primary">
                  Process: {this.state.DraftCount} Draft
                </Button>
              </div>
            </div>
          </GridItem>
  //         <GridItem xs={0} sm={0} md={0}>
  //   <div className="makeprocessed" style={{ textAlign: 'right' , marginTop: '-2rem' }}>
  //     <div className="with-icon1" style={{ width: '100%', position: 'relative', paddingRight: '41px' }}>
  //       <Button onClick={() => this.makeProcessed()} color="primary">
  //         Process: {this.state.DraftCount} Draft
  //       </Button>
  //     </div>
  //   </div>
  // </GridItem>

        )}
            </GridContainer>
          </CardHeader>
         {/* <CardBody>
         <ReactTable
            data={
              this.state.isFilterApplied
                ? this.state.filteredPayrollList
                : this.state.PayrollList.length > 0
                ? this.state.PayrollList
                : []
            }
              minRows={5}
              filterable={true}
              sortable={true}
              resizable={false}
              columns={tableColumns}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          </CardBody>*/}
          <CardBody>
          {this.state.isFilterApplied ? (
            <ReactTable
              data={this.state.filteredPayrollList}
              minRows={5}
              filterable={true}
              sortable={true}
              resizable={false}
              columns={tableColumns}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          ) : this.state.PayrollList.length > 0 ? (
            <ReactTable
              data={this.state.PayrollList}
              minRows={5}
              filterable={true}
              sortable={true}
              resizable={false}
              columns={tableColumns}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          ) : null}
        </CardBody>
        </Card>
      </div>
    );
  }
}

PayrollList.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(PayrollList);
