import React, { Component } from 'react';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import Tooltip from '@material-ui/core/Tooltip';
import cogoToast from 'cogo-toast';
import InputAdornment from '@material-ui/core/InputAdornment';
import ImageUploader from 'components/CustomUpload/PictureUpload';
import Wizard from "components/Wizard/Wizard.js";

import Profile from "components/Resource/Profile.js";
import Bank from "components/Resource/Bank.js";
import Other from "components/Resource/Other.js";
// class editbasicResource extends Component {


class editbasicResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditData: [],
      editProfile:this.props.location.state?.editProfile
    };
  }
  
  submit = (e) => {
    if(e.Profile.validate()){
    if(e.Other.validate()){
    var profile  = e.Profile.state ;
    var bank = e.Bank.state;
    var other = e.Other.state

    let formData = new FormData();
    formData.append("UserImg", profile.file);
      let data = {
        UserID: CommonConfig.loginData().PersonID,
        FirstName: profile.FirstName,
        MiddleName: profile.MiddleName,
        LastName: profile.LastName,
        Title : profile.Title,
        Suffix: profile.Suffix,
        Designation: profile.Designation,
        AddressType: profile.AddressType,
        ResourceCode: profile.ResourceCode,
        Line1: profile.Line1,
        Line2: profile.Line2,
        Line3: profile.Line3,
        PAN: profile.PAN,
        Passport: profile.Passport,
        AdharNumber: profile.AdharNumber,
        ContactNumber: profile.ContactNumber,
        Gender: profile.Gender,
        DOB:
          CommonConfig.isEmpty(profile.DOB) != true
            ? moment(profile.DOB,'DD/MM/YYYY').format("YYYY-MM-DD HH:mm:ss").toString()
            : null,
        Email: profile.Email,
        Password: profile.Password,
        Department: profile.Department ? profile.Department.value : '',
        CountryID: profile.Country.value,
        ResourceID: this.props.location.state.id,
        JoiningDate:  CommonConfig.isEmpty(other.JoiningDate) != true ? moment(other.JoiningDate).format("YYYY-MM-DD HH:mm:ss").toString() : null,
        AppraisalDate: CommonConfig.isEmpty(other.AppraisalDate) != true ? moment(other.AppraisalDate).format("YYYY-MM-DD HH:mm:ss").toString() : null,
        BasicSalary: profile.BasicSalary,
        YearlyLeave: other.YearlyLeave,
        ProfessionalTax : profile.ProfessionalTax,
        ComputerName: other.ComputerName,
        Salt: other.Salt,
        CubiclePosition: other.CubiclePosition,
        IPAddress: other.IPAddress,
      BankDetail: bank.viewBankList.filter((x) => x.Status === 'Active'),
      };
      try {        
      formData.append('data', JSON.stringify(data));
    
      api
        .post('resource/addResourceBasic', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.success) {
            cogoToast.success('Resource updated successfully');
            this.props.history.push('/admin/resourceListing');
          } else {
            cogoToast.error('Something went wrong');
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    } catch (err) {
      console.log('error', err);
    }
  }
} else {
  e.state.currentStep = 1;
  e.nextButtonClick();
} 
};

  render() {

    return (
      <GridContainer className="UserList-outer">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="btn-right-outer" color="primary" icon>
              <CardIcon color="primary">
                <HeadsetMic />
              </CardIcon>
              <h4 className="margin-right-auto text-color-black">
                Edit Resource
                  </h4>
            </CardHeader>
            <Wizard
              validate
              ref={wiz => this.wiz = wiz}                                      
              steps={[
                { stepName: "Profile", stepComponent: Profile, stepId: "Profile", editid: this.props.location.state !== undefined ? this.props.location.state.id : '', editProfile: this.state.editProfile },
                { stepName: "Bank", stepComponent: Bank, stepId: "Bank", editid: this.props.location.state !== undefined ? this.props.location.state.id : '' },
                { stepName: "Other", stepComponent: Other, stepId: "Other", editid: this.props.location.state !== undefined ? this.props.location.state.id : '' }
              ]}              
              title=""
              color="primary"
              nextButtonVisible={true}
              // subtitle="This information will let us know more about you."
              finishButtonClick={() => this.submit(this.wiz)}
            />
          </Card>

        </GridItem>
      </GridContainer>
    );
  }
}
export default editbasicResource;
