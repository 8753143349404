import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CreateIcon from "@material-ui/icons/Create";
import api from "../../utils/apiClient";
import Tooltip from "@material-ui/core/Tooltip";
import ReactTable from "react-table";
import { CommonConfig } from "../../utils/constant";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import cogoToast from 'cogo-toast';
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";
import SimpleBackdrop from "../../utils/general";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiBase } from "../../utils/config";
import {faArrowsRotate , faUserPlus} from "@fortawesome/free-solid-svg-icons"

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class InactiveResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      SearchData: "null",
      ResourceList: [],
      ResourceList2: [],
      Textsearch: "",
      searchValue: "",
      Accessvisible: false,
      Frontvisible: false,
      loggedUserID: 0,
      modal: false,
      name: "",
      modalInputName: "",
      Editvisible: false,
      Status: "",
      ResourceID: "",
      TaskStatusDate: "",
    };
  }

  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }

  componentDidMount() {
    this.getResourceList();
    this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
  }

  sendState() {
    return this.state;
  }
  getResourceList = () => {
    this.showLoader()
    try {
      api
        .get("resource/getResourceList")
        .then((res) => {
          this.hideLoader()
          var tmpResourceList = res.data.filter(x => x.Status === 'Inactive');
          this.setState({ ResourceList: tmpResourceList });
          this.setState({ ResourceList2: tmpResourceList });
        })
        .catch((error) => { });
    } catch (err) { }
  };

  handleCancel = () => {
    this.setState({ Editvisible: false });
    window.location.reload();
  };
  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  handleStatusDateChange = (date) => {
    this.setState({
      TaskStatusDate: moment(date, "DD/MM/YYYY")
        .format("YYYY-MM-DD HH:mm:ss")
        .toString(),
    });
  };

  Delete = (data) => {
    this.setState({ Editvisible: true, Loading: true });

    this.setState({
      Status: data.Status,
      ResourceID: data.ResourceID,
    });

  };

  DeleteResource = () => {
    let Data;

    if (this.state.TaskStatusDate == "" && this.state.Status === "Active") {
      cogoToast.error("Please Select Termination Date.");
    } else {
      let Data = {
        TerminationDate:
          this.state.TaskStatusDate != ""
            ? this.state.TaskStatusDate
            : new Date(),
        Status: this.state.Status === "Active" ? "Inactive" : "Active",
        ResourceID: this.state.ResourceID,
      };

      try {
        api
          .post("resource/activateOldResource", Data)
          .then((res) => {
            if (res.success) {
              cogoToast.success("Resource Activated Successfully");
              this.setState({ Editvisible: false });

              window.location.reload();
            } else {
              cogoToast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (err) {
        console.log("error", err);
      }
    }
  };
  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData;
    if (searchInput.length === 0) {
      filteredData = this.state.ResourceList2.filter((value) => {
        return value.FirstName.toLowerCase().includes(
          searchInput.toLowerCase()
        );
      });
    } else {
      filteredData = this.state.ResourceList2.filter((value) => {
        return value.FirstName.toLowerCase().includes(
          searchInput.toLowerCase()
        );
      });
    }

    this.setState({ ResourceList: filteredData });
  };

  render() {
    const { ResourceList, DOB, Editvisible } = this.state;
    const tableColumns = [
      {
        Header: "ID",
        accessor: "ResourceID",
        width: 60,
        id: "ResourceID",
        sortable: true,
      },
      {
        Header: "Image",
        accessor: "UserImg",
        Cell: ({ value: UserImg }) => { // Change 'UserImg' to 'value: UserImg'
         
          return UserImg ? (
            <img
              src={apiBase+ UserImg}
              width="40"
              height="40"
              style={{ borderRadius: "20px" }}
            />
          ): (
              <img
              src={"http://via.placeholder.com/200x200"}
              width="40"
              height="40"
              style={{ borderRadius: "20px" }}
              />  
          );
        },
        width: 70,
        id: "UserImg",
        sortable: true,
      },
      {
        Header: "Name",
        accessor: "FirstName",
        width: 100,
        id: "FirstName",
        sortable: true,
        filterMethod: (filter, row) => {
          let firstname = row._original.FirstName;
          return row._original
            ? String(firstname)
              .toLowerCase()
              .includes(filter.value.toLowerCase())
            : row;
        },
      },
      {
        Header: "Department",
        width: 120,
        accessor: "Department",
        id: "Department",
        sortable: true,
        filterMethod: (filter, row) => {
          let department = row._original.Department;
          return row._original
            ? String(department)
              .toLowerCase()
              .includes(filter.value.toLowerCase())
            : row;
        },
      },
      {
        Header: "Designation",
        width: 190,
        accessor: "Designation",
        id: "Designation",
        sortable: true,

        filterMethod: (filter, row) => {
          let Designation = row._original.Designation;
          return row._original
            ? String(Designation)
              .toLowerCase()
              .includes(filter.value.toLowerCase())
            : row;
        },
      },
      {
        Header: "Salary",
        width: 100,
        accessor: "BasicSalary",
        id: "BasicSalary",
        sortable: true,
        filterMethod: (filter, row) => {
          let salary = row._original.BasicSalary;
          return row._original
            ? String(salary).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
      },
      {
        Header: "Appraisal Date",
        width: 130,
        accessor: (data) => {
          if (CommonConfig.isEmpty(data.AppraisalDate)) {
            return moment().format("DD/MM/YYYY");
          } else {
            return moment(data.AppraisalDate).format(
              CommonConfig.dateFormat.dateOnly
            );
          }
        },
        id: "AppraisalDate",
        sortable: true,
      },
      {
        Header: "Status",
        width: 85,
        id: "Status",
        accessor: (data) => {
          return (
            <>
              <p style={{  height:"20px",  width:"55px", backgroundColor:"red",//"rgb(255, 99, 71 )",
               color: "white", textAlign: "center" ,  "borderRadius": "8px" }} > {data.Status}</p>
            </>
          );
       
        },
      },
      {
        Header: "Action",
        width: 150,
        id: "Action",

        accessor: (data) => {
          return (
            <div className="button-style" style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
            <>
            
             
                <Link
                  to={{
                    pathname: "/admin/editbasicResource",
                    state: { id: data.ResourceID },
                  }}
                >
                <Button color="primary" title="Edit" style={{
                  padding: "14px 3px", height: "20px", width: "60px",
                  textAlign: "center"
                }}>
                  <i className="fas fa-edit"></i>
                </Button>
                </Link>
              
              <Button color="primary" title="Active User" style={{
                marginLeft: "8px", padding: "14px 3px", height: "20px", width: "60px",
                textAlign: "center"
              }} onClick={() => this.Delete(data)}  >
                  <FontAwesomeIcon icon={faUserPlus} />
                  </Button>
            </>
            </div>
          );
        },
      },
    ];
    let { searchInput } = this.state;
    return (
      <GridContainer className="UserList-outer">
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <div className="search-resource">
                <ReactTable
                  data={ResourceList}
                  minRows={5}
                  filterable={true}
                  resizable={false}
                  columns={tableColumns}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
        {this.state.Status == "Inactive" || this.state.Status == "inactive" ? (
          <Dialog
            open={Editvisible}
            title="Credentials"
            onClose={() => this.handleCancel()}
            fullWidth
          >
            <DialogContent>
              <FormControl fullWidth style={{ minHeight: "80px" }}>
                <h4>Are You Sure to Active this User.?</h4>
              </FormControl>
            </DialogContent>

            <DialogActions>
              <Tooltip title="Save" placement="bottom">
                <Button
                  type="submit"
                  color="primary"
                  onClick={() => this.DeleteResource()}
                >
                  Yes
                </Button>
              </Tooltip>
              <Tooltip title="Back" placement="bottom">
                <Button key="back" onClick={() => this.handleCancel()}>
                  No
                </Button>
              </Tooltip>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={Editvisible}
            title="Credentials"
            onClose={() => this.handleCancel()}
            fullWidth
          >
            <DialogContent>
              <FormControl fullWidth style={{ minHeight: "200px" }}>
                <Datetime
                  dateFormat={"DD/MM/YYYY"}
                  timeFormat={false}
                  className="input-group"
                  value=""
                  maxDate={new Date()}
                  inputProps={{ placeholder: "Termination Date" }}
                  onChange={(date) => this.handleStatusDateChange(date)}
                  closeOnSelect={true}
                  renderInput={(params) => (
                    <TextField {...params} label="Termination Date" />
                  )}
                />
                <Icon className="date-icon tp-slam">Inactive Date</Icon>
              </FormControl>
            </DialogContent>

            <DialogActions>
              <Tooltip title="Save" placement="bottom">
                <Button
                  type="submit"
                  color="primary"
                  onClick={(e) => this.DeleteResource(e)}
                >
                  Save
                </Button>
              </Tooltip>
              <Tooltip title="Back" placement="bottom">
                <Button key="back" onClick={() => this.handleCancel()}>
                  Back
                </Button>
              </Tooltip>
            </DialogActions>
          </Dialog>
        )}
      </GridContainer>
    );
  }
}

InactiveResource.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(InactiveResource);
