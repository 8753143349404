import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import jsPDF from "jspdf";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import api from "../../utils/apiClient";
import moment from "moment";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import css1 from "./in.css";
import cogoToast from 'cogo-toast';
const reportTemplateRef = React.createRef(null);


const style = {
    ...customSelectStyle,
};

class PdfCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTaxExempt: "",
            isTaxExemptErr: false,
            isTaxExemptHelperText: "",
            TotalReimbursement: 0,

            pdfBtn: false,
            pdfURL: "",

            LeaveCreditHrs: 0,

            EmployeeCode: "",
            EmployeeCodeErr: false,
            EmployeeCodeHelperText: "",

            BasicSalary: 0,
            BasicSalaryErr: false,
            BasicSalaryHelperText: "",
            LeaveUsedHrs: 0,
            OpeningBalance: 0,
            FilePath: "",
            EmployeeName: "",
            EmployeeNeErr: false,
            EmployeeNameHelperText: "",
            LeaveHoursCount: "",
            Credit1: "",
            Designation: "",
            DesignationErr: false,
            DesignationHelperText: "",
            DesignationList: [],
            PaymentStatusList: [],
            LeaveCreditHrs: 0,
            AmountLimit: "",
            AmountlimitErr: false,
            AmountlimitHelperText: "",

            AmountLimitAsBasicSalary: "",

            AllowanceAmountlimit: "",
            AllowanceAmountlimitErr: false,
            AllowanceAmountlimitHelperText: "",

            Reimbursement: "",
            ReimbursementErr: false,
            ReimbursementHelperText: "",

            AllowanceType: "",
            AllowanceTypeErr: false,
            AllowanceTypeHelperText: "",
            AllowanceTypeList: [],

            AllowanceTypeList_label: [],
            PaymentModeList: [],

            PaymentMode: "",
            payMentModeErr: false,
            paymentModeHelperText: "",

            BankName: "",
            BankNameErr: false,
            BankNameHelperText: "",
            BankNameList: [],
            AllowanceAmountlimit123: {},
            isTaxExempt: false,

            PaymentDate: "",
            PaymentDateErr: false,
            PaymentDateHelperText: "",

            PendingLeaveData: "",
            MethodofPayment: "",
            MethodofPaymentErr: false,
            MethodofPaymentHelperText: "",

            FixTDS: false,

            SalaryPeriod: "",
            TDSAmount: "",
            TDSAmountErr: false,
            TDSAmountHelperText: "",

            IsESICApplicable: false,
            IsPFApplicable: false,
            IsTDSApplicable: false,
            IsLeaveNotApplicable: false,

            isChecked: false,
            allowanceList: [],
            AllowanceTotal: 0,

            ProjectIncentive: 0,
            IncentiveB: "",
            IncentiveBErr: false,
            IncentiveBHelperText: "",
            AllowanceAmount: "",
            OTIncentive: 0,
            OTIncentiveB1Err: false,
            OTIncentiveB1HelperText: "",

            LeaveWithoutPay: 0,
            LeaveWithoutPayErr: false,
            LeaveWithoutPayHelperText: "",

            NonTaxableTotal: 0,
            NonTaxableTotalErr: false,
            NonTaxableTotalHelperText: "",

            Salarydate: "",
            TaxableTotal: 0,
            TaxableTotalErr: false,
            TaxableTotalHelperText: "",

            TotalNonTaxableAmt: "",
            TotalNonTaxableAmtErr: false,
            TotalNonTaxableHelperText: "",

            AllowanceTypes: [],
            GrossAmount: "",
            GrossAmountErr: false,
            GrossAmountHelperText: "",

            EPF: "",
            EmployeePF: "",
            EmployeePFErr: false,
            EmployeePFHelperText: "",
            TotalTaxableAmount: 0,

            EmployeeESIC: 0,
            EmployeeESICErr: false,
            EmployeeESICHelperText: "",
            PaymentModeList: [],
            AllowanceSummary: [],
            BankNameList: [],

            Expenses: "",
            TDS: "",
            isTaxExempt: false,
            FixTDS: false,
            Comment: "",
            Editvisible: false,
            AdjvisibleAmt: false,
            TDSVisible: false,
            ReimbursementVisible: false,
            TotalWorkingHrs: "",
            Status: "",
            NetPayable: 0,
            PayrollID: "",
            PayRollid: null,
            HrsWorkedTD: "",
            ViewAll: false,
            LeaveCredit: 0,
            TotalOtHours: 0,

            PayrollStatus: "",

            ProfessionalTax: "",
            ProfessionalTaxErr: false,
            ProfessionalTaxHelperText: "",

            AdjustmentAmount: "",
            AdjustmentAmountErr: false,
            AdjustmentAmountHelperText: "",

            From: "",
            To: "",

            isShowSalaryMaster: false,
            ReimbursementID: "",
            ZeroLeave: "",
            IsEdited: false,
            AdjustmentDescription: "",
            AdjustmentAmountErr: false,
            AdjustmentAmountHelperText: "",
            WorkingHrsLeave: "",
            ResourceID: "",
            isChecked: false,
            allowanceList: [],
            ExpenceList: [],
            ReimbursementNames: [],
            ReimbursementName: "",
            NetFinalAmount: "",
            Expenses: [],
            mlist: [],
            TotalAllowance: 0,
            SalarySlip: "",
        };
    }

    componentDidMount() {
        this.getPayrollDetails();
        this.getResourceDetails();
    };
    handleGeneratePdf = (PDFpath) => {
        debugger
        console.log("pdfpath", PDFpath)
        // if (this.state.PendingLeaveData > 0) {
        //     cogoToast.error("Please Approve Or Reject Pending Leave.")
        // } else {
        const doc = new jsPDF({
            format: "a1",
            unit: "px",
            orientation: "portrait",
        });

        // Adding the fonts
        doc.setFont("Inter-Regular", "normal");

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save(PDFpath);
            }
        });
        // }


    };

    getPayrollDetails = () => {
        var dateNow = new Date();

        let data;
        data = {
            PayrollID: this.props.location.state.PayrollID,
            ResourceID: this.props.location.state.id,
            Month: this.props.location.state?.salaryMonth
                ? this.props.location.state?.salaryMonth
                : moment(dateNow).format("MM"),
            Year: this.props.location.state?.salaryYear
                ? this.props.location.state?.salaryYear
                : moment(dateNow).format("Y"),
                isAutoCalculateTDS:false
        };



        try {
            api
                .post("payroll/getPayRollByID", data)
                .then((res) => {

                    const LeaveStatusData = res.data[0].filter((data) => data.LeaveStatus == "Pending")

                    this.setState({
                        PendingLeaveData: LeaveStatusData ? LeaveStatusData.length : "",
                        MethodofPayment: res.data[0][0].MethodofPayment
                            ? res.data[0][0].MethodofPayment
                            : "",
                    });

                    var MainObj = res.data[0][0];
                    var ExpenseObj = res.data[2][0];

                    var AllowanceObj = res.data[2][0];
                    this.setState({
                        TotalOtHours: MainObj.TotalOtHours,
                    });
                    this.setState({
                        LeaveCredit: (MainObj.LeaveCredit = "8.5"),
                        LeaveCreditHrs: MainObj.FinalBalance,
                        LeaveUsedHrs: MainObj.LastMonthLeaveUsed,
                        OpeningBalance: MainObj.OpeningBalance,
                    });
                    this.setState({
                        TotalReimbursement: res.data[1][0]?.TotalReimbursement
                            ? res.data[1][0]?.TotalReimbursement
                            : 0,
                        TotalDeduction: MainObj?.TotalDeduction
                            ? MainObj?.TotalDeduction
                            : 0,
                        GrossPayable: MainObj?.GrossPayable ? Math.round(MainObj?.GrossPayable) : 0,
                        // MethodofPayment: MainObj.MethodofPayment
                        //   ? MainObj.MethodofPayment
                        //   : "",
                    });
                    this.setState({
                        // totalReimbursement: ExpenseObj.TotalReimbursement,
                        ResourceID: this.props.location.state.id,
                        PayrollID: MainObj.PayrollID !== null ? MainObj.PayrollID : "",
                        SalaryDate: MainObj.PaymentDate,
                        Reimbursement: MainObj.Reimbursements,
                        OTIncentive: Math.round(MainObj.OTIncentive),
                        // OTIncentive: Math.round(this.state.TotalOtHours / 2 * this.state.AmountLimit / 30 / 8.5),
                        LeaveWithoutPay: Math.round(MainObj.LeaveWithoutPay),
                        TDSAmount: MainObj.TDS,
                        TDS: MainObj.TDS,
                        IsTDSFixed: res.data.FixTDS ? 1 : 0,
                        GrossSalary: MainObj.GrossSalary,
                        BankName: MainObj.BankName,
                        // MethodofPayment: MainObj.MethodofPayment,
                        AdjustmentAmount: MainObj.AdjustmentAmounts,
                        AdjustmentDescription: MainObj.AdjustmentDescription,
                        ProfessionalTax: MainObj.ProfessionalTax,
                        EPF: MainObj.EmployerPF,
                        EmployeeESIC: MainObj.EmployerESIC,
                        ProjectIncentive: MainObj.ProjectIncentive,
                        TotalAllowance: MainObj.TotalAllowance,
                        NonTaxableTotal: MainObj.NonTaxableAllowTotal,
                        TaxableTotal: MainObj.TaxableAllowTotal,
                        TotalTaxableAmount: MainObj.TotalTaxableAmount
                            ? Math.round(MainObj.TotalTaxableAmount)
                            : 0,
                        TotalNonTaxableAmt: MainObj.NonTaxableAllowTotal,
                        GrossAmount: MainObj.GrossPayable,
                        IsESICApplicable: MainObj.IsESICApplicable,
                        IsPFApplicable: MainObj.IsPFApplicable,
                        IsTDSApplicable: MainObj.IsTDSApplicable,
                        IsLeaveNotApplicable: MainObj.IsLeaveNotApplicable,
                        AmountLimitAsBasicSalary: MainObj.AmountLimit,
                        ChequeNumber: MainObj.ChequeNumber,
                        PaymentDate: MainObj.PaymentDate,
                        PayrollProcess: MainObj.PayrollProcess,
                        PayrollStatus: MainObj.PayrollStatus,
                        PayrollError: MainObj.PayrollError,

                        ReimbursementNames: res["data"][4],
                        NetPayable: MainObj.NetPayable ? MainObj.NetPayable : 0,
                        SalaryPeriod: MainObj.SalaryPeriod,
                        // isShowSalaryMaster:  res.data.isShowSalaryMaster,
                        // AllowanceTypes: AllowanceObj,
                    });

                    if (res.data[2] != null && res.data[2].length > 0) {
                        var i = 0;
                        res.data[2].map((Obj) => {
                            Obj.Index = i;
                            Obj.IsTaxExempt = Obj.IsTaxExempt === null ? 0 : Obj.IsTaxExempt;
                            i++;
                            return Obj;
                        });
                        this.setState({ AllowanceTypes: res.data[2] });
                    }
                    var ExpenceListObj = res["data"][3];



                })

                .catch((error) => { });
        } catch (err) { }
    };

    getResourceDetails = () => {
        let data;
        data = {
            ResourceID: this.props.location.state.id,
            PayrollID: this.props.location.state.ResourceID,
            salaryMonth: this.props.location.state.salaryMonth,
            salaryYear: this.props.location.state.salaryYear,
        };
        try {
            api
                .post("payroll/getResourceDetails", data)
                .then((res) => {
                    this.setState({
                        ResourceID: this.props.location.state.id,
                        EmployeeName: res.data.FirstName + " " + res.data.LastName,
                        Designation: res.data.Designation,
                        Salarydate: res.data.SalaryDate
                            ? moment(res.data.SalaryDate).format("DD-MM-YYYY")
                            : "",
                        AmountLimit: res.data.BasicSalary,
                        BasicSalary: res.data.AmountLimit,
                        Reimbursement: res.data.Reimbursements,
                        DateJoined: moment(res.data.JoiningDate).format("DD-MM-YYYY"),
                        EmployeeCode: res.data.ResourceCode,
                        // IsTDSFixed: res.data.FixTDS,
                        ProfessionalTax: res.data.ProfessionalTax,
                        PayrollID: res.data.PayrollID,
                        // isShowSalaryMaster:  res.data.isShowSalaryMaster,
                    });

                })
                .catch((error) => { });
        } catch (err) { }
    };




    render() {

        return (
            <>
                <div className="pdf-wrap" ref={reportTemplateRef} >

                    <table
                        style={{ width: "100%", fontfamily: "Open Sans, sans-serif" }}
                    >
                        <tr>
                            <td>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            <link
                                                rel="apple-touch-icon"
                                                sizes="76x76"
                                                href="%PUBLIC_URL%/apple-icon.png"
                                            />
                                            {/* <img src={logo} alt="logo" className={classes.img} /> */}
                                            <td
                                                style={{
                                                    textAlign: "center",
                                                    fontsize: "24px",
                                                    color: "#008ed3",
                                                    fontweight: "bold",
                                                }}
                                            >
                                                <b>
                                                    <h3>Cognisun Infotech Pvt. Ltd.</h3>
                                                </b>
                                            </td>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <table
                                                style={{
                                                    width: "100%",
                                                    fontsize: "13px",
                                                    bordertop: "1px solid #000",
                                                    bordercollapse: "collapse",
                                                    borderSpacing: "0px"
                                                }}
                                            >
                                                <tr>
                                                    <td style={{ width: "50%", fontSize: "12px" }}>
                                                        <table>
                                                            <tr>
                                                                <td style={{ width: "25%" }}>
                                                                    <b>Employee ID:</b>
                                                                </td>
                                                                <td style={{ width: "25%" }}>{this.state.EmployeeCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "25%" }}>
                                                                    <b>Position:</b>
                                                                </td>
                                                                <td style={{ width: "25%" }}>{this.state.Designation}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "25%" }}>
                                                                    <b>Joining Date:</b>
                                                                </td>
                                                                <td style={{ width: "25%" }}>{this.state.DateJoined}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td style={{ width: "50%", fontSize: "12px" }}>
                                                        <table>
                                                            <tr>
                                                                <td style={{ width: "25%" }}>
                                                                    <b>Employee Name:</b>
                                                                </td>
                                                                <td style={{ width: "25%" }}>{this.state.EmployeeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "25%" }}>
                                                                    <b>Salary Date:</b>
                                                                </td>
                                                                <td style={{ width: "25%" }}>{this.state.SalaryDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "25%" }}>
                                                                    <b>Salary Period:</b>
                                                                </td>
                                                                <td style={{ width: "25%" }}>{this.state.SalaryPeriod}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>


                        <tr>
                            <td>
                                <table
                                    style={{
                                        width: "100%",
                                        fontsize: "13px",
                                        borderCollapse: "collapse",
                                        borderSpacing: "1px",
                                    }}
                                >
                                    <tr>
                                        <td style={{ width: "50%", verticalalign: "top" }}>
                                            <table
                                                style={{
                                                    bordercollapse: "collapse",
                                                    width: "100%",
                                                }}
                                            >
                                                <tr>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "left",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Earning</b>
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        <b>Amount (Rs.)</b>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Basic</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            textAlign: "right",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.AmountLimitAsBasicSalary}
                                                    </td>
                                                </tr>

                                                {this.state.ProjectIncentive != 0 ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Project Incentive</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.ProjectIncentive}
                                                        </td>
                                                    </tr>)
                                                    : ""}

                                                {this.state.OTIncentive != 0 ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>OT Incentive</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.OTIncentive}
                                                        </td>
                                                    </tr>) : ""}
                                                {this.state.LeaveWithoutPay ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Leave Without Pay</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.LeaveWithoutPay}
                                                        </td>
                                                    </tr>
                                                ) : ""}

                                                {this.state.NonTaxableTotal != 0 ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Non Taxable Allowances</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.NonTaxableTotal}
                                                        </td>
                                                    </tr>
                                                ) : ""}
                                                <tr>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Standard Deduction</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            textAlign: "right",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        4167
                                                    </td>
                                                </tr>
                                                {this.state.TaxableTotal != 0 ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Taxable Allowances</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.TaxableTotal}
                                                        </td>
                                                    </tr>
                                                ) : ""}
                                                <tr>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Total Taxable Amount</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            textAlign: "right",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.TotalTaxableAmount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "left",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Gross Earning</b>
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.GrossPayable}
                                                    </th>
                                                </tr>
                                            </table>

                                            {this.state.AdjustmentAmount != 0 || this.state.TotalReimbursement != 0 ? (
                                                <table
                                                    style={{
                                                        bordercollapse: "collapse",
                                                        width: "100%",
                                                        margintop: "10px",
                                                    }}
                                                >
                                                    <tr>
                                                        <th
                                                            style={{
                                                                border: "1px solid #000",
                                                                textAlign: "left",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Others</b>
                                                        </th>
                                                        <th
                                                            style={{
                                                                border: "1px solid #000",
                                                                textAlign: "right",
                                                                padding: "5px",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            <b>Amount (Rs.)</b>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Adjustment</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.AdjustmentAmount}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Reimbursement</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.TotalReimbursement}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th
                                                            style={{
                                                                border: "1px solid #000",
                                                                textAlign: "left",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Others Total</b>
                                                        </th>
                                                        <th
                                                            style={{
                                                                border: "1px solid #000",
                                                                textAlign: "right",
                                                                padding: "5px",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.AdjustmentAmount + this.state.TotalReimbursement}
                                                        </th>
                                                    </tr>
                                                </table>
                                            ) : ""}
                                            <table
                                                style={{
                                                    bordercollapse: "collapse",
                                                    width: "100%",
                                                    margintop: "10px",
                                                }}
                                            >
                                                <tr>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "left",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Net Payable</b>
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.NetPayable}
                                                    </th>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style={{ width: "50%", verticalAlign: "top" }}>
                                            <table
                                                style={{
                                                    bordercollapse: "collapse",
                                                    width: "100%",
                                                }}
                                            >
                                                <tr>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "left",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Deduction</b>
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        <b>Amount (Rs.)</b>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Professional Tax</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            textAlign: "right",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.ProfessionalTax}
                                                    </td>
                                                </tr>
                                                {this.state.TDS != 0 ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>TDS</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.TDS}
                                                        </td>
                                                    </tr>
                                                ) : ""}

                                                {this.state.EmployeeESIC != 0 ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>ESIC</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.EmployeeESIC}
                                                        </td>
                                                    </tr>
                                                ) : ""}
                                                {this.state.EPF != 0 ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>PF</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.EPF}
                                                        </td>
                                                    </tr>
                                                ) : ""}
                                                <tr>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "left",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Total Deduction</b>
                                                    </th>
                                                    <th
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.TotalDeduction}
                                                    </th>
                                                </tr>
                                            </table>
                                            <table
                                                style={{
                                                    bordercollapse: "collapse",
                                                    width: "100%",
                                                    margintop: "10px",
                                                }}
                                            >
                                                <tr>
                                                    <th
                                                        colspan="2"
                                                        style={{
                                                            border: "1px solid #000",
                                                            textAlign: "left",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Leave Summary (Hrs.)</b>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Opening Balance</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            textAlign: "right",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.OpeningBalance}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Leave credited</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            textAlign: "right",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {"8.5"}
                                                    </td>
                                                </tr>

                                                {this.state.LeaveUsedHrs ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Leave Used</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.LeaveUsedHrs * 8.5}
                                                        </td>
                                                    </tr>
                                                ) : ""}
                                                {this.state.TotalOtHours ? (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                borderright: "none",
                                                            }}
                                                        >
                                                            <b>Approved Extra Hours</b>
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid #000",
                                                                padding: "5px",
                                                                textAlign: "right",
                                                                borderleft: "none",
                                                            }}
                                                        >
                                                            {this.state.TotalOtHours / 2}
                                                        </td>
                                                    </tr>
                                                ) : ""}

                                                <tr>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            borderright: "none",
                                                        }}
                                                    >
                                                        <b>Final Balance</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            border: "1px solid #000",
                                                            padding: "5px",
                                                            textAlign: "right",
                                                            borderleft: "none",
                                                        }}
                                                    >
                                                        {this.state.LeaveCreditHrs}
                                                    </td>
                                                </tr>


                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table style={{ width: "50%", fontsize: "13px", marginTop: "30px" }}>
                                    <tr>
                                        <td style={{ width: "25%" }}>
                                            <b>Method of Payment:</b>
                                        </td>
                                        <td style={{ width: "25%" }}>{this.state.MethodofPayment}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>
                                            <b>Cheque Number:</b>
                                        </td>
                                        <td style={{ width: "25%" }}>--</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>
                                            <b>Payment Date:</b>
                                        </td>
                                        <td style={{ width: "25%" }}>{this.state.PaymentDate}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>
                                            <b>Bank Details:</b>
                                        </td>
                                        <td style={{ width: "25%" }}>{this.state.BankName}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>



                        <tr>
                            <td>
                                <table
                                    style={{
                                        width: "100%",
                                        fontsize: "10px",
                                        margintop: "10px",
                                    }}
                                >
                                    <tr>
                                        <td style={{ textAlign: "right", fontsize: "10px", }}>
                                            <b>For Cognisun Infotech Pvt. Ltd.</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right", fontsize: "10px" }}>
                                            <b>
                                                (This is an electronic document and therefore does
                                                not require a signature)
                                            </b>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>


                    </table>
                </div>
                <GridItem xs={12} sm={6} md={6} className="float-right date-filter">
                    <Button key="back" onClick={() => this.handleGeneratePdf(this.state.EmployeeName + '_' + this.state.SalaryDate)} color="rose">
                        Generate PDF
                    </Button>
                </GridItem>
            </>
        );
    }
}

PdfCommon.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(style)(PdfCommon);
