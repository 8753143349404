import React, { Component } from 'react';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Wizard from "components/Wizard/Wizard.js";

import Profile from "components/Resource/Profile.js";
import Bank from "components/Resource/Bank.js";
import Other from "components/Resource/Other.js";

class addbasicResource extends Component {

  constructor(props) {
    super(props);
    this.state = {
      EditData: {}
    }
  }
  
submit = (e) => {
  if(e.Profile.validate()){
    if(e.Other.validate()){
    var profile = e.Profile.state;
    var bank = e.Bank.state;
    var other = e.Other.state;

      let formData = new FormData();
      formData.append("UserImg", profile.file);
      let data = {
        UserID: CommonConfig.loginData().PersonID,
        FirstName: profile.FirstName,
        MiddleName: profile.MiddleName,
        LastName: profile.LastName,
        Title: profile.Title,
        Suffix: profile.Suffix,
        Designation: profile.Designation,
        AddressType: profile.AddressType,
        ResourceCode: profile.ResourceCode,
        Line1: profile.Line1,
        Line2: profile.Line2,
        Line3: profile.Line3,
        PAN: profile.PAN,
        Passport: profile.Passport,
        ContactNumber: profile.ContactNumber,
        AdharNumber: profile.AdharNumber,
        Gender: profile.Gender,
        DOB:
          CommonConfig.isEmpty(profile.DOB) != true
            ? moment(profile.DOB, 'DD/MM/YYYY')
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
            : null,
        Email: profile.Email,
        Password: profile.Password,
        Department: profile.Department ? profile.Department.value : '',
        CountryID: profile.Country.value,
        ResourceID: 0,
        JoiningDate:  CommonConfig.isEmpty(other.JoiningDate) != true ? moment(other.JoiningDate).format("YYYY-MM-DD HH:mm:ss").toString() : null,
        AppraisalDate:  CommonConfig.isEmpty(other.AppraisalDate) != true ? moment(other.AppraisalDate).format("YYYY-MM-DD HH:mm:ss").toString() : null,
        BasicSalary: profile.BasicSalary,
        YearlyLeave: other.YearlyLeave,
        ProfessionalTax: profile.ProfessionalTax,
        ComputerName: other.ComputerName,
        Salt: other.Salt,
        CubiclePosition: other.CubiclePosition,
        IPAddress: other.IPAddress,
        BankDetail: bank.viewBankList.filter(x => x.Status === 'Active'),
      };
      try {
      
        formData.append('data', JSON.stringify(data));
        api
          .post('resource/addResourceBasic', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (res.success) {
              cogoToast.success('Resource added successfully');
              this.props.history.push("/admin/resourceListing");
            
            } else {
              cogoToast.error('Something went wrong');
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (err) {
        console.log('error', err);
      }
    }
  } else {
    e.state.currentStep = 1;
    e.nextButtonClick();
  } 
};

  render() {

    return (
      <GridContainer className="UserList-outer">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="btn-right-outer" color="primary" icon>
              <CardIcon color="primary">
                <HeadsetMic />
              </CardIcon>
              <h4 className="margin-right-auto text-color-black">
                Add Resource
                  </h4>
            </CardHeader>
            <Wizard
              ref={wiz => this.wiz = wiz}
              validate
              steps={[
                { stepName: "Profile", stepComponent: Profile, stepId: "Profile" },
                { stepName: "Bank", stepComponent: Bank, stepId: "Bank" },
                { stepName: "Other", stepComponent: Other, stepId: "Other" }
              ]}
              title=""
              color="primary"
              nextButtonVisible={true}
              // subtitle="This information will let us know more about you."
              finishButtonClick={() => this.submit(this.wiz)}
            />
            
            {/* ref={foo => this.foo = foo} */}
          </Card>
          {/*<Button color="primary" onClick={()=>this.submit(this.wiz)}>Save</Button>*/}
        </GridItem>
      </GridContainer>
    );
  }
}
export default addbasicResource;
