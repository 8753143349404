import React, { Component } from "react";
import cogoToast from 'cogo-toast';
// core components
import Icon from "@material-ui/core/Icon";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CardHeader from "components/Card/CardHeader.js";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from "../../utils/constant";
import api from "../../utils/apiClient";
import Datetime from "react-datetime";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from "@material-ui/icons/Create";
import ReactTable from "react-table";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormHelperText, InputLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SimpleBackdrop from "../../utils/general";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class viewDailyTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Subject: "",
      filterVal: "",
      HrsToday: "",
      HrsTodayErr: false,
      HrsTodayHelperText: "",

      ResourceName: "",

      TaskStatusDate: moment(),
      TaskStatusDateErr: false,
      TaskStatusDateHelperText: "",

      filterType: "",

      PercentageCompleted: "",
      PercentageCompletedErr: "",
      PercentageCompletedHelperText: "",

      TaskList: [],
      filterData: [],
      notesList: [],

      BlockerID: "",
      StringMapName: "",
      BlockersList: [],
      viewBlockerList: [],

      TaskStatus: "",

      StartDate: "",
      FilterDate: moment(),
      filterDate: "",

      Notes: "",
      NotesErr: false,
      NotesHelperText: "",

      DailyTaskID: "",
      UpdatedOn: moment(),
      EstimetedStartDate: "",
      TotalHours: "",
      Editvisible: false,
      loggedUserID: 0,
      inProgressFilter: "",
      openFilter: "",
      resolvedFilter: "",
      closedFilter: "",
      todayFilter: "",
      ResourceID: "",
      DailyStatusList: [],
      filterString: "",
      TaskID: "",
      ProjectKey: "",
      currentDay: moment().startOf("day").format("dddd"),
      searchToggle: true,
      Loading: false,

      allFilter: "primary",
      DetailTotalHours: [],
      ApproveAccess: true,
      lastEditDate: "",
      checked: false,
    };
  }

  componentDidMount() {
    if (CommonConfig.getUserAccess("Daily Status")) {
      this.setState({
        ApproveAccess:
          CommonConfig.getUserAccess("Daily Status").AllAccess === 1
            ? false
            : true,
      });
    }
    this.getDailyStatusList();
    this.getBlockersList();
    this.getTaskList();
  }

  getTaskList = (filter) => {
    let data;
    if (!CommonConfig.isEmpty(filter)) {
      data = {
        ResourceID: filter.value,
      };
    } else {
      data = {
        ResourceID: CommonConfig.loginData().PersonID,
      };
    }
    try {
      api
        .post("dailystatus/getTaskByID", data)
        .then((res) => {
          this.setState({
            TaskList: res.data.TaskData,
            Loading: false,
            LastEditDaysAgo: res.data.NoOfDays ? res.data.NoOfDays : 0,
          });
          // var dates = res.data.map((x)=> { return new Date(x.UpdatedOn != null ?x.UpdatedOn : x.CreatedOn); })
          if (
            res.data.NoOfDays > 3 &&
            res.data.TaskData.filter((x) => x.IsNotFreeze == 0).length > 0
          ) {
            this.setState({ checked: false });
          } else {
            this.setState({ checked: true });
          }
          // this.setState({lastEditDate: new Date(Math.max.apply(null,dates))});
          this.applyFilter("ALL");
        })
        .catch((error) => {});
    } catch (err) {}
  };

  getBlockersList = () => {
    let data = {
      StringMapType: "BLOCKERS",
    };
    try {
      api
        .post("stringmap/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ BlockersList: res.data });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {}
  };
  redirectEdit = () => {
    this.props.history.push({
      pathname: "/admin/editDailyTasks",
      state: { id: this.state.TaskID },
    });
  };
  onChange = (e, type) => {
    if (type === "Notes") {
      this.setState({
        Notes: e.target.value,
        NotesErr: false,
        NotesHelperText: "",
      });
    }
    if (type === "HrsToday") {
      this.setState({
        HrsToday: e.target.value,
        HrsTodayErr: false,
        HrsTodayHelperText: "",
      });
    }
    if (type === "EstimetedEndDate") {
      this.setState({ EstimetedEndDate: e.target.value });
    }
  };
  selectChange = (e, type) => {
    if (type === "Blockers") {
      this.setState({ StringMapName: e.target.value });
    }
    if (type === "PercentageCompleted") {
      if (Number(e.target.value) >= 1 && Number(e.target.value) <= 99) {
        this.setState({ TaskStatus: "IN PROGRESS" });
        this.notes(
          this.state.PercentageCompleted,
          Number(e.target.value),
          "Task",
          "Status"
        );
      } else if (Number(e.target.value) === 100) {
        this.setState({ TaskStatus: "RESOLVED" });
        this.notes(this.state.TaskStatus, "RESOLVED", "Task", "Status");
      } else {
        this.setState({ TaskStatus: "OPEN" });
      }
      this.setState({
        PercentageCompleted: e.target.value,
        PercentageCompletedErr: false,
        PercentageCompletedHelperText: "",
      });
    }
  };
  handleDate = (date, type) => {
    if (type === "TaskStatusDate") {
      this.setState({
        TaskStatusDate: date,
        TaskStatusDateErr: false,
        TaskStatusDateHelperText: "",
      });
    }
    if (type === "FilterDate") {
      this.setState({ FilterDate: date });
    }
  };
  handleFilterDate = (date) => {
    this.setState({ FilterDate: date });
    if (this.state.filterType === "TODAY") {
      var filterDate = this.state.TaskList.filter(
        (x) =>
          moment(x.TaskStatusDate).format("DD-MM-YYYY") ==
          moment(date).format("DD-MM-YYYY")
      );
      this.setState({ isfilter: true, filterData: filterDate });
    }
  };
  handleStatusDateChange = (date) => {
    this.setState({ TaskStatusDate: date });
  };

  blockerslist = () => {
    return this.state.BlockersList.map((content) => {
      return (
        <MenuItem value={content.StringMapKey}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  viewBlockers = () => {
    return this.state.viewBlockerList
      .filter((x) => x.Status === "Active")
      .map((content) => {
        return (
          <tr>
            <td style={{ maxWidth: 600, margin: 0, height: 68, width: 600 }}>
              <div>{content.BlockerName}</div>
            </td>
            <td>
              <DeleteIcon
                onClick={() => this.removeBlocker(content.BlockerName)}
              />
            </td>
          </tr>
        );
      });
  };

  notes = (prevData, nextData, Type, Title) => {
    let data = {
      CreatedBy: CommonConfig.loginData().PersonID,
      NoteText: prevData + " => " + nextData,
      Index: this.state.notesList.length + 1,
      EntityID: this.state.TaskID,
      EntityType: Type,
      NoteTitle: Title,
      NoteID: null,
      Status: "Active",
    };
    this.setState({ notesList: [...this.state.notesList, data] });
  };

  applyFilter = (value) => {
    if (value === "ALL") {
      var filterTask = this.state.TaskList.filter(
        (v, i, a) => a.findIndex((t) => t.TaskID === v.TaskID) === i
      );
      this.setState({
        isfilter: true,
        openFilter: "",
        resolvedFilter: "",
        closedFilter: "",
        todayFilter: "",
        filterData: filterTask,
        filterType: value,
        inProgressFilter: "",
        allFilter: "active",
      });
    } else {
      var filterTask = this.state.TaskList.filter(
        (v, i, a) =>
          a.findIndex((t) => t.TaskID === v.TaskID) === i &&
          v.TaskStatus === value
      );

      if (value === "IN PROGRESS")
        this.setState({
          isfilter: true,
          openFilter: "",
          resolvedFilter: "",
          closedFilter: "",
          todayFilter: "",
          filterData: filterTask,
          filterType: value,
          inProgressFilter: "active",
          allFilter: "",
        });
      if (value === "OPEN")
        this.setState({
          isfilter: true,
          inProgressFilter: "",
          resolvedFilter: "",
          closedFilter: "",
          todayFilter: "",
          filterData: filterTask,
          filterType: value,
          openFilter: "active",
          allFilter: "",
        });
      if (value === "RESOLVED")
        this.setState({
          isfilter: true,
          inProgressFilter: "",
          openFilter: "",
          closedFilter: "",
          todayFilter: "",
          filterData: filterTask,
          filterType: value,
          resolvedFilter: "active",
          allFilter: "",
        });
      if (value === "CLOSED")
        this.setState({
          isfilter: true,
          inProgressFilter: "",
          openFilter: "",
          resolvedFilter: "",
          todayFilter: "",
          filterData: filterTask,
          filterType: value,
          closedFilter: "active",
          allFilter: "",
        });
    }
  };
  applyTodayFilter = (value) => {
    let date = new Date(this.state.FilterDate);
    var filterDate = this.state.TaskList.filter(
      (x) =>
        moment(x.TaskStatusDate).format("DD-MM-YYYY") ==
        moment(date).format("DD-MM-YYYY")
    );
    this.setState({
      isfilter: true,
      inProgressFilter: "",
      openFilter: "",
      resolvedFilter: "",
      closedFilter: "",
      filterData: filterDate,
      filterType: value,
      todayFilter: "active",
      allFilter: "",
    });
  };

  handleCancel = () => {
    this.setState({ Editvisible: false });
  };

  validate = () => {
    const { Notes, PercentageCompleted, TaskStatusDate, HrsToday } = this.state;
    let IsValid = true;

    if (CommonConfig.isEmpty(Notes)) {
      IsValid = false;
      this.setState({ NotesHelperText: "Please enter Notes", NotesErr: true });
    }
    if (CommonConfig.isEmpty(HrsToday)) {
      IsValid = false;
      this.setState({
        HrsTodayHelperText: "Please enter HrsToday",
        HrsTodayErr: true,
      });
    }
    if (CommonConfig.isEmpty(PercentageCompleted)) {
      IsValid = false;
      this.setState({
        PercentageCompletedHelperText: "Please enter Percentage Completed",
        PercentageCompletedErr: true,
      });
    }
    if (CommonConfig.isEmpty(TaskStatusDate)) {
      IsValid = false;
      this.setState({
        TaskStatusDateHelperText: "Please enter Task Status Date",
        TaskStatusDateErr: true,
      });
    }
    return IsValid;
  };

  update = (e) => {
    const {
      HrsToday,
      TotalHours,
      TaskID,
      TaskStatus,
      TaskStatusDate,
      notesList,
      PercentageCompleted,
      Notes,
      ProjectID,
      ResourceID,
      BlockerID,
    } = this.state;
    if (this.validate()) {
      let data = {
        UserID: CommonConfig.loginData().PersonID,
        ProjectID: ProjectID,
        TaskID: TaskID,
        ResourceID: ResourceID,
        PercentageCompleted: CommonConfig.isEmpty(PercentageCompleted)
          ? 10
          : PercentageCompleted,
        TaskStatus: TaskStatus,
        HrsToday: HrsToday,
        TotalHours: TotalHours,
        Notes: Notes,
        TaskStatusDate:
          CommonConfig.isEmpty(TaskStatusDate) !== true
            ? moment(TaskStatusDate).format("YYYY-MM-DD").toString()
            : moment().format("YYYY-MM-DD").toString(),
        DailyTaskID:
          CommonConfig.isEmpty(this.state.DailyTaskID) == true
            ? null
            : this.state.DailyTaskID,
        notesList: notesList,
        viewBlockerList: this.state.viewBlockerList,
      };

      try {
        api
          .post("dailystatus/addUpdateDailyStatus", data)
          .then((res) => {
            debugger;
            if (res.success) {
              cogoToast.success("Status updated successfully");
              this.setState({ Editvisible: false });
              window.location.reload();
            } else {
              cogoToast.error("Something went wrong");
            }
          })
          .catch((error) => {});
      } catch (err) {
        console.log("error", err);
      }
    } else {
      cogoToast.error("Please correct error and resubmit the form");
    }
  };

  updateFreezed = (e) => {
    let data = {
      ResourceID: this.state.filterVal ? this.state.filterVal.value : null,
      IsNotFreeze: e.target.checked === true ? 1 : 0,
    };

    try {
      api
        .post("dailystatus/UpdateFreezedTask", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success("Access updated successfully");
            this.getTaskList(this.state.filterVal);
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((error) => {});
    } catch (err) {
      console.log("error", err);
    }
  };

  addBlocker = (e) => {
    const {
      PersonID,
      StringMapName,
      TaskStatusDate,
      DailyTaskID,
      BlockerID,
    } = this.state;
    var addBlocker = this.state.viewBlockerList.filter(
      (x) => x.Status === "Active" && x.BlockerName === StringMapName
    );
    if (addBlocker.length === 0) {
      let data = {
        CreatedBy: CommonConfig.loginData().PersonID,
        BlockerName: this.state.StringMapName,
        TaskStatusDate:
          CommonConfig.isEmpty(TaskStatusDate) !== true
            ? moment(TaskStatusDate).format("YYYY-MM-DD").toString()
            : null,
        BlockerID: null,
        Status: "Active",
      };
      this.setState({ viewBlockerList: [...this.state.viewBlockerList, data] });
    } else {
      cogoToast.error("Blocker Already exist");
    }
  };

  removeBlocker = (idx) => {
    const removeBlocker = [...this.state.viewBlockerList];
    var Index = this.state.viewBlockerList.findIndex(
      (x) => x.BlockerName === idx
    );
    if (Index !== -1) {
      removeBlocker[Index]["Status"] = "Inactive";
      this.setState({ viewBlockerList: removeBlocker });
    }
  };

  increaseDate = () => {
      var currentDay = moment(this.state.FilterDate)
        .startOf("day")
        .add(1, "day")
        .format("dddd");
      this.setState({ currentDay: currentDay });
      let date = new Date(this.state.FilterDate);
      date.setDate(date.getDate() + 1);
      this.setState({ FilterDate: date });
      var filterDate = this.state.TaskList.filter(
        (x) =>
          moment(x.TaskStatusDate).format("DD-MM-YYYY") ==
          moment(date).format("DD-MM-YYYY")
      );
      this.setState({ isfilter: true, filterData: filterDate });
  };
  decreaseDate = () => {
      var currentDay = moment(this.state.FilterDate)
        .startOf("day")
        .subtract(1, "day")
        .format("dddd");
      this.setState({ currentDay: currentDay });
      let date = new Date(this.state.FilterDate);
      date.setDate(date.getDate() - 1);
      this.setState({ FilterDate: date });
      var filterDate = this.state.TaskList.filter(
        (x) =>
          moment(x.TaskStatusDate).format("DD-MM-YYYY") ==
          moment(date).format("DD-MM-YYYY")
      );

      this.setState({ isfilter: true, filterData: filterDate });
  };

  edit = (record) => {
    this.setState({ Editvisible: true, Loading: true });
    let data;
    if (
      !CommonConfig.isEmpty(record.original.DailyTaskID) &&
      this.state.filterType === "TODAY"
    ) {
      data = {
        DailyTaskID: record.original.DailyTaskID,
      };
    } else {
      data = {
        TaskID: record.original.TaskID,
      };
    }
    try {
      api
        .post("dailystatus/getDailyTaskByID", data)
        .then((res) => {
          if (res.success) {
            if (
              res.data.blockersList != null &&
              res.data.blockersList.length > 0
            ) {
              var i = 0;
              res.data.blockersList.map((Obj) => {
                Obj.Index = i;
                i++;
                return Obj;
              });
              this.setState({ viewBlockerList: res.data.blockersList });
            }

            this.setState({
              UpdatedOn: CommonConfig.isEmpty(res.data.UpdatedOn)
                ? moment()
                : res.data.UpdatedOn,
              DueDate: res.data.DueDate,
              DailyTaskID: res.data.DailyTaskID,
              ProjectName: res.data.ProjectName,
              TaskID: res.data.TaskID,
              ProjectKey: res.data.ProjectKey,
              ProjectID: res.data.ProjectID,
              Subject: res.data.Subject,
              Notes: res.data.Notes,
              ResourceID: res.data.ResourceID,
              FirstName: res.data.FirstName,
              LastName: res.data.LastName,
              PercentageCompleted: CommonConfig.isEmpty(
                res.data.PercentageCompleted
              )
                ? 10
                : res.data.PercentageCompleted,
              HrsToday: res.data.HrsToday,
              TotalHours: res.data.TotalHours,
              TaskStatusDate: CommonConfig.isEmpty(res.data.TaskStatusDate)
                ? moment()
                : res.data.TaskStatusDate,
              EstimetedStartDate: res.data.EstimetedStartDate,
              EstimetedEndDate: res.data.EstimetedEndDate,
              TaskStatus: res.data.TaskStatus,
              StartDate: res.data.StartDate,
              EndDate: res.data.EndDate,
              Loading: false,
            });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((error) => {});
    } catch (err) {}
  };
  getDailyStatusList = () => {
    try {
      api
        .get("dailystatus/getDailyStatusList")
        .then((res) => {
          if (res.success) {
            let obj = {
              FirstName: "Select All",
              LastName: "",
              ResourceID: "",
            };
            res.data.push(obj);
            res.data.map((r, i) => {
              res.data[i].FullName = `${r.FirstName} ${r.LastName}`;
            });
            this.setState({
              DailyStatusList: res.data,
              filterVal: {
                value: CommonConfig.loginData().PersonID,
                label:
                  CommonConfig.loginData().FirstName +
                  " " +
                  CommonConfig.loginData().LastName,
              },
            });
          }
        })
        .catch((error) => {});
    } catch (err) {}
  };
  redirectView = (record) => {
    this.getTaskList();

    this.getBlockersList();
  };
  requestChange = (e, value, type) => {
    if (type === "DailyStatus") {
      this.setState({
        DailyStatus: value,
        DailyStatusErr: false,
        DailyStatusHelperText: "",
      });
    }
  };
  handleResoureOnChange = (value) => {
    if (value !== null) {
      this.setState({ filterVal: value, Loading: true });
      this.getTaskList(value);
      this.getBlockersList();
    } else {
      this.setState({ filterVal: "", Loading: true });
      this.getTaskList();
      this.getBlockersList();
    }
  };
  viewTotalHrsDetail = (record) => {
    let data = {
      TaskID: record.original.TaskID,
      ResourceID: record.original.ResourceID,
    };
    try {
      api.post("dailystatus/getTaskHoursBreakdown", data).then((res) => {
        if (res.success) {
          var taskHrs = [];
          for (let i = 0; i < res.data.length; i++) {
            taskHrs.push({
              StartDate: res.data[i].TaskStatusDate
                ? moment(res.data[i].TaskStatusDate).format("DD/MM/YYYY")
                : "",
              Day: res.data[i].TaskStatusDate
                ? moment(res.data[i].TaskStatusDate)
                    .startOf("day")
                    .format("dddd")
                : "",
              PercentageComplete: res.data[i].PercentageCompleted,
              Devloper: res.data[i].HrsToday
                ? res.data[i].FirstName + " " + res.data[i].LastName
                : "",
              Hours: res.data[i].HrsToday,
              Note: res.data[i].Notes,
            });
          }
          this.setState({
            DetailTotalHours: taskHrs,
            IsTotalHrsActive: true,
          });
        } else {
          cogoToast.error("Something went wrong");
        }
      });
    } catch {}
  };
  searchTodayTotalHrslen = (len, e) => {
    var sum = 0;
    for (let i = 0; i < len.length; i++) {
      sum = sum + len[i]._original.HrsToday;
    }
    this.setState({ searchfinalLength: sum });
  };
  searchCheckProps = (e) => {
    if (this.state.isfilter && this.state.filterType == "TODAY") {
      var sum = 0;
      for (let i = 0; i < e.sortedData.length; i++) {
        sum = sum + e.sortedData[i]._original.HrsToday;
      }
      if (this.state.searchfinalLength !== sum) {
        this.searchTodayTotalHrslen(e.sortedData);
      }
    }
    return "";
  };
  TaskSearchFilter = (event) => {
    this.setState({ TaskSearch: event.target.value }, () => {
      if (this.state.TaskSearch) {
        var filterTask = [];
        let TaskList = this.state.TaskList.filter(
          (v, i, a) => a.findIndex((t) => t.TaskID === v.TaskID) === i
        );
        filterTask = TaskList.filter(
          (x) =>
            x.Subject.toLowerCase().indexOf(
              this.state.TaskSearch.toLowerCase()
            ) !== -1
        );
        this.setState({ isfilter: true, filterData: filterTask });
      }
    });
  };
  onClick = (value) => {
    this.getTaskList();
  };

  days_passed = (date) => {
    // var current = new Date();
    // var previous = new Date(date.getFullYear(), 0, 1);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // const firstDate = new Date();
    // const secondDate = new Date(date);
    // return Math.round(Math.abs((firstDate - secondDate) / oneDay));

    var iWeeks,
      iDateDiff,
      iAdjust = 0;
    var dDate2 = new Date();
    var dDate1 = new Date(date);
    if (dDate2 < dDate1) return -1;
    var iWeekday1 = dDate1.getDay();
    var iWeekday2 = dDate2.getDay();
    iWeekday1 = iWeekday1 == 0 ? 7 : iWeekday1;
    iWeekday2 = iWeekday2 == 0 ? 7 : iWeekday2;
    if (iWeekday1 > 5 && iWeekday2 > 5) iAdjust = 1;
    iWeekday1 = iWeekday1 > 5 ? 5 : iWeekday1;
    iWeekday2 = iWeekday2 > 5 ? 5 : iWeekday2;
    // iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)
    iWeeks = Math.round(Math.abs((dDate2 - dDate1) / oneDay));
    if (iWeekday1 <= iWeekday2) {
      iDateDiff = iWeeks * 5 + (iWeekday2 - iWeekday1);
    } else {
      iDateDiff = (iWeeks + 1) * 5 - (iWeekday1 - iWeekday2);
    }

    iDateDiff -= iAdjust;
    return iDateDiff;
  };

  render() {
    const {
      TaskList,
      ResourceID,
      FilterApplied,
      FirstName,
      LastName,
      TotalHours,
      DetailTotalHours,
      inProgressFilter,
      openFilter,
      resolvedFilter,
      closedFilter,
      todayFilter,
      StringMapName,
      NotesHelperText,
      NotesErr,
      allFilter,
      ProjectKey,
      Notes,
      Editvisible,
      ProjectName,
      TaskID,
      Subject,
      StartDate,
      DueDate,
      UpdatedOn,
      HrsToday,
      PercentageCompleted,
      PercentageCompletedErr,
      PercentageCompletedHelperText,
      TaskStatusDate,
      viewDailyTaskHelperText,
      viewDailyTaskErr,
      HrsTodayErr,
      HrsTodayHelperText,
      LastEditDaysAgo,
    } = this.state;
    const DailyStatus = this.state.DailyStatusList.map((DailyStatus) => {
      return { value: DailyStatus.ResourceID, label: DailyStatus.FullName };
    });
    const { viewDailyTasks, filterString } = this.state;
    const tableColumns = [
      {
        Header: "Key",
        accessor: (data) => {
          return (
            <Tooltip
              arrow
              title={
                data.ProjectName.split(" ").join("") + "-" + data.ProjectKey
              }

            >
              <span>
                {data.ProjectName.substring(0, 4) + "-" + data.ProjectKey}
              </span>
            </Tooltip>
          );
        },
        id: "KeyID",
        sortingMethod: (sorting, row) => {
          let subject = row._original.Subject;
          return row._original
            ? String(subject).startOf().includes(sorting.value.startOf())
            : row;
        },
        // sortable: true,
        filterMethod: (filter, row) => {
          let key =
            row._original.ProjectName && row._original.ProjectKey
              ? row._original.ProjectName + " " + row._original.ProjectKey
              : "";
          return row._original
            ? String(key).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        width: 100,
      },
      {
        Header: "Project",
        accessor: (data) => {
          return (
            <Tooltip title={data.ProjectName} arrow>
              <span>{data.ProjectName}</span>
            </Tooltip>
          );
        },
        id: "ProjectID",
        sortable: true,
        filterMethod: (filter, row) => {
          let projectname = row._original.ProjectName;
          return row._original
            ? String(projectname)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        width: 100,
        className: "nowrap",
      },
      {
        Header: "Task",
        accessor: (data) => {
          return (
            <Tooltip title={data.Subject} arrow>
              <span> {data.Subject} </span>
            </Tooltip>
          );
        },
        id: "TaskID",
        sortable: true,
        filterMethod: (filter, row) => {
          let subject = row._original.Subject;
          return row._original
            ? String(subject).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        className: "nowrap",
        width: 230,
      },
      {
        Header: "Status",
        width: 120,
        accessor: (data) => {
          return (
            <Tooltip title={data.TaskStatus} arrow>
              <span
                className={
                  "badge badge-" +
                  data.TaskStatus.split(" ").join("").toLowerCase()
                }
              >
                {data.TaskStatus}
              </span>
            </Tooltip>
          );
          
        },
        id: "Status",
        filterMethod: (filter, row) => {
          let taskStatus = row._original.TaskStatus;
          return row._original
            ? String(taskStatus)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
      },
      {
        Header: "Start",
        accessor: (data) => {
          if (CommonConfig.isEmpty(data.StartDate)) {
            return null;
          } else {
            return moment(data.StartDate).format(
              CommonConfig.dateFormat.dateFirst
            );
          }
        },
        id: "StartDate",
        filterMethod: (filter, row) => {
          let startdate = row._original.StartDate;
          return row._original
            ? String(startdate)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
        width: 100,
      },
      {
        Header: "Est Completion", 
        accessor: (data) => {
          if (CommonConfig.isEmpty(data.DueDate)) {
            return null;
          } else {
            let duedate = new Date(data.DueDate);
            let currentDate = new Date();
            currentDate.setHours(23, 59, 59, 0);
            return currentDate >= duedate &&
              data.TaskStatus !== "CLOSED" &&
              data.TaskStatus !== "RESOLVED" ? (
              <div style={{ color: "red" }}>
                {moment(data.DueDate).format(CommonConfig.dateFormat.dateFirst)}
              </div>
            ) : (
              <div>
                {moment(data.DueDate).format(CommonConfig.dateFormat.dateFirst)}
              </div>
            );
          }
          
        },
        id: "EstimetedEndDate",
        filterMethod: (filter, row) => {
          let duedate = row._original.DueDate;
          return row._original
            ? String(duedate).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
        width: 100,
      },
      {
        // className: "text-align-right",
        Header: "% Complete",
        accessor: "PercentageCompleted",
        id: "PercentageCompleted",
        sortable: true,
        filterMethod: (filter, row) => {
          let percentageCompleted = row._original.PercentageCompleted;
          return row._original
            ? String(percentageCompleted)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        width: 100,
      },
      {
        // className: "text-align-right",
        Header: "Hours",
        accessor: (data) => {
          if (
            moment(data.TaskStatusDate).format("DD-MM-YYYY") ===
            moment().format("DD-MM-YYYY")
          ) {
            return data.HrsToday;
          } else {
            return 0;
          }
        },
        id: "HrsToday",
        filterMethod: (filter, row) => {
          let hrstoday = row._original.HrsToday;
          return row._original
            ? String(hrstoday)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
        width: 80,
      },
      {
        Header: "Total Hours",
        accessor: "TotalHours",
        id: "TotalHours",
        sortable: true,
        Cell: (record) => {
          return (
            <span className="daily-total-hr">
            <>
            <p style={{
              height: "22px", width: "55px", backgroundColor: "#3B56A5", "color": "white",//"rgba(0, 0, 255, 0.5)",
              textAlign: "center", "borderRadius": "8px"
            }} > {record.original.TotalHours ? record.original.TotalHours : 0}</p>
          </>
              
            </span>
          );
        },
        filterMethod: (filter, row) => {
          let totalHours = row._original.TotalHours;
          return row._original
            ? String(totalHours)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        width: 100,
      },
      {
        Header: "Action",
        id: "action",
        sortable: false,
        className: "text-align-right",
        Cell: (record) => {
          return (
            <span>
              <Tooltip title="Edit" placement="bottom">
                <Button
                  disabled={
                    LastEditDaysAgo > 3 &&
                    this.state.TaskList.filter((x) => x.IsNotFreeze == 0)
                      .length > 0
                      ? true
                      : false
                  }
                  className="btn-icon"
                  justIcon
                  color="primary"
                  onClick={() => this.edit(record)}
                >
                  <CreateIcon />
                </Button>
              </Tooltip>
            </span>
          );
        },
        filterable: false,
        width: 70,
      },
    ];
    const tableColumnsTotalHrs = [
      {
        Header: "Date",
        accessor: "StartDate",
        id: "Date",
        sortable: true,
        filterable: true,
      },
      {
        Header: "Day",
        accessor: "Day",
        id: "Day",
        sortable: true,
        filterable: true,
      },
      {
        Header: "Devloper",
        accessor: "Devloper",
        id: "Devloper",
        sortable: true,
        filterable: true,
      },
      {
        Header: "%Completion",
        accessor: "PercentageComplete",
        id: "%Completion",
        sortable: true,
        filterable: true,
      },
      {
        Header: "Hours",
        accessor: "Hours",
        Header: "Hours",
        id: "Hours",
        sortable: true,
        filterable: true,
      },
      {
        Header: "Note",
        accessor: (data) => {
          return (
            <Tooltip title={data.Note} arrow>
              <span> {data.Note} </span>
            </Tooltip>
          );
        },
        id: "Note",
        sortable: true,
        filterable: true,
        className: "nowrap",
      },
    ];
    const pendingTableColumns = [
      {
        Header: "Key",
        accessor: (data) => {
          return (
            <Tooltip
              title={
                data.ProjectName.split(" ").join("") + "-" + data.ProjectKey
              }
              arrow
            >
              <span>
                {data.ProjectName.substring(0, 4) + "-" + data.ProjectKey}
              </span>
            </Tooltip>
          );
        },
        id: "ProjectID",
        filterMethod: (filter, row) => {
          let key =
            row._original.ProjectName && row._original.ProjectKey
              ? row._original.ProjectName + " " + row._original.ProjectKey
              : "";
          return row._original
            ? String(key).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
        width: 100,
      },
      {
        Header: "Project Task",
        accessor: "ProjectName",
        Cell: (record) => {
          return (
            <Tooltip title={record.original.ProjectName} arrow>
              <span>{record.original.ProjectName}</span>
            </Tooltip>
          );
        },
        id: "ProjectName",
        sortable: true,
        filterMethod: (filter, row) => {
          let projectname = row._original.ProjectName;
          return row._original
            ? String(projectname)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        className: "nowrap",
        width: 150,
      },
      {
        Header: "Task",
        accessor: (data) => {
          return (
            <Tooltip title={data.Subject} arrow>
              <span> {data.Subject} </span>
            </Tooltip>
          );
        },
        id: "TaskID",
        sortable: true,
        filterMethod: (filter, row) => {
          let subject = row._original.Subject;
          return row._original
            ? String(subject).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        width: 400,
        className: "nowrap",
      },
      {
        Header: "Status",
        accessor: (data) => {
          return (
            <Tooltip title={data.TaskStatus} arrow>
              <span
                className={
                  "badge badge-" +
                  data.TaskStatus.split(" ").join("").toLowerCase()
                }
              >
                {data.TaskStatus}
              </span>
            </Tooltip>
          );
        },
        id: "Status",
        filterMethod: (filter, row) => {
          let taskStatus = row._original.TaskStatus;
          return row._original
            ? String(taskStatus)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
      },
      {
        Header: "Est Start",
        accessor: (data) => {
          if (CommonConfig.isEmpty(data.StartDate)) {
            return null;
          } else {
            return moment(data.StartDate).format(
              CommonConfig.dateFormat.dateFirst
            );
          }
        },
        id: "StartDate",
        sortable: true,
        filterMethod: (filter, row) => {
          let startDate = row._original.StartDate;
          return row._original
            ? String(startDate)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        width: 90,
      },
      {
        Header: "Est Completion",
        accessor: (data) => {
          if (CommonConfig.isEmpty(data.DueDate)) {
            return null;
          } else {
            return moment() >= moment(data.DueDate) ? (
              <div style={{ color: "red" }}>
                {moment(data.DueDate).format(CommonConfig.dateFormat.dateFirst)}
              </div>
            ) : (
              <div>
                {moment(data.DueDate).format(CommonConfig.dateFormat.dateFirst)}
              </div>
            );
          }
        },
        id: "EstimetedEndDate",
        sortable: true,
        filterMethod: (filter, row) => {
          let dueDate = row._original.DueDate;
          return row._original
            ? String(dueDate).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        width: 110,
      },
      {
        Header: "Action",
        id: "action",
        sortable: true,
        className: "text-align-right",
        Cell: (record) => {
          return (
            <span>
              <Tooltip title="Edit" placement="bottom">
                <Button
                  disabled={
                    LastEditDaysAgo > 3 &&
                    this.state.TaskList.filter((x) => x.IsNotFreeze == 0)
                      .length > 0
                      ? true
                      : false
                  }
                  className="btn-icon"
                  color="primary"
                  onClick={() => this.edit(record)}
                >
                  <CreateIcon />
                </Button>
              </Tooltip>
            </span>
          );
        },
        filterable: false,
        width: 70,
      },
    ];
    const completedTableColumns = [
      {
        Header: "Key",
        accessor: (data) => {
          return (
            <Tooltip
              title={
                data.ProjectName.split(" ").join("") + "-" + data.ProjectKey
              }
              arrow
            >
              <span>
                {data.ProjectName.substring(0, 4) + "-" + data.ProjectKey}
              </span>
            </Tooltip>
          );
        },
        id: "ProjectID",
        sortable: true,
        filterMethod: (filter, row) => {
          let key =
            row._original.ProjectName && row._original.ProjectKey
              ? row._original.ProjectName + " " + row._original.ProjectKey
              : "";
          return row._original
            ? String(key).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        width: 100,
      },
      {
        Header: "Project Task",
        accessor: "ProjectName",
        Cell: (record) => {
          return (
            <Tooltip title={record.original.ProjectName} arrow>
              <span>{record.original.ProjectName}</span>
            </Tooltip>
          );
        },
        id: "ProjectName",
        sortable: true,
        filterMethod: (filter, row) => {
          let projectname = row._original.ProjectName;
          return row._original
            ? String(projectname)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        className: "nowrap",
      },
      {
        Header: "Task",
        accessor: (data) => {
          return (
            <Tooltip title={data.Subject} arrow>
              <span>{data.Subject}</span>
            </Tooltip>
          );
        },
        id: "TaskID",
        // sortable: true,
        sortingMethod: (sorting, row) => {
          let subject = row._original.Subject;
          return row._original
            ? String(subject)
                .toLowerCase()
                .includes(sorting.value.toLowerCase())
            : row;
        },
        filterMethod: (filter, row) => {
          let subject = row._original.Subject;
          return row._original
            ? String(subject).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        width: 300,
        className: "nowrap",
      },
      {
        Header: "Status",
        accessor: (data) => {
          return (
            <Tooltip title={data.TaskStatus} arrow>
              <span
                className={
                  "badge badge-" +
                  data.TaskStatus.split(" ").join("").toLowerCase()
                }
              >
                {data.TaskStatus}
              </span>
            </Tooltip>
          );
        },
        id: "Status",
        sortable: true,
        filterMethod: (filter, row) => {
          let taskStatus = row._original.TaskStatus;
          return row._original
            ? String(taskStatus)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
      },
      {
        Header: "Target",
        accessor: (data) => {
          if (CommonConfig.isEmpty(data.DueDate)) {
            return null;
          } else {
            return moment(data.DueDate).format(
              CommonConfig.dateFormat.dateFirst
            );
          }
        },
        id: "EstimetedEndDate",
        filterMethod: (filter, row) => {
          let dueDate = row._original.DueDate;
          return row._original
            ? String(dueDate).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
      },
      {
        Header: "Completion",
        accessor: (data) => {
          if (CommonConfig.isEmpty(data.UpdatedOn)) {
            return null;
          } else {
            return moment(data.UpdatedOn).format(
              CommonConfig.dateFormat.dateFirst
            );
          }
        },
        id: "EndDate",
        filterMethod: (filter, row) => {
          let updatedOn = row._original.UpdatedOn;
          return row._original
            ? String(updatedOn)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
      },
      {
        Header: "Action",
        id: "action",
        sortable: false,
        className: "text-align-right",
        Cell: (record) => {
          return (
            <span>
              <Tooltip title="Edit" placement="bottom">
                <Button
                  disabled={
                    LastEditDaysAgo > 3 &&
                    this.state.TaskList.filter((x) => x.IsNotFreeze == 0)
                      .length > 0
                      ? true
                      : false
                  }
                  className="btn-icon"
                  color="primary"
                  onClick={() => this.edit(record)}
                >
                  <CreateIcon />
                </Button>
              </Tooltip>
            </span>
          );
        },
        filterable: false,
      },
    ];
    const todayTableColumns = [
      {
        Header: "Key",
        accessor: (data) => {
          return (
            <Tooltip
              title={
                data.ProjectName.split(" ").join("") + "-" + data.ProjectKey
              }
              arrow
            >
              <span>
                {data.ProjectName.substring(0, 4) + "-" + data.ProjectKey}
              </span>
            </Tooltip>
          );
        },
        id: "KeyID",
        sortable: true,
        filterMethod: (filter, row) => {
          let key =
            row._original.ProjectName && row._original.ProjectKey
              ? row._original.ProjectName + " " + row._original.ProjectKey
              : "";
          return row._original
            ? String(key).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        width: 100,
      },
      {
        Header: "Project",
        accessor: (data) => {
          return (
            <Tooltip title={data.ProjectName} arrow>
              <span>{data.ProjectName}</span>
            </Tooltip>
          );
        },
        id: "ProjectID",
        sortable: true,
        filterMethod: (filter, row) => {
          let projectname = row._original.ProjectName;
          return row._original
            ? String(projectname)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        width: 200,
        className: "nowrap",
      },
      {
        Header: "Task",
        accessor: (data) => {
          return (
            <Tooltip title={data.Subject} arrow>
              <span>{data.Subject}</span>
            </Tooltip>
          );
        },
        id: "TaskID",
        sortable: true,
        filterMethod: (filter, row) => {
          let subject = row._original.Subject;
          return row._original
            ? String(subject).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        width: 250,
        className: "nowrap",
      },

      {
        Header: "Status",
        accessor: (data) => {
          return (
            <Tooltip title={data.TaskStatus} arrow>
              <span
                className={
                  "badge badge-" +
                  data.TaskStatus.split(" ").join("").toLowerCase()
                }
              >
                {data.TaskStatus}
              </span>
            </Tooltip>
          );
        },
        id: "Status",
        filterMethod: (filter, row) => {
          let taskStatus = row._original.TaskStatus;
          return row._original
            ? String(taskStatus)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
      },
      {
        Header: "Hours",
        accessor: (data) => {
          return <span> {data.HrsToday ? data.HrsToday : 0} </span>;
        },
        id: "HrsToday",
        filterMethod: (filter, row) => {
          let hrsToday = row._original.HrsToday;
          return row._original
            ? String(hrsToday)
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : row;
        },
        sortable: true,
      },
      {
        Header: "Notes",
        className: "text-align-left",
        accessor: (data) => {
          return (
            <Tooltip title={data.Notes} arrow>
              <span> {data.Notes} </span>
            </Tooltip>
          );
        },
        id: "Notes",
        sortable: true,
        filterMethod: (filter, row) => {
          let notes = row._original.Notes;
          return row._original
            ? String(notes).toLowerCase().includes(filter.value.toLowerCase())
            : row;
        },
        className: "nowrap",
      },
      {
        Header: "Action",
        id: "action",
        sortable: false,
        className: "text-align-right",
        Cell: (record) => {
          return record.original.SubmittedStatus !== "Submitted" &&
            record.original.SubmittedStatus !== "Approved" ? (
            <span>
              <Tooltip title="Edit" placement="bottom">
                <Button
                  disabled={
                    LastEditDaysAgo > 3 &&
                    this.state.TaskList.filter((x) => x.IsNotFreeze == 0)
                      .length > 0
                      ? true
                      : false
                  }
                  className="btn-icon"
                  color="primary"
                  onClick={() => this.edit(record)}
                >
                  <CreateIcon />
                </Button>
              </Tooltip>
            </span>
          ) : null;
        },
        filterable: false,
      },
    ];
    return (
      <div>
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        {this.state.IsTotalHrsActive == true ? (
          <GridContainer className="UserList-outer">
            <GridItem xs={12}>
              <Card>
                <CardHeader>
                  <Tooltip title="Back" placement="bottom">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() =>
                        this.setState({
                          IsTotalHrsActive: false,
                          DetailTotalHours: [],
                        })
                      }
                    >
                      Back
                    </Button>
                  </Tooltip>
                </CardHeader>
                <CardBody>
                  <GridContainer className="UserList-outer">
                    <GridItem xs={12}>
                      <ReactTable
                        data={DetailTotalHours}
                        minRows={5}
                        resizable={false}
                        style={{ cursor: "pointer" }}
                        columns={tableColumnsTotalHrs}
                        defaultPageSize={10}
                        className="-striped -highlight"
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer className="UserList-outer">
            <GridItem xs={12} sm={12} md={12}>
              <div className="filter-outer">
                <div className="fo-heading">
                  <a
                    onClick={() =>
                      this.setState({ searchToggle: !this.state.searchToggle })
                    }
                  >
                    {" "}
                    <i className="fas fa-angle-down"></i>
                    <h4>Search Filters</h4>
                  </a>
                </div>
                {this.state.searchToggle ? (
                  <div className="fo-content">
                    <GridContainer>
                      <GridItem xs={12} sm={3} md={9}>
                        <div className="task-tabs">
                          <span className="mr-10 font-bold">Status:</span>
                          <Tooltip title="ALL" placement="bottom">
                            <Button
                              className={allFilter}
                              onClick={() => this.applyFilter("ALL")}
                              value="All"
                            >
                              ALL
                            </Button>
                          </Tooltip>
                          <Tooltip title="In Progress" placement="bottom">
                            <Button
                              className={inProgressFilter}
                              onClick={() => this.applyFilter("IN PROGRESS")}
                              value="IN PROGRESS"
                            >
                              In Progress
                            </Button>
                          </Tooltip>
                          <Tooltip title="Open" placement="bottom">
                            <Button
                              className={openFilter}
                              onClick={() => this.applyFilter("OPEN")}
                              value="OPEN"
                            >
                              Open
                            </Button>
                          </Tooltip>
                          <Tooltip title="Resolved" placement="bottom">
                            <Button
                              className={resolvedFilter}
                              onClick={() => this.applyFilter("RESOLVED")}
                              value="RESOLVED"
                            >
                              Resolved
                            </Button>
                          </Tooltip>
                          <Tooltip title="Closed" placement="bottom">
                            <Button
                              className={closedFilter}
                              onClick={() => this.applyFilter("CLOSED")}
                              value="CLOSED"
                            >
                              Closed
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              moment(this.state.FilterDate).isSame(moment(), 'day')
                                ? 'TODAY'
                                : moment(this.state.FilterDate).format('DD MMM')
                            }
                            placement="bottom"
                          >
                            <Button
                              className={todayFilter}
                              onClick={() => this.applyTodayFilter("TODAY")}
                              value="TODAY"
                            >
                              {moment(this.state.FilterDate).format(
                                "DD MMM"
                              ) === moment().format("DD MMM")
                                ? "TODAY"
                                : moment(this.state.FilterDate).format(
                                    "DD MMM"
                                  )}
                            </Button>
                          </Tooltip>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <div className="submitted-on">
                          <span>Submitted On</span>
                          <FormControl>
                            <TextField
                              value={moment(UpdatedOn).format("DD/MM/YYYY")}
                              disabled
                            />
                          </FormControl>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer className="UserList-outer">
                      <GridItem xs={12} sm={3} md={4}>
                        <div className="date-filter">
                          <Tooltip title="Previous Date" placement="bottom">
                            <Button
                              color="primary"
                              className="prev-date-icon"
                              justIcon
                              onClick={() => this.decreaseDate()}
                            >
                              <ArrowBackIosIcon />
                            </Button>
                          </Tooltip>
                          <div className="date-filter-input">
                            <FormControl fullWidth>
                              <Datetime
                                dateFormat={"DD/MM/YYYY"}
                                timeFormat={false}
                                className="input-group"
                                value={moment(this.state.FilterDate)}
                                inputProps={{ placeholder: "Date" }}
                                onChange={(date) => this.handleFilterDate(date)}
                                closeOnSelect={true}
                                renderInput={(params) => (
                                  <TextField {...params} label="Date" />
                                )}
                              />
                              <Icon className="date-icon tp-slam">
                                date_range
                              </Icon>
                            </FormControl>
                            <div className="date-day">
                              {this.state.currentDay}
                            </div>
                          </div>
                          <Tooltip title="Next Date" placement="bottom">
                            <Button
                              color="primary"
                              className="next-date-icon"
                              onClick={() => this.increaseDate()}
                              justIcon
                            >
                              <ArrowForwardIosIcon />
                            </Button>
                          </Tooltip>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <div className="search-resource">
                          {/* <div className="with-icon"> */}
                          <Autocomplete
                            options={DailyStatus}
                            id="DailyStatus"
                            value={this.state.filterVal}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) =>
                              this.handleResoureOnChange(value)
                            }
                            disabled={this.state.ApproveAccess}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={viewDailyTaskErr}
                                helperText={viewDailyTaskHelperText}
                                label="Search Resources Name"
                              />
                            )}
                          />
                          {/* </div> */}
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <div className="search-resource">
                          <div className="with-icon">
                            <TextField
                              label="Search Task..."
                              type="text"
                              name="query"
                              value={this.state.TaskSearch}
                              onChange={(event) => this.TaskSearchFilter(event)}
                            />
                          </div>
                        </div>
                      </GridItem>
                      {LastEditDaysAgo > 3 &&
                      this.state.ApproveAccess == false ? (
                        <GridItem xs={12} sm={3} md={2}>
                          <span>Disable</span>
                          <span>
                            <Switch
                              checked={this.state.checked}
                              onChange={(e) => this.updateFreezed(e)}
                              name="checked"
                            />
                          </span>
                          <span>Enable</span>
                        </GridItem>
                      ) : null}
                    </GridContainer>
                  </div>
                ) : null}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className="page-content">
                <div className="common-datatable">
                  <GridContainer>
                    <GridItem xs={12}>
                      <ReactTable
                        data={
                          this.state.isfilter ? this.state.filterData : TaskList
                        }
                        minRows={5}
                        filterable={true}
                        sortable={true}
                        resizable={false}
                        columns={
                          this.state.isfilter &&
                          this.state.filterType == "INPROGRESS"
                            ? pendingTableColumns
                            : this.state.isfilter &&
                              this.state.filterType == "OPEN"
                            ? pendingTableColumns
                            : this.state.isfilter &&
                              this.state.filterType == "CLOSED"
                            ? completedTableColumns
                            : this.state.isfilter &&
                              this.state.filterType == "RESOLVED"
                            ? pendingTableColumns
                            : this.state.isfilter &&
                              this.state.filterType == "TODAY"
                            ? todayTableColumns
                            : tableColumns
                        }
                        pageText={
                          this.state.isfilter &&
                          this.state.filterType == "TODAY"
                            ? "Total hours : " +
                              parseFloat(this.state.searchfinalLength).toFixed(
                                2
                              )
                            : ""
                        }
                        getPaginationProps={(record) =>
                          this.searchCheckProps(record)
                        }
                        defaultPageSize={10}
                        className="-striped -highlight"
                      />
                    </GridItem>
                  </GridContainer>
                  <Dialog
                    open={Editvisible}
                    title="Credentials"
                    onClose={() => this.handleCancel()}
                  >
                    <DialogContent>
                      {this.state.Loading === true ? (
                        <div className="loading">
                          <SimpleBackdrop />
                        </div>
                      ) : null}
                      <FormControl fullWidth>
                        <TextField
                          label="Resource Name"
                          value={FirstName + " " + LastName}
                          disabled
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          label="Project Name"
                          value={ProjectName ? ProjectName : ""}
                          disabled
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          label="Task"
                          value={ProjectKey + "-" + Subject}
                          disabled
                        />
                      </FormControl>
                      <FormControl fullWidth error={HrsTodayErr}>
                        <TextField
                          value={HrsToday}
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          label="Hours Today"
                          onChange={(e) => this.onChange(e, "HrsToday")}
                        />
                        <FormHelperText>{HrsTodayHelperText}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          value={TotalHours}
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          label="Total Hours"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Datetime
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          className="input-group"
                          value={moment(this.state.TaskStatusDate)}
                          inputProps={{ placeholder: "Task Status Date" }}
                          onChange={(date) => this.handleStatusDateChange(date)}
                          closeOnSelect={true}
                          renderInput={(params) => (
                            <TextField {...params} label="Task Status Date" />
                          )}
                        />
                        <Icon className="date-icon tp-slam">date_range</Icon>
                      </FormControl>
                      <FormControl fullWidth error={PercentageCompletedErr}>
                        <InputLabel>% Completed</InputLabel>
                        <Select
                          onChange={(e) =>
                            this.selectChange(e, "PercentageCompleted")
                          }
                          value={PercentageCompleted}
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="30">30</MenuItem>
                          <MenuItem value="40">40</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="60">60</MenuItem>
                          <MenuItem value="70">70</MenuItem>
                          <MenuItem value="80">80</MenuItem>
                          <MenuItem value="90">90</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                        <FormHelperText>
                          {PercentageCompletedHelperText}
                        </FormHelperText>
                      </FormControl>
                      <FormControl fullWidth error={NotesErr}>
                        <TextareaAutosize
                          value={Notes}
                          placeholder="Notes"
                          rowsMin={5}
                          cols={90}
                          label="Notes"
                          onChange={(e) => this.onChange(e, "Notes")}
                        />
                        <FormHelperText>{NotesHelperText}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          label="Status for"
                          value={moment(UpdatedOn).format("DD/MM/YYYY")}
                          disabled
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          label="Start Date"
                          value={moment(StartDate).format("DD/MM/YYYY")}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          label="End Date"
                          value={moment(DueDate).format("DD/MM/YYYY")}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel>Blockers</InputLabel>
                        <Select
                          onChange={(e) => this.selectChange(e, "Blockers")}
                          value={StringMapName}
                        >
                          {this.blockerslist()}
                        </Select>
                        <Tooltip title="Add Blocker" placement="bottom">
                          <Button
                            type="submit"
                            color="primary"
                            onClick={(e) => this.addBlocker(e)}
                          >
                            Add
                          </Button>
                        </Tooltip>
                        {this.viewBlockers()}
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Tooltip title="Save" placement="bottom">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={(e) => this.update(e)}
                        >
                          Save
                        </Button>
                      </Tooltip>
                      <Tooltip title="Back" placement="bottom">
                        <Button key="back" onClick={() => this.handleCancel()}>
                          Back
                        </Button>
                      </Tooltip>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}
export default viewDailyTasks;
