import React, { Component } from "react";
import Wizard from "components/Wizard/Wizard";
import ListView from "components/Leave/ListView.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LeaveCalendar from "components/LeaveCalendar/LeaveCalendar.js";

class LeaveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LeaveList: [],
      ApproveAccess: false,
      IsSelectAll: false,
      LeaveBalance: 12,
      DailyStatusList: [],
      filterVal: "",
      Resoource: "",
      StartDate: "",
      EndDate: "",
      selectedDate: new Date(),
    };
  }

  render() {
    return (
      <div>
        <Wizard
          steps={[
            {
              stepName: "Leave List",
              stepComponent: ListView,
              stepId: "LeaveList",
            },
            {
              stepName: "Leave Calendar",
              stepComponent: LeaveCalendar,
              stepId: "LeaveCalendar",
            },
          ]}
          
          title=""
          color="primary"
          nextButtonVisible={false}
          finishButtonClick={(e) => this.submit(e)}
        />
        
      </div>
    );
  }
}
export default LeaveList;
