import React, { Component } from 'react';
import Add from "@material-ui/icons/Add";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import cogoToast from 'cogo-toast';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { title } from 'assets/jss/material-dashboard-pro-react';

class editTasks extends Component {

    constructor(props) {
        super(props);
        this.state = {

            Subject: "",
            SubjectErr: false,
            SubjectHelperText: "",

            EstimetedHours: "",
            EstimetedHoursErr: false,
            EstimetedHoursHelperText: "",

            Assignee: "",
            AssigneeErr: false,
            AssigneeHelperText: "",
            PersonList: [],

            TaskStatus: "",
            TaskStatusErr: false,
            TaskStatusHelperText: "",

            TypeList: [],
            StatusList: [],
            PriorityList: [],
            notesList: [],

            TaskType: "",
            TaskTypeHelperText: "",
            TaskTypeErr: "",

            Priority: "",
            PriorityHelperText: "",
            PriorityErr: "",

            Version: "",
            Category: "",
            Milestone: "",

            ProjectList: [],
            ProjectErr: false,
            ProjectHelperText: "",
            Project: "",

            Description: "",
            fileArray: [],
            Milestonevisible: false,
            Versionvisible: false,
            Categoryvisible: false,
            loggedUserID: 0,
            CategoryList: [],
            Observer: [],
            ObserverArray: [],
            fixedOptions:[],
            ObserverErr: false,
            ObserverHelperText: ''
        };
    }

    componentDidMount() {
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
        this.getPersonList();
        this.getTypeList();
        this.getProjectList();
        this.getStatusList();
        this.getPriorityList();
        this.getCategoryList();
        this.getEditData();
        this.getDailyStatusList();
    }

    getDailyStatusList = (Observer, ProjectManager) => {
        try {
            api.get("dailystatus/getDailyStatusList").then(res => {
                if (res.success) {
                    var Obserarr = [];
                    var observermain = [];
                    var ObserverFilter = Observer.filter(x=>x != 1)
                    observermain.push({
                        label: res.data.filter(x => x.ResourceID == 1)[0].ResourceID,
                        value: res.data.filter(x => x.ResourceID == 1)[0].FirstName + ' ' + res.data.filter(x => x.ResourceID == 1)[0].LastName,
                    })
                    if (ObserverFilter && ObserverFilter.length > 0) {
                        for (let i = 0; i < ObserverFilter.filter(x=>x != 1).length; i++) {
                            Obserarr.push({
                                label: res.data.filter(x => x.ResourceID == ObserverFilter[i])[0].ResourceID,
                                value: res.data.filter(x => x.ResourceID == ObserverFilter[i])[0].FirstName + ' ' + res.data.filter(x => x.ResourceID == ObserverFilter[i])[0].LastName,
                            })
                        }
                    }
                    if (ProjectManager && ProjectManager.length > 0 && !Obserarr.filter(x => x.label == ProjectManager[0].ProjectManager).length > 0) {
                        Obserarr.push({
                            label: res.data.filter(x => x.ResourceID == ProjectManager[0].ProjectManager)[0].ResourceID,
                            value: res.data.filter(x => x.ResourceID == ProjectManager[0].ProjectManager)[0].FirstName + ' ' + res.data.filter(x => x.ResourceID == ProjectManager[0].ProjectManager)[0].LastName,
                        })
                    }
                    this.setState({
                        ObserverArray: res.data, Observer: [...observermain,...Obserarr],fixedOptions:observermain
                    });
                }
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    getEditData() {
        let data = {
            TaskID: this.props.location.state.id
        };
        try {
            api.post("tasks/getTaskByID", data).then(res => {
                if (res.success) {
                    let personList = this.state.PersonList.filter(x => x.ResourceID === res.data.Assignee);
                    let personObj = personList[0] ? {
                        value: personList[0].ResourceID,
                        label: personList[0].FirstName + ' ' + personList[0].LastName
                    } : '';

                    let projectList = this.state.ProjectList.filter(x => x.ProjectID === res.data.ProjectID);
                    let projectObj = projectList[0] ? {
                        value: projectList[0].ProjectID,
                        label: projectList[0].ProjectName
                    } : '';

                    this.setState({
                        TaskID: res.data.TaskID,
                        Subject: res.data.Subject,
                        EstimetedHours: res.data.EstimetedHours,
                        Description: res.data.Description,
                        Assignee: personObj,
                        TaskStatus: res.data.TaskStatus,
                        TaskType: res.data.TaskType,
                        Category: res.data.Category,
                        Priority: res.data.Priority,
                        Version: res.data.Version,
                        Milestone: res.data.Milestone,
                        DueDate: res.data.DueDate,
                        Project: projectObj
                    });
                    this.getDailyStatusList(res.data.Observer);
                    this.getAsssigneeByProject(res.data.ProjectID);
                } else {
                    cogoToast.error('Something went wrong')
                }

            }).catch(error => {
            })

        }
        catch (err) {
        }
    }

    getCategoryList = () => {
        let data = {
            StringMapType: "CATEGORY"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ CategoryList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }


    getProjectList = () => {
        try {
            api.get("/tasks/getProjectList").then(res => {
                if (res.success) {
                    this.setState({ ProjectList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getTypeList = () => {
        let data = {
            StringMapType: "TASKTYPE"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ TypeList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getStatusList = () => {
        let data = {
            StringMapType: "TASKSTATUS"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ StatusList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getPriorityList = () => {
        let data = {
            StringMapType: "PRIORITY"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ PriorityList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getPersonList = () => {
        try {
            api.get("tasks/getPersonList").then(res => {
                if (res.success) {
                    this.setState({ PersonList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    onChange = (e, type) => {
        if (type === "Subject") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Subject: e.target.value, SubjectErr: true, SubjectHelperText: "Please enter Subject" });
            }
            else {
                this.setState({ Subject: e.target.value, SubjectErr: false, SubjectHelperText: "" });
            }
        }
        else if (type === "EstimetedHours") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ EstimetedHours: e.target.value, EstimetedHoursErr: true, EstimetedHoursHelperText: "Please enter Estimated Hours" });
            }
            else {
                this.setState({ EstimetedHours: e.target.value, EstimetedHoursErr: false, EstimetedHoursHelperText: "" });
            }
        }
        else if (type === "Description") {
            this.setState({ Description: e.target.value });
        }
        else if (type === "Milestone") {
            this.setState({ Milestone: e.target.value });
        }
        else if (type === "Version") {
            this.setState({ Version: e.target.value });
        }
        else if (type === "Category") {
            this.setState({ Category: e.target.value });
        }
    }

    redirectBack = () => {
        this.props.history.push("/admin/Tasks");
    };

    milestoneModel = () => {
        this.setState({ Milestonevisible: true });
    };

    milestoneCancel = () => {
        this.setState({ Milestonevisible: false });
    };

    versionModel = () => {
        this.setState({ Versionvisible: true });
    };

    versionCancel = () => {
        this.setState({ Versionvisible: false });
    };

    categoryModel = () => {
        this.setState({ Categoryvisible: true });
    };

    categoryCancel = () => {
        this.setState({ Categoryvisible: false });
    };


    projectlist = () => {
        return this.state.ProjectList.map(content => {
            return (
                <MenuItem value={content.ProjectID} > {content.ProjectName} </MenuItem>
            )
        })
    }

    typelist = () => {
        return this.state.TypeList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    statuslist = () => {
        return this.state.StatusList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    prioritylist = () => {
        return this.state.PriorityList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    getPersonName = (prevAssignee, nextAssignee) => {

        // this.notes(prevName, nextName, "Task", "Assignee");
    }

    handleDate = (date, type) => {
        if (type === "DueDate") {
            this.setState({ DueDate: date });
            this.notes(moment(this.state.DueDate).format("DD/MM/YYYY"), moment(date).format("DD/MM/YYYY"), "Task", "Due Date");
        }
    }

    notes = (prevData, nextData, Type, Title) => {
        if (prevData !== nextData) {

            let data = {
                CreatedBy: CommonConfig.loginData().PersonID,
                NoteText: prevData + " => " + nextData,
                Index: this.state.notesList.length + 1,
                EntityID: this.props.location.state.id,
                EntityType: Type,
                NoteTitle: Title,
                NoteID: null,
                Status: 'Active'
            }
            this.setState({ notesList: [...this.state.notesList, data] });
        }
    }


    validate = () => {
        const { Subject, Assignee, Priority, TaskStatus, TaskType, Version, Project, EstimetedHours } = this.state;
        let IsValid = true;
        if (CommonConfig.isEmpty(Subject)) {
            IsValid = false;
            this.setState({ SubjectHelperText: "Please enter Subject", SubjectErr: true });
        }

        if (CommonConfig.isEmpty(EstimetedHours)) {
            IsValid = false;
            this.setState({ EstimetedHoursHelperText: "Please enter Estimated Hours", EstimetedHoursErr: true });
        }

        if (CommonConfig.isEmpty(Assignee)) {
            IsValid = false;
            this.setState({ AssigneeHelperText: "Please enter Assignee", AssigneeErr: true });
        }

        if (CommonConfig.isEmpty(TaskStatus)) {
            IsValid = false;
            this.setState({ TaskStatusHelperText: "Please enter Task Status", TaskStatusErr: true });
        }

        if (CommonConfig.isEmpty(Priority)) {
            IsValid = false;
            this.setState({ PriorityHelperText: "Please enter Priority", PriorityErr: true });
        }

        if (CommonConfig.isEmpty(TaskType)) {
            IsValid = false;
            this.setState({ TaskTypeHelperText: "Please enter Issue Type", TaskTypeErr: true });
        }

        if (CommonConfig.isEmpty(Project)) {
            IsValid = false;
            this.setState({ ProjectHelperText: "Please enter Project", ProjectErr: true });
        }

        return IsValid;
    }

    update = (e) => {
        const { Subject, Assignee, Observer, TaskStatus, notesList, EstimetedHours, TaskType, Priority, Category, Version, Milestone, DueDate, Description, Project } = this.state;
        if (this.validate()) {
            let data = {
                UserID: CommonConfig.loginData().PersonID,
                Subject: Subject,
                TaskStatus: TaskStatus,
                EstimetedHours: EstimetedHours,
                TaskType: TaskType,
                Assignee: Assignee.value,
                Observer: Observer.map(x => { return x.label }),
                Category: Category,
                Priority: Priority,
                Version: Version,
                Milestone: Milestone,
                DueDate: CommonConfig.isEmpty(DueDate) !== true ? moment(DueDate).endOf('day').format("YYYY-MM-DD HH:mm:ss").toString() : null,
                Description: Description,
                ProjectID: Project.value,
                notesList: notesList,
                TaskID: this.props.location.state.id
            }
            try {
                api.post("tasks/addUpdateTask", data).then(res => {
                    if (res.success) {
                        cogoToast.success("Task updated successfully");
                        this.props.history.push("/admin/Tasks");
                    }
                    else {
                        cogoToast.error("Something went wrong");
                    }
                }).catch(error => {
                    console.log("error", error);
                });
            }
            catch (err) {
                console.log("error", err);
            }
        }
        else {
            cogoToast.error("Please correct error and resubmit the form");
        }
    }

    selectChange = (e, type) => {

        if (type === "Assignee") {
            this.setState({ Assignee: e, AssigneeHelperText: "", AssigneeErr: false });
            if (e !== null) {
                this.getPersonName(this.state.Assignee, e.ResourceID)
            }
        }
        if (type === "Observer") {
            this.setState({ Observer: [
                ...this.state.fixedOptions,
                ...e.filter((option) => this.state.fixedOptions.indexOf(option) === -1)
            ], ObserverHelperText: "", ObserverErr: false });
        }
        else if (type === "Project") {
            this.setState({ Project: e.target.value, ProjectHelperText: "", ProjectErr: false });
        }
        else if (type === "Version") {
            this.setState({ Version: e.target.value });
        }
        else if (type === "TaskStatus") {
            this.setState({ TaskStatus: e.target.value, TaskStatusHelperText: "", TaskStatusErr: false });
            this.notes(this.state.TaskStatus, e.target.value, "Task", "Status");
        }
        else if (type === "Milestone") {
            this.setState({ Milestone: e.target.value });
        }
        else if (type === "Priority") {
            this.setState({ Priority: e.target.value, PriorityHelperText: "", PriorityErr: false });
        }
        else if (type === "TaskType") {
            this.setState({ TaskType: e.target.value, TaskTypeHelperText: "", TaskTypeErr: false });
        }
        else if (type === "Category") {
            this.setState({ Category: e.target.value })
        }
    }

    categorylist = () => {
        return this.state.CategoryList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName} </MenuItem>
            )
        })
    }

    typeChange = (e, type) => {
        if (type === "Assignee") {
            if (e !== null) {
                // this.getPersonName(this.state.Assignee.label, e.label)
                this.notes(this.state.Assignee.label, e.label, "Task", "Assignee");
            }
            this.setState({ Assignee: e, AssigneeHelperText: "", AssigneeErr: false });
        }
        else if (type === "Project") {
            this.setState({ Project: e, ProjectHelperText: "", ProjectErr: false, Assignee: '' });
            if (e !== null) {
                this.getAsssigneeByProject(e.value);
            }
        }
    }

    getAsssigneeByProject = (ProjectID) => {
        let data = {
            ProjectID: ProjectID
        }
        api.post("projectManagement/getAssigneeByProjectID", data).then(res => {
            if (res.success) {
                this.setState({ PersonList: res.data.Assignee })
                this.getDailyStatusList(res.data.ProjectManager)
            }
        }).catch(error => {
            console.log("error....", error);
        })

    }


    render() {
        const { Subject, SubjectErr, SubjectHelperText, EstimetedHours, EstimetedHoursHelperText, EstimetedHoursErr, Milestonevisible, Categoryvisible, Versionvisible, Priority, PriorityHelperText, PriorityErr, TaskType, TaskTypeErr, TaskTypeHelperText, Assignee, AssigneeErr, AssigneeHelperText, Observer, ObserverErr, ObserverHelperText, Category, TaskStatus, TaskStatusErr, TaskStatusHelperText, Version, Milestone, DueDate, Description, Project, ProjectHelperText, ProjectErr } = this.state;
        const projectlist = this.state.ProjectList.map((type) => {
            return { value: type.ProjectID, label: type.ProjectName };
        });

        const personList = this.state.PersonList.length > 0 ? this.state.PersonList.map(type => {
            return { value: type.AssigneeID, label: type.FirstName + ' ' + type.LastName }
        }) : []

        // const ObserverArray = this.state.ObserverArray.length > 0 ? this.state.ObserverArray.map(DailyStatus => {
        //     return { value: DailyStatus.FirstName + ' ' + DailyStatus.LastName, label: DailyStatus.ResourceID }
        // }) : []

        let ObserverArray = this.state.ObserverArray.length > 0 ? this.state.ObserverArray.filter(x => !this.state.Observer.map(t => { return t.label }).includes(x.ResourceID)).map(DailyStatus => {
            return { value: DailyStatus.FirstName + ' ' + DailyStatus.LastName, label: DailyStatus.ResourceID }
        }) : []
        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader className="btn-right-outer" color="primary" icon>
                            <h4 className="margin-right-auto text-color-black">Edit Task</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <Autocomplete
                                        options={projectlist}
                                        id="Project"
                                        value={Project}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, value) => this.typeChange(value, "Project")}
                                        renderInput={params => (
                                            <TextField {...params} error={ProjectErr} helperText={ProjectHelperText}
                                                label="Project" />)}
                                    />
                                    {/* <FormControl fullWidth error={ProjectErr}>
                                        <InputLabel>Project</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Project")} value={Project} >
                                            {this.projectlist()}
                                        </Select>
                                        <FormHelperText>{ProjectHelperText}</FormHelperText>
                                    </FormControl> */}
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={TaskTypeErr}>
                                        <InputLabel>Issue Type</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "TaskType")} value={TaskType}>
                                            {this.typelist()}
                                        </Select>
                                        <FormHelperText>{TaskTypeHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <FormControl fullWidth label="Subject">
                                        <TextField
                                            helperText={SubjectHelperText}
                                            error={SubjectErr}
                                            value={Subject}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Subject"
                                            onChange={(e) => this.onChange(e, "Subject")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <FormControl>
                                    <TextareaAutosize
                                        value={Description}
                                        placeholder="Description"
                                        rowsMin={5}
                                        cols={90}
                                        label="Description"
                                        onChange={(e) => this.onChange(e, "Description")}
                                    />
                                </FormControl>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={TaskStatusErr}>
                                        <InputLabel>Task Status</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "TaskStatus")} value={TaskStatus} >
                                            {this.statuslist()}
                                        </Select>
                                        <FormHelperText>{TaskStatusHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Autocomplete
                                        options={personList}
                                        id="DailyStatus"
                                        value={Assignee}
                                        getOptionLabel={(option) => option.label}
                                        // getOptionLabel={option => option.ResourceID ? option.FirstName + " " + option.LastName : option}
                                        onChange={(event, value) => this.typeChange(value, "Assignee")}
                                        renderInput={params => (
                                            <TextField {...params} error={AssigneeErr} helperText={AssigneeHelperText}
                                                label="Assignee" />)}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <Autocomplete
                                        multiple
                                        options={ObserverArray}
                                        id="DailyStatus"
                                        value={Observer}
                                        getOptionLabel={option => option.label ? option.value : option}
                                        onChange={(event, value) => this.selectChange(value, "Observer")}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                              <Chip
                                                label={option.label ? option.value : option.label}
                                                {...getTagProps({ index })}
                                                disabled={this.state.fixedOptions.indexOf(option) !== -1}
                                              />
                                            ))
                                          }
                                        renderInput={params => (
                                            <TextField {...params} error={ObserverErr} helperText={ObserverHelperText}
                                                label="Observer" />)}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={PriorityErr}>
                                        <InputLabel>Priority</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Priority")} value={Priority} >
                                            {this.prioritylist()}
                                        </Select>
                                        <FormHelperText>{PriorityHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Milestone</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Milestone")} value={Milestone} >
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">3</MenuItem>
                                        </Select>
                                        {/* <Button justIcon round color="primary" onClick={() => this.milestoneModel()}><Add /></Button>
                                        <Dialog
                                            open={Milestonevisible}
                                            title="Credentials"
                                            onClose={() => this.milestoneCancel()}>
                                            <DialogContent>
                                                <FormControl>
                                                    <TextField
                                                        value={Milestone}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        label="Milestone"
                                                        onChange={(e) => this.onChange(e, "Milestone")}
                                                    />
                                                    <Button color="primary" onClick={() => this.milestoneCancel()}>Add</Button>
                                                    <Button color="primary" onClick={() => this.milestoneCancel()}>Cancel</Button>
                                                </FormControl>
                                            </DialogContent>
                                        </Dialog> */}
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Category</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Category")} value={Category} >
                                            {this.categorylist()}
                                        </Select>
                                        {/* <Button justIcon round color="primary" onClick={() => this.categoryModel()}><Add /></Button>
                                        <Dialog
                                            open={Categoryvisible}
                                            title="Credentials"
                                            onClose={() => this.categoryCancel()}>
                                            <DialogContent>
                                                <FormControl>
                                                    <TextField
                                                        value={Category}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        label="Category"
                                                        onChange={(e) => this.onChange(e, "Category")}
                                                    />
                                                    <Button color="primary" onClick={() => this.categoryCancel()}>Add</Button>
                                                    <Button color="primary" onClick={() => this.categoryCancel()}>Cancel</Button>
                                                </FormControl>
                                            </DialogContent>
                                        </Dialog> */}
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Version</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Version")} value={Version} >
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">3</MenuItem>
                                        </Select>
                                        {/* <Button justIcon round color="primary" onClick={() => this.versionModel()}><Add /></Button>
                                        <Dialog
                                            open={Versionvisible}
                                            title="Credentials"
                                            onClose={() => this.versionCancel()}>
                                            <DialogContent>
                                                <FormControl>
                                                    <TextField
                                                        value={Version}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        label="Version"
                                                        onChange={(e) => this.onChange(e, "Version")}
                                                    />
                                                    <Button color="primary" onClick={() => this.versionCancel()}>Add</Button>
                                                    <Button color="primary" onClick={() => this.versionCancel()}>Cancel</Button>
                                                </FormControl>
                                            </DialogContent>
                                        </Dialog> */}
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            dateFormat={"DD/MM/YYYY"}
                                            timeFormat={false}
                                            className="input-group"
                                            value={moment(DueDate)}
                                            selected={moment(DueDate)}
                                            inputProps={{ placeholder: "Due Date" }}
                                            onChange={(date) => this.handleDate(date, "DueDate")}
                                            closeOnSelect={true}
                                            renderInput={params => (
                                                <TextField
                                                    {...params} label="Due Date" margin="normal" />)} />
                                        <Icon className="date-icon tp-slam">date_range</Icon>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth label="EstimetedHours">
                                        <TextField
                                            helperText={EstimetedHoursHelperText}
                                            error={EstimetedHoursErr}
                                            value={EstimetedHours}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Estimated Hours"
                                            onChange={(e) => this.onChange(e, "EstimetedHours")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <Tooltip title="Update" placement="bottom">
                                <Button type="submit" color="primary" onClick={(e) => this.update(e)}>Update</Button></Tooltip>
                            <Tooltip title="Back" placement="bottom">
                                <Button type="submit" key="back" onClick={() => this.redirectBack()}>Back</Button></Tooltip>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default editTasks;