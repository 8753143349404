import React, { Component } from 'react';
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CardHeader from "components/Card/CardHeader.js";
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import Datetime from "react-datetime";
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormHelperText, InputLabel } from '@material-ui/core';

class viewTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Subject: "",
            EstimetedHours: "",
            Assignee: "",
            Project: "",

            TaskStatus: "",

            TaskType: "",
            Priority: "",

            Version: "",
            Category: "",
            Milestone: "",

            PersonList: [],
            ProjectList: [],
            notesList: [],
            Description: "",
            loggedUserID: 0,
            Observer: [],
            ObserverArray: [],
        };
    }

    componentDidMount() {
        this.getEditData();
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
        this.getPersonList();
        this.getProjectList();
        this.getDailyStatusList();
    }

    getDailyStatusList = (Observer,ProjectManager) => {
        try {
            api.get("dailystatus/getDailyStatusList").then(res => {
                if (res.success) {
                    var Obserarr = [];
                    // Obserarr.push({
                    //     label: res.data.filter(x => x.ResourceID == 1)[0].ResourceID,
                    //     value: res.data.filter(x => x.ResourceID == 1)[0].FirstName+' '+res.data.filter(x => x.ResourceID == 1)[0].LastName,
                    // })
                    if(Observer && Observer.length>0){
                        for(let i=0;i<Observer.length;i++){
                            Obserarr.push({
                                label: res.data.filter(x => x.ResourceID == Observer[i])[0].ResourceID,
                                value: res.data.filter(x => x.ResourceID == Observer[i])[0].FirstName+' '+res.data.filter(x => x.ResourceID == Observer[i])[0].LastName,
                            })
                        }
                    }
                    if(ProjectManager && ProjectManager.length>0 && !Obserarr.filter(x=>x.label == ProjectManager[0].ProjectManager).length>0){
                        Obserarr.push({
                            label: res.data.filter(x => x.ResourceID ==ProjectManager[0].ProjectManager)[0].ResourceID,
                            value: res.data.filter(x => x.ResourceID ==ProjectManager[0].ProjectManager)[0].FirstName+' '+res.data.filter(x => x.ResourceID ==ProjectManager[0].ProjectManager)[0].LastName,
                        })
                    }
                    this.setState({
                        ObserverArray: res.data,Observer: Obserarr
                    });
                }
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    getPersonList = () => {
        try {
            api.get("/tasks/getPersonList").then(res => {
                if (res.success) {
                    this.setState({ PersonList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getProjectList = () => {
        try {
            api.get("/tasks/getProjectList").then(res => {
                if (res.success) {
                    this.setState({ ProjectList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    personlist = () => {
        return this.state.PersonList.map(content => {
            return (
                <MenuItem value={content.ResourceID} > {content.FirstName + " " + content.LastName}  </MenuItem>
            )
        })
    }

    projectlist = () => {
        return this.state.ProjectList.map(content => {
            return (
                <MenuItem value={content.ProjectID} > {content.ProjectName} </MenuItem>
            )
        })
    }

    getEditData() {
        let data = {
            TaskID: this.props.location.state.id
        };
        try {
            api.post("tasks/getTaskByID", data).then(res => {
                if (res.success) {
                    this.setState({
                        TaskID: res.data.TaskID,
                        Subject: res.data.Subject,
                        EstimetedHours: res.data.EstimetedHours,
                        Description: res.data.Description,
                        Assignee: res.data.FirstName + " " + res.data.LastName,
                        TaskStatus: res.data.TaskStatus,
                        TaskType: res.data.TaskType,
                        Category: res.data.Category,
                        Priority: res.data.Priority,
                        Version: res.data.Version,
                        Milestone: res.data.Milestone,
                        DueDate: res.data.DueDate,
                        Project: res.data.ProjectName,
                        notesList: res.data.notesList,
                    });
                    this.getDailyStatusList(res.data.Observer);

                } else {
                    cogoToast.error('Something went wrong',)
                }

            }).catch(error => {
            })

        }
        catch (err) {
        }
    }

    redirectEdit = () => {
        this.props.history.push({
            pathname: "/admin/editTasks",
            state: { id: this.state.TaskID }
        })
    }

    viewNotes = () => {
        return this.state.notesList.filter(x => x.Status === 'Active').map((content, index) => {
            return (
                <tr>
                    <div>
                      <b>{content.FirstName + " " + content.LastName}</b>
                    </div>
                    <div>
                        {moment(content.CreatedOn).format("MMM DD YYYY hh:mm")}
                    </div>
                    <div>
                        {content.HrsToday ? content.HrsToday + ' Hours' : ''}  
                    </div>
                    <div>
                        {content.NoteText}
                    </div>
                </tr>
            )
        }
        )
    }

    redirectBack = () => {
        this.props.history.push("/admin/Tasks");
    }

    render() {
        const { Subject, Priority, TaskType, Assignee, Category, TaskStatus, EstimetedHours, Version, Milestone, DueDate, Description, Project } = this.state;

        const ObserverArray = this.state.ObserverArray.length > 0 ? this.state.ObserverArray.map(DailyStatus => {
            return { value: DailyStatus.FirstName + ' ' + DailyStatus.LastName, label: DailyStatus.ResourceID }
        }) : []
        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader className="btn-right-outer" color="primary" icon>
                            <h4 className="margin-right-auto text-color-black">View Task</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={Subject}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Subject"
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={Priority}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Priority"
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <FormControl fullWidth>
                                        <TextareaAutosize disabled
                                            value={Description}
                                            rowsMin={5}
                                            cols={90}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Description"
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={Assignee}
                                            label="Assignee">

                                        </TextField>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Autocomplete
                                        multiple
                                        disabled
                                        options={this.state.ObserverArray}
                                        id="DailyStatus"
                                        value={this.state.Observer}
                                        getOptionLabel={option => option.label ? option.value : option}
                                        // onChange={(event, value) => this.selectChange(value, "Observer")}
                                        renderInput={params => (
                                            <TextField {...params} label="Observer" />)}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={Project}
                                            label="Project">

                                        </TextField>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={TaskStatus}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="TaskStatus"
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={TaskType}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="TaskType"
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={Category}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Category"
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={Version}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Version"
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={Milestone}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Milestone"
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={moment(DueDate).format("DD/MM/YYYY")}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Due Date"
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField disabled
                                            value={EstimetedHours}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Estimated Hours"
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <Tooltip title="Edit" placement="bottom">
                                <Button color="primary" onClick={(record) => this.redirectEdit()}>Edit</Button></Tooltip>
                            <Tooltip title="Back" placement="bottom">
                                <Button type="submit" key="back" onClick={() => this.redirectBack()}>Back</Button></Tooltip>
                        </CardBody>
                    </Card>
                    <h3>Comments</h3>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem>
                                    {this.viewNotes()}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}
export default viewTasks;