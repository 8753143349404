import React, { Component } from 'react';
import cogoToast from 'cogo-toast';
import api from '../../utils/apiClient';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { InputLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import LockIcon from '@material-ui/icons/Lock';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import FormHelperText from '@material-ui/core/FormHelperText';
class EditProjectInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      InstanceName: "",
      instancenameErr: false,
      instancenameHelperText: "",

      Description: "",
      descriptionErr: false,
      descriptionHelperText: "",

      AccessURL: "",
      accessurlErr: false,
      accessurlHelperText: "",

      AccessURLRole: "",
      accessroleErr: false,
      accessroleHelperText: "",

      AccessURLUserName: "",
      accessusernameErr: false,
      accessusernameHelperText: "",

      AccessURLPassword: "",
      accesspasswordErr: false,
      accesspasswordHelperText: "",

      FrontIP: "",
      frontipErr: false,
      frontipHelperText: "",

      FrontRole: "",
      frontroleErr: false,
      frontroleHelperText: "",

      FrontUserName: "",
      frontusernameErr: false,
      frontusernameHelperText: "",

      FrontPassword: "",
      frontpasswordErr: false,
      frontpasswordHelperText: "",

      FrontPort: "",
      frontportErr: false,
      frontportHelperText: "",

      APIRole: "",
      apiroleErr: false,
      apiroleHelperText: "",

      APIUserName: "",
      apiusernameErr: false,
      apiusernameHelperText: "",

      APIPassword: "",
      apipasswordErr: false,
      apipasswordHelperText: "",

      APIIP: "",
      apiIpErr: false,
      apiIpHelperText: "",

      APIURL: "",
      apiurlErr: false,
      apiurlHelperText: "",

      APIParam: "",
      apiparamErr: false,
      apiparamHelperText: "",

      APIPort: "",
      apiportErr: false,
      apiportHelperText: "",

      DBIP: "",
      dbipErr: false,
      dbipHelperText: "",

      DBName: "",
      dbnameErr: false,
      dbnameHelperText: "",

      DBPort: "",
      dbportErr: false,
      dbportHelperText: "",

      DBRole: "",
      dbroleErr: false,
      dbroleHelperText: "",

      DBUserName: "",
      dbusernameErr: false,
      dbusernameHelperText: "",

      DBPassword: "",
      dbpasswordErr: false,
      dbpasswordHelperText: "",

      FrontServer: "",
      frontendserverErr: false,
      frontendserverHelperText: "",

      DBServer: "",
      dbserverErr: false,
      dbserverHelperText: "",

      APIServer: "",
      apiserverErr: false,
      apiserverHelperText: "",

      ServerNameList: [],
      ClientOwned: false,
      Accessvisible: false,
      Frontvisible: false,
      Apivisible: false,
      DBvisible: false,
      loggedUserID: 0,
    };
  }

  componentDidMount() {
    this.getEditData();
    this.getServerName();
  }

  getServerName = () => {

    try {
      api.post("/serverconfig/getServerName").then(res => {
        this.setState({ ServerNameList: res.data });
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  getEditData() {
    let data = {
      ProjectInstanceID: this.props.location.state.id
    };

    try {
      api.post("projectinstance/getProjectInstanceByID", data).then(res => {
        if (res.success) {
        //  console.log("Res...",res);
          this.setState({
            InstanceName: res.data[0].InstanceName,
            Description: res.data[0].Description,
            AccessURL: res.data[0].AccessURL,
            AccessURLRole: res.data[0].AccessURLRole,
            AccessURLUserName: res.data[0].AccessUserName,
            AccessURLPassword: res.data[0].AccessPassword,
            FrontIP: res.data[0].FrontEndIP,
            FrontPassword: res.data[0].FrontEndPassword,
            FrontUserName: res.data[0].FrontEndUserName,
            FrontRole: res.data[0].FrontEndURLRole,
            FrontPort: res.data[0].FrontEndPort,
            APIRole: res.data[0].APIURLRole,
            APIUserName: res.data[0].APIUserName,
            APIPassword: res.data[0].APIPassword,
            APIIP: res.data[0].APIIP,
            APIURL: res.data[0].APIUrl,
            APIParam: res.data[0].APIParam,
            DBServer: res.data[0].DBServer,
            APIServer: res.data[0].APIServer,
            FrontServer: res.data[0].FrontEndServer,
            APIPort: res.data[0].APIPort,
            DBIP: res.data[0].DBIP,
            DBName: res.data[0].DBName,
            DBPort: res.data[0].DBPort,
            DBUserName: res.data[0].DBUserName,
            DBPassword: res.data[0].DBPassword,
            DBRole: res.data[0].DBUserRole,
            ClientOwned: res.data[0].ClientOwned.data[0] === 1 ? true : false
          });
         // console.log("Project Instance",this.state.InstanceName);
        } else {
          cogoToast.error('Something went wrong',)
        }

      }).catch(error => {
      })

    }
    catch (err) {
    }
  }

  onChange = (e, type) => {

    if (type === "instancename") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ InstanceName: e.target.value, instancenameErr: true, instancenameHelperText: "Please enter Instance Name" });
      }
      else {
        this.setState({ InstanceName: e.target.value, instancenameErr: false, instancenameHelperText: "" });
      }
    }

    else if (type === "accessURL") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ AccessURL: e.target.value, accessurlErr: true, accessurlHelperText: "Please enter Access URL" });
      }
      else {
        this.setState({ AccessURL: e.target.value, accessurlErr: false, accessurlHelperText: "" });
      }

    }

    else if (type === "description") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ Description: e.target.value, descriptionErr: true, descriptionHelperText: "Please enter Description" });
      }
      else {
        this.setState({ Description: e.target.value, descriptionHelperText: "", descriptionErr: false });
      }

    }

    else if (type === "FrontIP") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ FrontIP: e.target.value, frontipErr: true, frontipHelperText: "Please enter Front IP" });
      }
      else {
        this.setState({ FrontIP: e.target.value, frontipErr: false, frontipHelperText: "" });
      }

    }

    else if (type === "FrontPort") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ FrontPort: e.target.value, frontportHelperText: "Please enter Front Port", frontportErr: true });
      }
      else {
        this.setState({ FrontPort: e.target.value, frontportHelperText: "", frontportErr: false });
      }

    }

    else if (type === "AccessURLRole") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ AccessURLRole: e.target.value, accessroleHelperText: "Please enter URL Role", accessroleErr: true });
      }
      else {
        this.setState({ AccessURLRole: e.target.value, accessroleHelperText: "", accessroleErr: false });
      }

    }

    else if (type === "AccessURLUserName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ AccessURLUserName: e.target.value, accessusernameHelperText: "Please enter URL UserName", accessusernameErr: true });
      }
      else {
        this.setState({ AccessURLUserName: e.target.value, accessusernameHelperText: "", accessusernameErr: false });
      }

    }

    else if (type === "AccessURLPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ AccessURLPassword: e.target.value, accesspasswordHelperText: "Please enter URL Password", accesspasswordErr: true });
      }
      else {
        this.setState({ AccessURLPassword: e.target.value, accesspasswordHelperText: "", accesspasswordErr: false });
      }

    }

    else if (type === "FrontRole") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ FrontRole: e.target.value, frontroleErr: true, frontipHelperText: "Please enter URL Role" });
      }
      else {
        this.setState({ FrontRole: e.target.value, frontroleErr: false, frontipHelperText: "" });
      }

    }

    else if (type === "FrontUserName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ FrontUserName: e.target.value, frontusernameErr: true, frontusernameHelperText: "Please enter URL UserName" });
      }
      else {
        this.setState({ FrontUserName: e.target.value, frontusernameErr: false, frontusernameHelperText: "" });
      }

    }

    else if (type === "FrontPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ FrontPassword: e.target.value, frontpasswordHelperText: "Please enter URL Password", frontpasswordErr: true });
      }
      else {
        this.setState({ FrontPassword: e.target.value, frontpasswordHelperText: "", frontpasswordErr: false });
      }

    }

    else if (type === "APIUserName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ APIUserName: e.target.value, apiusernameErr: true, apiusernameHelperText: "Please enter URL UserName" });
      }
      else {
        this.setState({ APIUserName: e.target.value, apiusernameErr: false, apiusernameHelperText: "" });
      }

    }

    else if (type === "APIRole") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ APIRole: e.target.value, apiroleErr: true, apiroleHelperText: "Please enter URL Role" });
      }
      else {
        this.setState({ APIRole: e.target.value, apiroleErr: false, apiroleHelperText: "" });
      }

    }

    else if (type === "APIPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ APIPassword: e.target.value, apipasswordHelperText: "Please enter URL Password", apipasswordErr: true });
      }
      else {
        this.setState({ APIPassword: e.target.value, apipasswordHelperText: "", apipasswordErr: false });
      }

    }

    else if (type === "DBUserName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ DBUserName: e.target.value, dbusernameErr: true, dbusernameHelperText: "Please enter URL UserName" });
      }
      else {
        this.setState({ DBUserName: e.target.value, dbusernameErr: false, dbusernameHelperText: "" });
      }

    }

    else if (type === "DBRole") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ DBRole: e.target.value, dbroleErr: true, dbroleHelperText: "Please enter URL Role" });
      }
      else {
        this.setState({ DBRole: e.target.value, dbroleErr: false, dbroleHelperText: "" });
      }

    }

    else if (type === "DBPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ DBPassword: e.target.value, dbpasswordHelperText: "Please enter URL Password", dbpasswordErr: true });
      }
      else {
        this.setState({ DBPassword: e.target.value, dbpasswordHelperText: "", dbpasswordErr: false });
      }

    }

    else if (type === "APIIP") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ APIIP: e.target.value, apiIpHelperText: "Please enter API IP", apiIpErr: true });
      }
      else {
        this.setState({ APIIP: e.target.value, apiIpHelperText: "", apiIpErr: false });
      }

    }

    else if (type === "APIURL") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ APIURL: e.target.value, apiurlHelperText: "Please enter API URL", apiurlErr: true });
      }
      else {
        this.setState({ APIURL: e.target.value, apiurlHelperText: "", apiurlErr: false });
      }

    }

    else if (type === "APIParam") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ APIParam: e.target.value, apiparamHelperText: "Please enter API Param", apiparamErr: true });
      }
      else {
        this.setState({ APIParam: e.target.value, apiparamHelperText: "", apiparamErr: false });
      }

    }

    else if (type === "DBIP") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ DBIP: e.target.value, dbipHelperText: "Please enter DB IP", dbipErr: true });
      }
      else {
        this.setState({ DBIP: e.target.value, dbipHelperText: "", dbipErr: false });
      }

    }

    else if (type === "DBName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ DBName: e.target.value, dbnameHelperText: "Please enter DB Name", dbnameErr: true });
      }
      else {
        this.setState({ DBName: e.target.value, dbnameHelperText: "", dbnameErr: false });
      }

    }

    else if (type === "APIPort") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ APIPort: e.target.value, apiportHelperText: "Please enter API Port", apiportErr: true });
      }
      else {
        this.setState({ APIPort: e.target.value, apiportHelperText: "", apiportErr: false });
      }

    }

    else if (type === "DBPort") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ DBPort: e.target.value, dbportHelperText: "Please enter DB Port", dbportErr: true });
      }
      else {
        this.setState({ DBPort: e.target.value, dbportHelperText: "", dbportErr: false });
      }

    }

  }

  checkboxChange = (e, type) => {
    if (type === "clientowned") {
      this.setState({ ClientOwned: e.target.checked });
    }
  }

  typelist = () => {
    return this.state.ServerNameList.map(content => {
      return (
        <MenuItem value={content.PanelName} > {content.PanelName}  </MenuItem>
      )
    })
  }

  validate = () => {
    const { InstanceName, Description, AccessURL, AccessURLRole,
      AccessURLUserName, AccessURLPassword, FrontServer, FrontIP, FrontPassword, FrontUserName, FrontRole,
      FrontPort, APIServer, APIRole, APIUserName, APIPassword, APIIP, APIURL, APIParam,
      APIPort, DBServer, DBIP, DBName, DBPort, DBUserName, DBPassword, DBRole } = this.state;

    let IsValid = true;

    if (CommonConfig.isEmpty(InstanceName)) {
      IsValid = false;
      this.setState({ instancenameHelperText: "Please enter Instance Name", instancenameErr: true });
    }

    if (CommonConfig.isEmpty(Description)) {
      IsValid = false;
      this.setState({ descriptionHelperText: "Please enter Description", descriptionErr: true });
    }

    if (CommonConfig.isEmpty(AccessURL)) {
      IsValid = false;
      this.setState({ accessurlHelperText: "Please enter Access URL", accessurlErr: true });
    }

    if (CommonConfig.isEmpty(AccessURLRole)) {
      IsValid = false;
      this.setState({ accessroleHelperText: "Please enter Access Role", accessroleErr: true });
    }

    if (CommonConfig.isEmpty(AccessURLUserName)) {
      IsValid = false;
      this.setState({ accessusernameHelperText: "Please enter Access URL UserName", accessusernameErr: true });
    }

    if (CommonConfig.isEmpty(AccessURLPassword)) {
      IsValid = false;
      this.setState({ accesspasswordHelperText: "Please enter Access URL Password", accesspasswordErr: true });
    }

    if (CommonConfig.isEmpty(FrontRole)) {
      IsValid = false;
      this.setState({ frontroleHelperText: "Please enter URL Role", frontroleErr: true });
    }

    if (CommonConfig.isEmpty(FrontUserName)) {
      IsValid = false;
      this.setState({ frontusernameHelperText: "Please enter URL UserName", frontusernameErr: true });
    }

    if (CommonConfig.isEmpty(FrontPassword)) {
      IsValid = false;
      this.setState({ frontpasswordHelperText: "Please enter URL Password", frontpasswordErr: true });
    }

    if (CommonConfig.isEmpty(APIRole)) {
      IsValid = false;
      this.setState({ apiroleHelperText: "Please enter URL Role", apiroleErr: true });
    }

    if (CommonConfig.isEmpty(APIUserName)) {
      IsValid = false;
      this.setState({ apiusernameHelperText: "Please enter URL UserName", apiusernameErr: true });
    }

    if (CommonConfig.isEmpty(APIPassword)) {
      IsValid = false;
      this.setState({ apipasswordHelperText: "Please enter URL Password", apipasswordErr: true });
    }

    if (CommonConfig.isEmpty(FrontIP)) {
      IsValid = false;
      this.setState({ frontipHelperText: "Please enter Front IP", frontipErr: true });
    }

    if (CommonConfig.isEmpty(FrontPort)) {
      IsValid = false;
      this.setState({ frontportHelperText: "Please enter Front Port", frontportErr: true });
    }

    if (CommonConfig.isEmpty(APIIP)) {
      IsValid = false;
      this.setState({ apiIpHelperText: "Please enter API IP", apiIpErr: true });
    }

    if (CommonConfig.isEmpty(APIURL)) {
      IsValid = false;
      this.setState({ apiurlHelperText: "Please enter API URL", apiurlErr: true });
    }

    if (CommonConfig.isEmpty(APIParam)) {
      IsValid = false;
      this.setState({ apiparamHelperText: "Please enter API Param", apiparamErr: true });
    }

    if (CommonConfig.isEmpty(APIPort)) {
      IsValid = false;
      this.setState({ apiportHelperText: "Please enter API Port", apiportErr: true });
    }

    if (CommonConfig.isEmpty(DBIP)) {
      IsValid = false;
      this.setState({ dbipHelperText: "Please enter DB IP", dbipErr: true });
    }

    if (CommonConfig.isEmpty(DBName)) {
      IsValid = false;
      this.setState({ dbnameHelperText: "Please enter DB Name", dbnameErr: true });
    }

    if (CommonConfig.isEmpty(DBPort)) {
      IsValid = false;
      this.setState({ dbportHelperText: "Please enter DB Port", dbportErr: true });
    }

    if (CommonConfig.isEmpty(DBRole)) {
      IsValid = false;
      this.setState({ dbroleHelperText: "Please enter URL Role", dbroleErr: true });
    }

    if (CommonConfig.isEmpty(DBUserName)) {
      IsValid = false;
      this.setState({ dbusernameHelperText: "Please enter URL UserName", dbusernameErr: true });
    }

    if (CommonConfig.isEmpty(DBPassword)) {
      IsValid = false;
      this.setState({ dbpasswordHelperText: "Please enter URL Password", dbpasswordErr: true });
    }

    if (CommonConfig.isEmpty(FrontServer)) {
      IsValid = false;
      this.setState({ frontendserverHelperText: "Please enter Front End Server Name", frontendserverErr: true});
    }

    if (CommonConfig.isEmpty(APIServer)) {
      IsValid = false;
      this.setState({ apiserverHelperText: "Please enter API Server Name", apiserverErr: true});
    }

    if (CommonConfig.isEmpty(DBServer)) {
      IsValid = false;
      this.setState({ dbserverHelperText: "Please enter DB Server Name", dbserverErr: true});
    }

    return IsValid;
  }

  submit = (e) => {

    const { InstanceName, Description, AccessURL, AccessURLRole,
      AccessURLUserName, AccessURLPassword, FrontIP, FrontPassword, FrontUserName, FrontRole,
      FrontPort, APIRole, APIUserName, APIPassword, APIIP, APIURL, APIParam, DBServer, APIServer, FrontServer,
      APIPort, DBIP, DBName, DBPort, DBUserName, DBPassword, DBRole, ClientOwned } = this.state;

    if (this.validate()) {

      let data = {
        InstanceName: InstanceName,
        Description: Description,
        AccessURL: AccessURL,
        AccessURLRole: AccessURLRole,
        AccessURLUserName: AccessURLUserName,
        AccessURLPassword: AccessURLPassword,
        FrontIP: FrontIP,
        FrontPassword: FrontPassword,
        FrontUserName: FrontUserName,
        FrontRole: FrontRole,
        FrontPort: FrontPort,
        APIURL: APIURL,
        APIIP: APIIP,
        APIRole: APIRole,
        APIParam: APIParam,
        APIPort: APIPort,
        APIUserName: APIUserName,
        APIPassword: APIPassword,
        DBIP: DBIP,
        DBName: DBName,
        DBPort: DBPort,
        DBUserName: DBUserName,
        DBPassword: DBPassword,
        DBRole: DBRole,
        ClientOwned: ClientOwned,
        UserID: CommonConfig.loginData().PersonID,
        DBServer: DBServer,
        APIServer: APIServer,
        FrontEndServer: FrontServer,
        ProjectInstanceID: this.props.location.state.id
      }
      try {
        api.post("projectinstance/addProjectInstance", data).then(res => {
          if (res.success) {
            cogoToast.success('Updated Successfully');
            this.props.history.push("/admin/ProjectInstanceListing");
          }
          else {
            cogoToast.error('Something went wrong')

          }
        }).catch(error => {
        });
      }

      catch (err) {
      }

    }
    else {
      cogoToast.error( 'Please correct error and resubmit the form')
    }

  }

  showModal = () => {
    this.setState({ Accessvisible: true });
  };

  handleOk = () => {
    this.setState({ Accessloading: true, Accessvisible: false });
  };

  handleCancel = () => {
    this.setState({ Accessvisible: false });
  };

  url1Model = () => {
    this.setState({ Frontvisible: true });
  };

  url1Ok = () => {
    this.setState({ Frontvisible: false });
  };

  url1Cancel = () => {
    this.setState({ Frontvisible: false });
  };

  url2Model = () => {
    this.setState({ Apivisible: true });
  };

  url2Ok = () => {
    this.setState({ Apivisible: false });
  };

  url2Cancel = () => {
    this.setState({ Apivisible: false });
  };

  dbmodel = () => {
    this.setState({ DBvisible: true });
  };

  dbok = () => {
    this.setState({ DBvisible: false });
  };

  dbCancel = () => {
    this.setState({ DBvisible: false });
  };


  selectChange = (e, type) => {
    if (type === "frontserver") {
      this.setState({ FrontServer: e.target.value, frontendserverErr: false, frontendserverHelperText: "" });
    }
    else if (type === "dbserver") {
      this.setState({ DBServer: e.target.value, dbserverErr: false, dbserverHelperText: "" });
    }
    else if (type === "apiserver") {
      this.setState({ APIServer: e.target.value, apiserverErr: false, apiserverHelperText: "" });
    }
  }

  deleteProjectInstance = () => {
    try {
      let data = {
        ProjectInstanceID: this.props.location.state.id
      }
      api.post("projectinstance/deleteProjectInstanceByID", data).then(res => {
        if (res.success) {
          cogoToast.success('Deleted Successfully')
          this.props.history.push("/admin/ProjectInstanceListing");
        }
        else {
          cogoToast.error('Something went wrong')

        }
      }).catch(error => {
      });

    }
    catch (err) {

    }
  }


  render() {
    const { InstanceName, Description, AccessURL, instancenameErr, descriptionErr, accessurlErr, accessroleErr, accessusernameErr,
      AccessURLRole, AccessURLUserName, AccessURLPassword, Accessvisible, accesspasswordErr, frontipErr, frontportErr,
      FrontIP, FrontPassword, FrontUserName, FrontRole, Frontvisible, descriptionHelperText, frontroleErr, frontusernameErr,
      frontpasswordErr, apipasswordErr, apiusernameErr, accessurlHelperText, accessroleHelperText, accessusernameHelperText,
      accesspasswordHelperText, frontipHelperText, frontpasswordHelperText, frontusernameHelperText, frontroleHelperText,
      frontportHelperText, apipasswordHelperText, apiusernameHelperText, apiroleHelperText, apiroleErr, apiIpHelperText, apiIpErr,
      FrontPort, APIRole, APIUserName, Apivisible, APIPassword, ClientOwned, instancenameHelperText, DBvisible,
      APIIP, APIURL, apiurlHelperText, apiurlErr, APIParam, apiparamHelperText, apiparamErr, APIPort, apiportErr, apiportHelperText,
      DBIP, dbipErr, dbipHelperText, DBName, dbnameHelperText, dbnameErr, DBPort, dbportErr, dbportHelperText, DBUserName, dbusernameHelperText,
      dbusernameErr, DBPassword, dbpasswordHelperText, dbpasswordErr, DBRole, dbroleHelperText, dbroleErr, DBServer, dbserverErr, dbserverHelperText, APIServer, apiserverErr, apiserverHelperText, FrontServer, frontendserverErr, frontendserverHelperText } = this.state;
    return (
      <GridContainer className="UserList-outer">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="btn-right-outer" color="primary" icon>
              <CardIcon color="primary">
                <HeadsetMic />
              </CardIcon>
              <h4 className="margin-right-auto text-color-black">Edit Project Instance</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={11} sm={11} md={11}>
                  <FormControlLabel
                    control={<Checkbox checked={ClientOwned} onChange={(e) => this.checkboxChange(e, "clientowned")} name="clientowned" />}
                    label="Client Owned"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>  
                <GridItem xs={3} sm={3} md={3}>
                  <FormControl label="Instance Name">
                    <TextField
                      helperText={instancenameHelperText}
                      error={instancenameErr}
                      value={InstanceName}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="Instance Name"
                      onChange={(e) => this.onChange(e, "instancename")}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControl>
                    <TextField
                      helperText={descriptionHelperText}
                      error={descriptionErr}
                      value={Description}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="Description"
                      onChange={(e) => this.onChange(e, "description")}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControl>
                    <TextField
                      value={AccessURL}
                      helperText={accessurlHelperText}
                      error={accessurlErr}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="Access URL"
                      onChange={(e) => this.onChange(e, "accessURL")}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                <Tooltip title="Credentials" placement="bottom">
                  <Button className="btn-icon" color="primary" onClick={this.showModal}>
                    <LockIcon />
                  </Button>
                  </Tooltip>
                  <Dialog
                    open={Accessvisible}
                    title="Credentials"
                    onClose={() => this.handleCancel()}
                  >
                    <DialogContent>
                      <FormControl>
                        <TextField
                          value={AccessURLRole}
                          helperText={accessroleHelperText}
                          error={accessroleErr}
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          label="URL Role"
                          onChange={(e) => this.onChange(e, "AccessURLRole")}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          value={AccessURLUserName}
                          helperText={accessusernameHelperText}
                          error={accessusernameErr}
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          label="User Name"
                          onChange={(e) => this.onChange(e, "AccessURLUserName")}
                        />
                      </FormControl>
                      <FormControl>
                        <TextField
                          type="password"
                          value={AccessURLPassword}
                          helperText={accesspasswordHelperText}
                          error={accesspasswordErr}
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          label="Password"
                          onChange={(e) => this.onChange(e, "AccessURLPassword")}
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                    <Tooltip title="Save" placement="bottom">
                      <Button
                        type="submit"
                        color="primary"
                        onClick={() => this.handleOk()}
                      >
                        Save
                          </Button>
                          </Tooltip>
                          <Tooltip title="Back" placement="bottom">
                      <Button key="back" onClick={() => this.handleCancel()}>
                        Back
                          </Button>
                          </Tooltip>
                    </DialogActions>
                  </Dialog>
                </GridItem>
              </GridContainer>
              <GridContainer>  
                  <GridItem xs={12} sm={12} md={12}>
                    <h2 className="inner-page-title">Front End</h2>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    <FormControl fullWidth className="input-select-outer" error={frontendserverErr} helperText={frontendserverHelperText}>             
                      <InputLabel>Select Server Name</InputLabel>
                      <Select onChange={(e) => this.selectChange(e, "frontserver")} value={FrontServer} >
                        {this.typelist()}
                      </Select>
                      <FormHelperText>{frontendserverHelperText}</FormHelperText>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormControl>
                      <TextField
                        value={FrontIP}
                        helperText={frontipHelperText}
                        error={frontipErr}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        label="Front IP"
                        onChange={(e) => this.onChange(e, "FrontIP")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormControl>
                      <TextField
                        value={FrontPort}
                        helperText={frontportHelperText}
                        error={frontportErr}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        label="Port"
                        onChange={(e) => this.onChange(e, "FrontPort")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                  <Tooltip title="Credentials" placement="bottom">
                    <Button className="btn-icon" color="primary" onClick={this.url1Model}>
                      <LockIcon />
                    </Button>
                    </Tooltip>
                    <Dialog
                      open={Frontvisible}
                      title="Credentials"
                      onClose={() => this.url1Cancel()}
                    >
                      <DialogContent>
                        <FormControl>
                          <TextField
                            value={FrontRole}
                            helperText={frontroleHelperText}
                            error={frontroleErr}
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            label="URL Role"
                            onChange={(e) => this.onChange(e, "FrontRole")}
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            helperText={frontusernameHelperText}
                            error={frontusernameErr}
                            value={FrontUserName}
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            label="User Name"
                            onChange={(e) => this.onChange(e, "FrontUserName")}
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            value={FrontPassword}
                            helperText={frontpasswordHelperText}
                            error={frontpasswordErr}
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            label="Password"
                            onChange={(e) => this.onChange(e, "FrontPassword")}
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                      <Tooltip title="Save" placement="bottom">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={this.url1Ok}
                        >
                          Save
                              </Button>
                              </Tooltip>
                              <Tooltip title="Back" placement="bottom">
                        <Button key="back" onClick={() => this.url1Cancel()}>
                          Back
                              </Button>
                              </Tooltip>
                      </DialogActions>
                    </Dialog>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h2 className="inner-page-title">Middle Tier (API)</h2>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>  
                    <FormControl fullWidth className="input-select-outer" error={apiserverErr} helperText={apiserverHelperText}>                 
                      <InputLabel>Select Server Name</InputLabel>
                      <Select onChange={(e) => this.selectChange(e, "apiserver")} value={APIServer} >
                        {this.typelist()}
                      </Select>
                      <FormHelperText>{apiserverHelperText}</FormHelperText>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    <FormControl>
                      <TextField
                        helperText={apiIpHelperText}
                        error={apiIpErr}
                        value={APIIP}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        label="IP"
                        onChange={(e) => this.onChange(e, "APIIP")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                    <FormControl>
                    <TextField
                      value={APIURL}
                      helperText={apiurlHelperText}
                      error={apiurlErr}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="API URL"
                      onChange={(e) => this.onChange(e, "APIURL")}
                    />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <FormControl>
                      <TextField
                        value={APIPort}
                        helperText={apiportHelperText}
                        error={apiportErr}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        label="Port"
                        onChange={(e) => this.onChange(e, "APIPort")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                  <Tooltip title="Credentials" placement="bottom">
                    <Button className="btn-icon" color="primary" onClick={() => this.url2Model()}>
                      <LockIcon />
                    </Button>
                    </Tooltip>
                    <Dialog
                      open={Apivisible}
                      title="Credentials"
                      onClose={() => this.url2Cancel()}
                    >
                      <DialogContent>
                        <FormControl>
                          <TextField
                            value={APIRole}
                            helperText={apiroleHelperText}
                            error={apiroleErr}
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            label="URL Role"
                            onChange={(e) => this.onChange(e, "APIRole")}
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            value={APIUserName}
                            helperText={apiusernameHelperText}
                            error={apiusernameErr}
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            label="User Name"
                            onChange={(e) => this.onChange(e, "APIUserName")}
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            helperText={apipasswordHelperText}
                            error={apipasswordErr}
                            value={APIPassword}
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            label="Password"
                            onChange={(e) => this.onChange(e, "APIPassword")}
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                      <Tooltip title="Save" placement="bottom">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={() => this.url2Ok()}
                        >
                          Save
                            </Button></Tooltip>
                            <Tooltip title="Back" placement="bottom">
                        <Button key="back" onClick={() => this.url2Cancel()}>
                          Back
                            </Button></Tooltip>
                      </DialogActions>
                    </Dialog>
                  </GridItem>
                  <GridItem xs={11} sm={11} md={11}>
                    <FormControl>
                    <label>API Parameter</label>
                    <TextareaAutosize
                      value={APIParam}
                      helperText={apiparamHelperText}
                      error={apiparamErr}
                      rowsMin={4}
                      label="API Param"
                      onChange={(e) => this.onChange(e, "APIParam")}
                    />
                    </FormControl>
                    <FormHelperText error={apiparamErr}>{apiparamHelperText}</FormHelperText>
                  </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h2 className="inner-page-title">DB Layer</h2>
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <FormControl fullWidth className="input-select-outer" error={dbserverErr} helperText={dbserverHelperText}>
                        <InputLabel>Select Server Name</InputLabel>
                        <Select onChange={(e) => this.selectChange(e, "dbserver")} value={DBServer} >
                          {this.typelist()}
                        </Select>
                        <FormHelperText>{dbserverHelperText}</FormHelperText>
                      </FormControl>
                    </GridItem>             
                    <GridItem xs={3} sm={3} md={3}>
                      <FormControl>
                        <TextField
                          value={DBIP}
                          helperText={dbipHelperText}
                          error={dbipErr}
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          label="IP"
                          onChange={(e) => this.onChange(e, "DBIP")}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <FormControl>
                        <TextField
                          value={DBName}
                          helperText={dbnameHelperText}
                          error={dbnameErr}
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          label="DB Name"
                          onChange={(e) => this.onChange(e, "DBName")}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                      <FormControl>
                        <TextField
                          value={DBPort}
                          helperText={dbportHelperText}
                          error={dbportErr}
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          label="Port"
                          onChange={(e) => this.onChange(e, "DBPort")}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={1} sm={1} md={1}>
                    <Tooltip title="Credentials" placement="bottom">
                      <Button className="btn-icon" color="primary" onClick={this.dbmodel}>
                        <LockIcon />
                      </Button>
                      </Tooltip>
                      <Dialog
                        open={DBvisible}
                        title="Credentials"
                        onClose={() => this.dbCancel()}
                      >
                        <DialogContent>
                          <FormControl>
                            <TextField
                              value={DBRole}
                              helperText={dbroleHelperText}
                              error={dbroleErr}
                              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                              label="URL Role"
                              onChange={(e) => this.onChange(e, "DBRole")}
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              value={DBUserName}
                              helperText={dbusernameHelperText}
                              error={dbusernameErr}
                              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                              label="User Name"
                              onChange={(e) => this.onChange(e, "DBUserName")}
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              value={DBPassword}
                              helperText={dbpasswordHelperText}
                              error={dbpasswordErr}
                              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                              label="Password"
                              onChange={(e) => this.onChange(e, "DBPassword")}
                            />
                          </FormControl>
                        </DialogContent>
                        <DialogActions>
                        <Tooltip title="Save" placement="bottom">
                          <Button
                            type="submit"
                            color="primary"
                            onClick={() => this.dbok()}
                          >
                            Save
                            </Button></Tooltip>
                            <Tooltip title="Back" placement="bottom">
                          <Button key="back" onClick={() => this.dbCancel()}>
                            Back
                            </Button></Tooltip>
                        </DialogActions>
                      </Dialog>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                    <Tooltip title="Update" placement="bottom">
                      <Button color="primary" type="submit" className="login-form-button M-right-10" onClick={(e) => this.submit(e)}>
                        Update Project Instance
                      </Button></Tooltip>
                      <Tooltip title="Delete" placement="bottom">
                      <Button color="info" className="login-form-button danger-color " onClick={() => this.deleteProjectInstance()}>
                        Delete Project Instance
                      </Button></Tooltip>
                    </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

}
export default EditProjectInstance;