import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
// material ui icons
import Contacts from "@material-ui/icons/Contacts";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { CommonConfig } from "../../utils/constant";
import CardBody from "components/Card/CardBody.js";
import cogoToast from 'cogo-toast';
import api from "../../utils/apiClient";
import Icon from "@material-ui/core/Icon";
import Datetime from "react-datetime";
import moment from "moment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
// style for this view
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import SimpleBackdrop from "../../utils/general";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { BorderAll } from "@material-ui/icons";

const style = {
  ...customSelectStyle,
};

class AddLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StartDate: "",
      StartDateErr: false,
      StartDatehelperText: "",
      EndDate: "",
      EndDateErr: false,
      EndDatehelperText: "",
      LeaveType: "",
      LeaveTypeErr: false,
      LeaveTypehelperText: "",
      ECN: "",
      ECNErr: false,
      ECNhelperText: "",
      AlternateEmail: "",
      AlternateEmailErr: false,
      AlternateEmailhelperText: "",
      LeaveReason: "",
      LeaveReasonErr: false,
      LeaveReasonhelperText: "",
      FullDay: true,
      Beforelunch: false,
      Afterlunch: false,
      DayLeaveTypes: [],
      LeaveTypes: [],
      currentDay: "",
      endDay: "",
      DayLeavetype: "",
      LeaveType: "",
      filterVal: "",
      DailyStatusList: [],
      enabledResource: false,
      ApproveAccess: true,

      Loading: false,
    };
  }

  componentDidMount() {
    this.getLeavetypelist();
    this.getDailyStatusList();
    this.getDayLeavetypelist();
    if (CommonConfig.getUserAccess("Leave")) {
      this.setState({
        enabledResource:
          CommonConfig.getUserAccess("Leave").AllAccess === 1 ? false : true,
      });
    }
    if (CommonConfig.getUserAccess("Leave").DeleteAccess === 1) {
      this.setState({ ApproveAccess: false });
    } else {
      this.setState({ ApproveAccess: true });
    }
    if (this.props.location.state) {
      this.getLeaveDetailById(this.props.location.state.id);
    } else {
      this.setState({ DayLeavetype: "Full Day" });
    }
  }
  validEndDate = (current) => {
    if (this.state.LeaveType === "Sick Leave") {
      return (
        current.day() !== 0 &&
        current.isAfter(this.state.StartDate) &&
        current.isBefore(new Date())
      );
    } else {
      return current.day() !== 0 && current.isAfter(this.state.StartDate);
    }
  };
  validStartDate = (current) => {
    if (this.state.LeaveType === "Sick Leave") {
      return current.day() !== 0 && current.isBefore(new Date());
    } else {
      return current.day() !== 0;
    }
  };
  validSickLeaveDate = (current) => {
    if (this.state.LeaveType === "Sick Leave") {
      return current.day() !== 0 && current.isBefore(new Date());
    } else {
      return current.day() !== 0 && current.isAfter(this.state.StartDate);
    }
  };
  getLeaveDetailById = (id) => {
    let data;
    data = {
      LeaveID: id,
    };

    try {
      api
        .post("leave/getLeaveDetailByID", data)
        .then((res) => {
          this.setState({ Loading: false });
          this.setState({
            StartDate: res.data[0].LeaveStartDate,
            EndDate: res.data[0].LeaveEndDate,
            LeaveType: res.data[0].LeaveType,
            ECN: res.data[0].EmergencyContactNumber,
            AlternateEmail: res.data[0].AlternateEmail,
            LeaveStatus: res.data[0].LeaveStatus,
            LeaveReason: res.data[0].LeaveReason,
            IsBeforeLunch:
              res.data[0].IsBeforeLunch !== null &&
                res.data[0].IsBeforeLunch.data[0] === 1
                ? true
                : false,
          });
          if (
            res.data[0].IsBeforeLunch !== null &&
            (res.data[0].IsBeforeLunch.data[0] === 1 ||
              res.data[0].HalfLeave.data[0] === 1)
          ) {
            this.setState({ DayLeavetype: "Half Day" });
          } else if (res.data[0].LeaveStartDate != res.data[0].LeaveEndDate) {
            this.setState({
              DayLeavetype: "Multi Day",
              IsMultiDayselected: true,
            });
          } else {
            this.setState({ DayLeavetype: "Full Day" });
          }
          // }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  getLeavetypelist = () => {
    let data = {
      StringMapType: "LEAVETYPE",
    };
    try {
      api
        .post("/stringmap/getStringMap", data)
        .then((res) => {
          this.setState({ Loading: false });
          if (res.success) {
            this.setState({ LeaveTypes: res.data });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) { }
  };
  getDailyStatusList = () => {
    try {
      api
        .get("dailystatus/getDailyStatusList")
        .then((res) => {
          if (res.success) {
            let obj = {
              FirstName: "",
              LastName: "",
              ResourceID: "",
            };
            res.data.push(obj);
            res.data.map((r, i) => {
              res.data[i].FullName = `${r.FirstName} ${r.LastName}`;
            });
            this.setState({
              DailyStatusList: res.data,
              filterVal: {
                value: CommonConfig.loginData().PersonID,
                label:
                  CommonConfig.loginData().FirstName +
                  " " +
                  CommonConfig.loginData().LastName,
              },
            });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };
  getDayLeavetypelist = () => {
    let data = {
      StringMapType: "DAYLEAVETYPE",
    };
    try {
      api
        .post("/stringmap/getStringMap", data)
        .then((res) => {
          this.setState({ Loading: false });
          if (res.success) {
            this.setState({ DayLeaveTypes: res.data });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) { }
  };

  dayLeavetype = () => {
    return this.state.DayLeaveTypes.map((content) => {
      return (
        <MenuItem value={content.StringMapDesc}>
          {" "}
          {content.StringMapDesc}{" "}
        </MenuItem>
      );
    });
  };
  leavetype = () => {
    return this.state.LeaveTypes.map((content) => {
      return (
        <MenuItem value={content.StringMapDesc}>
          {" "}
          {content.StringMapDesc}{" "}
        </MenuItem>
      );
    });
  };
  handleChange = (e, type, value) => {
    let EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/gi;
    let phoneRegExp = /^([0-9]+\s?)*$/g;

    if (type == "StartDate") {
      if (type === "StartDate") {
        this.setState({
          StartDate: e,
          StartDateHelperText: "",
          StartDateErr: false,
        });
      }
      this.setState({ StartDate: e });
      var currentDay = moment(e).startOf("day").add(0, "day").format("ddd");
      this.setState({ currentDay: currentDay });
    } else if (type == "EndDate") {
      if (type === "EndDate") {
        this.setState({ EndDate: e, EndDateHelperText: "", EndDateErr: false });
      }
      this.setState({ EndDate: e });
      var endDay = moment(e).endOf("day").add(0, "day").format("ddd");
      this.setState({ endDay: endDay });
    } else if (type == "LeaveType") {
      // if (type === '' || type === undefined || type === null) {
      //     this.setState({ LeaveType: '' });
      //     } else {
      //     this.setState({ LeaveType: type.value });
      //     }
      if (e.target.value == "") {
        this.setState({
          LeaveType: e.target.value,
          LeaveTypeErr: true,
          LeaveTypehelperText: "Leave Type is required",
        });
      } else {
        this.setState({
          LeaveType: e.target.value,
          LeaveTypeErr: false,
          LeaveTypehelperText: "",
        });
        if (e.target.value === "Sick Leave") {
          // var currentDay = moment(e).startOf("day").add(0,"day").format("ddd");
          // if(this.state.StartDate > currentDay){
          //    this.setState({currentDay: currentDay});
          // }
        } else {
        }
      }
    } else if (type === "ECN") {
      const ecnValue = e.target.value;
      let ecnValid = true;
      let ecnHelperText = "";

      if (ecnValue === "") {
        ecnValid = false;
        ecnHelperText = "Emergency Contact number is required";
      } else if (!/^\d{10}$/.test(ecnValue)) {
        ecnValid = false;
        ecnHelperText = "Emergency Contact number should be exactly 10 digits";
      }
      this.setState({
        ECN: ecnValue,
        ECNErr: !ecnValid,
        ECNhelperText: ecnHelperText,
      });
    } else if (type == "AlternateEmail") {
      if (e.target.value == "") {
        this.setState({
          AlternateEmail: e.target.value,
          AlternateEmailErr: true,
          AlternateEmailhelperText: "Alternate Email is required",
        });
      } else if (!e.target.value.match(EmailRegex)) {
        this.setState({
          AlternateEmail: e.target.value,
          AlternateEmailErr: true,
          AlternateEmailhelperText: "Alternate Email is not valid",
        });
      } else {
        this.setState({
          AlternateEmail: e.target.value,
          AlternateEmailErr: false,
          AlternateEmailhelperText: "",
        });
      }
    } else if (type == "LeaveReason") {
      if (e.target.value == "") {
        this.setState({
          LeaveReason: e.target.value,
          LeaveReasonErr: true,
          LeaveReasonhelperText: "Leave Reason is required",
        });
      } else {
        this.setState({
          LeaveReason: e.target.value,
          LeaveReasonErr: false,
          LeaveReasonhelperText: "",
        });
      }
    } else if (type == "DayLeavetype") {
      if (e.target.value == "") {
        this.setState({
          DayLeavetype: e.target.value,
          DayLeaveTypeErr: true,
          DayLeaveTypehelperText: "DayLeaveType is required",
        });
      } else {
        this.setState({
          IsHalfDayselected: e.target.value == "Half Day" ? true : false,
          IsFullDayselected: e.target.value == "Full Day" ? true : false,
          IsMultiDayselected: e.target.value == "Multi Day" ? true : false,
          DayLeavetype: e.target.value,
          DayLeaveTypeErr: false,
          DayLeaveTypehelperText: "",
        });
      }
    } else if (type === "Before Lunch") {
      if (e.target.checked === false) {
        this.setState({ IsBeforeLunch: false });
      } else {
        this.setState({ IsBeforeLunch: true });
      }
    } else if (type === "After Lunch") {
      if (e.target.checked === false) {
        this.setState({ IsBeforeLunch: true });
      } else {
        this.setState({ IsBeforeLunch: false });
      }
    }
  };
  handleResoureOnChange = (value) => {
    if (value !== null) {
      this.setState({ filterVal: value });
    }
  };

  validate = () => {
    let formIsvalid = true;
    const {
      DayLeavetype,
      StartDate,
      EndDate,
      LeaveType,
      ECN,
      AlternateEmail,
      LeaveReason,
    } = this.state;
    if (CommonConfig.isEmpty(StartDate)) {
      formIsvalid = false;
      this.setState({
        StartDateErr: true,
        StartDatehelperText: "Start Date is required",
      });
    }
    if (DayLeavetype === "Multi Day") {
      if (CommonConfig.isEmpty(EndDate)) {
        formIsvalid = false;
        this.setState({
          EndDateErr: true,
          EndDatehelperText: "End Date is required",
        });
      }
    }
    if (CommonConfig.isEmpty(LeaveType)) {
      formIsvalid = false;
      this.setState({
        LeaveTypeErr: true,
        LeaveTypehelperText: "LeaveType is required",
      });
    }
    if (CommonConfig.isEmpty(ECN)) {
      formIsvalid = false;
      this.setState({
        ECNErr: true,
        ECNhelperText: "Emergency Contact number is required",
      });
    }
    if (CommonConfig.isEmpty(AlternateEmail)) {
      formIsvalid = false;
      this.setState({
        AlternateEmailErr: true,
        AlternateEmailhelperText: "Alternate Email is required",
      });
    }
    if (CommonConfig.isEmpty(LeaveReason)) {
      formIsvalid = false;
      this.setState({
        LeaveReasonErr: true,
        LeaveReasonhelperText: "Leave Reason is required",
      });
    }
    return formIsvalid;
  };
  requestChange = (e, value, type) => {
    if (type === "DailyStatus") {
      this.setState({
        DailyStatus: value,
        DailyStatusErr: false,
        DailyStatusHelperText: "",
      });
    }
  };

  handleSubmit = (e, type) => {
    e.preventDefault();
    if (this.validate()) {
      this.setState({ Loading: true });
      if (type == "add") {
        let data;
        if (this.state.DayLeavetype === "Half Day") {
          this.setState({ Loading: false });

          data = {
            ResourceID:  this.state.filterVal.value,
            UserID: CommonConfig.loginData().PersonID,
            LeaveType: this.state.LeaveType,
            DayLeaveType: this.state.DayLeavetype,
            AlternateEmail: this.state.AlternateEmail,
            EmergencyContactNumber: this.state.ECN,
            HalfLeave: 1,
            IsBeforeLunch: this.state.IsBeforeLunch === true ? 1 : 0,
            LeaveStartDate: moment(this.state.StartDate)
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            LeaveEndDate:
              this.state.DayLeavetype !== "Multi Day"
                ? moment(this.state.StartDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString()
                : moment(this.state.EndDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString(),
            LeaveReason: this.state.LeaveReason,
            LeaveStatus: "Pending",
            LeaveID: null,
          };
        } else {
          data = {
            IsBeforeLunch: this.state.IsBeforeLunch === true ? 1 : 0,
            ResourceID:  this.state.filterVal.value,
            UserID: CommonConfig.loginData().PersonID,
            LeaveType: this.state.LeaveType,
            DayLeaveType: this.state.DayLeavetype,
            AlternateEmail: this.state.AlternateEmail,
            EmergencyContactNumber: this.state.ECN,
            HalfLeave: 0,
            LeaveStartDate: moment(this.state.StartDate)
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            LeaveEndDate:
              this.state.DayLeavetype !== "Multi Day"
                ? moment(this.state.StartDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString()
                : moment(this.state.EndDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString(),
            LeaveReason: this.state.LeaveReason,
            LeaveStatus: "Pending",
            LeaveID: null,
            Loading: false,
          };
        }

        console.log("datadataQ", data);
        try {
          api
            .post("leave/addUpdateLeave", data)
            .then((res) => {
              this.setState({ Loading: false });
              if (res.message == "Leave already exist.") {
                cogoToast.error("Leave already exist.");
              } else {
                if (res.success) {
                  cogoToast.success("Leave added successfully");
                  this.props.history.push("/admin/LeaveList");
                } else {
                  cogoToast.error("Something went wrong");
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        } catch (err) {
          console.log("error", err);
        }
      } else {
        let data;
        if (this.state.DayLeavetype === "Half Day") {
          data = {
            ResourceID: CommonConfig.loginData().PersonID,
            UserID: CommonConfig.loginData().PersonID,
            LeaveType: this.state.LeaveType,
            DayLeaveType: this.state.DayLeavetype,
            AlternateEmail: this.state.AlternateEmail,
            EmergencyContactNumber: this.state.ECN,
            HalfLeave: 1,
            IsBeforeLunch: this.state.IsBeforeLunch === true ? 1 : 0,
            LeaveStartDate: moment(this.state.StartDate)
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            LeaveEndDate:
              this.state.DayLeavetype !== "Multi Day"
                ? moment(this.state.StartDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString()
                : moment(this.state.EndDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString(),
            LeaveReason: this.state.LeaveReason,
            LeaveStatus: "Pending",
            LeaveID: this.props.location.state.id,
            Loading: false,
          };
        } else {
          data = {
            ResourceID: CommonConfig.loginData().PersonID,
            UserID: CommonConfig.loginData().PersonID,
            LeaveType: this.state.LeaveType,
            DayLeaveType: this.state.DayLeavetype,
            AlternateEmail: this.state.AlternateEmail,
            EmergencyContactNumber: this.state.ECN,
            HalfLeave: 0,
            LeaveStartDate: moment(this.state.StartDate)
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            LeaveEndDate:
              this.state.DayLeavetype !== "Multi Day"
                ? moment(this.state.StartDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString()
                : moment(this.state.EndDate)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .toString(),
            LeaveReason: this.state.LeaveReason,
            LeaveStatus: "Pending",
            LeaveID: this.props.location.state.id,
            Loading: false,
          };
        }

        try {
          api
            .post("leave/addUpdateLeave", data)
            .then((res) => {
              this.setState({ Editvisible: true, Loading: true });
              if (res.success) {
                cogoToast.success("Leave updated successfully");
                this.props.history.push("/admin/LeaveList");
              } else {
                cogoToast.error("Something went wrong");
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        } catch (err) {
          console.log("error", err);
        }
      }
    }
  };

  render() {

    const { classes } = this.props;
    const {
      ApproveAccess,
      addLeaveErr,
      addLeaveHelperText,
      DayLeavetype,
    } = this.state;
    const DailyStatus = this.state.DailyStatusList.map((DailyStatus) => {
      return { value: DailyStatus.ResourceID, label: DailyStatus.FullName };
    });
    return (
      <div>
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {this.props.location.state ? "Edit" : "Add"} Leave
                </h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <InputLabel> How Many Days</InputLabel>
                      <Select
                        onChange={(e) => this.handleChange(e, "DayLeavetype")}
                        value={this.state.DayLeavetype} type="number"
                      >
                        {this.dayLeavetype()}
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <InputLabel>Search Resource</InputLabel>
                      <div className="search-resource">
                        <Autocomplete
                          disabled={this.state.ApproveAccess}
                          options={DailyStatus}
                          id="DailyStatus"
                          value={this.state.filterVal}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) =>
                            this.handleResoureOnChange(value)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={addLeaveErr}
                              helperText={addLeaveHelperText}
                            />
                          )}
                        />
                      </div>
                    </GridItem>
                    {/* <GridItem xs={12} sm={6} md={6}>
                                        <div className="search-resource">
                                            <Autocomplete
                                            options={DailyStatus}
                                            id="DailyStatus"
                                            value={this.state.filterVal}
                                            getOptionLabel={option => option.label}
                                            onChange={(event, value) => this.handleResoureOnChange(value)}
                                            renderInput={params => (
                                                <TextField {...params} error={addLeaveErr} helperText={addLeaveHelperText}
                                                label="Search Resources Name" />)}
                                            />
                                        </div>
                                    </GridItem>  */}
                  </GridContainer>
                  {this.state.DayLeavetype === "Half Day" ? (
                    <GridContainer>
                      <label>
                        <Radio
                          name="Before Lunch"
                          checked={this.state.IsBeforeLunch ? true : false}
                          onChange={(e) => this.handleChange(e, "Before Lunch")}
                        />{" "}
                        Before Lunch
                      </label>
                      <label>
                        <Radio
                          name="After Lunch"
                          checked={this.state.IsBeforeLunch ? false : true}
                          onChange={(e) => this.handleChange(e, "After Lunch")}
                        />
                        After Lunch
                      </label>
                    </GridContainer>
                  ) : null}
                </form>

                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <Datetime
                        dateFormat={"DD/MM/YYYY"}
                        value={moment(this.state.StartDate)}
                        timeFormat={false}
                        selected={moment(this.state.StartDate)}
                        isValidDate={this.validStartDate}
                        onChange={(e, date) =>
                          this.handleChange(e, "StartDate")
                        }

                        closeOnSelect={true}
                        renderInput={(params) => (
                          <TextField
                            error={this.state.StartDateErr}
                            helperText={this.state.StartDatehelperText}
                            {...params}
                            label="Start Date*"
                            fullWidth
                            disabled
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Icon>date_range</Icon>
                                </InputAdornment>
                              ),
                              endAdornment: <div>{this.state.currentDay}</div>,
                              placeholder: "Start Date *",
                            }}
                          />
                        )}
                      />
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel>Leave Type</InputLabel>
                        <Select
                          onChange={(e) => this.handleChange(e, "LeaveType")}
                          value={this.state.LeaveType}
                        >
                          {this.leavetype()}
                        </Select>
                        {this.state.LeaveTypeErr == true ? (
                          <FormHelperText style={{ color: "red" }}>
                            {this.state.LeaveTypehelperText}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                      <TextField
                        success={this.state.ECNErr === false}
                        error={this.state.ECNErr === true}
                        value={this.state.ECN}
                        // error={this.state.ECNErr}
                        helperText={this.state.ECNhelperText}
                        label="Emergency Contact No *"
                        id="emergencycontactno"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        onChange={(e) => this.handleChange(e, "ECN")}
                        inputProps={{
                          autoComplete: "off",
                          maxLength: 10,
                          minLenght: 10,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      {this.state.IsMultiDayselected == true ? (
                        <Datetime
                          dateFormat={"DD/MM/YYYY"}
                          value={moment(this.state.EndDate)}
                          timeFormat={false}
                          selected={moment(this.state.EndDate)}
                          isValidDate={this.validEndDate}
                          onChange={(e) => this.handleChange(e, "EndDate")}
                          closeOnSelect={true}
                          renderInput={(params) => (
                            <TextField
                              error={this.state.EndDateErr}
                              helperText={this.state.EndDatehelperText}
                              {...params}
                              label="End Date *"
                              fullWidth
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                  </InputAdornment>
                                ),
                                endAdornment: <div>{this.state.endDay}</div>,
                                placeholder: "End Date *",
                              }}
                            />
                          )}
                        />
                      ) : null}
                      <TextField
                        success={this.state.AlternateEmailErr === false}
                        error={this.state.AlternateEmailErr === true}
                        value={this.state.AlternateEmail}
                        // error={this.state.AlternateEmailErr}
                        helperText={this.state.AlternateEmailhelperText}
                        label="Alternate Email *"
                        id="alternateemail"
                        onChange={(e) => this.handleChange(e, "AlternateEmail")}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          autoComplete: "off",
                        }}
                      />
                      <FormControl>
                        <TextareaAutosize
                          style={{ borderColor: "black" }}
                          value={this.state.LeaveReason}
                          placeholder="Leave Reason *"
                          rowsMin={13 / 2}
                          cols={65}
                          onChange={(e) => this.handleChange(e, "LeaveReason")}
                          label="Leave Reason"
                        />
                        {this.state.LeaveReasonErr == true ? (
                          <FormHelperText style={{ color: "red" }}>
                            {this.state.LeaveReasonhelperText}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.formCategory}></div>
                      <div
                        className={classes.center}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.props.location.state ? (
                          <Tooltip title="Cancel" placement="bottom">
                            <Button
                              color="rose"
                              onClick={(e) => this.handleSubmit(e, "edit")}
                            >
                              Save
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            color="rose"
                            onClick={(e) => this.handleSubmit(e, "add")}
                          >
                            Save
                          </Button>
                        )}

                        <Tooltip title="Cancel" placement="bottom">
                          <Button
                            color="secondary"
                            onClick={(e) => this.props.history.go(-1)}
                          >
                            Cancel
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AddLeave.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(AddLeave);
