import React, { Component } from "react";
// core components
import Wizard from "components/Wizard/Wizard";
import PayrollList from "components/salaryMaster/PayrollList";
import ConfigurePayroll from "components/salaryMaster/ConfigurePayroll";

class SalaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResourceList: [],
      ApproveAccess: false,
      IsSelectAll: false,
      LeaveBalance: 12,
      DailyStatusList: [],
      filterVal: "",
      Resoource: "",
      StartDate: "",
      EndDate: "",
      selectedDate: new Date(),
    };
  }

  render() {
    return (
      <Wizard
        steps={[
          {
            stepName: "Payroll List",
            stepComponent: PayrollList,
            stepId: "PayrollList",
          },
          {
            stepName: "Non-Configure Payroll",
            stepComponent: ConfigurePayroll,
            stepId: "ConfigurePayroll",
          },
        ]}
        title=""
        color="primary"
        nextButtonVisible={false}
        // subtitle="This information will let us know more about you."
        finishButtonClick={(e) => this.submit(e)}
      />
    );
  }
}
export default SalaryList;
