import clsx from "clsx";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import React, { PureComponent, useState } from "react";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import isWithinInterval from "date-fns/isWithinInterval";
import { createStyles } from "@material-ui/styles";
import { CSVLink } from "react-csv";


// core components
import ReactTable from "react-table";
import Icon from '@material-ui/core/Icon';
import { FormHelperText, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Datetime from "react-datetime";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, withStyles } from "@material-ui/core";
import cogoToast from 'cogo-toast';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import { endOfMonth } from "date-fns";


class Tasks extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isfilter: false,
            showStatus: false,
            showDate: false,
            createdFilter: false,
            dueDateFilter: false,
            updatedFilter: false,
            Week: [],
            filterData: [],
            TaskList: [],
            DailyStatusList: [],
            loggedUserID: 0,
            filterVal: "",
            selectedDate: new Date(),
            selectedStartDate: '',
            selectedEndDate: '',
            BlockersList: [],
            startofMonth: new Date(),
            csvData: [],
            IsSubmitted: null,
            Editvisible: false,
            FilterDate: moment(),
            filterDate: "",
            filterVal: "",
            filterType: "",
            filterData: [],
            filterString: '',
            viewBlockerList: [],
            notesList: [],
            viewOnly: false,
            exportData: [],
            ApproveAccess: false,
            weekFilter: "Active",
            monthFilter: "Inactive",
        };
        this.csvLink = React.createRef();
    }
    componentDidMount() {
        if (CommonConfig.getUserAccess("Report")) {
            this.setState({ ApproveAccess: CommonConfig.getUserAccess("Report").AllAccess === 1 ? true : false})
        }
        this.getDailyStatusList();
        if (this.props.history.location.state !== undefined && this.props.history.location.state !== null) {
            this.setState({
                loggedUserID: CommonConfig.loginData().PersonID,
                viewOnly: this.props.history.location.state.viewOnly

            })
            this.filterReportbyDate(this.props.history.location.state.startDate, this.props.history.location.state.endDate);
            this.handleWeekChange(new Date(this.props.history.location.state.startDate))
        }
        else {
            this.setState({
                loggedUserID: CommonConfig.loginData().PersonID,
                selectedStartDate: startOfWeek(new Date()),
                selectedEndDate: endOfWeek(new Date())
            });
        }
    }
    redirectView = (record) => {
        this.props.history.push({
            pathname: "/admin/viewTasks",
            state: { id: record.original.TaskID }
        })
    }
    applyFilter = (value) => {
        var filterTask = this.state.TaskList.filter(x => x.TaskStatus === value);
        this.setState({ isfilter: true, filterData: filterTask });
    }
    dateFilter = (type) => {
        if (type === "WEEK") {
            if (this.state.createdFilter) {
                var filterDate = [];
                var filterList = [];
                let startingDate = this.state.startCreated;
                let endingDate = this.state.endCreated;
                filterDate = this.state.TaskList.filter(x => moment(x.CreatedOn).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.CreatedOn).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                filterList = filterDate;
                this.setState({ isfilter: true, filterData: filterDate });
                if (this.state.updatedFilter) {
                    let startingDate = this.state.startUpdated;
                    let endingDate = this.state.endUpdated;
                    var filterDate1 = filterList.filter(x => moment(x.UpdatedOn).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.UpdatedOn).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                    filterList = filterDate1;
                    this.setState({ isfilter: true, filterData: filterDate1 });
                    if (this.state.dueDateFilter) {
                        let startingDate = this.state.startDue;
                        let endingDate = this.state.endDue;
                        var filterDate2 = filterList.filter(x => moment(x.DueDate).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.DueDate).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                        this.setState({ isfilter: true, filterData: filterDate2 });
                    }
                }
            }
            if (this.state.createdFilter === false && this.state.updatedFilter) {
                let startingDate = this.state.startUpdated;
                let endingDate = this.state.endUpdated;
                var filterDate = this.state.TaskList.filter(x => moment(x.UpdatedOn).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.UpdatedOn).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                this.setState({ isfilter: true, filterData: filterDate });
                if (this.state.dueDateFilter) {
                    let startingDate = this.state.startDue;
                    let endingDate = this.state.endDue;
                    var filterDate1 = this.state.filterData.filter(x => moment(x.DueDate).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.DueDate).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                    this.setState({ isfilter: true, filterData: filterDate1 });
                }
            }
            if (!this.state.createdFilter && !this.state.updatedFilter && this.state.dueDateFilter) {
                let startingDate = this.state.startDue;
                let endingDate = this.state.endDue;
                var filterDate = this.state.TaskList.filter(x => moment(x.DueDate).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.DueDate).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                this.setState({ isfilter: true, filterData: filterDate });
            }
            this.setState({ weekFilter: "Active", monthFilter: "Inactive" })
        }
        else {
            this.setState({ monthFilter: "Active", weekFilter: "Inactive" })
        }
    }
    filterReportbyDate = (startDate, endDate) => {
        let data;
        if (!(CommonConfig.isEmpty(startDate) && CommonConfig.isEmpty(endDate))) {
            data = {
                FromDate: moment(startDate).format(CommonConfig.dateFormat.dbDateTime),
                ToDate: moment(endDate).format(CommonConfig.dateFormat.dbDateTime),
                ResourceID: CommonConfig.isEmpty(this.state.filterVal) ? CommonConfig.loginData().PersonID : this.state.filterVal.value
            }
        }
        else {
            data = {
                FromDate: moment(this.state.selectedStartDate).format(CommonConfig.dateFormat.dbDateTime),
                ToDate: moment(this.state.selectedEndDate).format(CommonConfig.dateFormat.dbDateTime),
                ResourceID: CommonConfig.isEmpty(this.state.filterVal) ? CommonConfig.loginData().PersonID : this.state.filterVal.value
            }
        }
        try {
            api.post("dailystatus/getReportByDate", data).then(res => {
                this.setState({ TaskList: res.data, IsSubmitted: res.data.length > 0 ? res.data[0].SubmittedStatus !== "Draft" ? 'Yes' : 'No' : null });
                var csvValue = [];
                for (let i = 0; i < res.data.length; i++) {
                    
                    csvValue.push({
                        //ResourceName: res.data[i].ResourceName,
                        TaskID: res.data[i].TaskID,
                        DailyTaskID: res.data[i].DailyTaskID,
                        ProjectName: res.data[i].ProjectName,
                        TaskName: res.data[i].TaskName,
                        HrsToday: res.data[i].HrsToday,
                        TaskStatusDate: res.data[i].TaskStatusDate,
                        PercentageCompleted: res.data[i].PercentageCompleted,
                        Notes: res.data[i].Notes,
                        TotalHours: res.data[i].TotalHours,
                        Day: moment(res.data[i].TaskStatusDate).format("dddd")

                    }
                    )
                }

                this.setState({ csvData: csvValue })
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }
    onChange = (e, type) => {
        if (type === "Notes") {
            this.setState({ Notes: e.target.value, NotesErr: false, NotesHelperText: "" });
        }
        if (type === "HrsToday") {
            this.setState({ HrsToday: e.target.value });
        }
        if (type === "EstimetedEndDate") {
            this.setState({ EstimetedEndDate: e.target.value });
        }
    }
    handleCancel = () => {
        this.setState({ Editvisible: false });
    }
    // redirectSalary = () => {
    //     this.props.history.push("/admin/TasksReports");

    // }
    // redirectSalaryListing = () => {
    //     this.props.history.push("/admin/salaryListing");

    // }
  
    redirectstringMap = () => {
        this.props.history.push("/admin/addStringMap");

    }
    edit = (record) => {
        this.setState({ Editvisible: true });
        let data = {
            DailyTaskID: record.original.DailyTaskID
        };
        try {

            api.post("dailystatus/getDailyTaskByID", data).then(res => {
                if (res.success) {
                    if (res.data.blockersList != null && res.data.blockersList.length > 0) {
                        var i = 0;
                        res.data.blockersList.map(Obj => {
                            Obj.Index = i;
                            i++;
                            return Obj;
                        })
                        this.setState({ viewBlockerList: res.data.blockersList });
                    }

                    this.setState({
                        UpdatedOn: CommonConfig.isEmpty(res.data.UpdatedOn) ? moment() : res.data.UpdatedOn,
                        DueDate: res.data.DueDate,
                        DailyTaskID: res.data.DailyTaskID,
                        TaskID: res.data.TaskID,
                        ProjectKey: res.data.ProjectKey,
                        ProjectID: res.data.ProjectID,
                        Subject: res.data.Subject,
                        Notes: res.data.Notes,
                        ResourceID: res.data.ResourceID,
                        FirstName: res.data.FirstName,
                        LastName: res.data.LastName,
                        PercentageCompleted: CommonConfig.isEmpty(res.data.PercentageCompleted) ? 10 : res.data.PercentageCompleted,
                        HrsToday: res.data.HrsToday,
                        TotalHours: res.data.TotalHours,
                        TaskStatusDate: CommonConfig.isEmpty(res.data.TaskStatusDate) ? moment() : res.data.TaskStatusDate,
                        EstimetedStartDate: res.data.EstimetedStartDate,
                        EstimetedEndDate: res.data.EstimetedEndDate,
                        TaskStatus: res.data.TaskStatus,
                        StartDate: res.data.StartDate,
                        EndDate: res.data.EndDate,
                        ProjectName: res.data.ProjectName,
                    });
                } else {
                    cogoToast.error('Something went wrong');
                }

            }).catch(error => {
            })

        }
        catch (err) {
        }
    }
    validate = () => {
        const { Notes, PercentageCompleted, TaskStatusDate, HrsToday } = this.state;
        let IsValid = true;

        if (CommonConfig.isEmpty(Notes)) {
            IsValid = false;
            this.setState({ NotesHelperText: "Please enter Notes", NotesErr: true });
        }

        if (CommonConfig.isEmpty(PercentageCompleted)) {
            IsValid = false;
            this.setState({ PercentageCompletedHelperText: "Please enter Percentage Completed", PercentageCompletedErr: true });
        }
        if (CommonConfig.isEmpty(TaskStatusDate)) {
            IsValid = false;
            this.setState({ TaskStatusDateHelperText: "Please enter Task Status Date", TaskStatusDateErr: true });
        }
        return IsValid;
    }
    update = (e) => {

        const { HrsToday, TotalHours, TaskID, TaskStatus, TaskStatusDate, notesList, PercentageCompleted, Notes, ProjectID, ResourceID, BlockerID } = this.state;
        if (this.validate()) {
            let data = {
                UserID: CommonConfig.loginData().PersonID,
                ProjectID: ProjectID,
                TaskID: TaskID,
                ResourceID: ResourceID,
                PercentageCompleted: CommonConfig.isEmpty(PercentageCompleted) ? 10 : PercentageCompleted,
                TaskStatus: TaskStatus,
                HrsToday: HrsToday,
                TotalHours: TotalHours,
                Notes: Notes,
                TaskStatusDate: CommonConfig.isEmpty(TaskStatusDate) !== true ? moment(TaskStatusDate).format("YYYY-MM-DD").toString() : moment().format("YYYY-MM-DD").toString(),
                DailyTaskID: CommonConfig.isEmpty(this.state.DailyTaskID) == true ? null : this.state.DailyTaskID,
                notesList: notesList,
                viewBlockerList: this.state.viewBlockerList
            }
            try {

                api.post("dailystatus/addUpdateDailyStatus", data).then(res => {
                    if (res.success) {
                        cogoToast.success("Status updated successfully");
                        this.setState({ Editvisible: false });
                        window.location.reload();
                    }
                    else {
                        cogoToast.error("Something went wrong");
                    }
                }).catch(error => {
                });
            }
            catch (err) {
                console.log("error", err);
            }
        }
        else {
            cogoToast.error("Please correct error and resubmit the form");
        }
    }
    blockerslist = () => {
        return this.state.BlockersList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }
    viewBlockers = () => {
        return this.state.viewBlockerList.filter(x => x.Status === 'Active').map(content => {
            return (
                <tr>
                    <td style={{ maxWidth: 600, margin: 0, height: 68, width: 600 }}>
                        <div>
                            {content.BlockerName}
                        </div>
                    </td>
                    <td>
                        <DeleteIcon onClick={() => this.removeBlocker(content.BlockerName)} />
                    </td>
                </tr>
            )
        }
        )
    }
    selectChange = (e, type) => {
        if (type === "Blockers") {
            this.setState({ StringMapName: e.target.value });
        }
        if (type === "PercentageCompleted") {
            if (Number(e.target.value) >= 1 && Number(e.target.value) <= 99) {
                this.setState({ TaskStatus: "IN PROGRESS" });
                this.notes(this.state.TaskStatus, "IN PROGRESS", "Task", "Status");
            }
            else if (Number(e.target.value) === 100) {
                this.setState({ TaskStatus: "RESOLVED" });
                this.notes(this.state.TaskStatus, "RESOLVED", "Task", "Status");
            }
            else {
                this.setState({ TaskStatus: "OPEN" });
            }
            this.setState({ PercentageCompleted: e.target.value, PercentageCompletedErr: false, PercentageCompletedHelperText: "" });
        }
    }
    notes = (prevData, nextData, Type, Title) => {
        let data = {
            CreatedBy: CommonConfig.loginData().PersonID,
            NoteText: prevData + " => " + nextData,
            Index: this.state.notesList.length + 1,
            EntityID: this.state.TaskID,
            EntityType: Type,
            NoteTitle: Title,
            NoteID: null,
            Status: 'Active'
        }
        this.setState({ notesList: [...this.state.notesList, data] });

    }

    handleFilterDate = (date) => {
        this.setState({ FilterDate: date });
        if (this.state.filterType === "TODAY") {
            var filterDate = this.state.TaskList.filter(x => moment(x.TaskStatusDate).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY'));
            this.setState({ isfilter: true, filterData: filterDate });
        }
    }
    removeBlocker = (idx) => {
        const removeBlocker = [...this.state.viewBlockerList]
        var Index = this.state.viewBlockerList.findIndex(x => x.BlockerName === idx);
        if (Index !== -1) {
            removeBlocker[Index]["Status"] = "Inactive";
            this.setState({ viewBlockerList: removeBlocker })
        }
    }
    saveTimesheet = (e) => {
        e.preventDefault();
        let data = {
            FromDate: moment(this.state.selectedStartDate).format(CommonConfig.dateFormat.dbDateTime),
            ToDate: moment(this.state.selectedEndDate).format(CommonConfig.dateFormat.dbDateTime),
            ResourceID: CommonConfig.isEmpty(this.state.filterVal) ? CommonConfig.loginData().PersonID : this.state.filterVal.value,
            Status: 'Pending'
        }

        try {
            api.post("dailystatus/updateTimeSheetStatus", data).then(res => {
                if (res.success) {
                    cogoToast.success('Timesheet saved successfully')
                    this.props.history.push("/admin/timeSheetList");
                }
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    resetFilter = () => {
        this.setState({ isfilter: false, filterData: "", showDate: false, showStatus: false, createdFilter: false, dueDateFilter: false, updatedFilter: false });
    }
    redirectAdd = () => {
        this.props.history.push("/admin/addTasks");
    }
    redirectMonth = () => {
        this.setState({ showMonth: true, showWeek: false, showDay: false, showStatus: false })
    }
    redirectWeek = () => {
        this.setState({ 6: true, showStatus: false, showMonth: false, showDay: false })
    }
    redirectDay = () => {
        this.setState({ showDay: true, showStatus: false, showMonth: false, showWeek: false })
    }
    redirectStatus = () => {
        this.setState({ showStatus: true, showDate: false })
    }

    getDailyStatusList = () => {
        try {
            api.get("dailystatus/getDailyStatusList").then(res => {
                if (res.success) {
                    let obj = {
                        FirstName: "Select All",
                        LastName: "",
                        ResourceID: ""
                    }
                    res.data.push(obj);
                    res.data.map((r, i) => {
                        res.data[i].FullName = `${r.FirstName} ${r.LastName}`
                    });
                    this.setState({
                        DailyStatusList: res.data,
                        filterVal: {
                            value: CommonConfig.loginData().PersonID, label: CommonConfig.loginData().FirstName + ' ' + CommonConfig.loginData().LastName
                        }
                    });
                }
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }
    requestChange = (e, value, type) => {
        if (type === "DailyStatus") {
            this.setState({ DailyStatus: value, DailyStatusErr: false, DailyStatusHelperText: "" });
        }
    }
    handleResoureOnChange = (value) => {
        if (value !== null) {
            this.setState({ filterVal: value })
        }
    }

    handleWeekChange = date => {

        this.setState({ selectedDate: startOfWeek(date), selectedStartDate: startOfWeek(date), selectedEndDate: endOfWeek(date) });
    };
    handleMonthChange = date => {
        this.setState({ selectedDate: startOfMonth(date), selectedStartDate: startOfMonth(date), selectedEndDate: endOfMonth(date) });
    };
    formatWeekSelectLabel = (date, invalidLabel) => {
        let dateClone = date;
        return dateClone && isValid(dateClone)
            ? `Week of ${format(startOfWeek(dateClone), "MMM do")} - ${format(endOfWeek(dateClone), "do")}`
            : invalidLabel;
    };
    searchReportlen = (len) => {
        var sum = 0;
        for (let i = 0; i < len.length; i++) {
            sum = sum + len[i].HrsToday
        }
        this.setState({ searchfinalLength: sum });
    }
    searchCheckProps = (e) => {
        if (this.state.searchfinalLength !== e.sortedData.length) {
            this.searchReportlen(e.sortedData);
        }
        return '';
    }
    renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {

        const { classes } = this.props;
        let dateClone = date;
        let selectedDateClone = selectedDate;
        const start = startOfWeek(selectedDateClone);
        const end = endOfWeek(selectedDateClone);
        const dayIsBetween = isWithinInterval(dateClone, { start, end });
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);
        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        }); 
        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, "d")} </span>
                </IconButton>
            </div>
        );
    };

    downloadReport = () => {
        let data = {
            FromDate: moment(this.state.selectedStartDate).format(CommonConfig.dateFormat.dbDateTime),
            ToDate: moment(this.state.selectedEndDate).format(CommonConfig.dateFormat.dbDateTime),
            ResourceID: CommonConfig.isEmpty(this.state.filterVal) ? CommonConfig.loginData().PersonID : this.state.filterVal.value
        }
        try {
            api.post("dailystatus/downloadReport", data).then(res => {
                if (res.success) {
                    for (var i = 0; i < res.data[0].length; i++) {
                        delete res.data[0][i].ID;
                        delete res.data[0][i].ProjectID;
                        delete res.data[0][i].TaskID;
                    }
                    this.setState({ exportData: res.data[0] });
                    this.csvLink.current.link.click();
                }
            })

        }
        catch (err) {
            console.log("err", err);
        }
    }

    render() {
        const { TaskList, FirstName, LastName, TotalHours, StringMapName, NotesHelperText, NotesErr, weekFilter, monthFilter, ProjectKey,
            Notes, Editvisible, ProjectName, Subject, StartDate, DueDate, UpdatedOn, HrsToday,
            PercentageCompleted, PercentageCompletedErr, PercentageCompletedHelperText, TasksErr, TasksHelperText, selectedStartDate } = this.state;
        const DailyStatus = this.state.DailyStatusList.map(DailyStatus => {
            return { value: DailyStatus.ResourceID, label: DailyStatus.FullName }
        })
        const { Tasks, filterString } = this.state;
        const { selectedDate } = this.state;
        const filteredData = (!filterString) ? Tasks : TasksErr.filter(d => d.FullName == filterString);
        const tableColumns = [
            {
                Header: 'Project Task',
                accessor: 'ProjectName',
                Cell: record => {
                    return (
                        <Tooltip title={record.original.ProjectName} arrow>
                            <span>{record.original.ProjectName}</span>
                        </Tooltip>

                    )
                },
                id: 'ProjectName',
                sortable: false,
                className: "nowrap",
                width: 150
            },
            {
                Header: 'Task Project',
                accessor: 'TaskName',
                Cell: record => {
                    return (
                        <Tooltip title={record.original.TaskName} arrow>
                            <span>{record.original.TaskName}</span>
                        </Tooltip>

                    )
                },
                id: 'TaskName',
                className: "nowrap",
                sortable: false,
                width: 150
            },
            {
                Header: 'Day Hrs',
                accessor: 'HrsToday',
                id: 'HrsToday',
                sortable: false,
                width: 120
            },
            {
                Header: '%Completed',
                accessor: 'PercentageCompleted',
                id: 'PercentageCompleted',
                sortable: false,
            },
            {
                Header: 'Total Hours',
                accessor: 'TotalHours',
                id: 'TotalHours',
                sortable: false,
            },
            {
                Header: 'Status Date',
                accessor: data => {
                    if (CommonConfig.isEmpty(data.TaskStatusDate)) {
                        return null;
                    }
                    else {
                        return moment(data.TaskStatusDate).format(CommonConfig.dateFormat.dateFirst);
                    }
                },
                id: 'TaskStatusDate',
                sortable: false,
            },
            {
                Header: 'Action',
                className: "text-align-right",
                Cell: record => {
                    return (
                        !this.state.viewOnly && record.original.SubmittedStatus !== "Submitted" && record.original.SubmittedStatus !== "Approved" ?
                            <span>
                                <Tooltip title="Edit" placement="bottom">
                                    <Button className="btn-icon" justIcon color="primary" onClick={() => this.edit(record)}><CreateIcon /></Button>
                                </Tooltip>
                            </span>
                            :
                            null
                    )
                },
                filterable: false,
                id: 'action',
                sortable: false,

            },
        ]
        return (
            <div>

                <GridContainer justify="left">
                    <GridItem xs={12} sm={3} md={6}>
                        <Tooltip title="Date" placement="bottom">
                            <Button variant="contained" color="primary" className={weekFilter + " " + "margin-right-10"} onClick={() => this.dateFilter("WEEK")} >WEEk</Button>
                        </Tooltip>
                        <Tooltip title="Date" placement="bottom">
                            <Button variant="contained" color="rose" className={monthFilter} onClick={() => this.dateFilter("MONTH")}>MONTH</Button>
                        </Tooltip>
                    </GridItem>
                    <GridItem justify="end" xs={12} sm={3} md={6}>

                            <GridItem xs={12} sm={4} md={4} className="float-right date-filter">
                                <div className="submitted-on">
                                    <span>{"Total hours : " + parseFloat(this.state.searchfinalLength).toFixed(2)}</span>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} className="float-right date-filter">
                                <Button onClick={() => this.downloadReport()} className="margin-right-10" color="rose">
                                    Download Excel
                            </Button>
                            </GridItem>
                            {this.state.IsSubmitted === 'No' ?
                                <GridItem xs={12} sm={4} md={4} className="float-right date-filter">
                                    <Button color="primary" variant="contained" onClick={(e) => this.saveTimesheet(e)}>Submit</Button>
                                </GridItem >
                                : this.state.IsSubmitted == 'Yes' ? <GridItem xs={12} sm={4} md={4} className="float-right date-filter">
                                    <div className="submitted-on ">
                                        <span>Submitted</span>
                                    </div>
                                </GridItem> : null}

                    </GridItem>
                </GridContainer>
                <GridContainer>
                    {this.state.ApproveAccess === true ?
                        <GridItem xs={12} sm={3} md={3} className>
                            <div className="search-resource">
                                <div className="with-icon">
                                    <Autocomplete
                                        options={DailyStatus}
                                        id="DailyStatus"
                                        value={this.state.filterVal}
                                        getOptionLabel={option => option.label}
                                        onChange={(event, value) => this.handleResoureOnChange(value)}
                                        renderInput={params => (
                                            <TextField {...params} error={TasksErr} helperText={TasksHelperText}
                                                label="Search Resources Name" margin="normal" />)}
                                    />
                                </div>
                            </div>
                        </GridItem> : <GridItem xs={12} sm={3} md={3}>
                            <div className="search-resource">
                                <div className="with-icon">
                                    <Autocomplete
                                        disabled
                                        options={DailyStatus}
                                        id="DailyStatus"
                                        value={this.state.filterVal}
                                        getOptionLabel={option => option.label}
                                        onChange={(event, value) => this.handleResoureOnChange(value)}
                                        renderInput={params => (
                                            <TextField {...params} error={TasksErr} helperText={TasksHelperText}
                                                label="Search Resources Name" margin="normal" />)}
                                    />
                                </div>
                            </div>
                        </GridItem>}
                    {weekFilter === "Active" ?
                        <GridItem xs={12} sm={3} md={3}>
                            <div className="search-resource margin-top-16">
                                <div className="with-icon">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk={true}
                                            variant="inline"
                                            label="Select Week"
                                            value={selectedDate}
                                            onChange={this.handleWeekChange}
                                            renderDay={this.renderWrappedWeekDay}
                                            labelFunc={this.formatWeekSelectLabel}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <Tooltip title="Search" placement="top">
                                        <Button onClick={() => this.filterReportbyDate()} justIcon color="primary"><SearchIcon /></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </GridItem> : null}
                        {monthFilter === "Active" ?
                        (<GridItem xs={12} sm={3} md={3} >
                            <div className="search-resource margin-top-16">
                                <div className="with-icon">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk={true}
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Select Year and Month"
                                            value={selectedDate}
                                            onChange={(e) => this.handleMonthChange(e)}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <Tooltip title="Search" placement="top">
                                        <Button onClick={() => this.filterReportbyDate()} justIcon color="primary"><SearchIcon /></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </GridItem>
                        ) : null}


                </GridContainer>

                <Dialog
                    open={Editvisible}
                    title="Credentials"
                    onClose={() => this.handleCancel()}
                >
                    <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                label="Resource Name"
                                value={FirstName + " " + LastName}
                                disabled
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Project Name"
                                value={ProjectName}
                                disabled
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Task"
                                value={ProjectKey + "-" + Subject}
                                disabled
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                value={HrsToday}
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                label="Hours Today"
                                onChange={(e) => this.onChange(e, "HrsToday")}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField disabled
                                value={TotalHours}
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                label="Total Hours"
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Task Status Date"
                                disabled
                                value={moment(this.state.TaskStatusDate).format("DD/MM/YYYY")}
                                placeholder="Task Status Date"
                            />
                        </FormControl>
                        <FormControl fullWidth fullWidth error={PercentageCompletedErr}>
                            <InputLabel>% Completed</InputLabel>
                            <Select onChange={(e) => this.selectChange(e, "PercentageCompleted")} value={PercentageCompleted} >
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="20">20</MenuItem>
                                <MenuItem value="30">30</MenuItem>
                                <MenuItem value="40">40</MenuItem>
                                <MenuItem value="50">50</MenuItem>
                                <MenuItem value="60">60</MenuItem>
                                <MenuItem value="70">70</MenuItem>
                                <MenuItem value="80">80</MenuItem>
                                <MenuItem value="90">90</MenuItem>
                                <MenuItem value="100">100</MenuItem>
                            </Select>
                            <FormHelperText>{PercentageCompletedHelperText}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth error={NotesErr}>
                            <TextareaAutosize
                                value={Notes}
                                placeholder="Notes"
                                rowsMin={5}
                                cols={90}
                                label="Notes"
                                onChange={(e) => this.onChange(e, "Notes")}
                            />
                            <FormHelperText>{NotesHelperText}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Status for"
                                value={moment(UpdatedOn).format("DD/MM/YYYY")}
                                disabled
                            />
                        </FormControl>
                        <FormControl fullWidth fullWidth>
                            <TextField disabled
                                label="Start Date"
                                value={moment(StartDate).format("DD/MM/YYYY")}
                            />
                        </FormControl>
                        <FormControl fullWidth fullWidth>
                            <TextField disabled
                                label="End Date"
                                value={moment(DueDate).format("DD/MM/YYYY")}
                            />
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel>Blockers</InputLabel>
                            <Select onChange={(e) => this.selectChange(e, "Blockers")} value={StringMapName} >
                                {this.blockerslist()}
                            </Select>
                            <Tooltip title="Add Blocker" placement="bottom">
                                <Button type="submit" color="primary" onClick={(e) => this.addBlocker(e)}>Add</Button>
                            </Tooltip>
                            {this.viewBlockers()}
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Tooltip title="Save" placement="bottom">
                            <Button type="submit" color="primary" onClick={(e) => this.update(e)}>Save</Button>
                        </Tooltip>
                        <Tooltip title="Back" placement="bottom">
                            <Button key="back" onClick={() => this.handleCancel()}>Back</Button>
                        </Tooltip>
                    </DialogActions>
                </Dialog>
                {/* <Tooltip title="redirectSalary" placement="bottom">
                    <Button className="btn-icon" justIcon color="primary" onClick={() => this.redirectSalary()}></Button>
                </Tooltip>
                <Tooltip title="redirectSalaryListing" placement="bottom">
                    <Button className="btn-icon" justIcon color="primary" onClick={() => this.redirectSalaryListing()}></Button>
                </Tooltip>
                <Tooltip title="redirectStringMap" placement="bottom">
                    <Button className="btn-icon" justIcon color="primary" onClick={() => this.redirectstringMap()}></Button>
                </Tooltip> */}
               {/*  <Tooltip title="redirectPayRoll" placement="bottom">
                    <Button className="btn-icon" justIcon color="primary" onClick={() => this.redirectPayroll()}></Button>
                </Tooltip>
                <Tooltip title="redirectStringMap" placement="bottom">
                    <Button className="btn-icon" justIcon color="primary" onClick={() => this.redirectStringMap()}></Button>
                </Tooltip>
                <Tooltip title="redirectStringMap" placement="bottom">
                    <Button className="btn-icon" justIcon color="primary" onClick={() => this.redirectcognisunQuickView()}></Button>
                </Tooltip> */}
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <ReactTable
                                    data={this.state.isfilter ? this.state.filterData : TaskList}
                                    resizable={false}
                                    columns={tableColumns}
                                    defaultPageSize={5}
                                    style={{ cursor: "pointer" }}
                                    className="-striped -highlight"
                                    getPaginationProps={(record) => this.searchCheckProps(record)}
                                // pageText={"Total hours : " + parseFloat(this.state.searchfinalLength).toFixed(2)}
                                />
                                <CSVLink
                                    data={this.state.exportData}
                                    asyncOnClick={true}
                                    filename={this.state.filterVal.label + "_" + new Date(this.state.selectedStartDate).toLocaleString('default', { month: 'long' }) + "_" + new Date(this.state.selectedStartDate).getFullYear() + '.xls'}
                                    ref={this.csvLink}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const styles = createStyles(theme => ({
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: "#676767",
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
}));

export default withStyles(styles)(Tasks);