import React, { Component } from 'react';
import cogoToast from 'cogo-toast';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import ReactTable from "react-table";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from '@material-ui/core/Tooltip';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel, FormLabel } from '@material-ui/core';
import moment from 'moment';


class DailyStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DailyStatusList: [],
            loggedUserID: 0,
            filterString: '',
            filterVal:'',
        };
    }

    componentDidMount() {
        this.getDailyStatusList();
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
    }

    getDailyStatusList = () => {
        try {
            api.get("dailystatus/getDailyStatusList").then(res => {
                res.data.map((r, i) => {
                    res.data[i].FullName = `${r.FirstName} ${r.LastName}`
                });
                this.setState({ DailyStatusList: res.data });
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    redirectView = (record) => {
        this.props.history.push({
            pathname: "/admin/viewDailyTasks",
            state: {
                id: record.original.ResourceID,
                ResourceName: record.original.FirstName + " " + record.original.LastName,
            }
        })

    }
    requestChange = (e,value,type) => {
        if(type === "DailyStatus"){
          this.setState({DailyStatus:value , DailyStatusErr:false , DailyStatusHelperText:""});
        }
      }
    onChange = (value) => {
        this.setState({filterVal:value})

    }
    onClick = (value) => {
        this.setState({filterString: this.state.filterVal})
    }

    render() {

        const DailyStatus = this.state.DailyStatusList.map(DailyStatus => DailyStatus.FullName)
        const uniqueList = DailyStatus.filter((item, i, ar) => ar.indexOf(item) === i);
        const { DailyStatusList,DailyStatusErr,DailyStatusHelperText, filterString } = this.state;

        const filteredData = (!filterString) ? DailyStatusList : DailyStatusList.filter(d => d.FullName == filterString);
        const tableColumns = [
            {
                Header: 'Pic',
                accessor: 'Pic',
                id: 'Pic',
                sortable: false,
            },
            {
                Header: 'Resource',
                // accessor: 'TaskID',
                id: 'ResourceID',
                Cell: record => {
                    return (
                        <span>
                            <button onClick={() => this.redirectView(record)}>{record.original.FirstName + " " + record.original.LastName}</button>
                        </span>
                    )
                },
                sortable: false,
            },
            {
                Header: 'Designation',
                accessor: 'Designation',
                id: 'Designation',
                sortable: false,
            },
            {
                Header: 'Department',
                accessor: 'Department',
                id: 'Department',
                sortable: false,
            },
            {
                Header: 'Last Submission',
                // Cell: record => {
                //     console.log("record",record)
                //     return record.original.taskMasterList.map((content,idx) => {
                //       return (
                //         record.original.taskMasterList.length === idx + 1 ?
                //         <span>
                //           {content.TechStack}
                //         </span>
                //         :
                //         <span>
                //         {content.TechStack + " , "}
                //         </span>
                //       )
                //     })
                //   },
                accessor: data => {
                    return data.taskMasterList.map((content, idx) => {
                        if (data.taskMasterList.length === idx + 1) {
                            if (CommonConfig.isEmpty(content.UpdatedOn)) {
                                return null;
                            }
                            else {
                                return moment(content.UpdatedOn).format(CommonConfig.dateFormat.dateFirst);
                            }
                        }
                        else {
                            return null
                        }
                    })
                },
                id: 'DueDate',
                sortable: false,
            },
            {
                Header: 'Task Status',
                Cell: record => {
                    var i = 0;
                    var j = 0;
                    return record.original.taskMasterList.map((content, idx) => {
                            let duedate = new Date(content.DueDate);
                            let currentDate = new Date();
                            currentDate.setHours(23,59,59,0);
                            if(record.original.taskMasterList.length === idx + 1){
                                if(duedate.getTime() < currentDate.getTime()){
                                    j++;
                                }
                                if(duedate.getTime() === currentDate.getTime()){
                                    i++;
                                }
                                return "D - " + i + " O - " + j
                            }
                            else{
                                if(content.TaskStatus !== "CLOSED" && content.TaskStatus !== "RESOLVED"){

                                    if(duedate.getTime() < currentDate.getTime()){
                                        j++;
                                    }
                                    if(duedate.getTime() === currentDate.getTime()){
                                        i++;
                                    }
                                }
                            }
                    })
                },
                //accessor: 'TaskStatus',
                id: 'TaskStatus',
                sortable: false,
            },
        ]

        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={5} sm={5} md={5}>
                    <Autocomplete
                    options ={uniqueList}
                    id="DailyStatus"
                    value = {this.state.filterVal}
                    getOptionLabel={option => option}
                    onChange={(event,value)=>this.onChange(value)}
                    renderInput={params => (
                    <TextField {...params} error={DailyStatusErr} helperText={DailyStatusHelperText}
                    label="Search Resources Name" margin="normal" />)}
                    />
                </GridItem>
                <Tooltip title="Search" placement="bottom">
                <Button

                    color="primary"
                    onClick={() => this.onClick(this.state.filterVal)}
                >
                 Search Resource
                    </Button>
              </Tooltip>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <ReactTable
                                data={filteredData}
                                minRows={5}
                                resizable={false}
                                columns={tableColumns}
                                defaultPageSize={10}
                                className="-striped -highlight"
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}
export default DailyStatus;