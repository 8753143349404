import React, { Component } from 'react';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import Tooltip from '@material-ui/core/Tooltip';
import cogoToast from 'cogo-toast';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageUploader from 'components/CustomUpload/PictureUpload';
import PropTypes from "prop-types";
// @material-ui/icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Other extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

        JoiningDate:'',
        JoiningDateErr:false,
        JoiningDateHelpertext:'',

        AppraisalDate:'',
        AppraisalDateErr:false,
        AppraisalDateHelpertext:'',
  
        YearlyLeave:'',
        YearlyLeaveErr:false,
        YearlyLeaveHelperText:'',
  
        Email: "",
        EmailErr: false,
        EmailHelperText: "",
  
        ComputerName:'',
        ComputerNameErr:false,
        ComputerNameHelperText:'',
  
        IPAddress:'',
        IPAddressErr:false,
        IPAddressHelperText:'',
  
        CubiclePosition:'',
        cubiclepositionErr:false,
        cubiclepositionHelperText:'',
  
        Salt:'',
        SaltErr:false,
        SaltHelperText:'',

        ConfirmSalt:'',
        ConfirmSaltErr:false,
        ConfirmSaltHelperText:'',
  
        BankName:'',
        BankNameErr:false,
        BankNameHelperText:'',
  
        AccountNo:'',
        AccountNoErr:false,
        AccountNoHelperText:'',
  
        IFSCCode:'',
        IFSCCodeErr:false,
        IFSCCodeHelperText:'',
  
        password: "",
        passwordErr: false,
        passwordHelperText: "",

        ProfessionalTax:"",
        ProfessionalTaxErr:false,
        ProfessionalTaxHelperText:"",
  
        ConfirmPassword: "",
        ConfirmpasswordErr: false,
        ConfirmpasswordHelperText: "",

        Officelocation: '',
        officelocationHelpertext:'',
        officelocationErr: false,

        GenderList: [],
        DepartmentList: [],
        DesignationList: [],
        SuffixList: [],
        AddressList: [],
        TiltleList: [],      
        DOB: "",
        Department: "",
        Country: "",
        DepartmentList: [],
        DesignationList: [],
        CountryList: [],
        pictures: [],
        cubiclepositionList:[],
        OfficeLocationList:[],
        viewBankList: [],
    };
  }

  componentDidMount() {
    this.getEditData();
    this.getOfficeLocationList();
  }

  getEditData() {
    let data = {
      ResourceID: this.props.editprops.editid
    };

    if(data.ResourceID){
      try {
        api.post("resource/getResourceByID", data).then(res => {
          if (res.success) {
            
            if (res.data.BankDetail != null && res.data.BankDetail.length > 0) {
              var i = 0;
              res.data.BankDetail.map(Obj => {
                Obj.Index = i;
                i++;
                return Obj;
              })
              this.setState({ viewBankList: res.data.BankDetail });
            }
  
            this.setState({
  
              FirstName: res.data.FirstName ? res.data.FirstName : '',
              MiddleName: res.data.MiddleName ? res.data.MiddleName : '',
              LastName: res.data.LastName ? res.data.LastName : '',
              Title: res.data.Title ? res.data.Title : '',
              Suffix: res.data.Suffix ? res.data.Suffix : '',
              Designation: res.data.Designation ? res.data.Designation : '',
              AddressType: res.data.AddressType ? res.data.AddressType : '',
              ResourceCode: res.data.ResourceCode ? res.data.ResourceCode : '',
              Line1: res.data.Line1 ? res.data.Line1 : '',
              Line2: res.data.Line2 ? res.data.Line2 : '',
              Line3: res.data.Line3 ? res.data.Line3 : '',
              PAN: res.data.PAN ? res.data.PAN : '',
              Passport: res.data.Passport ? res.data.Passport : '',
              ContactNumber: res.data.ContactNumber ? res.data.ContactNumber : '',
              AdharNumber: res.data.AadharNumber ? res.data.AadharNumber : '',
              Gender: res.data.Gender ? res.data.Gender : '',
              DOB: res.data.DOB? moment(res.data.DOB).format("DD/MM/YYYY"): '',
              Email: res.data.Email ? res.data.Email : '',
              CountryID: res.data.CountryID ? res.data.CountryID: '',
              Password: res.data.Password ? res.data.Password : '',
              Department: res.data.Department ? res.data.Department : '',
              ResourceID: res.data.ResourceID ? res.data.ResourceID : '',
              JoiningDate: res.data.JoiningDate ? moment(res.data.JoiningDate).format("DD/MM/YYYY") : '',
              AppraisalDate:res.data.AppraisalDate ? moment(res.data.AppraisalDate).format("DD/MM/YYYY") : '',
              BasicSalary: res.data.BasicSalary ? res.data.BasicSalary : '',
              ProfessionalTax: res.data.ProfessionalTax ? res.data.ProfessionalTax : '',
              YearlyLeave: res.data.LeaveBalance ? res.data.LeaveBalance : '',
              ComputerName: res.data.ComputerName ? res.data.ComputerName : '',
              Salt: res.data.Salt ? res.data.Salt : '',
              CubiclePosition: res.data.CubiclePosition ? res.data.CubiclePosition : '',
              IPAddress: res.data.IPAddress ? res.data.IPAddress : '',
            });
            if(res.data.CountryID){
              try {
                api.get("/country/getCountry").then(country => {
                  if (country.success) {
                    this.setState({ CountryList: country.data,Country:{
                      value:country.data.filter(x=>x.CountryID == res.data.CountryID)[0].CountryID,
                      label : country.data.filter(x=>x.CountryID == res.data.CountryID)[0].CountryName
                    } });
                  }
                  else {
                    cogoToast.error("Something went wrong");
                  }
                }).catch(err => {
                  console.log(err)
                })
              } catch (err) {
              }
            }
          } else {
            cogoToast.error('Something went wrong')
          }
  
        }).catch(error => {
        })
  
      }
      catch (err) {
      }
    }
    else{
      
    }
}


  handleDate = (date, type) => {
    if (type === "DOB") {
      this.setState({ 
        DOB: date,
        DateofBirthErr:false,
        DateofBirthHelpertext:"",
      
      });
    }
    if (type === "JoiningDate") {
      this.setState({ 
        JoiningDate: date,
        JoiningDateErr:false,
        JoiningDateHelpertext:"",
        AppraisalDate: date,
        AppraisalDateErr:false,
        AppraisalDateHelpertext:"",
      });
    }
    if (type === "AppraisalDate") {
      this.setState({ 
        AppraisalDate: date,
        AppraisalDateErr:false,
        AppraisalDateHelpertext:"",
      
      });
    }
  }
  validate = () => {
    const {JoiningDate,AppraisalDate,YearlyLeave,Salt,IPAddress,ConfirmSalt} = this.state;

    let IsValid = true;

    if (CommonConfig.isEmpty(JoiningDate)) {
      // alert(2)
      IsValid = false;
      this.setState({
        JoiningDateHelpertext: "Please select Joining Date",
        JoiningDateErr: true,
      });
    }

     if (CommonConfig.isEmpty(AppraisalDate)) {
      // alert(2)
      IsValid = false;
      this.setState({
        AppraisalDateHelpertext: "Please select AppraisalDate",
        AppraisalDateErr: true,
      });
    } else if (new Date(AppraisalDate) < new Date(JoiningDate)){
      IsValid = false;
      this.setState({
        AppraisalDateHelpertext: "Please select valid AppraisalDate",
        AppraisalDateErr: true,
      })
    }

     if (CommonConfig.isEmpty(YearlyLeave)) {
      // alert(2)
      IsValid = false;
      this.setState({
        YearlyLeaveHelpertext: "Please enter Yearly Leave",
        YearlyLeaveErr: true,
      });
    }  
    // if (CommonConfig.isEmpty(IPAddress)) {
    //   IsValid = false;
    //   this.setState({
    //     IPAddressHelperText: "Please enter Salt",
    //     IPAddressErr: true,
    //   });
    // }    
    if(this.props.editprops.editid == undefined){
      if (CommonConfig.isEmpty(Salt)) {
        IsValid = false
        this.setState({ SaltErr: true, 
        SaltHelperText: "Please enter Salt",
        SaltErr: true, 

      });
      }
      
      // if (CommonConfig.isEmpty(ConfirmSalt)) {
      //   IsValid = false
      //   this.setState({ 
      //     confirmSaltHelperText: "Please enter Confirm Salt",
      //     confirmSaltErr: true,
      //   });
      // } else {
      //   if (ConfirmSalt !== Salt) {
      //     IsValid = false
      //     this.setState({ 
      //       confirmSaltErr: true, 
      //       confirmSaltHelperText: "Salt and confirm Salt do not match" });
      //   }
      // }
    }
    
    return IsValid;
  };
  submit = (e) => {
    const {FirstName, MiddleName,LastName,Designation,AddressType,ResourceCode,AdharNumber,PAN,Passport,Gender,Line1,Line2,Line3,DOB,
      Email,Password,Department,Country,IPAddress,JoiningDate,AppraisalDate,Salt,Title,Suffix,ComputerName,CubiclePosition,YearlyLeave} = this.state;
    
    if (this.validate()) {
      let data = {
        UserID: '',
        FirstName: FirstName,
        MiddleName: MiddleName,
        LastName: LastName,
        Title : Title,
        Suffix: Suffix,
        Designation: Designation,
        AddressType: AddressType,
        ResourceCode: ResourceCode,
        Line1: Line1,
        Line2: Line2,
        Line3: Line3,
        PAN: PAN,
        Passport: Passport,
        AdharNumber: AdharNumber,
        Gender: Gender,
        DOB:
          CommonConfig.isEmpty(DOB) != true
            ? moment(DOB).format("YYYY-MM-DD HH:mm:ss").toString()
            : null,
        Email: Email,
        Password: Password,
        Department: Department,
        CountryID: Country.value,
        ProfessionalTax : this.state.ProfessionalTax,
        BasicSalary:this.state.BasicSalary,
        ResourceID: 0,
        JoiningDate: moment(JoiningDate).format("YYYY-MM-DD HH:mm:ss").toString(),
        AppraisalDate: moment(AppraisalDate).format("YYYY-MM-DD HH:mm:ss").toString(),
        YearlyLeave: YearlyLeave,
        ComputerName: ComputerName,
        Salt: Salt,
        CubiclePosition: CubiclePosition,
        IPAddress: IPAddress,
        BankDetail: this.state.viewBankList.filter(x => x.Status === 'Active')
      };
      try {
        api
          .post("resource/addResourceBasic", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success("Resource added successfully");
              this.props.history.push("/admin/resourceListing");
            } else {
              cogoToast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (err) {
        console.log("error", err);
      }
    }

  };

  addCancel = () => {
    this.props.history.push("/admin/resourceListing");
  };


  onChange = (e, type) => {
    if (type === "Email") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Email: e.target.value,
          EmailErr: true,
          EmailHelperText: "Please enter Email",
        });
      } else {

        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
        const result = pattern.test(e.target.value);
        if (result === true) {
          this.setState({
            Email: e.target.value,
            EmailErr: false,
            EmailHelperText: "",
          });
        } else {
          this.setState({
            Email: e.target.value,
            EmailErr: true,
            EmailHelperext: "Please enter valid Email",
          });
        }
      }  
    }
    if (type === "Password") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Password: e.target.value,
          passwordErr: true,
          PassportHelpertext: "Please Enter Password",
        });
      } else {
        this.setState({
          Password: e.target.value,
          passwordErr: false,
          passwordHelperText: "",
        });
      }
    }
    if(type === "ConfirmPassword"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({
          ConfirmPassword: e.target.value,
          confirmpasswordErr: true,
          confirmpasswordHelperText: "Please Enter ConfirmPassword ",
        })
      }

      if(!CommonConfig.isEmpty(e.target.value)){
        let {Password} = this.state;
        this.setState({
          ConfirmPassword: e.target.value
        });
        if(e.target.value === Password) {
        
          this.setState({
            confirmpasswordErr: false,
            confirmpasswordHelperText: "",
          });
        } else {
        
          this.setState({
            confirmpasswordHelperText: "Confirm password should match with password",
            confirmpasswordErr: true
          });
        }
      }
      
    }
    if (type === "Salt") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Salt: e.target.value,
          SaltErr: true,
          SaltHelpertext: "Please Enter Salt",
        });
      } else {
        this.setState({
          Salt: e.target.value,
          SaltErr: false,
          SaltHelperText: "",
        });
      }
    }
    if(type === "ConfirmSalt"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({
          ConfirmSalt: e.target.value,
          confirmSaltErr: true,
          confirmSaltHelperText: "Please Enter ConfirmSalt ",
        })
      }

      if(!CommonConfig.isEmpty(e.target.value)){
      let {Salt} = this.state;
      this.setState({
        ConfirmSalt: e.target.value
      });
      if(e.target.value === Salt) {
        
        this.setState({
          confirmSaltErr: false,
          confirmSaltHelperText: "",
        });
      } else {
        
        this.setState({
          confirmSaltHelperText: "Confirm Salt should match with Salt",
          confirmSaltErr: true
        });
      }
    }
    
    }
    if(type === "YearlyLeave"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({
          YearlyLeave: e.target.value,
          YearlyLeaveErr: true,
          YearlyLeaveHelperText: "Please enter annual leave quota",
        })
      }
      else if(Number(e.target.value) <= 20){
        this.setState({
          YearlyLeave: e.target.value,
          YearlyLeaveErr: false,
          YearlyLeaveHelperText: ""      
        })
      }
    }
  };

  getOfficeLocationList = () => {
    let data = {
      StringMapType: "OFFICELOCATION"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ OfficeLocationList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  selectChange = (e, type) => {
    if (type === "Officelocation") {
      this.setState({ 
        Officelocation: e.target.value,
        officelocationHelpertext:"",
        officelocationErr:false,
      
      });
    }
  }  
  
  OfficeLocation = () => {
    return this.state.OfficeLocationList.map((content) => {
      return (
        <MenuItem value={content.StringMapKey}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    
    return this.validate();
  }
  render() {
    const { classes } = this.props;
    const countryList = this.state.CountryList.map(country => { return { value:country.CountryID , label:country.CountryName }})

    const { YearlyLeave,JoiningDate,cubiclepositionList,CubiclePosition,IPAddress,Salt,ComputerName,JoiningDateHelpertext,JoiningDateErr,ComputerNameErr,ComputerNameHelperText,IPAddressErr,
            IPAddressHelperText,SaltErr,SaltHelperText,AppraisalDate,AppraisalDateHelpertext, AppraisalDateErr,ConfirmSalt,ConfirmSaltErr,ConfirmSaltHelperText,ProfessionalTaxErr,ProfessionalTaxHelperText,ProfessionalTax,cubiclepositionErr,cubiclepositionHelperText, YearlyLeaveErr,YearlyLeaveHelperText,officelocationErr,officelocationHelpertext,Officelocation} = this.state;
    return (
      <GridContainer className="UserList-outer">
      <GridItem xs={12} sm={12} md={12}>
      <GridContainer>
              <GridItem xs={6}>
                 <FormControl fullWidth>
                    <Datetime
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        className="input-group"
                        value={JoiningDate}
                        // selected={JoiningDate}
                        inputProps={{ placeholder: "Joining Date" }}
                        onChange={(date) => this.handleDate(date, "JoiningDate")}
                        closeOnSelect={true}
                        renderInput={params => (
                            <TextField
                                helperText={JoiningDateHelpertext}
                                error={JoiningDateErr}
                                {...params} label="Joining Date *" margin="normal" />)} />
                    <Icon className="date-icon tp-slam">date_range</Icon>
                </FormControl>
              </GridItem>
              <GridItem xs={6}>
              <FormControl fullWidth>
                    <Datetime
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        className="input-group"
                        value={AppraisalDate}
                        inputProps={{ placeholder: "Apprasial Date" }}
                        onChange={(date) => this.handleDate(date, "AppraisalDate")}
                        closeOnSelect={true}
                        isValidDate={(joineDate) => {
                          const AppraisalDate = new Date(this.state.AppraisalDate);
                          return joineDate >= AppraisalDate;
                        }}
                        renderInput={params => (
                            <TextField
                                helperText={AppraisalDateHelpertext}
                                error={AppraisalDateErr}
                                {...params} label="AppraisalDate *" margin="normal" />)} />
                    <Icon className="date-icon tp-slam">date_range</Icon>
                </FormControl>
              </GridItem>
              <GridItem xs={6}>
                <FormControl fullWidth label="Annual Leave Quota">
                  <TextField
                    helperText={YearlyLeaveHelperText}
                    error={YearlyLeaveErr}
                    value={YearlyLeave}
                    type="number"
                    required
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="Annual Leave Quota"
                    onChange={(e) => this.onChange(e, "YearlyLeave")}
                    onFocus={() => this.setState({YearlyLeaveHelperText:"", YearlyLeaveErr:false})}
                    // onBlur={(e) => this.onChange(e, "YearlyLeave")}
                  />
                </FormControl>
              </GridItem>
           
             <GridItem xs={6}>
              <FormControl fullWidth label="Computer Name">
                  <TextField
                    helperText={ComputerNameHelperText}
                    error={ComputerNameErr}
                    value={ComputerName}
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="Computer Name"
                    onChange={(e) => this.setState({ComputerName: e.target.value})}
                    onFocus={() => this.setState({ComputerNameHelperText:"", ComputerNameErr:false})}
                    onBlur={(e) => this.onChange(e, "ComputerName")}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                      options ={cubiclepositionList}
                      id="cubicleposition"
                      getOptionLabel={option => option.label}
                      value={CubiclePosition}
                      onChange={(event,value)=>this.requestChange(event,value,"cubicleposition")}
                      renderInput={params => (
                      <TextField {...params} error={cubiclepositionErr} helperText={cubiclepositionHelperText} 
                      label="Cubicle Position" margin="normal" />)}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>          
              {/* <GridItem xs={6}> */}
              {/* {this.props.editprops.editid == undefined? <GridItem xs={6}> */}
              <GridItem xs={6}>
              <FormControl fullWidth label="Salt">
                  <TextField
                    helperText={SaltHelperText}
                    error={SaltErr}
                    value={Salt}
                    type="password"
                    required
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="Salt"
                    onChange={(e) => this.setState({Salt: e.target.value})}
                    onFocus={() => this.setState({SaltHelperText:"", ConfirmSaltErr:false})}
                    onBlur={(e) => this.onChange(e, "Salt")}
                  />
                </FormControl>
              </GridItem> 
              {/* :null} */}
              {/* {this.props.editprops.editid == undefined? <GridItem xs={6}> */}
              {/* <GridItem xs={6}>
              <FormControl fullWidth label="Confirm Salt">
                  <TextField
                    helperText={ConfirmSaltHelperText}
                    error={ConfirmSaltErr}
                    value={ConfirmSalt}
                    type="password"
                    required
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="Confirm Salt"
                    onChange={(e) => this.setState({ConfirmSalt: e.target.value})}
                    onFocus={() => this.setState({ConfirmSaltHelperText:"", ConfirmSaltErr:false})}
                    onBlur={(e) => this.onChange(e, "ConfirmSalt")}
                  />
                </FormControl>
              </GridItem>  */}
              {/* :null} */}
             
              <GridItem xs={6}>
                <FormControl fullWidth label="IP Address">
                  <TextField
                    helperText={IPAddressHelperText}
                    error={IPAddressErr}
                    value={IPAddress}
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="IP Address"
                    onChange={(e) => this.setState({IPAddress: e.target.value})}
                    onFocus={() => this.setState({IPAddressHelperText:"", IPAddressErr:false})}
                    onBlur={(e) => this.onChange(e, "IPAddress")}
                  />
                </FormControl>
              </GridItem>
              {/* <GridItem xs={6}>
                <FormControl error={officelocationErr} fullWidth label="Office Location">
                  <InputLabel>Office Location</InputLabel>
                  <Select
                    onChange={(e) => this.selectChange(e, "Officelocation")}
                    value={Officelocation}
                  >
                    {this.OfficeLocation()}
                  </Select>
                  <FormHelperText>{officelocationHelpertext}</FormHelperText>
                </FormControl>
              </GridItem> */}
            </GridContainer>
      </GridItem>
    </GridContainer>
    );
  }
}

Other.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Other);
