import React, { Component } from 'react';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import cogoToast from 'cogo-toast';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';


class addProjectManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {

      ProjectCode: "",
      ProjectCodeErr: false,
      ProjectCodeHelperText: "",

      ProjectName: "",
      ProjectNameErr: false,
      ProjectNameHelperText: "",

      ProjectManager: "",
      ProjectManagerErr: false,
      ProjectManagerHelperText: "",
      ProjectManagerList: [],

      Type: "",
      TypeErr: false,
      TypeHelperText: "",
      TypeList: [],

      TechStackID: "",
      StringMapName: "",
      TechStackList: [],
      viewTechStackList: [],

      AssigneeType: '',
      AssigneeTypeErr:false,
      AssigneeTypeHelperText:'',
      AssigneeTypeList: [],
      
      AssigneeName: '',
      AssigneeErr:false,
      AssigneeHelperText:'',

      Client: "",
      ClientErr: false,
      ClientHelperText: "",
      ClientList: [],

      EstimetedStartDate: "",
      EstimetedStartDateErr: false,
      EstimetedStartDateHelperText: "",

      ActualStartDate: "",
      ActualStartDateErr: false,
      ActualStartDateHelperText: "",

      EstimetedEndDate: "",
      ActualEndDate: "",
      HoursPlanned: "",
      HoursConsumed: 0,
      Description: "",

      PersonList: [],
      viewPersonList: [],
      AssigneeErr:false,
      AssigneeHelperText:'',
      Completed: false,
      Accessvisible: false,
      Frontvisible: false,
      loggedUserID: 0,

      isDisabled : true,
    };
  }

  componentDidMount() {
    this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
    this.getManagerList();
    this.getClientList();
    this.getPersonList();
    this.getTypeList();
    this.getTechStackList();
    this.getAssigneeTypeList();
  }

  getPersonList = () => {
    try {
        api.get("/tasks/getPersonList").then(res => {
          debugger
            if (res.success) {
            this.setState({ PersonList: res.data, 
                    Assignee: {
                        ResourceID: CommonConfig.loginData().PersonID, FirstName: CommonConfig.loginData().FirstName, LastName : CommonConfig.loginData().LastName
                    } });
            }
            else {
                cogoToast.error("Something went wrong");
              }
            this.setState({ PersonList: res.data,
                Assignee: {
                    value: CommonConfig.loginData().PersonID, label: CommonConfig.loginData().FirstName + ' ' + CommonConfig.loginData().LastName
                }
          });
        }).catch(err => {
            console.log(err)
        })
    } catch (err) {
    }
  }

  getTypeList = () => {
    let data = {
      StringMapType: "INDUSTRY"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ TypeList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getTechStackList = () => {
    let data = {
      StringMapType: "TECHSTACK"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ TechStackList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }
  getAssigneeTypeList = () => {
    let data = {
      StringMapType: "ASSIGNEETYPE"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ AssigneeTypeList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }
  redirectBack = () => {
    this.props.history.push("/admin/ProjectManagement");
  }

  getManagerList = () => {
    try {
      api.get("/client/getProjectManagerList").then(res => {
        if (res.success) {
          this.setState({ ProjectManagerList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getClientList = () => {
    try {
      api.get("/client/getClient").then(res => {
        if (res.success) {
          this.setState({ ClientList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  onChange = (e, type) => {
    if (type === "AssigneeName") {
      this.setState({ AssigneeName: e, AssigneeHelperText: "", AssigneeErr: false });
    }
    if (type === "AssigneeType") {
      this.setState({ AssigneeType: e, AssigneeTypeHelperText: "", AssigneeTypeErr: false });
    }
    if (type === "ProjectCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ ProjectCode: e.target.value, ProjectCodeErr: true, ProjectCodeHelperText: "Please enter Project Code" });
      }
      else {
        this.setState({ ProjectCode: e.target.value, ProjectCodeErr: false, ProjectCodeHelperText: "" });
      }
    }

    else if (type === "ProjectName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ ProjectName: e.target.value, ProjectNameErr: true, ProjectNameHelperText: "Please enter Project Name" });
      }
      else {
        this.setState({ ProjectName: e.target.value, ProjectNameErr: false, ProjectNameHelperText: "" });
      }
    }

    else if (type === "Description") {
      this.setState({ Description: e.target.value });
    }

    else if (type === "HoursConsumed") {
      this.setState({ HoursConsumed: e.target.value });
    }

    else if (type === "HoursPlanned") {
      this.setState({ HoursPlanned: e.target.value });
    }
  }

  buttonSelected = (e, type) => {
    if (type === "Completed") {
      this.setState({ Completed: true });
    }
  }

  managerlist = () => {
    return this.state.ProjectManagerList.map(content => {
      return (
        <MenuItem value={content.ResourceID} > {content.FirstName + " " + content.LastName}  </MenuItem>
      )
    })
  }

  techstacklist = () => {
    return this.state.TechStackList.map(content => {
      return (
        <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
      )
    })
  }
  AssigneeTypeList = () => {
    return this.state.AssigneeTypeList.map(content => {
      return (
        <MenuItem value={content.StringMapName} > {content.StringMapName}  </MenuItem>
      )
    })
  }

  clientlist = () => {
    return this.state.ClientList.map(content => {
      return (
        <MenuItem value={content.ClientID} > {content.ClientName}  </MenuItem>
      )
    })
  }

  typelist = () => {
    return this.state.TypeList.map(content => {
      return (
        <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
      )
    })
  }


  handleDate = (date, type) => {
    if (type === "EstimetedStartDate") {
      this.setState({ EstimetedStartDate: date, EstimetedStartDateErr: false, EstimetedStartDateHelperText: "" });
    }
    else if (type === "EstimetedEndDate") {
      this.setState({ EstimetedEndDate: date });
    }
    else if (type === "ActualStartDate") {
      this.setState({ ActualStartDate: date, ActualStartDateErr: false, ActualStartDateHelperText: "" });
    }
    else if (type === "ActualEndDate") {
      this.setState({ ActualEndDate: date });
    }
  }

  // changeAssigneetype = (e,type) => {
  //   debugger
  //   if (type === "Assignee") {
  //     this.setState({ Assignee: e, AssigneeHelperText: "", AssigneeErr: false });
  //   }
  //   else if (type === "AssigneeType"){
  //     this.setState({ AssigneeType:e.target.value, AssigneeTypeErr:false,AssigneeTypeHelperText:"" });
  //   }
  // }

  viewTechStack = () => {
    return this.state.viewTechStackList.filter(x => x.Status === 'Active').map((content,index) => {
      return (
        <tr>
          <td>
            <div>
              <input disabled name="TechStack" value={content.TechStack} />
            </div>
          </td>
          <td>
            <DeleteIcon onClick={() => this.removeTechStack(index)} />
          </td>
        </tr>
      )
    }
    )
  }
  
  viewAssignee = () => {
    return this.state.viewPersonList.filter(x =>  x.Status === 'Active').map((content,index) => {
      return (
        <tr>
          <td>
            <div>
              <input disabled name="Assignee" value={content.Assignee ? content.FirstName + ' ' + content.LastName : null }/>
            </div>
            <div>
              {content.IsEdit == false?
              <input disabled  name="AssigneeType" value={content.AssigneeType === "PRIMARY" ? "P" : "S"} />
                :
              <Select onChange={(e) =>{
                const editAssignee = [...this.state.viewPersonList]
                var Index = this.state.viewPersonList.findIndex(x => x.Index === content.Index);
                if (Index !== -1) {
                  editAssignee[Index]["AssigneeType"] = e.target.value;
                  this.setState({ viewPersonList: editAssignee })
                }
              }} value={content.AssigneeType} >
                      {this.AssigneeTypeList()}
              </Select>
              }
            </div>
          </td>  
          <td>
            <DeleteIcon onClick={() => this.removeAssignee(content.Index)} />
          </td>
          {content.IsEdit == false?
            <td>
            <EditIcon disabled={this.state.isDisabled} onClick={() => this.enabledAssigneetype(content.Index,"edit")} />
            </td>:
            <td>
              <button onClick={() => this.enabledAssigneetype(content.Index,"save")}>Save</button>
              </td>
          }
        </tr>
      )
    }
    )
  }
  addAssignee = (e) => {debugger
    const {Assignee, AssigneeName, AssigneeType } = this.state;
    var addAssignee = this.state.viewPersonList ? this.state.viewPersonList.filter(x => (x.Status === "Active" && x.Assignee.ResourceID === AssigneeName.ResourceID)) : []
    
    if (addAssignee.length === 0) {
      let data = {
        CreatedBy: CommonConfig.loginData().PersonID,        
        Assignee: AssigneeName,
        FirstName: AssigneeName.FirstName,
        LastName: AssigneeName.LastName,
        AssigneeType: AssigneeType,
        Index: this.state.viewPersonList.length + 1,
        Status: 'Active',
        IsEdit: false
      }
      this.setState({ viewPersonList: [...this.state.viewPersonList, data] });
    }
    else {
      cogoToast.error("Assignee Already exist");
    }
  }

  enabledAssigneetype = (idx,type) => {
    const editAssignee = [...this.state.viewPersonList]
    var Index = this.state.viewPersonList.findIndex(x => x.Index === idx);
    if (Index !== -1) {
      editAssignee[Index]["IsEdit"] = type=="edit"?true:false;
      this.setState({ viewPersonList: editAssignee })
    }
  }

  removeAssignee = (idx) => {
    const removeAssignee = [...this.state.viewPersonList]
    var Index = this.state.viewPersonList.findIndex(x => x.Index === idx);
    if (Index !== -1) {
      removeAssignee[Index]["Status"] = "Inactive";
      this.setState({ viewPersonList: removeAssignee })
    }

  }
  addTechStack = (e) => {
    const { PersonID, StringMapName } = this.state;
    var addTechStack = this.state.viewTechStackList.filter(x => (x.Status === "Active" && (x.TechStack === StringMapName)))
    
    if (addTechStack.length === 0) {
      let data = {
        CreatedBy: CommonConfig.loginData().PersonID,
        TechStack: this.state.TechStack,
        Index: this.state.viewTechStackList.length + 1,
        ProjectTechStackID: null,
        Status: 'Active'
      }
      this.setState({ viewTechStackList: [...this.state.viewTechStackList, data] });
    }
    else {
      cogoToast.error("TechStack Already exist");
    }
  }


  removeTechStack = (idx) => {
    const removeTechStack = [...this.state.viewTechStackList]
    var Index = this.state.viewTechStackList.findIndex(x => x.Index === idx);
    if (Index !== -1) {
      removeTechStack[Index]["Status"] = "Inactive";
      this.setState({ viewTechStackList: removeTechStack })
    }
  }

  validate = () => {
    const { ProjectCode, ProjectName, ProjectManager, AssigneeName, AssigneeType,Type, Client, EstimetedStartDate, ActualStartDate } = this.state;
    let IsValid = true;
    if (CommonConfig.isEmpty(ProjectCode)) {
      IsValid = false;
      this.setState({ ProjectCodeHelperText: "Please enter Project Code", ProjectCodeErr: true });
    }
    if (CommonConfig.isEmpty(ProjectName)) {
      IsValid = false;
      this.setState({ ProjectNameHelperText: "Please enter  Project Name", ProjectNameErr: true });
    }

    if (CommonConfig.isEmpty(ProjectManager)) {
      IsValid = false;
      this.setState({ ProjectManagerHelperText: "Please enter Project Manager", ProjectManagerErr: true });
    }

    if (CommonConfig.isEmpty(Type)) {
      IsValid = false;
      this.setState({ TypeHelperText: "Please enter Type", TypeErr: true });
    }

    if (CommonConfig.isEmpty(Client)) {
      IsValid = false;
      this.setState({ ClientHelperText: "Please enter Client", ClientErr: true });
    }
    // if (CommonConfig.isEmpty(AssigneeName)) {
    //   IsValid = false;
    //   this.setState({ AssigneeHelperText: "Please enter Assignee", AssigneeErr: true });
    // }
    // if (CommonConfig.isEmpty(AssigneeType)) {
    //   IsValid = false;
    //   this.setState({ AssigneeTypeHelperText: "Please enter AssigneeType", AssigneeTypeErr: true });
    // }
    if (CommonConfig.isEmpty(EstimetedStartDate)) {
      IsValid = false;
      this.setState({ EstimetedStartDateHelperText: "Please enter Estimeted Start Date", EstimetedStartDateErr: true });
    }

    if (CommonConfig.isEmpty(ActualStartDate)) {
      IsValid = false;
      this.setState({ ActualStartDateHelperText: "Please enter Actual Start Date", ActualStartDateErr: true });
    }

    return IsValid;
  }


  submit = (e) => {
    const { ProjectCode, ProjectName, ProjectManager, Type, Client, Completed, EstimetedStartDate, EstimetedEndDate, ActualStartDate, ActualEndDate, HoursPlanned, HoursConsumed, Description } = this.state;
    if (this.validate()) {
      let data = {
        UserID: CommonConfig.loginData().PersonID,
        ProjectCode: ProjectCode,
        ProjectName: ProjectName,
        ProjectManager: ProjectManager,
        Type: Type,
        viewTechStackList: this.state.viewTechStackList,
        Client: Client,
        Assignee : this.state.viewPersonList.filter(x=> x. Status === "Active").map(x => {return x.Assignee}),
        AssigneeType : this.state.viewPersonList.filter(x=> x. Status === "Active").map(x => {return x.AssigneeType === "PRIMARY" ? 1 : 0}),
        EstimetedStartDate: CommonConfig.isEmpty(EstimetedStartDate) != true ? moment(EstimetedStartDate).startOf('day').format("YYYY-MM-DD").toString() : null,
        EstimetedEndDate: CommonConfig.isEmpty(EstimetedEndDate) != true ? moment(EstimetedEndDate).endOf('day').format("YYYY-MM-DD").toString() : null,
        ActualStartDate: CommonConfig.isEmpty(ActualStartDate) != true ? moment(ActualStartDate).startOf('day').format("YYYY-MM-DD").toString() : null,
        ActualEndDate: CommonConfig.isEmpty(ActualEndDate) != true ? moment(ActualEndDate).endOf('day').format("YYYY-MM-DD").toString() : null,
        HoursPlanned: HoursPlanned,
        HoursConsumed: HoursConsumed,
        Description: Description,
        Completed: Completed,
        ProjectID: null,
      }
      try {
        api.post("projectManagement/addUpdateProject", data).then(res => {
          if (res.success) {
            cogoToast.success("Project added successfully");
            this.props.history.push("/admin/ProjectManagement");
          }
          else {
            cogoToast.error("Something went wrong");
          }
        }).catch(error => {
          console.log("error", error);
        });
      }
      catch (err) {
        console.log("error", err);
      }
    }
    else {
      cogoToast.error("Please correct error and resubmit the form");
    }
  }

  selectChange = (e, type) => {
    if (type === "AssigneeName") {
      this.setState({ AssigneeName: e, AssigneeHelperText: "", AssigneeErr: false });
    }
    else if (type === "AssigneeType"){
      this.setState({ AssigneeType:e.target.value, AssigneeTypeErr:false,AssigneeTypeHelperText:"" });
    }
    else if (type === "ProjectManager") {
      this.setState({ ProjectManager: e.target.value, ProjectManagerHelperText: "", ProjectManagerErr: false });
    }
    else if (type === "Client") {
      this.setState({ Client: e.target.value, ClientHelperText: "", ClientErr: false });
    }
    else if (type === "Type") {
      this.setState({ Type: e.target.value, TypeHelperText: "", TypeErr: false });
    }
    else if (type === "TechStack") {
      this.setState({ TechStack:e.target.value, TechStackErr:false,TechStackHelperText:"" });
    }
  }

  render() {
    const { ProjectCode, ProjectCodeErr, ProjectCodeHelperText, ProjectName, ProjectNameErr, ProjectNameHelperText,
      ProjectManager, ProjectManagerErr, ProjectManagerHelperText, Type, TypeErr, TypeHelperText, StringMapName, Client, 
      ClientErr, ClientHelperText, EstimetedStartDate, EstimetedStartDateErr, EstimetedStartDateHelperText, 
      EstimetedEndDate, ActualStartDate, ActualStartDateErr, ActualStartDateHelperText, ActualEndDate, HoursPlanned, 
      HoursConsumed, Description, Completed,AssigneeName,AssigneeErr,AssigneeHelperText, AssigneeType,TechStack,AssigneeTypeErr,AssigneeTypeHelperText } = this.state;

    return (
      <GridContainer className="UserList-outer">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="btn-right-outer" color="primary" icon>
              <CardIcon color="primary">
                <HeadsetMic />
              </CardIcon>
              <h4 className="margin-right-auto text-color-black">Add Project</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      helperText={ProjectNameHelperText}
                      error={ProjectNameErr}
                      value={ProjectName}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="Project Name"
                      onChange={(e) => this.onChange(e, "ProjectName")}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={6}>
                  <FormControl fullWidth label="Project Code">
                    <TextField
                      helperText={ProjectCodeHelperText}
                      error={ProjectCodeErr}
                      value={ProjectCode}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="Project Code"
                      onChange={(e) => this.onChange(e, "ProjectCode")}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={6}>
                  <FormControl fullWidth error={ProjectManagerErr}>
                    <InputLabel>Project Manager</InputLabel>
                    <Select onChange={(e) => this.selectChange(e, "ProjectManager")} value={ProjectManager} >
                      {this.managerlist()}
                    </Select>
                    <FormHelperText>{ProjectManagerHelperText}</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={6}>
                  <FormControl fullWidth error={ClientErr}> 
                    <InputLabel>Client</InputLabel>
                    <Select onChange={(e) => this.selectChange(e, "Client")} value={Client} >
                      {this.clientlist()}
                    </Select>
                    <FormHelperText>{ClientHelperText}</FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={6}>
                  <FormControl fullWidth error={TypeErr}>
                    <InputLabel>Type</InputLabel>
                    <Select onChange={(e) => this.selectChange(e, "Type")} value={Type} >
                      {this.typelist()}
                    </Select>
                    <FormHelperText>{TypeHelperText}</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      value={HoursPlanned}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="Hours Planned"
                      onChange={(e) => this.onChange(e, "HoursPlanned")}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={6}>
                  <FormControl fullWidth >
                    <InputLabel>TechStack</InputLabel>
                    <Select onChange={(e) => this.selectChange(e, "TechStack")} value={TechStack} >
                      {this.techstacklist()}
                    </Select>
                    <Tooltip title="Add TechStack" placement="bottom">
                      <Button type="submit" color="primary" onClick={(e) => this.addTechStack(e)}>Add</Button>
                    </Tooltip>
                    {this.viewTechStack()}
                  </FormControl>
                </GridItem>
                
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      disabled
                      value={HoursConsumed}
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      label="Hours Consumed"
                      onChange={(e) => this.onChange(e, "HoursConsumed")}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"DD/MM/YYYY"}
                      timeFormat={false}
                      selected={moment(EstimetedStartDate)}
                      inputProps={{ placeholder: "Estimeted Start Date" }}
                      onChange={(date) => this.handleDate(date, "EstimetedStartDate")}
                      closeOnSelect={true}
                      renderInput={params => (
                        <TextField
                          error={EstimetedStartDateErr}
                          helperText={EstimetedStartDateHelperText}
                          {...params} label="Estimeted Start Date" margin="normal" />)} />
                    <Icon className="date-icon tp-slam">date_range</Icon>
                  </FormControl>
                </GridItem>
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"DD/MM/YYYY"}
                      timeFormat={false}
                      selected={moment(EstimetedEndDate)}
                      inputProps={{ placeholder: " Estimeted End Date" }}
                      onChange={(date) => this.handleDate(date, "EstimetedEndDate")}
                      closeOnSelect={true}
                      renderInput={params => (
                        <TextField
                          {...params} label="Estimeted End Date" margin="normal" />)} />
                    <Icon className="date-icon tp-slam">date_range</Icon>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"DD/MM/YYYY"}
                      timeFormat={false}
                      selected={moment(ActualStartDate)}
                      inputProps={{ placeholder: "Actual Start Date" }}
                      onChange={(date) => this.handleDate(date, "ActualStartDate")}
                      closeOnSelect={true}
                      renderInput={params => (
                        <TextField
                          error={ActualStartDateErr}
                          helperText={ActualStartDateHelperText}
                          {...params} label="Actual Start Date" margin="normal" />)} />
                    <Icon className="date-icon tp-slam">date_range</Icon>
                  </FormControl>
                </GridItem>
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <Datetime
                      dateFormat={"DD/MM/YYYY"}
                      timeFormat={false}
                      selected={moment(ActualEndDate)}
                      inputProps={{ placeholder: " Actual End Date" }}
                      onChange={(date) => this.handleDate(date, "ActualEndDate")}
                      closeOnSelect={true}
                      renderInput={params => (
                        <TextField
                          {...params} label="Actual End Date" margin="normal" />)} />
                    <Icon className="date-icon tp-slam">date_range</Icon>
                    <Tooltip title="Set as completed" placement="bottom">
                    <Button color="primary" onClick={(e) => this.buttonSelected(e, "Completed")} value={Completed} name="Completed">Set As Completed</Button>
                    </Tooltip>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={6} md={3}>
              <FormControl fullWidth  error={AssigneeErr} >
                  <div className="search-resource">
                      <div className="with-icon">
                      <Autocomplete
                          options={this.state.PersonList}
                          id="DailyStatus"
                          value={this.state.AssigneeName ?this.state.AssigneeName:null}
                          getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                          getOptionValue={(option) => option.ResourceID}
                          onChange={(event, value) => this.onChange(value, "AssigneeName")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={AssigneeHelperText}
                              label="Assignee"
                            />
                          )}
                        />

                      {/* <Autocomplete
                          options={this.state.PersonList}
                          id="DailyStatus"
                          value={AssigneeName}
                          getOptionLabel={option => option.ResourceID ? option.FirstName + " " + option.LastName : option}
                          onChange={(event, value) => this.onChange(value, "AssigneeName")}
                          renderInput={params => (
                              <TextField {...params} helperText={AssigneeHelperText}
                                  label="Assignee" />)}
                      /> */}
                      </div>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={3}>
              <FormControl fullWidth error={AssigneeTypeErr}>
                  <InputLabel>Assignee Type</InputLabel>
                    <Select onChange={(e) => this.selectChange(e, "AssigneeType")} value={AssigneeType} >
                      {this.AssigneeTypeList()}
                    </Select>
                    <FormHelperText>{AssigneeTypeHelperText}</FormHelperText>
              </FormControl>
              </GridItem>
              </GridContainer>
                <Tooltip title="Add Assignee" placement="bottom">
                  <Button type="submit" color="primary" onClick={(e) => this.addAssignee(e)}>Add Assignee</Button>
                </Tooltip>
                  {this.viewAssignee()}
              <GridContainer>
                <FormControl>
                  <TextareaAutosize
                    value={Description}
                    placeholder="Description"
                    rowsMin={5}
                    cols={90}
                    label="Description"
                    onChange={(e) => this.onChange(e, "Description")}
                  />
                </FormControl>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        
        <div className="submit-btn">
          <Tooltip title="Save" placement="bottom">
            <Button color="primary" type="submit" onClick={(e)=>this.submit(e)} className="width-150 mr-4 M-right-10" >
              Save
            </Button>
          </Tooltip>
          <Tooltip title="Cancel" placement="bottom">
            <Button  color="secondary" type="submit" onClick={(e)=>this.redirectBack(e)} className="width-150 mr-4" >
              Cancel
            </Button>
          </Tooltip>
        </div>
      </GridContainer>
    );
  }
}
export default addProjectManagement;