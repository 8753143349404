import React from "react";
// react plugin for creating charts

// @material-ui/core components
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import { Pie, Bar, Line } from "react-chartjs-2";
// core components
import Table from "components/Table/Table.js";
import moment from 'moment';
import ReactTable from "react-table";
import Assignment from "@material-ui/icons/Assignment";
import SimpleBackdrop from "../../utils/general";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart
} from "variables/charts";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import stylescharts from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { useState, useEffect } from 'react';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Badge from "components/Badge/Badge.js";
import TextField from "@material-ui/core/TextField";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import classNames from "classnames";
import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";
import { Grid } from "@material-ui/core";

const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};
const useStylesCharts = makeStyles(stylescharts);
const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const [pieChartData, setpieChartData] = React.useState([])
  const [pieChartProjectData, setpieChartProjectData] = React.useState([])
  const [projectSummary, setprojectSummary] = React.useState([])
  const [NotificationList, setNotificationList] = React.useState([])
  const [LeaveList, setLeaveList] = React.useState([])
  const [Birthday, SetBirthday] = React.useState([])
  const [TaskList, setTaskList] = React.useState([])
  const [ProjectName, setProjectName] = React.useState('');
  const [filterVal, setfilterVal] = React.useState('');
  const [DailyStatusList, setDailyStatusList] = React.useState([]);
  const [viewDailyTaskErr, setviewDailyTaskErr] = React.useState(false);
  let [ApproveAccess, setApproveAccess] = React.useState(false);
  let [Loading, setLoading] = React.useState(false);
  const [openLabel, setopenLabel] = React.useState(false)
  const [viewDailyTaskHelperText, setviewDailyTaskHelperText] = React.useState('');
  const [projectNameErr, setprojectNameErr] = React.useState(false);
  const [projectNameHelperText, setprojectNameHelperText] = React.useState('');
  const classescharts = useStylesCharts();
  const chartColors = [
    "#00acc1",
    "#ff9800",
    "#f44336",
    "#666699",
    "#CC9933",
    "#006666",
    "#3399FF",
    "#993300",
    "#CCCC99",
    "#666666",
    "#FFCC66",
    "#6699CC",
    "#663366",
    "#9999CC",
    "#CCCCCC",
    "#669999",
    "#CCCC66",
    "#CC6600",
    "#9999FF",
    "#0066CC",
    "#99CCCC",
    "#999999",
    "#FFCC00",
    "#009999",
    "#99CC33",
    "#FF9900",
    "#999966",
    "#66CCCC",
    "#339966",
    "#CCCC33",
    "#003f5c",
    "#665191",
    "#a05195",
    "#d45087",
    "#2f4b7c",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
    "#EF6F6C",
    "#465775",
    "#56E39F",
    "#59C9A5",
    "#5B6C5D",
    "#0A2342",
    "#2CA58D",
    "#84BC9C",
    "#CBA328",
    "#F46197",
    "#DBCFB0",
    "#545775"
  ];

  useEffect(() => {

    let checkAccess = CommonConfig.getUserAccess("Dashboard");
    if (checkAccess) {
      if (checkAccess.AllAccess == 1) {
        setApproveAccess(true)
      } else {
        setApproveAccess(false)
      }
    }
    getTaskList();
    getLeaveList();
    getBirthdayList();
    getNotificationList();
    getDailyStatusList();
  }, [])

  const getNotificationList = (filterValue) => {
    let data;
    if (!CommonConfig.isEmpty(filterValue) || !CommonConfig.isEmpty(filterVal)) {
      data = {
        ResourceID: filterValue ? filterValue.value : filterVal.value
      }
    }
    else {
      data = {
        ResourceID: CommonConfig.loginData().PersonID
      }
    }
    try {
      api.post("dailystatus/getDesktopNotification", data).then(res => {
        setNotificationList(res.data);
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  const getLeaveList = (filterValue) => {
    let data;
    if (!CommonConfig.isEmpty(filterValue) || !CommonConfig.isEmpty(filterVal)) {
      data = {
        ResourceID: filterValue ? filterValue.value : filterVal.value
      }
    }
    else {
      data = {
        ResourceID: CommonConfig.loginData().PersonID
      }
    }
    try {
      api.post("leave/getLeaveListByResource", data).then(res => {

        var today = new Date();
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var leave = [];
        var filteredvalue = res.data.filter(x => Date.parse(x.LeaveStartDate) > Date.parse(today))
        for (let i = 0; i < filteredvalue.length; i++) {
          let start = new Date(filteredvalue[i].LeaveStartDate);
          let end = new Date(filteredvalue[i].LeaveEndDate);
          const diffTime = Math.abs(end - start);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          var LeaveDate = monthNames[start.getMonth()] + " " + start.getDate() + " " + (diffDays == 0 ? ", " + start.getFullYear() + " (" + days[start.getDay()] + ")" : "to " + (start.getMonth() !== end.getMonth() ? monthNames[end.getMonth()] + " " : "") + end.getDate() + " (" + (diffDays + 1) + " d)")
          leave.push({
            "LeaveStartDate": filteredvalue[i].LeaveStartDate,
            "LeaveEndDate": filteredvalue[i].LeaveEndDate,
            "LeaveDate": new Date(filteredvalue[i].LeaveStartDate).getDate() + " " + monthNames[new Date(filteredvalue[i].LeaveStartDate).getMonth()] + " " + new Date(filteredvalue[i].LeaveStartDate).getFullYear() + " " + "to" + " " + new Date(filteredvalue[i].LeaveEndDate).getDate() + " " + monthNames[new Date(filteredvalue[i].LeaveEndDate).getMonth()] + " " + new Date(filteredvalue[i].LeaveEndDate).getFullYear(),
            "LeaveDate": LeaveDate,
            "LeaveReason": filteredvalue[i].LeaveReason,
            "LeaveStatus": filteredvalue[i].LeaveStatus,
            "FirstName": filteredvalue[i].FirstName,
            "HalfLeave": filteredvalue[i].HalfLeave,
          })
        }
        setLeaveList(leave);
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  //.....Birthday............................................................................................
  const getBirthdayList = (filterValue) => {
    debugger;
    try {
      debugger;
      api.post("dailystatus/getUpcomingBirthday",).then(res => {
       console.log("dataaaaaaa---------------birthdatw",res.data);
        
        var birth = res.data;
        
      
        birth.push({
            "FirstName": birth.FirstName,
            "DOB": birth.DOB,
            "YearCompleted": birth.Year_Old,
           
          })
        
        SetBirthday(birth);
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }
  //........................end birthday...............................................................................

  const getTaskList = (Project, filterValue) => {

    let data;
    if (!CommonConfig.isEmpty(filterValue) || !CommonConfig.isEmpty(filterVal)) {
      data = {
        ResourceID: filterValue ? filterValue.value : filterVal.value
      }
    }
    else {
      data = {
        ResourceID: CommonConfig.loginData().PersonID
      }
    }
    try {
      api.post("dailystatus/getTaskByID", data).then(res => {
        var obj = {};        
        for (var i = 0, len = res.data.TaskData.length; i < len; i++) {
          obj[res.data.TaskData[i]['ProjectName']] = res.data.TaskData[i];
        }

        var pfilter = [];
        for (var key in obj) {
          pfilter.push(obj[key]);
        }
        var fil = []

        // fil.push({
        //   ProjectName: "Select All",
        //   ProjectTask: "",
        // });
        for (let i = 0; i < pfilter.length; i++) {
          fil.push({
            ProjectName: pfilter[i].ProjectName,
            ProjectTask: res.data.TaskData.filter((v, l, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === l && (v.ProjectName === pfilter[i].ProjectName)).length
          })
        }

        setprojectSummary(fil);

        var pieChart;
        var pieChartNew;
        
        if (!CommonConfig.isEmpty(Project)) {
          var Total = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.ProjectName === Project.ProjectName)).length;
          var inProgress = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "IN PROGRESS") && (v.ProjectName === Project.ProjectName)).length;
          var open = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "OPEN") && (v.ProjectName === Project.ProjectName)).length;
          var resolved = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "RESOLVED") && (v.ProjectName === Project.ProjectName)).length;
          var closed = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "CLOSED") && (v.ProjectName === Project.ProjectName)).length;

        } else {
          var Total = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i).length;
          var inProgress = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "IN PROGRESS")).length;
          var open = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "OPEN")).length;
          var resolved = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "RESOLVED")).length;
          var closed = res.data.TaskData.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i && (v.TaskStatus === "CLOSED")).length;
        }

        var inProgressPercent = parseFloat((Number(inProgress) / (Number(Total)) * 100)).toFixed(2)
        var openPercent = parseFloat((Number(open) / (Number(Total)) * 100)).toFixed(2)
        var resolvedPercent = parseFloat((Number(resolved) / (Number(Total)) * 100)).toFixed(2)
        var closedPercent = parseFloat((Number(closed) / (Number(Total)) * 100)).toFixed(2)

        pieChart = {
          maintainAspectRatio: false,
          responsive: false,
          labels: ["In Progress " + "(" + inProgressPercent + "%" + ")", "Open " + "(" + openPercent + "%" + ")", "Resolved " + "(" + resolvedPercent + "%" + ")", "Closed " + "(" + closedPercent + "%" + ")"],
          options: [inProgress, open, resolved, closed],
          datasets: [
            {
              data: [inProgress, open, resolved, closed],
              backgroundColor: chartColors,
              hoverBackgroundColor: chartColors
            }
          ]
        };

        var pieChartProject;
        var TotalTask = 0;
        for (let i = 0; i < fil.length; i++) {
          TotalTask = TotalTask + fil[i].ProjectTask
        }

        var finalpercent = [];
        var final = []
        for (let i = 0; i < fil.length; i++) {
          finalpercent.push(fil[i].ProjectName + ' (' + Math.round((Number(fil[i].ProjectTask) / (Number(TotalTask)) * 100)) + "%)")
          final.push(fil[i].ProjectTask)
        }
        
     
        pieChartProject = {
          maintainAspectRatio: false,
          responsive: false,
          labels: finalpercent,
          options: final,
          datasets: [
            {
              data: final,
              backgroundColor: chartColors,
              hoverBackgroundColor: chartColors
            }
          ]
        };

        var today = new Date();
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        var task = [];
        for (let i = 0; i < 5; i++) {
          task.push({
            "FirstName": res.data.TaskData[i].FirstName,
            "Subject": res.data.TaskData[i].Subject,
            "ProjectName": res.data.TaskData[i].ProjectName,
            "StartDate": new Date(res.data.TaskData[i].StartDate).getDate() + " " + monthNames[new Date(res.data.TaskData[i].StartDate).getMonth()] + " " + new Date(res.data.TaskData[i].StartDate).getFullYear(),
            "DueDate": new Date(res.data.TaskData[i].DueDate).getDate() + " " + monthNames[new Date(res.data.TaskData[i].DueDate).getMonth()] + " " + new Date(res.data.TaskData[i].DueDate).getFullYear()
          })
        }

        setTaskList(task);
        setpieChartData(pieChart);
        setpieChartProjectData(pieChartProject);
        setLoading(false);
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  const handleChange = (value, type) => {
    setLoading(true);
    if (type == "Project") {
      if (value !== null) {
        setProjectName(value)
        getTaskList(value);
      } else {
        setProjectName('')
        getTaskList(value);
      }
    } else {
      if (value !== null) {
        setfilterVal(value)
        getTaskList('', value);
        getLeaveList(value);
        getBirthdayList(value);
        getNotificationList(value);
      } else {
        setfilterVal('')
        getTaskList('', value);
        getNotificationList('');

      }
    }
  }

  const getDailyStatusList = () => {
    try {
      api.get("dailystatus/getDailyStatusList").then(res => {
        if (res.success) {
          let obj = {
            FullName: "Select All",
            ResourceID: ""
          }
          var finalResourceList = []

          res.data.map((r, i) => {
            res.data[i].FullName = `${r.FirstName} ${r.LastName}`
          });
          finalResourceList.push(obj)
          for (let i = 0; i < res.data.length; i++) {
            finalResourceList.push(
              res.data[i]
            )
          }
          setDailyStatusList(finalResourceList)
          setfilterVal({
            value: CommonConfig.loginData().PersonID, label: CommonConfig.loginData().FirstName + ' ' + CommonConfig.loginData().LastName
          })
        }
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  var DailyStatus = DailyStatusList.map(DailyStatus => {
    return { value: DailyStatus.ResourceID, label: DailyStatus.FullName }
  });

  
  // DailyStatus.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))

  const LeavetableColumns = [

    {
      Header: 'Resource',
      accessor: 'FirstName',
      Cell: record => {
        return (
          // <Tooltip title={record.original.FirstName} arrow>
          <span>{record.original.FirstName}</span>
          // </Tooltip>
        )
      },
      id: 'Resource',
      sortable: false,
      className: "nowrap",
    },
    {
      Header: 'Leave Date',
      Cell: record => {

        return (
          <span>
            {record.original.LeaveDate}
          </span>
        )
      },
      id: 'LeaveStartDate',
      sortable: false,
      className: "nowrap",
      width: 150
    },
    // {
    //   Header: 'Leave Type',
    //   accessor: data => {
    //     return (
    //       <span>{Date.parse(data.LeaveStartDate) == Date.parse(data.LeaveEndDate) && data.HalfLeave.data[0] == 1? "HalfDay":Date.parse(data.LeaveStartDate) == Date.parse(data.LeaveEndDate)?"FullDay":"MultiDay"}</span>
    //     )
    //   },
    //   id: 'LeaveType',
    //   sortable: true,
    // },
    {
      Header: 'Reason',
      accessor: 'LeaveReason',
      Cell: record => {
        return (
          <Tooltip title={record.original.LeaveReason} arrow>
            <span>{record.original.LeaveReason}</span>
          </Tooltip>

        )
      },
      id: 'LeaveReason',
      sortable: false,
      className: "nowrap",
      width: 180

    },
    {
      Header: 'Status',
      Cell: record => {

        return (
          <Tooltip title={record.original.LeaveStatus} arrow>
            <span>
              {record.original.LeaveStatus}
            </span>
          </Tooltip>
        )
      },
      id: 'LeaveStatus',
      sortable: false,
      className: "nowrap",
      width: 80
    },
  ]


  const BirthdaytableColumns = [

    {
      Header: 'Name',
      accessor: 'FirstName',
      Cell: record => {
        return (

          <span>{record.original.FirstName}</span>

        )
      },
      id: 'FirstName',
      sortable: false,
      className: "nowrap",
      width: 100
    },
    {
      Header: 'Birthday',
      Cell: record => {
        if (CommonConfig.isEmpty(record.original.DOB)) {
          return null
        }
        else {

        return (
          <span>
            {moment(record.original.DOB).format(CommonConfig.dateFormat.dateFirst)}
          </span>
        )
        }
      },
      id: 'DOB',
      sortable: false,
      className: "nowrap",
      width: 100
    },
    {
      Header: 'Year Completed',
      Cell: record => {

        return (
          <span>
            {record.original.Year_Old}
          </span>
        )
      },
      id: 'Year_Old',
      sortable: false,
      className: "nowrap",
      width: 110
    },


  ]

  const NotificationtableColumns = [

    {
      Header: 'Resource',
      accessor: 'FirstName',
      Cell: record => {
        return (
          // <Tooltip title={record.original.FirstName} arrow>
          <span>{record.original.FirstName}</span>
          // </Tooltip>
        )
      },
      id: 'Resource',
      sortable: false,
      className: "nowrap",
    },
    {
      Header: 'Title',
      Cell: record => {

        return (
          <Tooltip title={record.original.NotificationHeader} arrow>
            <span>
              {record.original.NotificationHeader}
            </span>
          </Tooltip>
        )
      },
      id: 'Title',
      sortable: false,
      className: "nowrap",
      width: 180
    },
    {
      Header: 'Notification Date',
      Cell: record => {

        return (
          <Tooltip title={record.original.CreatedOn} arrow>
            <span>
              {record.original.CreatedOn}
            </span>
          </Tooltip>
        )
      },
      id: 'Notification Date',
      sortable: false,
      className: "nowrap",
      // width: 270
    },
    {
      Header: 'Description',
      accessor: 'NotificationBody',
      Cell: record => {
        return (
          <Tooltip title={record.original.NotificationBody} arrow>
            <span>{record.original.NotificationBody}</span>
          </Tooltip>

        )
      },
      id: 'Description',
      sortable: false,
      className: "nowrap",
    },
  ]

  const tableColumns = [
    {
      Header: 'Resource',
      accessor: 'FirstName',
      Cell: record => {
        return (
          // <Tooltip title={record.original.FirstName} arrow>
          <span>{record.original.FirstName}</span>
          // </Tooltip>
        )
      },
      id: 'Resource',
      sortable: false,
      className: "nowrap",
    },
    {
      Header: 'Subject',
      accessor: 'Subject',
      Cell: record => {
        return (
          <Tooltip title={record.original.Subject} arrow>
            <span>{record.original.Subject}</span>
          </Tooltip>

        )
      },
      id: 'Subject',
      sortable: false,
      className: "nowrap",
      width: 270
    },
    {
      Header: 'Project Name',
      Cell: record => {

        return (
          <Tooltip title={record.original.ProjectName} arrow>
            <span>
              {record.original.ProjectName}
            </span>
          </Tooltip>
        )
      },
      id: 'ProjectName',
      sortable: false,
      className: "nowrap",
    },
    {
      Header: 'Start',
      Cell: record => {

        return (
          <span>
            {moment(record.original.StartDate).format(CommonConfig.dateFormat.dateFirst)}
          </span>
        )
      },
      id: 'StartDate',
      sortable: false,
    },
    {
      Header: 'Due Date',
      Cell: record => {
        if (CommonConfig.isEmpty(record.original.DueDate)) {
          return null
        }
        else {
          return (
            <span>
              {moment(record.original.DueDate).format(CommonConfig.dateFormat.dateFirst)}
            </span>
          )
        }
      },
      id: 'DueDate',
      sortable: false,
    },
  ]

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });

  const { rtlActive } = props;

  return (
    <div>
      {Loading === true ? (
        <div className="loading">
          <SimpleBackdrop />
        </div>
      ) : null
      }
      <GridContainer className="ml-auto">
        {ApproveAccess === true ? <GridItem xs={12} sm={4} md={3}>
          <div className="search-resource">
            <Autocomplete
              options={DailyStatus}
              id="DailyStatus"
              value={filterVal}
              getOptionLabel={option => option.label}
              onChange={(event, value) => handleChange(value, "Resource")}
              renderInput={params => (
                <TextField {...params} error={viewDailyTaskErr} helperText={viewDailyTaskHelperText}
                  label="Search Resource" />)}
            />
            {/* <Tooltip title="Search" placement="top">
                <Button onClick={() => this.redirectView()} justIcon color="primary"><SearchIcon /></Button>
              </Tooltip> */}
          </div>
        </GridItem>
          :
          <GridItem xs={12} sm={3} md={3}>
            <div className="search-resource">
              {/* <div className="with-icon"> */}
              <Autocomplete
                disabled
                options={DailyStatus}
                id="DailyStatus"
                value={filterVal}
                getOptionLabel={option => option.label}
                onChange={(event, value) => handleChange(value, "Resource")}
                renderInput={params => (
                  <TextField {...params} error={viewDailyTaskErr} helperText={viewDailyTaskHelperText}
                    label="Search Resource Name" />)}
              />
              {/* <Tooltip title="Search" placement="top">
                <Button onClick={() => this.redirectView()} justIcon color="primary"><SearchIcon /></Button>
              </Tooltip> */}
              {/* </div> */}
            </div>
          </GridItem>
        }
        <GridItem xs={12} sm={3} md={3}>
          <div className="search-resource">
            {/* <div className="with-icon"> */}
            <Autocomplete
              options={projectSummary}
              id="projectSummary"
              value={ProjectName}
              getOptionLabel={option => option.ProjectName}
              onChange={(event, value) => handleChange(value, "Project")}
              renderInput={params => (
                <TextField {...params} error={projectNameErr} helperText={projectNameHelperText}
                  label="Search Project" />)}
            />
            {/* <Tooltip title="Search" placement="top">
                    <Button onClick={() => this.redirectView()} justIcon color="primary"><SearchIcon /></Button>
                  </Tooltip> */}
            {/* </div> */}
          </div>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card className="dashboard-chart">
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <Timeline />
              </CardIcon>
              <h4 className={classescharts.cardIconTitle}>Task Summary Chart</h4>

            </CardHeader>
            <CardBody>
              {/* <ChartistGraph
                data={pieChart.data}
                type="Pie"
                options={pieChart.options}
              /> */}
              <Pie
                data={pieChartData}
              />
            </CardBody>
            <CardFooter stats className={classescharts.cardFooter}>
              {/* <h6 className={classescharts.legendTitle}>Legend</h6>
              <i className={"fas fa-circle " + classescharts.info} /> In Progress{` `}
              <i className={"fas fa-circle " + classescharts.warning} /> Open
              {` `}
              <i className={"fas fa-circle " + classescharts.danger} /> Resolved
              {` `}
              <i className={"fas fa-circle " + classescharts.closed} /> Closed
              {` `} */}
              <i className={"fas fa-circle "} /> Overall Tasks :
              {pieChartData.options && pieChartData.options.length > 0 ? parseInt(pieChartData.options[0] + pieChartData.options[1] + pieChartData.options[2] + pieChartData.options[3]) : 0}

            </CardFooter>

          </Card>
          <div class="dashboard-chart-btn" onClick={() => props.history.push('/admin/Tasks')}>
            <Badge> View all</Badge>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card className="dashboard-chart">
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <Timeline />
              </CardIcon>
              <h4 className={classescharts.cardIconTitle}>Project Summary Chart</h4>
            </CardHeader>
            <CardBody>
              {/* <ChartistGraph
                data={pieChart.data}
                type="Pie"
                options={pieChart.options}
              /> */}
              <Pie
                data={pieChartProjectData}
              />
            </CardBody>
            <CardFooter stats className={classescharts.cardFooter}>
              {/* <h6 className={classescharts.legendTitle}>Legend</h6>
              {projectSummary.length < 5 ?
                projectSummary.map((x, idx) => {
                  return (
                    <>
                      <i className={"fas fa-circle"} style={{ color: chartColors[idx] }} /> {x.ProjectName + ` `}
                    </>
                  )
                })
                :
                <>
                  <i className={"fas fa-circle"} style={{ color: chartColors[0] }} /> {projectSummary[0].ProjectName + ` `}
                  <i className={"fas fa-circle"} style={{ color: chartColors[1] }} /> {projectSummary[1].ProjectName + ` `}
                  <i className={"fas fa-circle"} style={{ color: chartColors[2] }} /> {projectSummary[2].ProjectName + ` `}
                  <i className={"fas fa-circle"} style={{ color: chartColors[3] }} /> {projectSummary[3].ProjectName + ` `}
                </>
              } */}
            </CardFooter>
          </Card>

          {projectSummary.length < 5 ? null :
            <>
              <div class="dashboard-chart-btn" onClick={(event) => {
                if (openLabel && openLabel.contains(event.target)) {
                  setopenLabel(null);
                } else {
                  setopenLabel(event.currentTarget);
                }

              }} >
                <Badge color="rose"> View all</Badge>
              </div>
              <Popper
                open={Boolean(openLabel)}
                anchorEl={openLabel}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                  [classes.popperClose]: !openLabel,
                  [classes.popperResponsive]: true,
                  [classes.popperNav]: true
                })}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="profile-menu-list"
                    style={{ transformOrigin: "0 0 0" }}
                  >
                    <Paper className={classes.dropdown}>
                      <ClickAwayListener onClickAway={() => {
                        setopenLabel(null);
                      }}>
                        <MenuList role="menu">
                          {projectSummary.map((x, idx) => {
                            return (
                              <>
                                <MenuItem
                                  className={dropdownItem}
                                ><i className={"fas fa-circle"} style={{ color: chartColors[idx] }} /> {x.ProjectName + ` `}
                                </MenuItem>

                              </>
                            )
                          })
                          }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          }
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card className="dashboard-chart">
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Upcoming Leave
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <ReactTable
                    data={LeaveList}
                    minRows={5}
                    resizable={false}
                    columns={LeavetableColumns}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    style={{ cursor: "pointer" }}
                    showPagination={false}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                  />
                  {/* <Table
                  hover
                  tableHeadColor="gray"
                  tableHead={[
                    "Leave Date",
                    "Leave Reason",
                    "Status"
                  ]}
                  tableData={LeaveList}
                  coloredColls={[2]}
                  colorsColls={["rose"]}
                /> */}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter stats className={classescharts.cardFooter}>
            </CardFooter>
          </Card>
          <div className="dashboard-chart-btn" onClick={() => props.history.push('/admin/LeaveList')}>
            <Badge>View all</Badge>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card className="dashboard-chart">
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Daily Status List
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <ReactTable
                    data={TaskList}
                    minRows={5}
                    resizable={false}
                    columns={tableColumns}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    style={{ cursor: "pointer" }}
                    showPagination={false}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                  />
                </GridItem>
                {/* <Table
                  hover
                  tableHeadColor="gray"
                  tableHead={[
                    "Task Name",
                    "Project Name",
                    "Task Start Date",
                    "Task End Date"
                  ]}
                  tableData={TaskList}
                  // coloredColls={[3]}
                  // colorsColls={["rose"]}
                /> */}
              </GridContainer>
            </CardBody>
            <CardFooter stats className={classescharts.cardFooter}>

            </CardFooter>
          </Card>
          <div className="dashboard-chart-btn" onClick={() => props.history.push('/admin/Tasks')}>
            <Badge>View all</Badge>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Notification
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <ReactTable
                    data={NotificationList}
                    minRows={5}
                    resizable={false}
                    columns={NotificationtableColumns}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    style={{ cursor: "pointer" }}
                    showPagination={false}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter stats className={classescharts.cardFooter}>
            </CardFooter>
          </Card>
          {/* <span onClick={() => props.history.push('/admin/LeaveList')}><Badge color="rose">View all</Badge></span> */}
        </GridItem>
     


      
        <GridItem xs={12} sm={12} md={6}>

          <Card className="dashboard-chart">
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Upcoming Birthday
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <ReactTable
                    data={Birthday}
                    minRows={5}
                    resizable={false}
                    columns={BirthdaytableColumns}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    style={{ cursor: "pointer" }}
                    showPagination={false}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                  />
                  
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter stats className={classescharts.cardFooter}>
            </CardFooter>
          </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}
