import React, { Component } from 'react';
import cogoToast from 'cogo-toast';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CardIcon from "components/Card/CardIcon.js";
import { InputLabel } from '@material-ui/core';
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import LockIcon from '@material-ui/icons/Lock';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

class addprojectDomainConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {

            DomainName: "",
            domainnameErr: false,
            domainnameHelperText: "",

            Comment: "",

            UserName: "",
            usernameErr: false,
            usernameHelperText: "",

            Password: "",
            passwordErr: false,
            passwordHelperText: "",

            Server: "",
            serverErr: false,
            serverHelperText: "",

            PanelType: "",
            paneltypeErr: false,
            paneltypeHelperText: "",

            Project: "",
            projectErr: false,
            projectHelperText: "",

            Accessvisible: false,
            ServerNameList: [],
            ProjectNameList: [],
            PanelTypeList: [],
            loggedUserID: 0,
        };
    }

    componentDidMount() {
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
        this.getServerName();
        this.getProjectName();
        this.getPanelType();
    }

    getServerName = () => {

        try {
            api.post("/serverconfig/getServerName").then(res => {
                this.setState({ ServerNameList: res.data });
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    getProjectName = () => {

        try {
            api.get("/projectManagement/getProjectList").then(res => {
                this.setState({ ProjectNameList: res.data });
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    getPanelType = () => {

        let data = {
            StringMapType: "PANELTYPE"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                this.setState({ PanelTypeList: res.data });
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    onChange = (e, type) => {

        if (type === "DomainName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ DomainName: e.target.value, domainnameErr: true, domainnameHelperText: "Please enter Domain Name" });
            }
            else {
                this.setState({ DomainName: e.target.value, domainnameErr: false, domainnameHelperText: "" });
            }

        }

        else if (type === "UserName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ UserName: e.target.value, usernameErr: true, usernameHelperText: "Please enter User Name" });
            }
            else {
                this.setState({ UserName: e.target.value, usernameErr: false, usernameHelperText: "" });
            }

        }

        else if (type === "Password") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Password: e.target.value, passwordHelperText: "Please enter URL Password", passwordErr: true });
            }
            else {
                this.setState({ Password: e.target.value, passwordHelperText: "", passwordErr: false });
            }

        }

        else if (type === "Comment") {
            this.setState({ Comment: e.target.value });
        }
    }

    servernamelist = () => {
        return this.state.ServerNameList.map(content => {
            return (
                <MenuItem value={content.ServerConfigID} > {content.PanelName}  </MenuItem>
            )
        })
    }

    projectnamelist = () => {
        return this.state.ProjectNameList.map(content => {
            return (
                <MenuItem value={content.ProjectID} > {content.ProjectName}  </MenuItem>
            )
        })
    }

    paneltypelist = () => {
        return this.state.PanelTypeList.map(content => {
            return (
                <MenuItem value={content.PanelName} > {content.PanelName}  </MenuItem>
            )
        })
    }

    validate = () => {
        const { Server, Project, DomainName, PanelType, UserName, Password } = this.state;

        let IsValid = true;

        if (CommonConfig.isEmpty(DomainName)) {
            IsValid = false;
            this.setState({ domainnameHelperText: "Please enter Domain Name", domainnameErr: true });
        }

        if (CommonConfig.isEmpty(UserName)) {
            IsValid = false;
            this.setState({ usernameHelperText: "Please enter User Name", usernameErr: true });
        }

        if (CommonConfig.isEmpty(Password)) {
            IsValid = false;
            this.setState({ passwordHelperText: "Please enter Password", passwordErr: true });
        }

        if (CommonConfig.isEmpty(Server)) {
            IsValid = false;
            this.setState({ serverHelperText: "Please enter Server Name", serverErr: true });
        }

        if (CommonConfig.isEmpty(Project)) {
            IsValid = false;
            this.setState({ projectHelperText: "Please enter Project Name", projectErr: true });
        }

        if (CommonConfig.isEmpty(PanelType)) {
            IsValid = false;
            this.setState({ paneltypeHelperText: "Please enter Panel Type", paneltypeErr: true });
        }

        return IsValid;
    }

    submit = (e) => {

        const { DomainName, Comment, PanelType, Project, Server,
            UserName, Password, loggedUserID } = this.state;

        if (this.validate()) {

            let data = {
                Comment: Comment,
                DomainName: DomainName,
                UserName: UserName,
                Password: Password,
                UserID: loggedUserID,
                PanelType: PanelType,
                Project: Project,
                Server: Server,
                ProjectDomainConfigID: null
            }
            try {
                api.post("projectdomainconfig/addProjectDomainConfig", data).then(res => {
                    if (res.success) {
                        cogoToast.success("CPanel added Successfully");
                        this.props.history.push("/admin/projectdomainconfigListing");
                    }
                    else {
                        cogoToast.error("Something went wrong");
                    }
                }).catch(error => {
                });
            }

            catch (err) {
            }

        }
        else {
            cogoToast.error( 'Please correct error and resubmit the form')
        }

    }

    selectChange = (e, type) => {
        if (type === "Server") {
            this.setState({ Server: e.target.value, serverErr: false, serverHelperText: "" });
        }
        else if (type === "PanelType") {
            this.setState({ PanelType: e.target.value, paneltypeErr: false, paneltypeHelperText: "" });
        }
        else if (type === "Project") {
            this.setState({ Project: e.target.value, projectErr: false, projectHelperText: "" });
        }
    }

    showModal = () => {
        this.setState({ Accessvisible: true });
    };
    handleCancel = () => {
        this.setState({ Accessvisible: false });
    };
    handleOk = () => {
        this.setState({ Accessvisible: false });
    };

    render() {
        const { DomainName, domainnameErr, domainnameHelperText, Comment, UserName, usernameHelperText,
            usernameErr, Password, passwordHelperText, passwordErr, PanelType, paneltypeErr, paneltypeHelperText,
            Project, projectErr, projectHelperText, Server, serverErr, serverHelperText, Accessvisible } = this.state;
        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader className="btn-right-outer" color="primary" icon>
                            <CardIcon color="primary">
                                <HeadsetMic />
                            </CardIcon>
                            <h4 className="margin-right-auto text-color-black">Add Project Domain Configuration</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={3} sm={3} md={3}>
                                    <FormControl fullWidth className="input-select-outer" error={serverErr} helperText={serverHelperText}>
                                        <InputLabel>Server</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Server")} value={Server}>
                                            {this.servernamelist()}
                                        </Select>
                                        <FormHelperText>{serverHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3}>
                                    <FormControl fullWidth className="input-select-outer" error={projectErr} helperText={projectHelperText} >
                                        <InputLabel>Project</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Project")} value={Project}>
                                            {this.projectnamelist()}
                                        </Select>
                                        <FormHelperText>{projectHelperText}</FormHelperText>
                                    </FormControl>
                                      {/* <GridItem xs={2} sm={2} md={2}>
                                        <Autocomplete
                                            options={projectlist}
                                            id="URL1"
                                            value={Project}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, value) => this.typeChange(value, "Project")}
                                            renderInput={params => (
                                            <TextField {...params} error={ProjectErr} helperText={ProjectHelperText}
                                                label="URL1" />)}
                                        /> */}
                                    </GridItem>
                               
                                <GridItem xs={3} sm={3} md={3}>
                                    <FormControl fullWidth className="input-select-outer" error={paneltypeErr} helperText={paneltypeHelperText}>
                                        <InputLabel>Panel Type</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "PanelType")} value={PanelType}>
                                            {/* {this.paneltypelist()} */}
                                            <MenuItem value="test">test</MenuItem>
                                            <MenuItem value="cpanel">CPanel</MenuItem>
                                        </Select>
                                        <FormHelperText>{paneltypeHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={3}>
                                    <FormControl>
                                        <TextField
                                            value={DomainName}
                                            helperText={domainnameHelperText}
                                            error={domainnameErr}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Domain Name"
                                            onChange={(e) => this.onChange(e, "DomainName")}
                                        />
                                    </FormControl>
                                </GridItem>

                                <GridItem xs={1} sm={1} md={1}>
                                <Tooltip title="Credentials" placement="bottom">
                                    <Button className="btn-icon" color="primary" type="button" onClick={() => this.showModal()}>
                                        <LockIcon />
                                    </Button></Tooltip>
                                    <Dialog
                                        open={Accessvisible}
                                        title="Credentials"
                                        onClose={() => this.handleCancel()}
                                    >
                                        <DialogContent>
                                            <FormControl>
                                                <TextField
                                                    value={UserName}
                                                    helperText={usernameHelperText}
                                                    error={usernameErr}
                                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    label="User Name"
                                                    onChange={(e) => this.onChange(e, "UserName")}
                                                />
                                            </FormControl>

                                            <FormControl>
                                                <TextField
                                                    type="password"
                                                    value={Password}
                                                    helperText={passwordHelperText}
                                                    error={passwordErr}
                                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    label="Password"
                                                    onChange={(e) => this.onChange(e, "Password")}
                                                />
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                        <Tooltip title="Save" placement="bottom">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                onClick={() => this.handleOk()}
                                            >
                                                Save
                                            </Button></Tooltip>
                                            <Tooltip title="Back" placement="bottom">
                                            <Button key="back" onClick={() => this.handleCancel()}>
                                                Back
                                            </Button></Tooltip>
                                        </DialogActions>
                                    </Dialog>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={11} sm={11} md={11}>
                                    <FormControl>
                                        <label>Comment</label>
                                        <TextareaAutosize
                                            value={Comment}
                                            rowsMin={5}
                                            label="Comment"
                                            onChange={(e) => this.onChange(e, "Comment")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            <Tooltip title="Add" placement="bottom">
                                <Button color="primary" type="submit" className="login-form-button" onClick={(e) => this.submit(e)}>
                                    Add Project Domain Configuration
                      </Button></Tooltip>
                            </GridItem>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer >
        );
    }

}
export default addprojectDomainConfig;