import React, { Component } from "react";
// core components
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import cogoToast from 'cogo-toast';
import DoneIcon from "@material-ui/icons/Done";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns";
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import ImageUploader from "components/CustomUpload/PictureUpload";
import Wizard from "components/Wizard/Wizard";
import ActiveResource from "components/Resource/ActiveResource.js";
import Other from "components/Resource/Other.js";
import InactiveResource from "components/Resource/InactiveResource.js";

class ResourceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResourceList: [],
      ApproveAccess: false,
      IsSelectAll: false,
      LeaveBalance: 12,
      DailyStatusList: [],
      filterVal: "",
      Resoource: "",
      StartDate: "",
      EndDate: "",
      selectedDate: new Date(),
    };
  }

  render() {
    return (
      <Wizard
        steps={[
          {
            stepName: "Active Resource List",
            stepComponent: ActiveResource,
            stepId: "ActiveResource",
          },
          {
            stepName: "Inactive Resource List",
            stepComponent: InactiveResource,
            stepId: "InactiveResource",
          },
        ]}
        title=""
        color="primary"
        nextButtonVisible={false}
        // subtitle="This information will let us know more about you."
        finishButtonClick={(e) => this.submit(e)}
      />
    );
  }
}
export default ResourceList;
