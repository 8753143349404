import React, { Component } from 'react';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";
import cogoToast from 'cogo-toast';
import Button from "components/CustomButtons/Button.js";
import SimpleBackdrop from "../../utils/general";
const useStyles = makeStyles(styles);
class changePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NewPassword: "",
      ConfirmPassword: "",
      CurrentPassword: "",
      CurrentpasswordHelperText: "",
      CurrentpasswordErr: false,
      NewpasswordHelperText: "",
      NewpasswordErr: false,
      ConfirmpasswordHelperText: "",
      ConfirmpasswordErr: false,
      type: "password",
      icon: "fa-solid fa-eye-slash",
      NewPasswordtype: "password",
      NewPasswordicon: "fa-solid fa-eye-slash",
      ConfirmPasswordtype: "password",
      ConfirmPasswordicon: "fa-solid fa-eye-slash",
    };

  }

  show = () => {
    this.state.type === "password" ? this.setState({ type: "text" }) : this.setState({ type: "password" });
    this.state.icon === "fa-solid fa-eye"
      ? this.setState({ icon: "fa-solid fa-eye-slash" })
      : this.setState({ icon: "fa-solid fa-eye" });
  };

  NewPasswordshow = () => {
    this.state.NewPasswordtype === "password" ? this.setState({ NewPasswordtype: "text" }) : this.setState({ NewPasswordtype: "password" });
    this.state.NewPasswordicon === "fa-solid fa-eye"
      ? this.setState({ NewPasswordicon: "fa-solid fa-eye-slash" })
      : this.setState({ NewPasswordicon: "fa-solid fa-eye" });
  };

  ConfirmPasswordshow = () => {
    this.state.ConfirmPasswordtype === "password" ? this.setState({ ConfirmPasswordtype: "text" }) : this.setState({ ConfirmPasswordtype: "password" });
    this.state.ConfirmPasswordicon === "fa-solid fa-eye"
      ? this.setState({ ConfirmPasswordicon: "fa-solid fa-eye-slash" })
      : this.setState({ ConfirmPasswordicon: "fa-solid fa-eye" });
  };


  validate = () => {
    let IsValid = true;

    if (CommonConfig.isEmpty(this.state.CurrentPassword)) {
      IsValid = false;
      this.setState({ CurrentpasswordHelperText: "Please enter Current Password", CurrentpasswordErr: true });
    }

    if (CommonConfig.isEmpty(this.state.NewPassword)) {
      IsValid = false;
      this.setState({ NewpasswordHelperText: "Please enter New Password", NewpasswordErr: true });
    }
    if (CommonConfig.isEmpty(this.state.ConfirmPassword)) {
      IsValid = false;

      this.setState({ ConfirmpasswordHelperText: "Please enter Confirm Password", ConfirmpasswordErr: true });
    }
    if (this.state.NewPassword !== this.state.ConfirmPassword) {
      IsValid = false;
      this.setState({ ConfirmpasswordErr: true, ConfirmpasswordHelperText: "Password and Confirm Password does not match" });
    }
    return IsValid;

  }
  onChange = (e, type) => {
    if (type === "CurrentPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ CurrentPassword: e.target.value, CurrentpasswordErr: true, CurrentpasswordHelperText: "Please enter Current PassWord" });
      }
      else {
        this.setState({ CurrentPassword: e.target.value, CurrentpasswordErr: false, CurrentpasswordHelperText: "" });
      }
    }
    else if (type === "NewPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ NewPassword: e.target.value, NewpasswordErr: true, NewpasswordHelperText: "Please enter New PassWord" });
      }
      else {
        this.setState({ NewPassword: e.target.value, NewpasswordErr: false, NewpasswordHelperText: "" });
      }

    }
    else if (type === "ConfirmPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ ConfirmPassword: e.target.value, ConfirmpasswordErr: true, ConfirmpasswordHelperText: "Please enter New PassWord" });
      }
      else {
        this.setState({ ConfirmPassword: e.target.value, ConfirmpasswordErr: false, ConfirmpasswordHelperText: "" });
      }

    }
  }

  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }
  resetpassword = () => {
    const isValid = this.validate();
    if (isValid) {
      let data = {
        CurrentPassword: this.state.CurrentPassword,
        Password: this.state.NewPassword,
        PersonID: CommonConfig.loginData().PersonID,
      }
      this.showLoader();
      try {
        api.post("login/resetPassword", data).then(res => {
          this.hideLoader();
          if (res.success) {
            localStorage.clear();
            this.props.history.push("/auth/login-page");
          }
          else {
            cogoToast.error(res.message);
          }
        }).catch(err => {
        });
      }
      catch (error) {
      }
    }

  }
  render() {
    const { UserName, CurrentPassword, NewPassword, ConfirmPassword, usernameErr, usernameHelperText,
      CurrentpasswordHelperText, CurrentpasswordErr, ConfirmpasswordErr, ConfirmpasswordHelperText, NewpasswordErr, NewpasswordHelperText } = this.state;
    return (

      <div className="login-outer">
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null
        }
        <div className="login-box">
          <div className="login-welcome">
            <p>RESET PASSWORD</p>
          </div>

          <div className="login-input">
            <FormControl fullWidth>
              <TextField
                type={this.state.type}
                label="Current Password"
                helperText={CurrentpasswordHelperText}
                error={CurrentpasswordErr}
                value={CurrentPassword}
                onChange={(e) => this.onChange(e, "CurrentPassword")}
              />
              <i
                onClick={() => this.show()} class={this.state.icon}
              >
              </i>

            </FormControl>
          </div>
          <div className="login-input">
            <FormControl fullWidth>
              <TextField
                type={this.state.NewPasswordtype}
                label="New Password"
                helperText={NewpasswordHelperText}
                error={NewpasswordErr}
                value={NewPassword}
                onChange={(e) => this.onChange(e, "NewPassword")}
              />
              <i
                onClick={() => this.NewPasswordshow()} class={this.state.NewPasswordicon}
              >
              </i>
            </FormControl>
          </div>
          <div className="login-input">
            <FormControl fullWidth>
              <TextField
                type={this.state.ConfirmPasswordtype}
                label="Confirm Password"
                helperText={ConfirmpasswordHelperText}
                error={ConfirmpasswordErr}
                value={ConfirmPassword}
                onChange={(e) => this.onChange(e, "ConfirmPassword")}
              />
              <i
                onClick={() => this.ConfirmPasswordshow()} class={this.state.ConfirmPasswordicon}
              >
              </i>
            </FormControl>
          </div>
          <div className="Reset-submit">
            <Tooltip title="Submit" placement="bottom">
              <Button color="primary" onClick={() => this.resetpassword()}>RESET PASSWORD</Button>
            </Tooltip>
          </div>
        </div>
      </div >

    )
  }
}

export default changePassword;