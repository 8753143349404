import React, { Component } from 'react';
import cogoToast from 'cogo-toast';
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import InputAdornment from '@material-ui/core/InputAdornment';
import ImageUploader from 'components/CustomUpload/PictureUpload';
import PropTypes from "prop-types";
import Tooltip from '@material-ui/core/Tooltip';
import defaultImage from "assets/img/default-avatar.png";
import Button from "components/CustomButtons/Button.js";
import { apiBase } from "../../utils/config";
// @material-ui/icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preImgUrl:"",
      firstname: "",
      firstnameState: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: "",

      UserImg: "",
      UserImg1: "",
      UserImgHelpertext: "",
      UserImgErr: true,

      Title: "",
      TitleErr: false,
      TitleHelpertext: "",

      FirstName: "",
      FirstNameErr: false,
      FirstNameHelpertext: "",

      MiddleName: "",
      MiddleNameErr: false,
      MiddleNameHypertext: "",

      LastName: "",
      LastNameErr: false,
      LastNameHelpertext: "",

      Designation: "",
      DesignationErr: false,
      DesignationHelpertext: "",

      AddressType: "",
      AddressTypeErr: false,
      AddressTypeHelpertext: "",

      Line1: "",
      Line2: "",
      // Line3: "",

      ResourceCode: "",
      ResourceCodeErr: false,
      ResourceCodeHelpertext: "",

      PAN: "",
      PANErr: false,
      PANHelpertext: "",

      Passport: "",
      PassportErr: false,
      PassportHelpertext: "",


      ContactNumber: '',
      ContactNumberErr: false,
      ContactNumberHelperText: '',

      AdharNumber: "",
      AdharNumberErr: false,
      AdharNumberHelpertext: "",

      Gender: "",
      GenderErr: false,
      GenderHelpertext: "",

      Country: "",
      countryErr: false,
      countryHelperText: "",

      AppraisalDate: "",
      AppraisalDateErr: false,
      AppraisalDateHelperText: '',

      YearlyLeave: '',
      YearlyLeaveErr: false,
      YearlyLeaveHelperText: '',

      Email: "",
      EmailErr: false,
      EmailHelperText: "",

      ComputerName: '',
      ComputerNameErr: false,
      ComputerNameHelperText: '',

      IPAddress: '',
      IPAddressErr: false,
      IPAddressHelperText: '',

      CubiclePosition: '',
      cubiclepositionErr: false,
      cubiclepositionHelperText: '',

      Salt: '',
      SaltErr: false,
      SaltHelperText: '',

      BankName: '',
      BankNameErr: false,
      BankNameHelperText: '',

      AccountNo: '',
      AccountNoErr: false,
      AccountNoHelperText: '',

      IFSCCode: '',
      IFSCCodeErr: false,
      IFSCCodeHelperText: '',

      password: "",
      passwordErr: false,
      passwordHelperText: "",

      ConfirmPassword: "",
      ConfirmpasswordErr: false,
      ConfirmpasswordHelperText: "",

      BasicSalary: "",
      BasicSalaryErr: false,
      BasicSalaryHelpertext: "",

      ProfessionalTaxErr: false,
      ProfessionalTaxHelperText: '',
      ProfessionalTax: '',

      JoiningDate: "",
      JoiningDateErr: false,
      JoiningDateHelperText: '',

      GenderList: [],
      // DepartmentList: [],
      DesignationList: [],
      SuffixList: [],
      AddressList: [],
      TiltleList: [],
      DOB: "",
      Department: "",
      Country: "",
      DepartmentList: [],
      imagePreviewUrl: defaultImage,
      file: [],
      // DesignationList: [],
      CountryList: [],
      StateList: [],
      pictures: [],
      cubiclepositionList: [],
      viewBankList: [],
      navBarProfile: this.props?.location?.state?.navBarProfile,
      editProfile:  this.props.location ? this.props.location.state.editProfile : this.props.editprops.editProfile
    };
  }

  componentDidMount() {
    this.getGenderList();
    this.getSuffixList();
    this.getDepartmentList();
    this.getDesignationList();
    this.getCountryList();
    this.getTiltleList();
    this.getAddressList();
    this.getEditData();
  }

  getEditData() {
    let data = {
      ResourceID: this.props.location ? this.props.location.state.editid : this.props.editprops.editid
    };
    if (data.ResourceID !== "") {
      try {
        api.post("resource/getResourceByID", data).then(res => {

          if (res.success) {

            if (res.data.BankDetail != null && res.data.BankDetail.length > 0) {
              var i = 0;
              res.data.BankDetail.map(Obj => {
                Obj.Index = i;
                i++;
                return Obj;
              })
              this.setState({ viewBankList: res.data.BankDetail });
            }

            let departmentVal = {
              value: res.data.Department,
              label: res.data.Department
            }

            this.setState({
             
              UserImg1: res.data.UserImg ? res.data.UserImg : '',
              FirstName: res.data.FirstName ? res.data.FirstName : '',
              MiddleName: res.data.MiddleName ? res.data.MiddleName : '',
              LastName: res.data.LastName ? res.data.LastName : '',
              Title: res.data.Title ? res.data.Title : '',
              Suffix: res.data.Suffix ? res.data.Suffix : '',
              Designation: res.data.Designation ? res.data.Designation : '',
              AddressType: res.data.AddressType ? res.data.AddressType : '',
              ResourceCode: res.data.ResourceCode ? res.data.ResourceCode : '',
              Line1: res.data.Line1 ? res.data.Line1 : '',
              Line2: res.data.Line2 ? res.data.Line2 : '',
              // Line3: res.data.Line3 ? res.data.Line3 : '',
              PAN: res.data.PAN ? res.data.PAN : '',
              Passport: res.data.Passport ? res.data.Passport : '',
              ContactNumber: res.data.ContactNumber ? res.data.ContactNumber : '',
              AdharNumber: res.data.AadharNumber ? res.data.AadharNumber : '',
              Gender: res.data.Gender ? res.data.Gender : '',
              DOB: res.data.DOB ? moment(res.data.DOB).format("DD/MM/YYYY") : '',
              Email: res.data.Email ? res.data.Email : '',
              CountryID: res.data.CountryID ? res.data.CountryID : '',
              Password: res.data.Password ? res.data.Password : '',
              Department: departmentVal ? departmentVal : '',
              ResourceID: res.data.ResourceID ? res.data.ResourceID : '',
              JoiningDate: res.data.JoiningDate ? moment(res.data.JoiningDate).format("DD/MM/YYYY") : '',
              AppraisalDate: res.data.AppraisalDate ? moment(res.data.AppraisalDate).format("DD/MM/YYYY") : '',
              BasicSalary: res.data.BasicSalary ? res.data.BasicSalary : '',
              ProfessionalTax: res.data.ProfessionalTax ? res.data.ProfessionalTax : '',
              YearlyLeave: res.data.LeaveBalance ? res.data.LeaveBalance : '',
              ComputerName: res.data.ComputerName ? res.data.ComputerName : '',
              Salt: res.data.Salt ? res.data.Salt : '',
              CubiclePosition: res.data.CubiclePosition ? res.data.CubiclePosition : '',
              IPAddress: res.data.IPAddress ? res.data.IPAddress : '',
            });
            if (res.data.CountryID) {
              try {
                api.get("/country/getCountry").then(country => {
                  if (country.success) {
                    this.setState({
                      CountryList: country.data, Country: {
                        value: country.data.filter(x => x.CountryID == res.data.CountryID)[0].CountryID,
                        label: country.data.filter(x => x.CountryID == res.data.CountryID)[0].CountryName
                      }
                    });
                  }
                  else {
                    cogoToast.error("Something went wrong");
                  }
                }).catch(err => {
                  console.log(err)
                })
              } catch (err) {
              }
            }
            // if(res.data.CountryID){
            //   try {
            //     debugger;
            //     api.get("/country/getState").then(State => {
            //       if (State.success) {
            //         this.setState({ StateList: State.data,State:{
            //           value:State.data.filter(x=>x.StateID == res.data.StateID)[0].StateID,
            //           label : State.data.filter(x=>x.StateID == res.data.StateID)[0].StateName
            //         } });
            //       }
            //       else {
            //         cogoToast.error("Something went wrong");
            //       }
            //     }).catch(err => {
            //       console.log(err)
            //     })
            //   } catch (err) {
            //   }
            // }
          } else {
            cogoToast.error( 'Something went wrong')
          }

        }).catch(error => {
        })

      }
      catch (err) {
      }
    }
    else {

    }

  }

  getCountryList = () => {
    try {
      api.get("/country/getCountry").then(res => {
        if (res.success) {
          this.setState({ CountryList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getDepartmentList = () => {
    let data = {
      StringMapType: "DEPARTMENT"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ DepartmentList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getDesignationList = () => {
    let data = {
      StringMapType: "DESIGNATION"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ DesignationList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getGenderList = () => {
    let data = {
      StringMapType: "GENDER"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ GenderList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getTiltleList = () => {
    let data = {
      StringMapType: "TITLE"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ TiltleList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getSuffixList = () => {
    let data = {
      StringMapType: "SUFFIX"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ SuffixList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  getAddressList = () => {
    let data = {
      StringMapType: "ADDRESSTYPE"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ AddressList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }

  handleDate = (date, type) => {
    if (type === "DOB") {
      this.setState({
        DOB: date,
        DateofBirthErr: false,
        DateofBirthHelpertext: "",
      });
    }

  }

  validate = () => {
    const {
      Title, FirstName, LastName, Gender, Designation, DOB, Email, Password, ResourceCode, ConfirmPassword, ContactNumberHelperText, ContactNumber, ContactNumberErr, AdharNumber, PAN, AddressType, BasicSalary, Line1, Department, Country, State } = this.state;

    let IsValid = true;

    if (CommonConfig.isEmpty(Title)) {
      // alert(2)
      IsValid = false;
      this.setState({
        TitleHelpertext: "Please enter Title",
        TitleErr: true,
      });
    }
    if (CommonConfig.isEmpty(FirstName)) {
      // alert(2)
      IsValid = false;
      this.setState({
        FirstNameHelpertext: "Please enter First Name",
        FirstNameErr: true,
      });
    }

    if (CommonConfig.isEmpty(LastName)) {
      // alert(2)
      IsValid = false;
      this.setState({
        LastNameHelpertext: "Please enter Last Name",
        LastNameErr: true,
      });
    }
    if (CommonConfig.isEmpty(Gender)) {
      IsValid = false;
      this.setState({
        GenderHelpertext: "Please enter Gender",
        GenderErr: true,
      });
    }
    if (CommonConfig.isEmpty(Designation)) {
      IsValid = false;
      this.setState({
        DesignationHelpertext: "Please enter Designation",
        DesignationErr: true,
      });
    }
    if (CommonConfig.isEmpty(DOB)) {
      IsValid = false;
      this.setState({
        DateofBirthHelpertext: "Please enter Date of Birth",
        DateofBirthErr: true,
      });
    }
    if (CommonConfig.isEmpty(Email)) {
      IsValid = false;
      this.setState({
        EmailHelperText: "Please enter Email",
        EmailErr: true,
      });
    }
    if (!this.state.editProfile && !this.props.location) {
      if (CommonConfig.isEmpty(Password)) {
        IsValid = false
        this.setState({
          passwordErr: true,
          passwordHelperText: "Please enter Password",
          passwordErr: true,
        });
      }
      if (CommonConfig.isEmpty(ConfirmPassword)) {
        IsValid = false
        this.setState({
          confirmpasswordHelperText: "Please enter Confirm Password",
          confirmpasswordErr: true,
        });
      } else {
        if (ConfirmPassword !== Password) {
          IsValid = false
          this.setState({
            confirmpasswordErr: true,
            confirmpasswordHelperText: "password and confirm password do not match"
          });
        }
      }
    }
    if (CommonConfig.isEmpty(BasicSalary)) {
      IsValid = false
      this.setState({
        BasicSalaryHelpertext: "Please enter Basic Salary",
        BasicSalaryErr: true,
      });
    }
    if (CommonConfig.isEmpty(ContactNumber)) {
      IsValid = false
      this.setState({
        ContactNumberHelpertext: "Please enter Contact Number",
        ContactNumberErr: true,
      });
    }
    else if (ContactNumber.length !== 10) {
      IsValid = false
      this.setState({
        ContactNumberHelpertext: "Contact Number should be 10 Digit",
        ContactNumberErr: true,
      });
    }
    if (CommonConfig.isEmpty(AdharNumber)) {
      IsValid = false
      this.setState({
        AdharNumberHelpertext: "Please enter Aadhar Number",
        AdharNumberErr: true,
      });
    }
    if (!CommonConfig.isEmpty(AdharNumber) && AdharNumber.length != 12) {
      IsValid = false
      this.setState({
        AdharNumberHelpertext: "Aadhar should be 12 Digit",
        AdharNumberErr: true,
      });
    }
    if (CommonConfig.isEmpty(PAN)) {
      IsValid = false
      this.setState({
        PANHelpertext: "Please enter PAN Number",
        PANErr: true,
      });
    }
    if (!CommonConfig.isEmpty(PAN) && PAN.length != 10) {
      IsValid = false
      this.setState({
        PANHelpertext: "PAN should be 10 Digit",
        PANErr: true,
      });
    }
    if (CommonConfig.isEmpty(AddressType)) {
      IsValid = false;
      this.setState({
        AddressTypeHelpertext: "Please enter Address Type",
        AddressTypeErr: true,
      });
    }
    if (CommonConfig.isEmpty(ResourceCode)) {
      IsValid = false;
      this.setState({
        ResourceCodeHelpertext: "Please enter Employee Code",
        ResourceCodeErr: true,
      });
    }
    if (CommonConfig.isEmpty(Line1)) {
      IsValid = false;
      this.setState({
        Line1Helpertext: "Please enter Line-1",
        Line1Err: true,
      });
    }
    if (CommonConfig.isEmpty(Department)) {
      IsValid = false;
      this.setState({
        DepartmentHelpertext: "Please enter Department",
        DepartmentErr: true,
      });
    }
    if (CommonConfig.isEmpty(Country)) {
      IsValid = false;
      this.setState({ countryErr: true, countryHelperText: "Please select Country" });
    }
    return IsValid;

  };
  submit = (e) => {
    const {
      UserImg, UserImg1, Title, FirstName, MiddleName, LastName, Designation, AddressType, ResourceCode, ContactNumber, AdharNumber, PAN, Passport, Gender, Line1, Line2, Line3, DOB,
      Email, Password, Department, Country, State, ProfessionalTax, IPAddress, JoiningDate, BasicSalary, Salt, Suffix, ComputerName, CubiclePosition, YearlyLeave
    } = this.state;
  debugger;
    if (this.state.navBarProfile) {
      // Perform API call without validation
      let formData = new FormData();
      formData.append("UserImg", UserImg ? UserImg : UserImg1 );

      let data = {
         ResourceID:  CommonConfig.loginData().PersonID,
          UserID: CommonConfig.loginData().PersonID,
          FirstName: FirstName,
          MiddleName: MiddleName,
          LastName: LastName,
          Title: Title,
          Suffix: Suffix,
          Designation: Designation,
          AddressType: AddressType,
          ResourceCode: ResourceCode,
          ContactNumber: ContactNumber,
          Line1: Line1,
          Line2: Line2,
          Line3: Line3,
          PAN: PAN,
          Passport: Passport,
          AdharNumber: AdharNumber,
          Gender: Gender,
          DOB:
            CommonConfig.isEmpty(DOB) != true
              ? moment(DOB).format("YYYY-MM-DD HH:mm:ss").toString()
              : null,
          Email: Email,
          Password: Password,
          Department: Department ? Department.value : '',
          CountryID: Country.value,
          JoiningDate: moment(JoiningDate).format("YYYY-MM-DD HH:mm:ss").toString(),
          BasicSalary: BasicSalary,
          ProfessionalTax: this.state.ProfessionalTax,
          YearlyLeave: YearlyLeave,
          ComputerName: ComputerName,
          Salt: Salt,
          CubiclePosition: CubiclePosition,
          IPAddress: IPAddress,
          BankDetail: this.state.viewBankList.filter(x => x.Status === 'Active')
        };
      
      try {
        formData.append('data', JSON.stringify(data));
        api
          .post('resource/addResourceBasic', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (res.success) {
              if (this.state.navBarProfile) {
              cogoToast.success('Profile picture updated successfully');
              this.props.history.push("/admin/dashboard");
              } else {
                cogoToast.success('Resource updated successfully');
              }
            } else {
              cogoToast.error('Something went wrong');
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (err) {
        console.log('error', err);
      }
    }
   else {
      // Perform validation and then API call
      if (this.validate()) {
        let data = {
          UserID: CommonConfig.loginData().PersonID,
          UserImg: UserImg,
          FirstName: FirstName,
          MiddleName: MiddleName,
          LastName: LastName,
          Title: Title,
          Suffix: Suffix,
          Designation: Designation,
          AddressType: AddressType,
          ResourceCode: ResourceCode,
          ContactNumber: ContactNumber,
          Line1: Line1,
          Line2: Line2,
          Line3: Line3,
          PAN: PAN,
          Passport: Passport,
          AdharNumber: AdharNumber,
          Gender: Gender,
          DOB:
            CommonConfig.isEmpty(DOB) != true
              ? moment(DOB).format("YYYY-MM-DD HH:mm:ss").toString()
              : null,
          Email: Email,
          Password: Password,
          Department: Department ? Department.value : '',
          CountryID: Country.value,
          ResourceID: 0,
          JoiningDate: moment(JoiningDate).format("YYYY-MM-DD HH:mm:ss").toString(),
          BasicSalary: BasicSalary,
          ProfessionalTax: this.state.ProfessionalTax,
          YearlyLeave: YearlyLeave,
          ComputerName: ComputerName,
          Salt: Salt,
          CubiclePosition: CubiclePosition,
          IPAddress: IPAddress,
          BankDetail: this.state.viewBankList.filter(x => x.Status === 'Active')
        };

        try {
          api
            .post("resource/addResourceBasic", data)
            .then((res) => {
              if (res.success) {
                cogoToast.success("Resource added successfully");
                this.props.history.push("/admin/resourceListing");
              } else {
                cogoToast.error("Something went wrong");
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        } catch (err) {
          console.log("error", err);
        }
      }
    }
  };

  selectChange = (e, type) => {
    if (type === "Gender") {
      this.setState({
        Gender: e.target.value,
        GenderHelpertext: "",
        GenderErr: false,

      });
    }
    if (type === "Department") {
      this.setState({
        Department: e.target.value,
        DepartmentHelpertext: "",
        DepartmentErr: false,

      });
    }
    if (type === "Designation") {
      this.setState({
        Designation: e.target.value,
        DesignationHelpertext: "",
        DesignationErr: false,
      });
    }
    if (type === "AddressType") {
      this.setState({
        AddressType: e.target.value,
        AddressTypeHelpertext: "",
        AddressTypeErr: false,
      });
    }
    if (type === "Title") {
      this.setState({
        Title: e.target.value,
        TitleHelpertext: "",
        TitleErr: false,
      });
    }
    if (type === "Suffix") {
      this.setState({
        Suffix: e.target.value,
        SuffixListHelpertext: "",
        SuffixListErr: false,
      });
    }

  };
  designationlist = () => {
    return this.state.DesignationList.map((content, index) => {
      return (
        <MenuItem value={content.StringMapKey} key={`designation-${index.toString()}`}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  departmentlist = () => {
    return this.state.DepartmentList.map((content, index) => {
      return (
        <MenuItem value={content.StringMapKey} key={`department-${index.toString()}`}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  genderlist = () => {
    return this.state.GenderList.map((content, index) => {
      return (
        <MenuItem value={content.StringMapKey} key={`gender-${index.toString()}`}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  suffixlist = () => {
    return this.state.SuffixList.map((content, index) => {
      return (
        <MenuItem value={content.StringMapKey} key={`suffix-${index.toString()}`}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  titlelist = () => {
    return this.state.TiltleList.map((content, index) => {
      return (
        <MenuItem value={content.StringMapKey} key={`titles-${index.toString()}`}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  addresslist = () => {
    return this.state.AddressList.map((content, index) => {
      return (
        <MenuItem value={content.StringMapKey} key={`addresses-${index.toString()}`}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  departmentlist = () => {
    return this.state.DepartmentList.map((content, index) => {
      return (
        <MenuItem value={content.StringMapKey} key={`departments-${index.toString()}`}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  requestChange = (e, value, type) => {
    if (type === "country") {
      this.setState({ Country: value, countryErr: false, countryHelperText: "" });
    }
    if (type === "state") {
      this.setState({ State: value, stateErr: false, stateHelperText: "" });
    }
    if (type === "Department") {
      this.setState({ Department: value, DepartmentErr: false, DepartmentHelpertext: "" });
    }
  }
  addCancel = () => {
    this.props.history.push("/admin/resourceListing");
  };
  getProfessionalTax = () => {
    let data = {
      BasicSalary: this.state.BasicSalary
    };
    try {
      api
        .post("resource/getProfessionalTax", data)
        .then((res) => {
          if (res.success) {
            this.setState({ ProfessionalTax: res.data[0].ProfessionalTax })
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (err) {
      console.log("error", err);
    }

  };
  onChange = (e, type) => {
    const { editProfile } = this.state;
    if (type === "Title") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Title: e.target.value,
          TitleErr: true,
          TitleHelpertext: "Please Enter Title",
        });
      } else {
        this.setState({
          Title: e.target.value,
          TitleErr: false,
          TitleHelpertext: "",
        });
      }
    }
    if (type === "FirstName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          FirstName: e.target.value,
          FirstNameErr: true,
          FirstNameHelpertext: "Please Enter FirstName",
        });
      } else {
        this.setState({
          FirstName: e.target.value,
          FirstNameErr: false,
          FirstNameHelpertext: "",
        });
      }
    }
    if (type === "MiddleName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          MiddleName: e.target.value,
        });
      } else {
        this.setState({
          MiddleName: e.target.value,
          MiddleNameErr: false,
          MiddleNameHelpertext: "",
        });
      }
    }
    if (type === "ResourceCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          ResourceCode: e.target.value,
        });
      } else {
        this.setState({
          ResourceCode: e.target.value, ResourceCodeErr: false, ResourceCodeHelpertext: "",
        });
      }
    }
    if (type === "PAN") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PAN: e.target.value,
        });
      } else {
        this.setState({
          PAN: e.target.value, PANErr: false, PANHelpertext: "",
        });
      }
    }
    if (type === "ContactNumber") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          ContactNumber: e.target.value,
        });
      } else {
        this.setState({
          ContactNumber: e.target.value, ContactNumberErr: false, ContactNumberHelpertext: "",
        });
      }
    }
    if (type === "AdharNumber") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          AdharNumber: e.target.value,
        });
      } else {
        this.setState({
          AdharNumber: e.target.value, AdharNumberErr: false, AdharNumberHelpertext: "",
        });
      }
    }
    if (type === "Passport") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Passport: e.target.value,
        });
      } else {
        this.setState({
          Passport: e.target.value,
          PassportErr: false,
          PassportHelpertext: "",
        });
      }
    }
    if (type === "BasicSalary") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          BasicSalary: e.target.value,
        });
      } else {
        this.setState({
          BasicSalary: e.target.value, BasicSalaryErr: false, BasicSalaryHelpertext: "",
        });
      }
      setTimeout(() => {
        this.getProfessionalTax();
      }, 600);
    }
    if (type === "LastName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          LastName: e.target.value,
          LastNameErr: true,
          LastNameHelpertext: "Please Enter LastName",
        });
      } else {
        this.setState({
          LastName: e.target.value,
          LastNameErr: false,
          LastNameHelpertext: "",
        });
      }
    }
    if (type === "Line1") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Line1: e.target.value,
          Line1Err: true,
          Line1Helpertext: "Please Enter Line1",
        });
      } else {
        this.setState({
          Line1: e.target.value,
          Line1Err: false,
          Line1Helpertext: "",
        });
      }
    }
    if (type === "Line2") {
      this.setState({
        Line2: e.target.value,
      });
    }
    if (type === "Email") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Email: e.target.value,
          EmailErr: true,
          EmailHelperText: "Please enter Email",
        });
      } else {

        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
        const result = pattern.test(e.target.value);
        if (result === true) {
          this.setState({
            Email: e.target.value,
            EmailErr: false,
            EmailHelperText: "",
          });
        } else {
          this.setState({
            Email: e.target.value,
            EmailErr: true,
            EmailHelperText: "Please enter valid Email",
          });
        }
      }
    }
    if (!this.state.editProfile) {
    if (type === "Password") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Password: e.target.value,
          passwordErr: true,
          passwordHelperText: "Please Enter Password",
        });
      } else {
        this.setState({
          Password: e.target.value,
          passwordErr: false,
          passwordHelperText: "",
        });
      }
    }
    }
    if (type === "ConfirmPassword") {
      if (editProfile) {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          ConfirmPassword: e.target.value,
          confirmpasswordErr: true,
          confirmpasswordHelperText: "Please enter confirm password",
        })
      }

      if (!CommonConfig.isEmpty(e.target.value)) {
        let { Password } = this.state;
        this.setState({
          ConfirmPassword: e.target.value
        });
        if (e.target.value === Password) {

          this.setState({
            confirmpasswordErr: false,
            confirmpasswordHelperText: "",
          });
        } else {

          this.setState({
            confirmpasswordHelperText: "confirm password should match with password",
            confirmpasswordErr: true
          });
        }
      }
    }
  }
  };

  onPasswordBlur = (e, type) => {
    const { editProfile } = this.state;
    if (!this.state.editProfile) {
    if (type === "Password") {
      let passwordVal = e.target.value;
      if (!CommonConfig.isEmpty(passwordVal)) {
        this.setState({ Password: passwordVal, passwordErr: false, passwordHelperText: "" });
      }
      else {
        this.setState({ Password: passwordVal, passwordErr: true, passwordHelperText: "Please enter password" });
      }
      if (passwordVal.match(CommonConfig.RegExp.regExpNumber)) {
        this.setState({ Password: passwordVal, passwordErr: false, passwordHelperText: "" });
      }
      else {
        this.setState({ Password: passwordVal, passwordErr: true, passwordHelperText: "Please enter valid password" });
      }
      if (passwordVal.match(CommonConfig.RegExp.regExpUpperCase)) {
        this.setState({ Password: passwordVal, passwordErr: false, passwordHelperText: "" });
      }
      else {
        this.setState({ Password: passwordVal, passwordErr: true, passwordHelperText: "Please enter valid password" });
      }
      if (passwordVal.match(CommonConfig.RegExp.regExpLowerCase)) {
        this.setState({ Password: passwordVal, passwordErr: false, passwordHelperText: "" });
      }
      else {
        this.setState({ Password: passwordVal, passwordErr: true, passwordHelperText: "Please enter valid password" });
      }
      if (passwordVal.length >= 8) {
        this.setState({ Password: passwordVal, passwordErr: false, passwordHelperText: "" });
      }
      else {
        this.setState({ Password: passwordVal, passwordErr: true, passwordHelperText: "Please enter valid password" });
      }
      if (passwordVal.match(CommonConfig.RegExp.regExpSpecialCharacter)) {
        this.setState({ Password: passwordVal, passwordErr: false, passwordHelperText: "" });
      }
      else {
        this.setState({ Password: passwordVal, passwordErr: true, passwordHelperText: "Please enter valid password" });
      }
    }
  }
  }

  sendState() {
    return this.state;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  isValidated() {
    return this.validate();
  }

handleImageChange = (e) => {
  e.preventDefault();
  let reader = new FileReader();
  let newFile = e.target.files[0];
  let UserImg1 = this.state.UserImg1
  this.setState({
    preImgUrl: URL.createObjectURL(e.target.files[0]),
    UserImg: newFile ? newFile : UserImg1
    })
  reader.onloadend = () => {
    this.setState({ file: newFile, imagePreviewUrl: reader.result,
    })
  };

  if (newFile) {
    reader.readAsDataURL(newFile);
  }
};
  validDOB(current) {
    return current.day() !== 0 && current.isBefore(moment().subtract(18, 'years'));
  }


  render() {
    const { classes } = this.props;
    const countryList = this.state.CountryList.map(country => { return { value: country.CountryID, label: country.CountryName } })
    const departmentlist = this.state.DepartmentList.map(department => { return { value: department.StringMapKey, label: department.StringMapName } })
    const stateList = this.state.StateList.map(State => { return { value: State.CountryID, label: State.StateName } })

    const { UserImg, UserImg1, Suffix, Title, TitleErr, TitleHelpertext, FirstName, MiddleName, LastName, Designation, AddressType, ResourceCode, PAN, Passport, Gender, Line1,
      Line2, Line3, DOB, ContactNumber, ContactNumberHelpertext, ContactNumberErr, AdharNumber, Email, YearlyLeave, Password, ConfirmPassword, CountryHelpertext, Department, Country,
      BasicSalary, BasicSalaryErr, BasicSalaryHelpertext, cubiclepositionList, CubiclePosition, IPAddress, Salt, ComputerName,
      ProfessionalTaxErr, ProfessionalTax, ProfessionalTaxHelperText, IFSCCode, IFSCCodeErr, AccountNoErr, BankNameErr, AccountNoHelperText, BankNameHelperText, IFSCCodeHelperText, LastNameErr, LastNameHelpertext, MiddleNameHelpertext, MiddleNameErr, FirstNameHelpertext,
      FirstNameErr, SuffixListHelpertext, ResourceCodeErr, SuffixListErr, GenderHelpertext, GenderErr, DesignationHelpertext, ResourceCodesErr,
      AdharNumberHelpertext, AdharNumberErr, PANHelpertext, PANErr, DateofBirthHelpertext, DateofBirthErr, ComputerNameErr, ComputerNameHelperText,
      IPAddressErr, IPAddressHelperText, SaltErr, SaltHelperText, ResourceCodeHelpertext, cubiclepositionErr, cubiclepositionHelperText, AddressTypeHelpertext,
      AddressTypeErr, Line1Helpertext, Line1Err, DepartmentHelpertext, DesignationErr, EmailErr, EmailHelperText, YearlyLeaveErr,
      YearlyLeaveHelperText, State, stateErr, stateHelperText, passwordErr, passwordHelperText, DepartmentErr, countryErr, countryHelperText, confirmpasswordErr, confirmpasswordHelperText, } = this.state;
    return (
      <GridContainer className="UserList-outer">
        <GridItem xs={12} sm={12} md={12}>

          <div className="picture-container">
             <div className="picture">
               {this.state.UserImg1 ? (
                 <img src={this.state.preImgUrl?this.state.preImgUrl:apiBase + UserImg1} className="picture-src" alt="Default" />
               ) : (
                 <img src={this.state.imagePreviewUrl} className="picture-src" alt="..." />
               )}
           
               <input
                 type="file"
                 onChange={e => this.handleImageChange(e)}
                 withIcon={true}
                 defaultValue={UserImg}
                 maxFileSize={5242880}
                 imgExtension={[".jpg", ".gif", ".png", ".gif"]}
               />
             </div>
             <h6 className="description">Choose Picture</h6>
           </div>
          <GridContainer>
            <GridItem xs={6}>
              <FormControl fullWidth error={TitleErr}>
                <InputLabel>Title *</InputLabel>
                <Select
                  onChange={(e) => this.selectChange(e, "Title")}
                  value={Title}
                  disabled={this.state.navBarProfile === true}
                >
                  {this.titlelist()}
                </Select>
                <FormHelperText>{TitleHelpertext}</FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="First Name">
                <TextField
                  helperText={FirstNameHelpertext}
                  error={FirstNameErr}
                  value={FirstName}
                  required
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="First Name"
                  onChange={(e) => this.onChange(e, "FirstName")}
                  onFocus={() => this.setState({ FirstNameHelpertext: "", FirstNameErr: false })}
                  onBlur={(e) => this.onChange(e, "FirstName")}
                  disabled={this.state.navBarProfile}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Middle Name">
                <TextField
                  value={MiddleName}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="Middle Name"
                  onChange={(e) => this.onChange(e, "MiddleName")}
                  disabled={this.state.navBarProfile}

                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Last Name">
                <TextField
                  helperText={LastNameHelpertext}
                  error={LastNameErr}
                  value={LastName}
                  required
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="Last Name"
                  onChange={(e) => this.onChange(e, "LastName")}
                  onFocus={() => this.setState({ LastNameHelpertext: "", LastNameErr: false })}
                  onBlur={(e) => this.onChange(e, "LastName")}
                  disabled={this.state.navBarProfile}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl error={SuffixListErr}>
                <InputLabel>Suffix</InputLabel>
                <Select
                  value={Suffix ? Suffix : ''}
                  disabled={this.state.navBarProfile}
                  onChange={(e) => this.selectChange(e, "Suffix")}
                >
                  {this.suffixlist()}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  options={departmentlist}
                  id="country"
                  getOptionLabel={option => option.label ? option.label : ''}
                  value={Department}
                  disabled={this.state.navBarProfile}
                  onChange={(event, value) => this.requestChange(event, value, "Department")}
                  renderInput={params => (
                    <TextField {...params} error={DepartmentErr} helperText={DepartmentHelpertext} required autoComplete="off"
                      label="Department" margin="normal" />)} />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6}>
              <FormControl fullWidth error={GenderErr}>
                <InputLabel>Gender *</InputLabel>
                <Select
                  onChange={(e) => this.selectChange(e, "Gender")}
                  value={Gender}
                  disabled={this.state.navBarProfile}
                >
                  {this.genderlist()}
                </Select>
                <FormHelperText>{GenderHelpertext}</FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl error={DesignationErr} fullWidth label="Designation">
                <InputLabel>Designation *</InputLabel>
                <Select
                  onChange={(e) => this.selectChange(e, "Designation")}
                  value={Designation}
                  disabled={this.state.navBarProfile}
                >
                  {this.designationlist()}
                </Select>
                <FormHelperText>{DesignationHelpertext}</FormHelperText>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6}>
              <FormControl fullWidth label="Employee Code">
                <TextField
                  helperText={ResourceCodeHelpertext}
                  error={ResourceCodeErr}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  value={ResourceCode}
                  disabled={this.state.navBarProfile}
                  label="Employee Code *"
                  onChange={(e) => this.onChange(e, "ResourceCode")}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Contact Number">
                <TextField
                  helperText={ContactNumberHelpertext}
                  error={ContactNumberErr}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  value={ContactNumber}
                  disabled={this.state.navBarProfile}
                  label="Contact Number *"
                  onChange={(e) => this.onChange(e, "ContactNumber")}
                  inputProps={{
                    autoComplete: "off",
                    maxLength: 14
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Aadhar Number">
                <TextField
                  helperText={AdharNumberHelpertext}
                  error={AdharNumberErr}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  value={AdharNumber}
                  disabled={this.state.navBarProfile}
                  label="Aadhar Number *"
                  onChange={(e) => this.onChange(e, "AdharNumber")}
                  inputProps={{
                    autoComplete: "off",
                    maxLength: 14
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Basic Salary">
                <TextField
                  helperText={BasicSalaryHelpertext}
                  error={BasicSalaryErr}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  value={BasicSalary}
                  disabled={this.state.navBarProfile}
                  label="Gross Salary *"
                  onChange={(e) => this.onChange(e, "BasicSalary")}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Professional Tax">
                <TextField
                  disabled
                  type="number"
                  label="Professional Tax"
                  helperText={ProfessionalTaxHelperText}
                  error={ProfessionalTaxErr}
                  value={ProfessionalTax}
                  onChange={(e) => this.onChange(e, "ProfessionalTax")}
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="PAN">
                <TextField
                  helperText={PANHelpertext}
                  error={PANErr}
                  value={PAN}
                  disabled={this.state.navBarProfile}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="PAN *"
                  onChange={(e) => this.onChange(e, "PAN")}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Passport#">
                <TextField
                  value={Passport}
                  disabled={this.state.navBarProfile}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="Passport"
                  onChange={(e) => this.onChange(e, "Passport")}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl error={DateofBirthErr} fullWidth>
                <div>
                  <Datetime
                    dateFormat={"DD/MM/YYYY"}
                    timeFormat={false}
                    inputProps={{ disabled: this.state.navBarProfile }}
                    selected={moment(DOB)}
                    value={DOB}
                    isValidDate={this.validDOB}
                    onChange={(date) => this.handleDate(date, "DOB")}
                    closeOnSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Date of Birth"
                        disabled={this.state.navBarProfile}
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon>date_range</Icon>
                            </InputAdornment>
                          ),
                          placeholder: "Date of Birth"
                        }}
                      />
                    )}
                  >
                  </Datetime>
                </div>
                <FormHelperText>{DateofBirthHelpertext}</FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth label="Email">
                <TextField
                  helperText={EmailHelperText}
                  error={EmailErr}
                  value={Email}
                  disabled={this.state.navBarProfile}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      sd                                                                                                                          
                  required
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="Email"
                  onChange={(e) => this.setState({ Email: e.target.value })}
                  onFocus={() => this.setState({ EmailHelperText: "", EmailErr: false })}
                  onBlur={(e) => this.onChange(e, "Email")}
                />
              </FormControl>
            </GridItem>

            {!this.state.editProfile && this.props?.location?.state?.editid == undefined ?
              <GridItem xs={6}>
                <FormControl error={passwordErr}>
                  <InputLabel htmlFor="standard-adornment-password">Password *</InputLabel>
                  <Input
                    inputProps={{
                      autoComplete: "password",
                    }}
                    id="standard-adornment-password"
                    type={'password'}
                    value={Password}
                    disabled={this.state.navBarProfile}
                    onChange={(e) => this.onChange(e, "Password")}
                    onFocus={() => this.setState({ passwordHelperText: "", passwordErr: false })}
                    onBlur={(e) => this.onPasswordBlur(e, "Password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title={<React.Fragment>
                          <Typography color="inherit">Your password must have:</Typography>
                          <b>{'One uppercase letter'}</b>{' '}<br />
                          <b>{'One lowercase letter'}</b>{' '}<br />
                          <b>{'One number'}</b>{' '}<br />
                          <b>{'Minimum 8 characters'}</b>{' '}<br />
                        </React.Fragment>} arrow><i className="fa fa-info-circle"></i></Tooltip>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{passwordHelperText}</FormHelperText>
                </FormControl>
              </GridItem> : null}
            {!this.state.editProfile && !this.props.location ?
              <GridItem xs={6}>
                <FormControl fullWidth label="ConfirmPassWord">
                  <TextField
                    type="password"
                    error={confirmpasswordErr}
                    helperText={confirmpasswordHelperText}
                    value={ConfirmPassword}
                    disabled={this.state.navBarProfile}
                    label="Confirm Password *"
                    onChange={(e) => this.setState({ ConfirmPassword: e.target.value })}
                    onFocus={() => this.setState({ confirmpasswordHelperText: "", confirmpasswordErr: false })}
                    onBlur={(e) => this.onChange(e, "ConfirmPassword")}
                    // inputProps={
                    //   autoComplete = "off"
                    // }
                    inputProps={{
                      autoComplete: "ConfirmPassword",
                    }}
                  />
                </FormControl>
              </GridItem> : null}

            <GridItem xs={6}>
              <FormControl error={AddressTypeErr} fullWidth label="Address Type">
                <InputLabel>Address Type*</InputLabel>
                <Select
                  onChange={(e) => this.selectChange(e, "AddressType")}
                  onFocus={() => this.setState({ AddressTypeHelpertext: "", AddressTypeErr: false })}
                  onBlur={(e) => this.onChange(e, "AddressType")}
                  value={AddressType}
                  disabled={this.state.navBarProfile}
                >
                  {this.addresslist()}
                </Select>
                <FormHelperText>{AddressTypeHelpertext}</FormHelperText>
              </FormControl>
            </GridItem>

            <GridItem xs={6}>
              <FormControl fullWidth label="Line 1">
                <TextField
                  helperText={Line1Helpertext}
                  error={Line1Err}
                  value={Line1}
                  required
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="Line 1"
                  onChange={(e) => this.onChange(e, "Line1")}
                  onFocus={() => this.setState({ Line1Helpertext: "", Line1Err: false })}
                  onBlur={(e) => this.onChange(e, "Line1")}
                  disabled={this.state.navBarProfile}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6}>
              <FormControl fullWidth label="Line 2">
                <TextField
                  value={Line2}
                  disabled={this.state.navBarProfile}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="Line 2"
                  onChange={(e) => this.onChange(e, "Line2")}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  options={countryList}
                  id="country"
                  getOptionLabel={option => option.label ? option.label : ''}
                  value={Country}
                  disabled={this.state.navBarProfile}
                  onChange={(event, value) => this.requestChange(event, value, "country")}
                  renderInput={params => (
                    <TextField {...params} error={countryErr} helperText={countryHelperText} required autoComplete="off"
                      label="Country" margin="normal" />)} />
              </FormControl>
            </GridItem>
          </GridContainer>
          {/* <GridContainer> */}

          {/* <GridItem xs={3}>
                <FormControl fullWidth label="Line 2">
                  <TextField
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="ZipCode #"
                    disabled={this.state.navBarProfile}
                    onChange={(e) => this.onChange(e, "Line2")}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth>
                <Autocomplete
                    options ={stateList}
                    id="state"
                    getOptionLabel={option => option.label}
                    value={State}
                    onChange={(event,value)=>this.requestChange(event,value,"state")}
                    renderInput={params => (
                    <TextField {...params} error={stateErr} helperText={stateHelperText} required autoComplete="off" 
                    label="State" margin="normal" />)}/>
                </FormControl>
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth label="Line 2">
                  <TextField
                    // helperText={Line2Helpertext}
                    // error={Line2Err}
                    // value={Line2}
                    prefix={
                      <Icon
                        type="user"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    label="City"
                    disabled={this.state.navBarProfile}
                    onChange={(e) => this.onChange(e, "Line2")}
                  />
                </FormControl>
              </GridItem>
            </GridContainer> */}
            {this.state.navBarProfile === true && this.state.UserImg ?  (
              <Button color="primary" onClick={() => this.submit()}>Save</Button>
            ) : null}

        </GridItem>
      </GridContainer>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Profile);
