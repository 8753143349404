import React, { Component } from 'react';

import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import cogoToast from 'cogo-toast';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';

class editProjectManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {

            ProjectCode: "",
            ProjectCodeErr: false,
            ProjectCodeHelperText: "",

            ProjectName: "",
            ProjectNameErr: false,
            ProjectNameHelperText: "",

            ProjectManager: "",
            ProjectManagerErr: false,
            ProjectManagerHelperText: "",
            ProjectManagerList: [],

            Type: "",
            TypeErr: false,
            TypeHelperText: "",
            TypeList: [],

            TechStackID: "",
            StringMapName: "",
            TechStackList: [],
            viewTechStackList: [],

            Client: "",
            ClientErr: false,
            ClientHelperText: "",
            ClientList: [],

            AssigneeType: '',
            AssigneeTypeErr: false,
            AssigneeTypeHelperText: '',
            AssigneeTypeList: [],

            PersonList: [],
            viewPersonList: [],

            EstimetedStartDate: "",
            EstimetedStartDateErr: false,
            EstimetedStartDateHelperText: "",

            ActualStartDate: "",
            ActualStartDateErr: false,
            ActualStartDateHelperText: "",

            EstimetedEndDate: "",
            ActualEndDate: "",
            HoursPlanned: "",
            HoursConsumed: "",
            Status: "",
            Description: "",

            Assignee: [],
            loggedUserID: 0,

        };
    }

    componentDidMount() {
        this.getEditData();
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
        this.getManagerList();
        this.getClientList();
        this.getTypeList();
        this.getPersonList();
        this.getTechStackList();
        this.getAssigneeTypeList();
        // this.diff_hours();
    }

    getPersonList = (AssigneeList) => {
        try {
            api.get("/tasks/getPersonList").then(res => {
                var Assignee = []
                if (res.success) {
                    this.setState({ PersonList: res.data });
                    if (this.state.Assignee) {
                        this.setState({ Assignee: res.data.filter(x => x.ResourceID == this.state.Assignee)[0] })
                    }

                    if (AssigneeList && AssigneeList.length > 0) {
                        for (let i = 0; i < AssigneeList.length; i++) {
                            Assignee.push(res.data.filter(x => x.ResourceID == AssigneeList[i].AssigneeID)[0])
                        }
                    }
                }
                else {
                    cogoToast.error("Something went wrong");
                }
                this.setState({
                    PersonList: res.data,
                    Assignee: Assignee

                });
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getTechStackList = () => {
        let data = {
            StringMapType: "TECHSTACK"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ TechStackList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }
    getAssigneeTypeList = () => {
        let data = {
            StringMapType: "ASSIGNEETYPE"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ AssigneeTypeList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getEditData() {
        let data = {
            ProjectID: this.props.location.state.id
        };

        try {
            api.post("projectManagement/getProjectByID", data).then(res => {
                if (res.success) {
                    if (res.data.techstackList != null && res.data.techstackList.length > 0) {
                        var i = 0;
                        res.data.techstackList.map(Obj => {
                            Obj.Index = i;
                            i++;
                            return Obj;
                        })
                        this.setState({ viewTechStackList: res.data.techstackList });
                    }
                    if (res.data.AssigneeList != null && res.data.AssigneeList.length > 0) {
                        var i = 0;
                        res.data.AssigneeList.map(Obj => {
                            Obj.Index = i;
                            i++;
                            return Obj;
                        })
                        this.setState({ viewPersonList: res.data.AssigneeList });
                    }

                    this.setState({
                        ProjectCode: res.data.ProjectCode,
                        ProjectName: res.data.ProjectName,
                        ProjectManager: res.data.ProjectManager,
                        Type: res.data.Type,
                        Client: res.data.Client,
                        EstimetedStartDate: res.data.EstimetedStartDate,
                        EstimetedEndDate: res.data.EstimetedEndDate,
                        ActualStartDate: res.data.ActualStartDate,
                        ActualEndDate: res.data.ActualEndDate,
                        HoursPlanned: res.data.HoursPlanned,
                        HoursConsumed: res.data.HoursConsumed,
                        Description: res.data.Description,
                        Status: res.data.Status
                        // Assignee: res.data.AssigneeList
                    });
                    this.diff_hours();
                    this.getPersonList(res.data.AssigneeList);
                } else {
                    cogoToast.error( 'Something went wrong')
                }

            }).catch(error => {
            })

        }
        catch (err) {
        }
    }

    redirectBack = () => {
        this.props.history.push("/admin/ProjectManagement");
    }

    getTypeList = () => {
        let data = {
            StringMapType: "INDUSTRY"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ TypeList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getManagerList = () => {
        try {
            api.get("/client/getProjectManagerList").then(res => {
                if (res.success) {
                    this.setState({ ProjectManagerList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getClientList = () => {
        try {
            api.get("/client/getClient").then(res => {
                if (res.success) {
                    this.setState({ ClientList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }
    AssigneeTypeList = () => {
        return this.state.AssigneeTypeList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    onChange = (e, type) => {
        if (type === "Assignee") {
            this.setState({ Assignee: e, AssigneeHelperText: "", AssigneeErr: false });
        }
        if (type === "ProjectCode") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ ProjectCode: e.target.value, ProjectCodeErr: true, ProjectCodeHelperText: "Please enter Project Code" });
            }
            else {
                this.setState({ ProjectCode: e.target.value, ProjectCodeErr: false, ProjectCodeHelperText: "" });
            }
        }

        else if (type === "ProjectName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ ProjectName: e.target.value, ProjectNameErr: true, ProjectNameHelperText: "Please enter Project Name" });
            }
            else {
                this.setState({ ProjectName: e.target.value, ProjectNameErr: false, ProjectNameHelperText: "" });
            }
        }

        else if (type === "Description") {
            this.setState({ Description: e.target.value });
        }

        else if (type === "HoursConsumed") {
            this.setState({ HoursConsumed: e.target.value });
        }

        else if (type === "Status") {
            this.setState({ Status: e.target.value });
        }

        else if (type === "HoursPlanned") {
            this.setState({ HoursPlanned: e.target.value });
        }
    }
    enabledAssigneetype = (idx, type) => {
        debugger
        const editAssignee = [...this.state.viewPersonList]
        var Index = this.state.viewPersonList.findIndex(x => x.Index === idx);
        if (Index !== -1) {
            editAssignee[Index]["IsEdit"] = type === "edit" ? true : false;
            this.setState({ viewPersonList: editAssignee })
        }
    }

    changeAssigneetype = (e) => {
        var type = document.getElementById("Assigneetype").value;
        if (type === "P") {
            this.setState({ AssigneeType: "PRIMARY" })
        } else if (type === "S") {
            this.setState({ AssigneeType: "SECONDARY" })
        }
    }

    diff_hours = () => {
        var dt1 = new Date(this.state.EstimetedStartDate).getTime();
        var dt2 = new Date().getTime();
        var diff = (dt2 - dt1) / 1000;
        diff /= (60 * 60);
        this.setState({ HoursConsumed: Math.abs(Math.round(diff)) });
    }

    managerlist = () => {
        return this.state.ProjectManagerList.map(content => {
            return (
                <MenuItem value={content.ResourceID} > {content.FirstName + " " + content.LastName}  </MenuItem>
            )
        })
    }

    techstacklist = () => {
        return this.state.TechStackList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    clientlist = () => {
        return this.state.ClientList.map(content => {
            return (
                <MenuItem value={content.ClientID} > {content.ClientName}  </MenuItem>
            )
        })
    }

    typelist = () => {
        return this.state.TypeList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }


    handleDate = (date, type) => {
        if (type === "EstimetedStartDate") {
            this.setState({ EstimetedStartDate: date, EstimetedStartDateErr: false, EstimetedStartDateHelperText: "" });
        }
        else if (type === "EstimetedEndDate") {
            this.setState({ EstimetedEndDate: date });
        }
        else if (type === "ActualStartDate") {
            this.setState({ ActualStartDate: date, ActualStartDateErr: false, ActualStartDateHelperText: "" });
        }
        else if (type === "ActualEndDate") {
            this.setState({ ActualEndDate: date });
        }
    }

    viewTechStack = () => {
        return this.state.viewTechStackList.filter(x => x.Status === 'Active').map(content => {
            return (
                <tr>
                    <td>
                        <div>
                            <input disabled name="TechStackName" value={content.TechStack} />
                        </div>
                    </td>
                    <td>
                        <DeleteIcon onClick={() => this.removeTechStack(content.ProjectTechStackID)} />
                    </td>
                </tr>
            )
        }
        )
    }
    viewAssignee = () => {
        return this.state.viewPersonList.filter(x => x.Status === 'Active').map((content, Index) => {
            return (
                <tr>
                    <td>
                        <div>
                            <input disabled name="Assignee" value={content.AssigneeID ? content.FirstName + ' ' + content.LastName : content.FirstName + ' ' + content.LastName} />
                        </div>
                        <div>
                            {content.IsEdit == false ?
                                <input disabled id="Assigneetype" onChange={() => this.changeAssigneetype()} name="AssigneeType" value={content.AssigneeType === "PRIMARY" || (content.AssigneeType.data && content.AssigneeType.data.length > 0 && content.AssigneeType.data[0] === 1) ? "P" : "S"} />
                                :
                                <Select onChange={(e) => {
                                    const editAssignee = [...this.state.viewPersonList]
                                    var Index = this.state.viewPersonList.findIndex(x => x.Index === content.Index);
                                    if (Index !== -1) {
                                        editAssignee[Index]["AssigneeType"] = e.target.value;
                                        this.setState({ viewPersonList: editAssignee })
                                    }
                                }} value={content.AssigneeType === "PRIMARY" || (content.AssigneeType.data && content.AssigneeType.data.length > 0 && content.AssigneeType.data[0] === 1) ? "PRIMARY" : "SECONDARY"} >
                                    {this.AssigneeTypeList()}
                                </Select>
                            }
                        </div>
                    </td>
                    <td>
                        <DeleteIcon onClick={() => this.removeAssignee(content.Index)} />
                    </td>
                    {content.IsEdit == false ?
                        <td>
                            <EditIcon disabled={this.state.isDisabled} onClick={() => this.enabledAssigneetype(content.Index, "edit")} />
                        </td> :
                        <td>
                            <button onClick={() => this.enabledAssigneetype(content.Index, "save")}>Save</button>
                        </td>
                    }
                </tr>
            )
        }
        )
    }
    addAssignee = (e, res) => {
        const { PersonID, Assignee } = this.state;
        var addAssignee = this.state.viewPersonList ? this.state.viewPersonList.filter(x => (x.Status === "Active" && (x.AssigneeID === Assignee.ResourceID))) : []

        if (addAssignee.length === 0) {
            let data = {
                CreatedBy: CommonConfig.loginData().PersonID,
                AssigneeID: Assignee ? Assignee.ResourceID : null,
                FirstName: Assignee ? Assignee.FirstName : null,
                LastName: Assignee ? Assignee.LastName : null,
                AssigneeType: this.state.AssigneeType,
                Index: this.state.viewPersonList.length + 1,
                Status: 'Active',
                IsEdit: false
            }
            this.setState({ viewPersonList: [...this.state.viewPersonList, data] });

        }
        else {
            cogoToast.error("Assignee Already exist");
        }
    }
    removeAssignee = (idx) => {
        const removeAssignee = [...this.state.viewPersonList]
        var Index = this.state.viewPersonList.findIndex(x => x.Index === idx);
        if (Index !== -1) {
            removeAssignee[Index]["Status"] = "Inactive";
            this.setState({ viewPersonList: removeAssignee })
        }

    }
    addTechStack = (e) => {
        const { PersonID, StringMapName } = this.state;
        var addTechStack = this.state.viewTechStackList.filter(x => (x.Status === "Active" && (x.TechStack === StringMapName)))

        if (addTechStack.length === 0) {
            let data = {
                CreatedBy: CommonConfig.loginData().PersonID,
                TechStack: this.state.TechStack,
                ProjectTechStackID: null,
                Status: 'Active'
            }
            this.setState({ viewTechStackList: [...this.state.viewTechStackList, data] });
        }
        else {
            cogoToast.error("TechStack Already exist");
        }
    }

    removeTechStack = (idx) => {
        const removeTechStack = [...this.state.viewTechStackList]
        var Index = this.state.viewTechStackList.findIndex(x => x.ProjectTechStackID === idx);
        if (Index !== -1) {
            removeTechStack[Index]["Status"] = "Inactive";
            this.setState({ viewTechStackList: removeTechStack })
        }
    }

    validate = () => {
        const { ProjectCode, ProjectName, viewPersonList, ProjectManager, Type, Client, EstimetedStartDate, Assignee, AssigneeType, ActualStartDate } = this.state;
        let IsValid = true;
        if (CommonConfig.isEmpty(ProjectCode)) {
            IsValid = false;
            this.setState({ ProjectCodeHelperText: "Please enter Project Code", ProjectCodeErr: true });
        }

        if (CommonConfig.isEmpty(ProjectName)) {
            IsValid = false;
            this.setState({ ProjectNameHelperText: "Please enter  Project Name", ProjectNameErr: true });
        }

        if (CommonConfig.isEmpty(ProjectManager)) {
            IsValid = false;
            this.setState({ ProjectManagerHelperText: "Please enter Project Manager", ProjectManagerErr: true });
        }

        if (CommonConfig.isEmpty(Type)) {
            IsValid = false;
            this.setState({ TypeHelperText: "Please enter Type", TypeErr: true });
        }

        if (CommonConfig.isEmpty(Client)) {
            IsValid = false;
            this.setState({ ClientHelperText: "Please enter Client", ClientErr: true });
        }

        if (CommonConfig.isEmpty(EstimetedStartDate)) {
            IsValid = false;
            this.setState({ EstimetedStartDateHelperText: "Please enter Estimeted Start Date", EstimetedStartDateErr: true });
        }

        if (CommonConfig.isEmpty(ActualStartDate)) {
            IsValid = false;
            this.setState({ ActualStartDateHelperText: "Please enter Actual Start Date", ActualStartDateErr: true });
        }

        if (this.state.viewPersonList.length == 0) {
            IsValid = false;
            this.setState({ AssigneeHelperText: "Please enter Assignee", AssigneeErr: true });
        }

        if (this.state.viewPersonList.length == 0) {
            IsValid = false;
            this.setState({ AssigneeTypeHelperText: "Please enter AssigneeType", AssigneeTypeErr: true });
        }

        return IsValid;
    }

    update = (e) => {
        const { ProjectCode, ProjectName, Assignee, AssigneeType, ProjectManager, Type, Client, Completed, EstimetedStartDate, EstimetedEndDate, ActualStartDate, ActualEndDate, HoursPlanned, HoursConsumed, Description, Status } = this.state;
        if (this.validate()) {
            let data = {
                ProjectCode: ProjectCode,
                ProjectName: ProjectName,
                ProjectManager: ProjectManager,
                Type: Type,
                Client: Client,
                UserID: CommonConfig.loginData().PersonID,
                AssigneeType: this.state.viewPersonList.map(x => { return x.AssigneeType === "PRIMARY" || x.AssigneeType.data && x.AssigneeType.data.length > 0 && x.AssigneeType.data[0] === 1 ? 1 : 0 }),
                EstimetedStartDate: CommonConfig.isEmpty(EstimetedStartDate) != true ? moment(EstimetedStartDate).startOf('day').format("YYYY-MM-DD").toString() : null,
                EstimetedEndDate: CommonConfig.isEmpty(EstimetedEndDate) != true ? moment(EstimetedEndDate).endOf('day').format("YYYY-MM-DD").toString() : null,
                ActualStartDate: CommonConfig.isEmpty(ActualStartDate) != true ? moment(ActualStartDate).startOf('day').format("YYYY-MM-DD").toString() : null,
                ActualEndDate: CommonConfig.isEmpty(ActualEndDate) != true ? moment(ActualEndDate).endOf('day').format("YYYY-MM-DD").toString() : null,
                HoursPlanned: HoursPlanned,
                HoursConsumed: HoursConsumed,
                Description: Description,
                Status: Status,
                Completed: Completed,
                Assignee: this.state.viewPersonList.filter(x => x.Status === "Active").map(x => { return x.AssigneeID }),
                viewTechStackList: this.state.viewTechStackList,
                ProjectID: this.props.location.state.id
            }
            try {
                api.post("projectManagement/addUpdateProject", data).then(res => {
                    if (res.success) {
                        cogoToast.success("Project updated successfully");
                        this.props.history.push("/admin/ProjectManagement");
                    }
                    else {
                        cogoToast.error("Something went wrong");
                    }
                }).catch(error => {
                    console.log("error", error);
                });
            }
            catch (err) {
                console.log("error", err);
            }
        }
        else {
            cogoToast.error("Please correct error and resubmit the form");
        }
    }

    selectChange = (e, type) => {
        if (type === "Assignee") {
            this.setState({ Assignee: e, AssigneeHelperText: "", AssigneeErr: false });
        }
        else if (type === "AssigneeType") {
            this.setState({ AssigneeType: e.target.value, AssigneeTypeErr: false, AssigneeTypeHelperText: "" });
        }
        if (type === "ProjectManager") {
            this.setState({ ProjectManager: e.target.value, ProjectManagerHelperText: "", ProjectManagerErr: false });
        }
        else if (type === "Client") {
            this.setState({ Client: e.target.value, ClientHelperText: "", ClientErr: false });
        }
        else if (type === "Type") {
            this.setState({ Type: e.target.value, TypeHelperText: "", TypeErr: false });
        }
        else if (type === "TechStack") {
            this.setState({ TechStack: e.target.value, TechStackErr: false, TechStackHelperText: "" });
        }
    }


    deleteProject = () => {
        try {
            let data = {
                ProjectID: this.props.location.state.id
            }
            api.post("projectManagement/deleteProjectByID", data).then(res => {
                if (res.success) {
                    cogoToast.success("Project deleted successfully");
                    this.props.history.push("/admin/ProjectManagement");
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(error => {
                console.log("error", error);
            });
        }
        catch (err) {
            console.log("error", err);
        }
    }

    render() {
        const { ProjectCode, ProjectCodeErr, ProjectCodeHelperText, ProjectName, ProjectNameErr, ProjectNameHelperText,
            ProjectManager, ProjectManagerErr, ProjectManagerHelperText, StringMapName, Type, TypeErr, TypeHelperText, Client,
            ClientErr, ClientHelperText, TechStack, EstimetedStartDate, EstimetedStartDateErr, EstimetedStartDateHelperText,
            EstimetedEndDate, ActualStartDate, ActualStartDateErr, ActualStartDateHelperText, ActualEndDate, HoursPlanned,
            HoursConsumed, Assignee, AssigneeErr, AssigneeHelperText, HoursConsumedHelperText, HoursConsumedErr, AssigneeType, AssigneeTypeErr, Description, Status, StatusErrText, StatusErr } = this.state;
        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader className="btn-right-outer" color="primary" icon>
                            <CardIcon color="primary">
                                <HeadsetMic />
                            </CardIcon>
                            <h4 className="margin-right-auto text-color-black">Edit Project</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            helperText={ProjectNameHelperText}
                                            error={ProjectNameErr}
                                            value={ProjectName}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Project Name"
                                            onChange={(e) => this.onChange(e, "ProjectName")}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth label="Project Code">
                                        <TextField
                                            helperText={ProjectCodeHelperText}
                                            error={ProjectCodeErr}
                                            value={ProjectCode}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Project Code"
                                            onChange={(e) => this.onChange(e, "ProjectCode")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={ProjectManagerErr}>
                                        <InputLabel>Project Manager</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "ProjectManager")} value={ProjectManager} >
                                            {this.managerlist()}
                                        </Select>
                                        <FormHelperText>{ProjectManagerHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={ClientErr}>
                                        <InputLabel>Client</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Client")} value={Client} >
                                            {this.clientlist()}
                                        </Select>
                                        <FormHelperText>{ClientHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={TypeErr}>
                                        <InputLabel>Type</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Type")} value={Type} >
                                            {this.typelist()}
                                        </Select>
                                        <FormHelperText>{TypeHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={HoursPlanned}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Hours Planned"
                                            onChange={(e) => this.onChange(e, "HoursPlanned")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth >
                                        <InputLabel>TechStack</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "TechStack")} value={TechStack} >
                                            {this.techstacklist()}
                                        </Select>
                                        <Tooltip title="Add TechStack" placement="bottom">
                                            <Button type="submit" color="primary" onClick={(e) => this.addTechStack(e)}>Add</Button>
                                        </Tooltip>
                                        {/* <ul>{this.blockersList()}</ul> */}
                                        {/* <TextField value={BlockerName}>{this.blockersList()}</TextField> */}
                                        {this.viewTechStack()}
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={HoursConsumedErr}>
                                        <TextField disabled
                                            value={HoursConsumed}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Hours Consumed"
                                            onChange={(e) => this.onChange(e, "HoursConsumed")}
                                        />
                                        <FormHelperText>{HoursConsumedHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            // dateFormat={"YYYY/MM/DD"}
                                            value={moment(this.state.EstimetedStartDate)}
                                            timeFormat={false}
                                            selected={moment(EstimetedStartDate)}
                                            inputProps={{ placeholder: "Estimeted Start Date" }}
                                            onChange={(date) => this.handleDate(date, "EstimetedStartDate")}
                                            closeOnSelect={true}
                                            renderInput={params => (
                                                <TextField
                                                    error={EstimetedStartDateErr}
                                                    helperText={EstimetedStartDateHelperText}
                                                    {...params} label="Estimeted Start Date" margin="normal" />)} />
                                        <Icon className="date-icon tp-slam">date_range</Icon>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            //dateFormat={"YYYY/MM/DD"}
                                            timeFormat={false}
                                            value={moment(this.state.EstimetedEndDate)}
                                            selected={moment(EstimetedEndDate)}
                                            inputProps={{ placeholder: " Estimeted End Date" }}
                                            onChange={(date) => this.handleDate(date, "EstimetedEndDate")}
                                            closeOnSelect={true}
                                            renderInput={params => (
                                                <TextField
                                                    {...params} label="Estimeted End Date" margin="normal" />)} />
                                        <Icon className="date-icon tp-slam">date_range</Icon>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            //dateFormat={"YYYY/MM/DD"}
                                            value={moment(this.state.ActualStartDate)}
                                            timeFormat={false}
                                            selected={moment(ActualStartDate)}
                                            inputProps={{ placeholder: "Actual Start Date" }}
                                            onChange={(date) => this.handleDate(date, "ActualStartDate")}
                                            closeOnSelect={true}
                                            renderInput={params => (
                                                <TextField
                                                    error={ActualStartDateErr}
                                                    helperText={ActualStartDateHelperText}
                                                    {...params} label="Actual Start Date" margin="normal" />)} />
                                        <Icon className="date-icon tp-slam">date_range</Icon>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6} >
                                    <FormControl fullWidth>
                                        <Datetime
                                            //dateFormat={"YYYY/MM/DD"}
                                            value={moment(this.state.ActualEndDate)}
                                            timeFormat={false}
                                            selected={moment(ActualEndDate)}
                                            inputProps={{ placeholder: " Actual End Date" }}
                                            onChange={(date) => this.handleDate(date, "ActualEndDate")}
                                            closeOnSelect={true}
                                            renderInput={params => (
                                                <TextField
                                                    {...params} label="Actual End Date" margin="normal" />)} />
                                        <Icon className="date-icon tp-slam">date_range</Icon>
                                        <Tooltip title="Set As Completed" placement="bottom">
                                            <Button color="primary" onClick={this.showModal}>Set As Completed</Button>
                                        </Tooltip>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={StatusErr}>
                                        <TextField
                                            value={Status}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Status"
                                            onChange={(e) => this.onChange(e, "Status")}
                                        />
                                        <FormHelperText>{StatusErrText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={6} md={3}>
                                    <FormControl>
                                        <div className="search-resource">
                                            <div className="with-icon">
                                                <Autocomplete
                                                    options={this.state.PersonList}
                                                    id="DailyStatus"
                                                    value={this.state.Assignee ? this.state.Assignee : null}
                                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                                    getOptionValue={(option) => option.ResourceID}
                                                    onChange={(event, value) => this.onChange(value, "Assignee")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            helperText={AssigneeHelperText}
                                                            label="Assignee"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6} md={3}>
                                    <FormControl fullWidth error={AssigneeTypeErr}>
                                        <InputLabel>Assignee Type</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "AssigneeType")} value={AssigneeType} >
                                            {this.AssigneeTypeList()}
                                        </Select>
                                        <FormHelperText>{TypeHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <Tooltip title="Add Assignee" placement="bottom">
                                <Button type="submit" color="primary" onClick={(e) => this.addAssignee(e)}>Add Assignee</Button>
                            </Tooltip>
                            {this.viewAssignee()}
                            <GridContainer>
                                <FormControl>
                                    <TextareaAutosize
                                        value={Description}
                                        placeholder="Description"
                                        rowsMin={5}
                                        cols={90}
                                        label="Description"
                                        onChange={(e) => this.onChange(e, "Description")}
                                    />
                                </FormControl>
                            </GridContainer>
                            <Tooltip title="Update" placement="bottom">
                                <Button type="submit" color="primary" onClick={(e) => this.update(e)}>Update</Button></Tooltip>
                            <Tooltip title="Delete" placement="bottom">
                                <Button type="submit" color="primary" key="back" onClick={() => this.deleteProject()}>Inactive</Button></Tooltip>
                            <Tooltip title="Back" placement="bottom">
                                <Button type="submit" key="back" onClick={() => this.redirectBack()}>Back</Button></Tooltip>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}
export default editProjectManagement;