import React, { Component } from 'react';
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import cogoToast from 'cogo-toast';

class addDailyTasks extends Component {

    constructor(props) {
        super(props);
        this.state = {

            HrsToday: "",

            TotalHours: 0,

            TaskStatusDate: "",
            TaskStatusDateErr: false,
            TaskStatusDateHelperText: "",

            TaskList: [],
            Task: "",
            TaskErr: false,
            TaskHelperText: "",

            ProjectList: [],
            ProjectErr: false,
            ProjectHelperText: "",
            Project: "",

            PercentageCompleted: "",
            PercentageCompletedErr: false,
            PercentageCompletedHelperText: "",

            Notes: "",
            NotesErr: false,
            NotesHelperText: "",

            loggedUserID: 0,
        };
    }

    componentDidMount() {
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
        this.getTaskList();
        this.getProjectList();
    }

    getProjectList = () => {
        try {
            api.get("/dailystatus/getProjectList").then(res => {
                if (res.success) {
                    this.setState({ ProjectList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getTaskList = () => {
        try {
            api.get("/dailystatus/getTaskList").then(res => {
                if (res.success) {
                    this.setState({ TaskList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    onChange = (e, type) => {
        if (type === "HrsToday") {
            this.setState({ HrsToday: e.target.value });
        }
        if (type === "TotalHours") {
            this.setState({ TotalHours: e.target.value });
        }
        if (type === "Notes") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Notes: e.target.value, NotesErr: true, NotesHelperText: "Please enter Notes" });
            }
            else {
                this.setState({ Notes: e.target.value, NotesErr: false, NotesHelperText: "" })
            }
        }
    }
    redirectBack = () => {
        this.props.history.push("/admin/viewDailyTasks");
    };

    tasklist = () => {
        return this.state.TaskList.map(content => {
            return (
                <MenuItem value={content.TaskID} > {content.TaskID} </MenuItem>
            )
        })
    }

    projectlist = () => {
        return this.state.ProjectList.map(content => {
            return (
                <MenuItem value={content.ProjectID} > {content.ProjectName} </MenuItem>
            )
        })
    }

    handleDate = (date, type) => {
        if (type === "TaskStatusDate") {
            this.setState({ TaskStatusDate: date, TaskStatusDateErr: false, TaskStatusDateHelperText: "" });
        }
    }

    validate = () => {
        const { Task, Project, TaskStatusDate, Notes, PercentageCompleted } = this.state;
        let IsValid = true;
        if (CommonConfig.isEmpty(Task)) {
            IsValid = false;
            this.setState({ TaskHelperText: "Please enter Task", TaskErr: true });
        }

        if (CommonConfig.isEmpty(Project)) {
            IsValid = false;
            this.setState({ ProjectHelperText: "Please enter Project", ProjectErr: true });
        }

        if (CommonConfig.isEmpty(TaskStatusDate)) {
            IsValid = false;
            this.setState({ TaskStatusDateHelperText: "Please enter Task Status Date", TaskStatusDateErr: true });
        }

        if (CommonConfig.isEmpty(Notes)) {
            IsValid = false;
            this.setState({ NotesHelperText: "Please enter Notes", NotesErr: true });
        }

        if (CommonConfig.isEmpty(PercentageCompleted)) {
            IsValid = false;
            this.setState({ PercentageCompletedHelperText: "Please enter Percentage Completed", PercentageCompletedErr: true });
        }

        return IsValid;
    }

    submit = (e) => {
        const { HrsToday, TotalHours, Task, TaskStatusDate, PercentageCompleted, Notes, Project } = this.state;
        if (this.validate()) {
            let data = {
                UserID: CommonConfig.loginData().PersonID,
                HrsToday: HrsToday,
                TotalHours: TotalHours,
                TaskID: Task,
                TaskStatusDate: CommonConfig.isEmpty(TaskStatusDate) !== true ? moment(TaskStatusDate).format("YYYY-MM-DD").toString() : null,
                Notes: Notes,
                PercentageCompleted: PercentageCompleted,
                ProjectID: Project,
                DailyTaskID: null
            }
            try {
                api.post("dailystatus/addUpdateTask", data).then(res => {
                    if (res.success) {
                        cogoToast.success("Task added successfully");
                        this.props.history.push("/admin/viewDailyTasks");
                    }
                    else {
                        cogoToast.error("Something went wrong");
                    }
                }).catch(error => {
                    console.log("error", error);
                });
            }
            catch (err) {
                console.log("error", err);
            }
        }
        else {
            cogoToast.error("Please correct error and resubmit the form");
        }
    }

    selectChange = (e, type) => {

        if (type === "Task") {
            this.setState({ Task: e.target.value, TaskHelperText: "", TaskErr: false });
        }
        else if (type === "Project") {
            this.setState({ Project: e.target.value, ProjectHelperText: "", ProjectErr: false });
        }
        else if (type === "percentagecompleted") {
            this.setState({ PercentageCompleted: e.target.value, PercentageCompletedHelperText: "", PercentageCompletedErr: false });
        }
    }

    render() {
        const { Task, TaskErr, TaskHelperText, Project, ProjectHelperText, ProjectErr, HrsToday, TotalHours, TaskStatusDate, TaskStatusDateErr, TaskStatusDateHelperText, NotesHelperText, NotesErr, Notes, PercentageCompleted, PercentageCompletedErr, PercentageCompletedHelperText } = this.state;

        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader className="btn-right-outer" color="primary" icon>
                            <h4 className="margin-right-auto text-color-black">Add Task</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={TaskErr}>
                                        <InputLabel>Task</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Task")} value={Task} >
                                            {this.tasklist()}
                                        </Select>
                                        <FormHelperText>{TaskHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={ProjectErr}>
                                        <InputLabel>Project</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Project")} value={Project} >
                                            {this.projectlist()}
                                        </Select>
                                        <FormHelperText>{ProjectHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth label="Hrs Today">
                                        <TextField
                                            value={HrsToday}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Hrs Today"
                                            onChange={(e) => this.onChange(e, "HrsToday")}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth label="Total Hours">
                                        <TextField
                                            value={TotalHours}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Total Hours"
                                            onChange={(e) => this.onChange(e, "TotalHours")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            dateFormat={"DD/MM/YYYY"}
                                            timeFormat={false}
                                            className="input-group"
                                            selected={moment(TaskStatusDate)}
                                            inputProps={{ placeholder: "Task Status Date" }}
                                            onChange={(date) => this.handleDate(date, "TaskStatusDate")}
                                            closeOnSelect={true}
                                            renderInput={params => (
                                                <TextField
                                                    helperText={TaskStatusDateHelperText}
                                                    error={TaskStatusDateErr}
                                                    {...params} label="Task Status Date" margin="normal" />)} />
                                        <Icon className="date-icon tp-slam">date_range</Icon>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                <FormControl fullWidth error={PercentageCompletedErr}>
                                    <InputLabel>Percentage Completed</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "percentagecompleted")} value={PercentageCompleted} >
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value="40">40</MenuItem>
                                            <MenuItem value="50">50</MenuItem>
                                            <MenuItem value="60">60</MenuItem>
                                            <MenuItem value="70">70</MenuItem>
                                            <MenuItem value="80">80</MenuItem>
                                            <MenuItem value="90">90</MenuItem>
                                            <MenuItem value="100">100</MenuItem>
                                        </Select>
                                            <FormHelperText>{PercentageCompletedHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <FormControl fullWidth error={NotesErr}>
                                        <TextareaAutosize
                                            value={Notes}
                                            placeholder="Notes"
                                            rowsMin={5}
                                            cols={90}
                                            label="Notes"
                                            onChange={(e) => this.onChange(e, "Notes")}
                                        />
                                        <FormHelperText>{NotesHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <Tooltip title="Save" placement="bottom">
                            <Button type="submit" color="primary" onClick={(e) => this.submit(e)}>Save</Button>
                            </Tooltip>
                            <Tooltip title="Back" placement="bottom">
                            <Button type="submit" key="back" onClick={() => this.redirectBack()}>Back</Button>
                            </Tooltip>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default addDailyTasks;