import React, { Component } from 'react';
import api from '../../utils/apiClient';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
import CreateIcon from '@material-ui/icons/Create';
class serverconfiglisting extends Component {
  constructor(props){
    super(props);
    this.state={
      serverconfiglist:[],
    }
  }

  componentDidMount(){
    this.getserverconfigList();
  }

  getserverconfigList = () => {
    try{
      api.get("serverconfig/getserverconfigList").then(res =>{
        this.setState({serverconfiglist:res.data});
      }).catch(error => {
    })

    }
    catch(err){
    }
  } 

  edit = (record) => {
    this.props.history.push({
      pathname:"/admin/EditServerConfig",
      state:{ id : record.original.ServerConfigID }
    })
  }

  redirectAdd = () => {
    this.props.history.push("/admin/addServerConfig");
  }

  render() {
    const {serverconfiglist} = this.state;
    const tableColumns = [
      
      {
        Header: "Panel Name",
        accessor: "PanelName",
        sortable: true,
        width: 150,
        maxWidth: 150,
      },
      {
        Header: "URL",
        accessor: "AccessURL",
        sortable: true,
        width: 100,
        maxWidth: 150,
      },
      {
        Header: "Description",
        accessor: "Description",
        sortable: true,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: "Action",
        accessor: "action",
        sortable: false,
        width: 90,
        maxWidth: 90,
        className:"text-align-right",
        Cell: record => {
          return (
            <span>
            <Tooltip title="Edit" placement="bottom">
                <Button className="btn-icon" color="primary" onClick={() => this.edit(record)}><CreateIcon /></Button>
            </Tooltip>
            </span>
            )  
      },
        filterable: false
      }
    ];

  return(
    <Card>
      <CardHeader>
      <Tooltip title="Add" placement="bottom">
        <Button type="button" color="primary" onClick={()=>this.redirectAdd()}>Add Server Configuration</Button>
        </Tooltip>
      </CardHeader>  
      <CardBody>
        <ReactTable
          data={serverconfiglist}
          filterable
          minRows = {2}
          resizable={false}
          columns={tableColumns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
       </CardBody>
    </Card>
  )
  }
}
export default serverconfiglisting;