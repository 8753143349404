import React, { Component } from 'react';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import cogoToast from 'cogo-toast';
import FormControl from '@material-ui/core/FormControl';
import CardIcon from "components/Card/CardIcon.js";
import Datetime from "react-datetime";
import Card from "components/Card/Card.js";
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import CardBody from "components/Card/CardBody.js";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import Icon from "@material-ui/core/Icon";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class addClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientCode: "",
      clientcodeErr: false,
      clientcodeHelperText: "",

      ClientName: "",
      clientnameErr: false,
      clientnameHelperText: "",

      ClientManager: [],
      clientmanagerErr: false,
      clientmanagerHelperText: "",
      ClientManagerList: [],

      Country: "",
      countryErr: false,
      countryHelperText: "",
      CountryList: [],

      Industry: "",
      industryErr: false,
      industryHelperText: "",

      Description: "",
      descriptionErr: false,
      descriptionHelperText: "",

      Email: "",
      emailErr: false,
      emailHelperText: "",

      ContactNo: "",
      contactNoErr: false,
      contactNoHelperText: "",

      PhoneID: null,
      EmailID: null,

      Phone: "",

      TypeList: [],
    };
  }

  async componentDidMount() {
    await this.getCountry();
    await this.getManagerList();
    await this.getTypeList();
    await this.getEditData();

  }

  getTypeList = () => {
    let data = {
      StringMapType: "INDUSTRY"
    }
    try {
      api.post("/stringmap/getStringMap", data).then(res => {
        if (res.success) {
          this.setState({ TypeList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {
    }
  }


  getManagerList = () => {
    try {
      api.get("client/getProjectManagerList").then(res => {
        if (res.success) {
          this.setState({ ClientManagerList: res.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (err) {

    }
  }

  getEditData = () => {
    let data = {
      ClientID: this.props.location.state.id
    };
    try {

      api.post("client/getClientByID", data).then(response => {
        if (response.success) {
          var managername = []
          var countryFinal = this.state.CountryList.filter(x => x.CountryID === response.data[0].CountryID);
          for (let i = 0; i < response.data[0].ClientManager.length; i++) {
            managername.push({
              ResourceID: this.state.ClientManagerList.filter(x => x.ResourceID == response.data[0].ClientManager[i])[0].ResourceID,
              FirstName: this.state.ClientManagerList.filter(x => x.ResourceID == response.data[0].ClientManager[i])[0].FirstName,
              LastName: this.state.ClientManagerList.filter(x => x.ResourceID == response.data[0].ClientManager[i])[0].LastName
            })
          }
          var countryname = {
            label: countryFinal[0].CountryName,
            value: countryFinal[0].CountryID
          }
          this.setState({
            ClientCode: response.data[0].ClientCode,
            ClientName: response.data[0].ClientName,
            ClientManager: managername,
            Industry: response.data[0].Industry,
            Description: response.data[0].Description,
            Email: response.data[0].Email,
            ContactNo: response.data[0].PhoneNumber,
            PhoneID: response.data[0].PhoneID,
            EmailID: response.data[0].EmailID,
            StartDate: moment(response.data[0].StartClientDate).startOf('day').format("MM-DD-YYYY"),
            Country: countryname
          })
        }
        else {
          cogoToast.error("Something Went Wrong");
        }
      }).catch(err => {
        console.log("error", err);
      });
    }
    catch (err) {
      console.log(err);
    }
  }


  cancelClient = () => {
    this.props.history.push("/admin/clientlisting");
  }


  getCountry = () => {
    try {
      api.get("country/getCountry").then(response => {
        if (response.success) {
          this.setState({ CountryList: response.data });
        }
        else {
          cogoToast.error("Something went wrong");
        }
      }).catch(err => {
        console.log(err)
      })
    }
    catch (err) {
      console.log("error", err);
    }
  }

  onChange = (e, type) => {
    let val = e.target.value
    if (type === "clientcode") {

      if (CommonConfig.isEmpty(val)) {
        this.setState({ ClientCode: val, clientcodeHelperText: "Please enter Client Code", clientcodeErr: true });
      } else {
        this.setState({ ClientCode: val, clientcodeHelperText: "", clientcodeErr: false });
      }
    }
    else if (type === "clientname") {

      if (CommonConfig.isEmpty(val)) {
        this.setState({ ClientName: val, clientnameHelperText: "Please enter Client Name", clientnameErr: true });
      } else {
        this.setState({ ClientName: val, clientnameHelperText: "", clientnameErr: false });
      }
    }
    else if (type === "description") {
      this.setState({ Description: val, descriptionHelperText: "", descriptionErr: false });
    }
    else if (type === "email") {
      if (CommonConfig.isEmpty(val)) {
        this.setState({ Email: e.target.value, emailErr: true, emailHelperText: 'Please enter Email OR Contact No' });
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({ Email: e.target.value, emailErr: true, emailHelperText: 'Please enter valid Email' });
      } else {
        this.setState({ Email: e.target.value, emailErr: false, emailHelperText: '' });
      }
    }
    else if (type === "contactNo") {
      if (CommonConfig.isEmpty(val)) {
        this.setState({ ContactNo: val, contactNoHelperText: "Please enter Email OR Contact No", contactNoErr: true });
      } else if (!val.match(CommonConfig.RegExp.phoneNumber)) {
        this.setState({ ContactNo: val, contactNoErr: true, contactNoHelperText: 'Please enter valid Contact Number' });
      } else {
        this.setState({ ContactNo: val, contactNoHelperText: "", contactNoErr: false });
      }
    }
  }

  validate = () => {
    let IsValid = true;

    const { ClientCode, ClientName, ClientManager, Country, Industry, Description, Email, ContactNo } = this.state;

    if (CommonConfig.isEmpty(ClientCode)) {
      IsValid = false;
      this.setState({ clientcodeErr: true, clientcodeHelperText: "Please enter Client Code" });
    }
    if (CommonConfig.isEmpty(ClientName)) {
      IsValid = false;
      this.setState({ clientnameErr: true, clientnameHelperText: "Please enter Client Name" });
    }
    if (CommonConfig.isEmpty(ClientManager)) {
      IsValid = false;
      this.setState({ clientmanagerErr: true, clientmanagerHelperText: "Please select Client Manager" });
    }
    if (CommonConfig.isEmpty(Email || ContactNo)) {
      IsValid = false;
      this.setState({
        emailErr: true, emailHelperText: "Please enter Email ID",
        contactNoErr: true, contactNoHelperText: "Please Enter Contact No"
      });
    }
    // if(CommonConfig.isEmpty(ContactNo)){
    //   IsValid = false;
    //   this.setState({contactNoErr:true , contactNoHelperText:"Please select Contact No"});
    // }
    if (CommonConfig.isEmpty(Industry)) {
      IsValid = false;
      this.setState({ industryErr: true, industryHelperText: "Please select Industry" });
    }
    if (CommonConfig.isEmpty(Country)) {
      IsValid = false;
      this.setState({ countryErr: true, countryHelperText: "Please select Country" });
    }

    return (IsValid);
  }

  submit = (e) => {
    const { ClientCode, ClientName, ClientManager, StartDate, loggedUserID, Country, Industry, Description, Email, ContactNo } = this.state;
    if (this.validate()) {
      let data = {
        ClientCode: ClientCode,
        ClientName: ClientName,
        ClientManager: ClientManager.map(x => { return x.ResourceID }),
        CountryID: Country.value,
        Industry: Industry,
        Description: Description,
        UserID: CommonConfig.loginData().PersonID,
        ClientID: this.props.location.state.id,
        Email: Email,
        ContactNo: ContactNo,
        StartClientDate: moment(StartDate).startOf('day').format("YYYY-MM-DD HH:mm:ss").toString(),
        EmailID: this.state.EmailID,
        PhoneID: this.state.PhoneID
      }
      try {
        api.post("client/addClient", data).then(res => {
          if (res.success) {
            cogoToast.success("Client Updated Successfully");
            this.props.history.push("/admin/clientlisting");
          }
          else {
            cogoToast.error("Something went wrong");
          }
        }).catch(err => {
          console.log(err)
        });
      }
      catch (err) {
        console.log(err);
      }
    }
    else {
      if ((Email === undefined || Email === null || Email === '') && (ContactNo === undefined || ContactNo === null || ContactNo === '') &&
        ((!CommonConfig.isEmpty(ClientCode && ClientName && ClientManager && Country.value && Industry)))) {

        cogoToast.error("Please Enter Anyone Of Below Field");
      } else {
        cogoToast.error("Please correct error and resubmit the form");
      }
    }
  }

  selectChange = (e, type) => {
    if (type === "industry") {
      this.setState({ Industry: e.target.value, industryErr: false, industryHelperText: "" });
    }
    else if (type === "clientmanager") {
      this.setState({ ClientManager: e.target.value, clientmanagerHelperText: "", clientmanagerErr: false });
    }
  }

  requestChange = (e, value, type) => {
    if (type === "country") {
      this.setState({ Country: value, countryErr: false, countryHelperText: "" });
    }
    else if (type === "clientmanager") {
      this.setState({ ClientManager: value, clientmanagerHelperText: "", clientmanagerErr: false });
    }
  }

  handleDate = (date, type) => {
    if (type === "startdate") {
      this.setState({ StartDate: date });
    }
  }

  typelist = () => {
    return this.state.TypeList.map(content => {
      return (
        <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
      )
    })
  }


  render() {
    const countryList = this.state.CountryList.map(country => { return { value: country.CountryID, label: country.CountryName } })

    const { ClientCode, clientcodeErr, clientcodeHelperText, ClientName, clientnameErr,
      clientnameHelperText, ClientManager, clientmanagerErr, clientmanagerHelperText,
      Country, countryErr, countryHelperText, Industry, industryErr, industryHelperText,
      Description, StartDate, startdateErr, startdateHelperText, descriptionErr, descriptionHelperText, Email, emailErr, emailHelperText,
      ContactNo, contactNoErr, contactNoHelperText, ClientManagerList } = this.state;
    return (
      <GridContainer className="UserList-outer">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="btn-right-outer" color="primary" icon>
              <CardIcon color="primary">
                <HeadsetMic />
              </CardIcon>
              <h4>Client</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      value={ClientCode}
                      error={clientcodeErr}
                      helperText={clientcodeHelperText}
                      label="Client Code"
                      onChange={(e) => this.onChange(e, "clientcode")}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      value={ClientName}
                      error={clientnameErr}
                      helperText={clientnameHelperText}
                      label="Client Name"
                      onChange={(e) => this.onChange(e, "clientname")}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      options={ClientManagerList}
                      id="managerlist"
                      getOptionLabel={option => option.ResourceID ? option.FirstName + " " + option.LastName : option}
                      value={ClientManager}
                      onChange={(event, value) => this.requestChange(event, value, "clientmanager")}
                      renderInput={params => (
                        <TextField {...params} error={clientmanagerErr} helperText={clientmanagerHelperText}
                          label="Client Manager" margin="normal" />)} />
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={countryList}
                      id="country"
                      getOptionLabel={option => (option.label ? option.label : "")}
                      value={Country}
                      onChange={(event, value) => this.requestChange(event, value, "country")}
                      renderInput={params => (
                        <TextField {...params} error={countryErr} helperText={countryHelperText}
                          label="Country" margin="normal" />)} />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl fullWidth error={industryErr}>
                    <InputLabel>Industry</InputLabel>
                    <Select onChange={(e) => this.selectChange(e, "industry")} value={Industry} >
                      {this.typelist()}
                    </Select>
                    <FormHelperText>{industryHelperText}</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    <TextareaAutosize
                      value={Description}
                      placeholder="Description"
                      rowsMin={5}
                      cols={90}
                      label="Description"
                      onChange={(e) => this.onChange(e, "description")}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      className="input-group"
                      value={StartDate}
                      inputProps={{ placeholder: "Start Date" }}
                      onChange={(date) => this.handleDate(date, "startdate")}
                      closeOnSelect={true}
                      renderInput={params => (
                        <TextField
                          error={startdateErr}
                          helperText={startdateHelperText}
                          {...params} label="Start Date" margin="normal" />)} />
                    <Icon className="date-icon tp-slam">date_range</Icon>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    value={Email}
                    error={emailErr}
                    helperText={emailHelperText}
                    label="Email"
                    onChange={(e) => this.onChange(e, "email")}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    value={ContactNo}
                    error={contactNoErr}
                    helperText={contactNoHelperText}
                    label="Contact No"
                    onChange={(e) => this.onChange(e, "contactNo")}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Tooltip title="Save & Exit" placement="bottom">
            <Button color="primary" type="submit" onClick={(e) => this.submit(e)} className="width-150 mr-4 M-right-10" >
              Save & Exit
            </Button>
          </Tooltip>
          <Tooltip title="Cancel" placement="bottom">
            <Button color="" type="submit" onClick={(e) => this.cancelClient(e)} className="width-150 mr-4">
              Cancel
            </Button>
          </Tooltip>
        </GridItem>
      </GridContainer>
    );
  }
}

export default addClient;


