import React, { Component } from 'react';
// core components
import Icon from '@material-ui/core/Icon';
import ReactTable from "react-table";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel, FormLabel, Card } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import SimpleBackdrop from "../../utils/general";
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DayPicker, { DateUtils } from 'react-day-picker';
// import { kanbanData } from './datasource';
import 'react-day-picker/lib/style.css'
import CardBody from 'components/Card/CardBody';

class Tasks extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };
    constructor(props) {
        super(props);
        this.state = {
            isfilter: false,
            showStatus: false,
            showDate: false,
            createdFilter: false,
            dueDateFilter: false,
            updatedFilter: false,

            filterData: [],
            TaskList: [],
            DailyStatusList: [],
            from: '',
            to: '',
            loggedUserID: 0,
            filterVal: "",
            inProgressFilter: "",
            openFilter: "",
            resolvedFilter: "",
            closedFilter: "",
            todayFilter: "",
            allFilter: 'active',
            searchToggle: true,
            ProjectList: [],
            Loading: false,
            ApproveAccess:true
        };
    }

    componentDidMount() {
        if (CommonConfig.getUserAccess("Task")) {
            this.setState({ ApproveAccess: CommonConfig.getUserAccess("Task").AllAccess === 1 ? false : true})
        }
        this.getTaskList(CommonConfig.loginData().PersonID);
        this.getDailyStatusList();
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
    }

    getTaskList = (Assignee,Observer) => {
        let data;
        this.setState({Loading : true})
        if (!CommonConfig.isEmpty(Assignee)) {
            data = {
                Assignee: Assignee
            }
        }
        try {
            api.post("tasks/getTaskList", data).then(res => {
                if (this.state.ProjectSearch) {
                    var filterTask = [];
                    filterTask = res.data.filter(x => x.ProjectName.toLowerCase().indexOf(this.state.ProjectSearch.toLowerCase()) !== -1);
                    this.setState({ isfilter: true, filterData: filterTask });
                }else{
                    var filterTask = [];
                    filterTask = res.data;
                    this.setState({ isfilter: true, filterData: filterTask,Loading:false });
                }
                var obj = {};
                var arrTask = []
                for (var i = 0, len = res.data.length; i < len; i++) {
                    obj[res.data[i]['ProjectName']] = res.data[i];
                    if(Observer && res.data[i].Observer){
                        var array = res.data[i].Observer.split(",");
                        if(array.filter(x=>x==Observer).length > 0){
                            arrTask.push(
                                res.data[i]
                            )
                        }
                    }
                }

                var pfilter = [];
                for (var key in obj) {
                    pfilter.push(obj[key]);
                }
                var filterproject = []
                for (let i = 0; i < pfilter.length; i++) {
                    filterproject.push({
                        ProjectName: pfilter[i].ProjectName
                    })
                }

                this.setState({ TaskList: Observer?arrTask:res.data, ProjectList: filterproject,Loading:false });
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }
    redirectView = (record) => {

        this.props.history.push({
            pathname: "/admin/viewTasks",
            state: { id: record.original.TaskID }
        })
    }
    applyFilter = (value) => {
        if (value == "ALL") {
            var filterTask = this.state.TaskList.filter(x => x.TaskStatus !== value);
            this.setState({
                isfilter: true, openFilter: "", resolvedFilter: "", closedFilter: "",
                todayFilter: "", filterData: filterTask, filterType: value, inProgressFilter: "", allFilter: "active"
            })
        }
        else {
            var filterTask = this.state.TaskList.filter(x => x.TaskStatus === value);
            if (value === "IN PROGRESS")
                this.setState({
                    isfilter: true, openFilter: "", resolvedFilter: "", closedFilter: "",
                    todayFilter: "", filterData: filterTask, filterType: value, inProgressFilter: "active", allFilter: ""
                });
            if (value === "OPEN")
                this.setState({
                    isfilter: true, inProgressFilter: "", resolvedFilter: "", closedFilter: "",
                    todayFilter: "", filterData: filterTask, filterType: value, openFilter: "active", allFilter: ""
                });
            if (value === "RESOLVED")
                this.setState({
                    isfilter: true, inProgressFilter: "", openFilter: "", closedFilter: "",
                    todayFilter: "", filterData: filterTask, filterType: value, resolvedFilter: "active", allFilter: ""
                });
            if (value === "CLOSED")
                this.setState({
                    isfilter: true, inProgressFilter: "", openFilter: "", resolvedFilter: "",
                    todayFilter: "", filterData: filterTask, filterType: value, closedFilter: "active", allFilter: ""
                });
        }
    }
    TaskSearchFilter = (event) => {
        this.setState({ TaskSearch: event.target.value },  () => {
            if (this.state.TaskSearch) {
                var filterTask = [];
                let TaskList = this.state.TaskList.filter((v, i, a) => a.findIndex(t => (t.TaskID === v.TaskID)) === i);
                filterTask = TaskList.filter(x => x.Subject.toLowerCase().indexOf(this.state.TaskSearch.toLowerCase()) !== -1);
                this.setState({ isfilter: true, filterData : filterTask });
            }
        })   
    }
    ProjectSearchFilter = (value) => {
        if (this.state.ProjectSearch) {
            var filterTask = [];
            filterTask = this.state.TaskList.filter(x => x.ProjectName.toLowerCase().indexOf(this.state.ProjectSearch.toLowerCase()) !== -1);
            this.setState({ isfilter: true, filterData: filterTask });
        }
        if(value){
            var filterTask = [];
            filterTask = this.state.TaskList.filter(x => x.ProjectName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            this.setState({ isfilter: true, filterData: filterTask,Loading:false });
        }
        else{
            var filterTask = [];
            filterTask = this.state.TaskList;
            this.setState({ isfilter: true, filterData: filterTask,Loading:false });
        }
    }
    dateFilter = () => {
        if (this.state.createdFilter || this.state.updatedFilter || this.state.dueDateFilter) {
            if (this.state.createdFilter) {
                var filterDate = [];
                var filterList = [];
                let startingDate = this.state.startCreated;
                let endingDate = this.state.endCreated;
                filterDate = this.state.TaskList.filter(x => moment(x.CreatedOn).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.CreatedOn).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                filterList = filterDate;
                this.setState({ isfilter: true, filterData: filterDate });
                if (this.state.updatedFilter) {
                    let startingDate = this.state.startUpdated;
                    let endingDate = this.state.endUpdated;
                    var filterDate1 = filterList.filter(x => moment(x.UpdatedOn).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.UpdatedOn).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                    filterList = filterDate1;
                    this.setState({ isfilter: true, filterData: filterDate1 });
                    if (this.state.dueDateFilter) {
                        let startingDate = this.state.startDue;
                        let endingDate = this.state.endDue;
                        var filterDate2 = filterList.filter(x => moment(x.DueDate).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.DueDate).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                        this.setState({ isfilter: true, filterData: filterDate2 });
                    }
                }
            }
        }
        if (!this.state.createdFilter && (this.state.updatedFilter || this.state.dueDateFilter)) {
            let startingDate = this.state.startUpdated;
            let endingDate = this.state.endUpdated;
            var filterDate = this.state.TaskList.filter(x => moment(x.UpdatedOn).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.UpdatedOn).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
            this.setState({ isfilter: true, filterData: filterDate });
            if (this.state.dueDateFilter) {
                let startingDate = this.state.startDue;
                let endingDate = this.state.endDue;
                var filterDate1 = this.state.filterData.filter(x => moment(x.DueDate).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.DueDate).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
                this.setState({ isfilter: true, filterData: filterDate1 });
            }
        }
        if (!this.state.createdFilter && !this.state.updatedFilter && this.state.dueDateFilter) {
            let startingDate = this.state.startDue;
            let endingDate = this.state.endDue;
            var filterDate = this.state.TaskList.filter(x => moment(x.DueDate).format('DD-MM-YYYY') >= moment(startingDate).format('DD-MM-YYYY') && moment(x.DueDate).format('DD-MM-YYYY') <= moment(endingDate).format('DD-MM-YYYY'));
            this.setState({ isfilter: true, filterData: filterDate });
        }
    }

    resetFilter = () => {
        this.setState({ isfilter: false, filterData: "", showDate: false, showStatus: false, createdFilter: false, dueDateFilter: false, updatedFilter: false });
    }
    redirectAdd = () => {
        this.props.history.push("/admin/addTasks");
    }
    redirectDate = () => {
        this.setState({ showDate: true, showStatus: false })
        if (this.state.showDate) {
            this.setState({ showDate: false, showStatus: false })
        }
    }
    redirectStatus = () => {
        this.setState({ showStatus: true, showDate: false })
    }

    handleDate = (date, type) => {
        if (type === "startDue") {
            this.setState({ startDue: date, dueDateFilter: true });
        }
        if (type === "startCreated") {
            this.setState({ startCreated: date, createdFilter: true });
        }
        if (type === "startUpdated") {
            this.setState({ startUpdated: date, updatedFilter: true });
        }
        if (type === "endDue") {
            this.setState({ endDue: date });
        }
        if (type === "endCreated") {
            this.setState({ endCreated: date });
        }
        if (type === "endUpdated") {
            this.setState({ endUpdated: date });
        }
    }

    getDailyStatusList = () => {
        try {
            api.get("dailystatus/getDailyStatusList").then(res => {
                if (res.success) {
                    let obj = {
                        FullName: "Select All",
                        ResourceID: ""
                    }
                    var finalResourceList = []

                    res.data.map((r, i) => {
                        res.data[i].FullName = `${r.FirstName} ${r.LastName}`
                    });
                    finalResourceList.push(obj)
                    for (let i = 0; i < res.data.length; i++) {
                        finalResourceList.push(
                            res.data[i]
                        )
                    }
                    this.setState({
                        DailyStatusList: finalResourceList,
                        filterVal: {
                            value: CommonConfig.loginData().PersonID, label: CommonConfig.loginData().FirstName + ' ' + CommonConfig.loginData().LastName
                        }
                    });
                }
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }
    requestChange = (e, value, type) => {
        if (type === "DailyStatus") {
            this.setState({ DailyStatus: value, DailyStatusErr: false, DailyStatusHelperText: "" });
        }
    }
    handleResoureOnChange = (value,type) => {
        if (value !== null) {
            if(type == "Assignee"){
                this.setState({Loading:true})
                this.setState({ filterVal: value })
                this.getTaskList(value.value)
            }else{
                this.setState({ filterValObserver: value })
                this.getTaskList(this.state.filterVal.value,value)
            }
        }
    }
    TasksonClick = (value) => {

        this.getTaskList(this.state.filterVal.value)

    }
    onRowClick = (action, row, event) => {
        this.props.history.push({
            pathname: "/admin/viewTasks",
            state: { id: row.TaskID }
        })
    };
    getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    handleDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
    }

    handleResetClick() {
        this.setState(this.getInitialState());
    }
    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
        return true;
    };
    
    onActionFailure = (e) => {
        const span = document.createElement('span');
        if (this.kanban) {
            this.kanban.element.parentNode.insertBefore(span, this.kanban.element);
            span.style.color = "#FF0000";
            span.innerHTML = "Server exception: 404 Not found";
        }
    }

    render() {
        const { TaskList, startCreated, startDue, startUpdated, endCreated, endDue, endUpdated,
            inProgressFilter, openFilter, resolvedFilter, closedFilter, allFilter,
            TasksErr, TasksHelperText,ObserverErr,ObserverHelperText } = this.state;
        const { from, to } = this.state;
        
        const DailyStatus = this.state.DailyStatusList.length > 0 ? this.state.DailyStatusList.map(DailyStatus => {
            return { value: DailyStatus.ResourceID, label: DailyStatus.FullName }
        }) : []

        const modifiers = { start: from, end: to };
        const { Tasks, filterString } = this.state;
        const filteredData = (!filterString) ? Tasks : TasksErr.filter(d => d.FullName == filterString);
        this.onActionFailure = this.onActionFailure.bind(this);
        
        

        const tableColumns = [
            {
                Header: 'Issue Type',
                accessor: data => {

                    return (
                        <span class="badgetype badge-warning">{data.TaskType}</span>
                    )
                },

                id: 'TaskType',
                sortable: false,
                filterable:false,
                width: 80
            },
            {
                Header: 'Key',
                id: 'TaskID',
                Cell: record => {
                    console.log("record.original.....",record.original);
                    return (
                        <Tooltip arrow title={record.original.ProjectName.split(" ").join("") + "-" + record.original.ProjectKey} >
                            <span>{record.original.ProjectName.substring(0, 4) + "-" + record.original.ProjectKey}</span>
                        </Tooltip>
                    )
                },
                sortable: true,
                filterMethod: (filter, row ) => {
                    let key =  row._original.ProjectName && row._original.ProjectKey ? 
                    row._original.ProjectName + " " + row._original.ProjectKey : '';
                    return (
                        row._original ? String(key).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                filterable:true,
                width: 100
            },
            {
                Header: 'Subject',
                accessor: 'Subject',
                Cell: record => {
                    return (
                        <Tooltip title={record.original.Subject} arrow>
                            <span>{record.original.Subject}</span>
                        </Tooltip>

                    )
                },
                id: 'Subject',
                sortable: true,
                filterable: true,
                className: "nowrap",
                width: 270,
            },
            {
                Header: 'Assignee',
                Cell: record => {
                    return (
                        <Tooltip title={record.original.FirstName + " " + record.original.LastName} arrow >
                            <span>
                                {record.original.FirstName + " " + record.original.LastName}
                            </span>
                        </Tooltip>
                    )
                },
                id: 'Assignee',
                className: "nowrap",
                filterMethod: (filter, row ) => {
                    let assignee =  row._original.FirstName && row._original.LastName ? 
                    row._original.FirstName + " " + row._original.LastName : '';
                    return (
                        row._original ? String(assignee).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
                width: 130,
            },
            {
                Header: 'Observer',
                Cell: record => {
                    if(record.original.Observer != null){
                    var array = record.original.Observer.split(",");
                    return array.map((content,idx) => {

                    return (
                        array.length === idx + 1 ?
                        <Tooltip title={content} arrow >
                            <span>
                                {content}
                            </span>
                        </Tooltip>
                        :
                        <Tooltip title={content + " , "} arrow >
                            <span>
                                {content + " , "}
                            </span>
                        </Tooltip>
                    )
                    })
                }else{
                    return(
                        <span></span>
                    )
                }
                },
                id: 'Observer',
                sortable: true,
                filterMethod: (filter, row ) => {
                    let observer =  row._original.Observer;
                    return (
                        row._original ? String(observer).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                className: "nowrap",
                width: 130,
            },
            {
                Header: 'Task Performance',
                Cell: record => {
                    let TaskPerformance = (record.original.TotalHours < record.original.EstimetedHours) ? "EXELLENT" : (record.original.TotalHours == record.original.EstimetedHours) ? "GOOD" : "OVERDUE";

                    let TimePercentage = (record.original.TotalHours / record.original.EstimetedHours)*100 ;
                    return (
                        <Tooltip title={record.original.TaskPerformance}>
                            <span className={"badge badge-" + TaskPerformance.toLowerCase()}>
                            {TaskPerformance}-{TimePercentage.toFixed()}%
                             </span>
                        </Tooltip>  
                    )
                },
                id: 'TaskPerformance',
                width: 110,
                sortable: true,
                filterMethod: (filter, row ) => {
                    let TaskPerformance =  row._original.TaskPerformance;
                    return (
                        row._original ? String(TaskPerformance).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
            },
            {
                Header: 'Status',
                Cell: record => {

                    return (
                        <Tooltip title={record.original.TaskStatus} arrow >
                            <span className={"badge badge-" + record.original.TaskStatus.split(" ").join("").toLowerCase()}>
                                {record.original.TaskStatus}
                            </span>
                        </Tooltip>
                    )
                },
                id: 'TaskStatus',
                sortable: true,
                filterMethod: (filter, row ) => {
                    let taskStatus =  row._original.TaskStatus;
                    return (
                        row._original ? String(taskStatus).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
            },
            {
                Header: 'Priority',
                Cell: record => {

                    return (
                        <span>
                            {record.original.Priority}
                        </span>
                    )
                },
                id: 'Priority',
                sortable: true,
                filterMethod: (filter, row ) => {
                    let priority =  row._original.Priority;
                    return (
                        row._original ? String(priority).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
            },
            {
                Header: 'CreatedBy',
                Cell: record => {
                    return (
                        <Tooltip title={record.original.FirstName + " " + record.original.LastName} arrow >
                            <span>
                                {record.original.FirstName + " " + record.original.LastName}
                            </span>
                        </Tooltip>
                    )
                },
                id: 'CreatedBy',
                sortable: true,
                filterMethod: (filter, row ) => {
                    let createdBy =  row._original.FirstName && row._original.LastName ? 
                    row._original.FirstName + " " + row._original.LastName : '';
                    return (
                        row._original ? String(createdBy).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
            },
            {
                Header: 'Hrs Today',
                Cell: record => {
                    if (CommonConfig.isEmpty(record.original.HrsToday) || moment(record.original.TaskStatusDate).format('DD-MM-YYYY') !== moment().format('DD-MM-YYYY')) {
                        return (
                            <span>
                                {"0"}
                            </span>
                        )

                    }
                    else {
                        return (
                            <span>
                                {record.original.HrsToday}
                            </span>
                        )
                    }
                },
                id: 'HrsToday',
                filterMethod: (filter, row ) => {
                    let hrsToday =  row._original.HrsToday;
                    return (
                        row._original ? String(hrsToday).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
            },
            {
                // className: "text-align-right",
                Header: 'Total Hours',
                Cell: record => {
                    if (CommonConfig.isEmpty(record.original.TotalHours)) {
                        return 0
                    }
                    else {
                        return (
                            <span>
                                {record.original.TotalHours}
                            </span>
                        )
                    }
                },
                id: 'TotalHours',
                filterMethod: (filter, row ) => {
                    let totalHours =  row._original.TotalHours;
                    return (
                        row._original ? String(totalHours).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
            },
            {
                // className: "text-align-right",
                Header: '%Complete',
                Cell: record => {
                    if (CommonConfig.isEmpty(record.original.PercentageCompleted)) {
                        return null
                    }
                    else {
                        return (
                            <span>
                                {record.original.PercentageCompleted}
                            </span>
                        )
                    }
                },
                id: 'PercentageCompleted',
                filterMethod: (filter, row ) => {
                    let percentageCompleted =  row._original.PercentageCompleted;
                    return (
                        row._original ? String(percentageCompleted).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
            },
            {
                Header: 'Start',
                Cell: record => {

                    return (
                        <span>
                            {moment(record.original.StartDate).format(CommonConfig.dateFormat.dateFirst)}
                        </span>
                    )
                },
                id: 'StartDate',
                filterMethod: (filter, row ) => {
                    let startdate =  row._original.StartDate;
                    return (
                        row._original ? String(startdate).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
            },
            {
                Header: 'Created',
                Cell: record => {
                    if (CommonConfig.isEmpty(record.original.CreatedOn)) {
                        return null
                    }
                    else {
                        return (
                            <span>
                                {moment(record.original.CreatedOn).format(CommonConfig.dateFormat.dateFirst)}
                            </span>
                        )
                    }
                },
                id: 'CreatedOn',
                filterMethod: (filter, row ) => {
                    let createdon =  row._original.CreatedOn;
                    return (
                        row._original ? String(createdon).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
            },
            {
                Header: 'Updated',
                Cell: record => {

                    if (CommonConfig.isEmpty(record.original.UpdatedOn)) {
                        return null
                    }
                    else {
                        return (
                            <span>
                                {moment(record.original.UpdatedOn).format(CommonConfig.dateFormat.dateFirst)}
                            </span>
                        )
                    }
                },
                id: 'UpdatedOn',
                filterMethod: (filter, row ) => {
                    let updated =  row._original.UpdatedOn;
                    return (
                        row._original ? String(updated).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
            },
            {
                Header: 'Due Date',
                Cell: record => {
                    if (CommonConfig.isEmpty(record.original.DueDate)) {
                        return null
                    }
                    else {
                        return (
                            <span>
                                {moment(record.original.DueDate).format(CommonConfig.dateFormat.dateFirst)}
                            </span>
                        )
                    }
                },
                id: 'DueDate',
                filterMethod: (filter, row ) => {
                    let duedate =  row._original.DueDate;
                    return (
                        row._original ? String(duedate).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                sortable: true,
            },
            {
                Header: 'Attachment',
                id: 'Attachment',
                accessor: 'Attachment',
                sortable: true,
            },
        ]
        console.log("HrsToday is ", this.state.HrsToday);
        // const tableColumnskanbanBar = [
        //     {
        //         Header: 'Project',
        //         accessor: data => {
        //             return (
        //                 <Tooltip title={data.ProjectName} arrow>
        //                     <span>{data.ProjectName}</span>
        //                 </Tooltip>
        //             )
        //         },
        //         id: 'ProjectID',
        //         sortable: true,
        //         filterMethod: (filter, row ) => {
        //             let projectname =  row._original.ProjectName;
        //             return (
        //                 row._original ? String(projectname).toLowerCase().includes(filter.value.toLowerCase()) : row
        //             )
        //         }, 
        //         width: 100,
        //         className: "nowrap",
        //     },
        //     {
        //         Header: 'Task',
        //         accessor: data => {
        //             return (
        //                 <Tooltip title={data.Subject} arrow>
        //                     <span> {data.Subject} </span>
        //                 </Tooltip>
        //             )
        //         },
        //         id: 'TaskID',
        //         sortable: true,
        //         filterMethod: (filter, row ) => {
        //             let subject =  row._original.Subject;
        //             return (
        //                 row._original ? String(subject).toLowerCase().includes(filter.value.toLowerCase()) : row
        //             )
        //         }, 
        //         className: "nowrap",
        //         width: 230,
        //     },
        //     {
        //         Header: 'Assignee',
        //         Cell: record => {
        //             return (
        //                 <Tooltip title={record.original.FirstName + " " + record.original.LastName} arrow >
        //                     <span>
        //                         {record.original.FirstName + " " + record.original.LastName}
        //                     </span>
        //                 </Tooltip>
        //             )
        //         },
        //         id: 'Assignee',
        //         className: "nowrap",
        //         filterMethod: (filter, row ) => {
        //             let assignee =  row._original.FirstName && row._original.LastName ? 
        //             row._original.FirstName + " " + row._original.LastName : '';
        //             return (
        //                 row._original ? String(assignee).toLowerCase().includes(filter.value.toLowerCase()) : row
        //             )
        //         }, 
        //         sortable: true,
        //     },
        //     {
        //         Header: 'Est Date',
        //         accessor: "EstDate",
        //         id: 'EstDate',
        //         sortable: true,
        //         filterable:true
        //     },
        //     {
        //         Header: 'Actual Date',
        //         accessor: "ActualDate",
        //         Header: 'ActualDate',
        //         id: 'ActualDate',
        //         sortable: true,
        //         filterable:true
        //     },
        //     {
        //         Header: 'Status',
        //         accessor: data => {
        //             return (
        //                 <Tooltip title={data.TaskStatus} arrow>
        //                     <span className={"badge badge-" + data.TaskStatus.split(" ").join("").toLowerCase()}>{data.TaskStatus}</span>
        //                 </Tooltip>
        //             )
        //         },
        //         id: 'Status',
        //         sortable: true,
        //         filterMethod: (filter, row ) => {
        //             let taskStatus =  row._original.TaskStatus;
        //             return (
        //                 row._original ? String(taskStatus).toLowerCase().includes(filter.value.toLowerCase()) : row
        //             )
        //         }, 
        //     },
        //     {
        //         Header: 'Note',
        //         className: "text-align-left",
        //         accessor: data => {
        //             return (
        //                 <Tooltip title={data.Note} arrow>
        //                     <span> {data.Note} </span>
        //                 </Tooltip>
        //             )
        //         },
        //         id: 'Note',
        //         sortable: true,
        //         filterable:true,
        //         className: "nowrap",
        //     },
        // ]
        return (
        <div>
            <Box m={0} pt={0}>
       
        </Box>

            <GridContainer className="UserList-outer">
                {this.state.Loading === true ? (
                    <div className="loading">
                    <SimpleBackdrop />
                    </div>
                ) : null
                }
                <GridItem xs={12} sm={12} md={12}>                    
                    <div className="filter-outer ">
                        <div className="fo-heading">
                            <a onClick={() => this.setState({ searchToggle: !this.state.searchToggle })} ><i className="fas fa-angle-down"></i><h4>Search Filters</h4></a>
                        </div>
                    </div>
                    <GridContainer className="UserList-outer">
                                <GridItem xs={12} sm={3} md={3}>
                                    <div className="search-resource">
                                        {/* <div className="with-icon"> */}
                                            <Autocomplete
                                                options={DailyStatus}
                                                id="DailyStatus"
                                                value={this.state.filterVal}
                                                getOptionLabel={option => option.label}
                                                onChange={(event, value) => this.handleResoureOnChange(value,"Assignee")}
                                                disabled={this.state.ApproveAccess}
                                                renderInput={params => (
                                                    <TextField {...params} error={TasksErr} helperText={TasksHelperText}
                                                        label="Search Resources Name" />)}
                                            />

                                   {/* <div className="with-icon">
                                    <Tooltip title="Search" placement="bottom">
                                        <Button style={{ top: "29px", right: "45px" }} color="primary" justIcon  onClick={(event, value) => this.handleResoureOnChange(value,"Assignee")} ><SearchIcon /></Button>
                                    </Tooltip>
                                    </div> */}
                                            {/* <Tooltip title="Search" >
                                                <Button color="primary" justIcon onClick={() => this.TasksonClick()}><SearchIcon /></Button>
                                            </Tooltip> */}
                                        {/* </div> */}
                                    </div>
                                </GridItem>
                                {/* <GridItem xs={12} sm={3} md={2}>
                                    <div className="search-resource">
                                        <div className="with-icon">
                                            <Autocomplete
                                            id="DailyStatus"
                                            options={DailyStatus}
                                                value={this.state.filterValObserver}
                                                getOptionLabel={option => option.label}
                                                onChange={(event, value) => this.handleResoureOnChange(value,"Observer")}
                                                renderInput={params => (
                                                    <TextField {...params} error={ObserverErr} helperText={ObserverHelperText}
                                                        label="Search Observer" />)}
                                            />
                                            <Tooltip title="Search" >
                                                <Button color="primary" justIcon onClick={() => this.ObserveronClick()}><SearchIcon /></Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </GridItem> */}
                                <GridItem xs={12} sm={3} md={3}>
                                    <div className="search-project">
                                        {/* <div className="with-icon"> */}
                                            <Autocomplete
                                                options={this.state.ProjectList}
                                                id="DailyStatus"
                                                value={this.state.ProjectSearch}
                                                getOptionLabel={option => option.ProjectName}
                                                onChange={(event, value) => {
                                                    this.setState({ ProjectSearch: value ? value.ProjectName : '',Loading:true })
                                                    this.ProjectSearchFilter(value ? value.ProjectName : '')
                                                }}
                                                renderInput={params => (
                                                    <TextField {...params} label="Projects List" />)}
                                            />
                                            {/* <Tooltip title="Search" placement="bottom">
                                                <Button color="primary" justIcon onClick={() => this.ProjectSearchFilter()}><SearchIcon /></Button>
                                            </Tooltip> */}

                                            {/* <TextField label="Projects List..." type="text" name="query" value={this.state.ProjectSearch} onChange={(event) => this.setState({ ProjectSearch: event.target.value })} />
                                            <Tooltip title="Search" placement="bottom">
                                                <Button color="primary" justIcon onClick={() => this.ProjectSearchFilter()}><SearchIcon /></Button>
                                            </Tooltip> */}
                                        {/* </div> */}
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                    <div className="search-resource">
                                        <div className="with-icon">
                                            <TextField label="Search Task..." type="text" name="query" value={this.state.TaskSearch} onChange={(event) => this.TaskSearchFilter(event)} />
                                        </div>
                                        {/* <div className="with-icon">
                                    <Tooltip title="Search" placement="bottom">
                                        <Button style={{ top: "0px", right: "0px" }} color="primary" onClick={(event, value) => this.handleResoureOnChange(value,"Assignee")} >Search</Button>
                                    </Tooltip>
                                    </div> */}
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                    <div className="task-filter">
                                        <Tooltip title="Filter" placement="bottom">
                                            <Fab color="primary" aria-label="Add" icon="<AddIcon />" position="fixed" style={{ width: "36px", height: "36px", position: "relative" }} onClick={() => this.redirectDate()}>
                                                <i className="fas fa-filter"></i>
                                            </Fab>
                                        </Tooltip>
                                    </div>
                                </GridItem>
                            </GridContainer>
                          
                    {this.state.searchToggle ?
                        <div className="fo-content">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={8}>
                                <div className="task-tabs">
                                    <span className="mr-10 font-bold">Status:</span>
                                    <Tooltip title="ALL" placement="bottom">
                                        <Button className={allFilter} onClick={() => this.applyFilter("ALL")} value="ALL">ALL</Button>
                                    </Tooltip>
                                    <Tooltip title="In Progress" placement="bottom">
                                        <Button className={inProgressFilter} onClick={() => this.applyFilter("IN PROGRESS")} value="IN PROGRESS">In Progress</Button>
                                    </Tooltip>
                                    <Tooltip title="Open" placement="bottom">
                                        <Button className={openFilter} onClick={() => this.applyFilter("OPEN")} value="OPEN">Open</Button>
                                    </Tooltip>
                                    <Tooltip title="Resolved" placement="bottom">
                                        <Button className={resolvedFilter} onClick={() => this.applyFilter("RESOLVED")} value="RESOLVED">Resolved</Button>
                                    </Tooltip>
                                    <Tooltip title="Closed" placement="bottom">
                                        <Button className={closedFilter} onClick={() => this.applyFilter("CLOSED")} value="CLOSED">Closed</Button>
                                    </Tooltip>
                                </div>
                            </GridItem>
                            <GridItem justify="end" xs={12} sm={12} md={4}>
                                <div className="add-task">
                                    <Tooltip title="Add Task" placement="bottom">
                                        {/* <Fab color="primary" aria-label="Add" icon="<AddIcon />" position="fixed" style={{ width: "36px", height: "36px", position: "relative" }} onClick={() => this.redirectAdd()}>
                                            <AddIcon />
                                        </Fab> */}
                                        <Button color="primary" justIcon onClick={() => this.redirectAdd()}><AddIcon /> Add Task</Button>
                                    </Tooltip>
                                </div>
                            </GridItem>
                        </GridContainer>
                    
                        </div>
                        : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {this.state.showDate ?
                        (<GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div className="filter-input">
                                            <h5>Created</h5>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    dateFormat={"DD/MM/YYYY"}
                                                    timeFormat={false}
                                                    className="input-group"
                                                    selected={moment(startCreated)}
                                                    inputProps={{ placeholder: "Start Date" }}
                                                    onChange={(date) => this.handleDate(date, "startCreated")}
                                                    closeOnSelect={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params} label="Start Date" />)} />
                                                <Icon className="date-icon tp-slam">date_range</Icon>
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div className="filter-input">
                                            <h5>To</h5>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    dateFormat={"DD/MM/YYYY"}
                                                    timeFormat={false}
                                                    className="input-group"
                                                    selected={moment(endCreated)}
                                                    inputProps={{ placeholder: "End Date" }}
                                                    onChange={(date) => this.handleDate(date, "endCreated")}
                                                    closeOnSelect={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params} label="End Date" />)} />
                                                <Icon className="date-icon tp-slam">date_range</Icon>
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div className="filter-input">
                                            <h5>Due Date</h5>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    dateFormat={"DD/MM/YYYY"}
                                                    timeFormat={false}
                                                    className="input-group"
                                                    selected={moment(startDue)}
                                                    inputProps={{ placeholder: "Start Date" }}
                                                    onChange={(date) => this.handleDate(date, "startDue")}
                                                    closeOnSelect={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params} label="Start Date" />)} />
                                                <Icon className="date-icon tp-slam">date_range</Icon>
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div className="filter-input">
                                            <h5>To</h5>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    dateFormat={"DD/MM/YYYY"}
                                                    timeFormat={false}
                                                    className="input-group"
                                                    selected={moment(endDue)}
                                                    inputProps={{ placeholder: "End Date" }}
                                                    onChange={(date) => this.handleDate(date, "endDue")}
                                                    closeOnSelect={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params} label="End Date" />)} />
                                                <Icon className="date-icon tp-slam">date_range</Icon>
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div className="filter-input">
                                            <h5>Updated</h5>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    dateFormat={"DD/MM/YYYY"}
                                                    timeFormat={false}
                                                    className="input-group"
                                                    selected={moment(startUpdated)}
                                                    inputProps={{ placeholder: "Start Date" }}
                                                    onChange={(date) => this.handleDate(date, "startUpdated")}
                                                    closeOnSelect={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params} label="Start Date" />)} />
                                                <Icon className="date-icon tp-slam">date_range</Icon>
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div className="filter-input">
                                            <h5>To</h5>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    dateFormat={"DD/MM/YYYY"}
                                                    timeFormat={false}
                                                    className="input-group"
                                                    selected={moment(endUpdated)}
                                                    inputProps={{ placeholder: "End Date" }}
                                                    onChange={(date) => this.handleDate(date, "endUpdated")}
                                                    closeOnSelect={true}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params} label="End Date" />)} />
                                                <Icon className="date-icon tp-slam">date_range</Icon>
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={1} md={1}>
                                <div className="with-icon">
                                    <Tooltip title="Search" placement="bottom">
                                        <Button style={{ top: "29px", right: "55px" }} color="primary" justIcon onClick={() => this.dateFilter()} ><SearchIcon /></Button>
                                    </Tooltip>
                                    <Tooltip title="Close" placement="bottom">
                                        <Button style={{ top: "29px", left: "25px" }} color="danger" justIcon onClick={() => this.resetFilter()}><CloseIcon /></Button>
                                    </Tooltip>
                                </div>
                            </GridItem>
                        </GridContainer>
                        ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>

                    <div class="page-content">
                        <div class="common-datatable">
                            <ReactTable
                                data={this.state.isfilter ? this.state.filterData : TaskList}
                                minRows={5}
                                resizable={false}
                                sortable={true}
                                filterable={true}
                                columns={tableColumns}
                                defaultPageSize={10}
                                defaultFilterMethod={CommonConfig.filterCaseInsensitive}
                                className="-striped -highlight"
                                style={{ cursor: "pointer" }}
                                getTdProps={(state, rowInfo, column) => {
                                    if (rowInfo) {
                                        return {
                                            onClick: (event, handleOriginal) => {
                                                event.stopPropagation();
                                                let selection = window.getSelection().toString();
                                                if (selection.length <= 0) {
                                                    this.onRowClick("click", rowInfo.row._original, event);
                                                } else {
                                                }
                                                if (handleOriginal) handleOriginal();
                                            }
                                        };
                                    }
                                    else {
                                        return '';
                                    }
                                }}
                            />
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
            </div>
        );
    }
}
export default Tasks;