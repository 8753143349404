import React, { Component } from 'react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CreateIcon from '@material-ui/icons/Create';
import api from '../../utils/apiClient';
import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
class ProjectInstancelisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProjectInstanceList: [],
      searchText: "",
      searchedColumn: ""
    }
  }

  componentDidMount() {
    this.getProjectInstanceList();
  }

  getProjectInstanceList = () => {
    try {
      api.get("projectinstance/getProjectInstanceList").then(res => {
        this.setState({ ProjectInstanceList: res.data });
      }).catch(error => {
      })

    }
    catch (err) {
    }
  }

  edit = (record) => {
    this.props.history.push({
      pathname: "/admin/EditProjectInstance",
      state: { id: record.original.ProjectInstanceID }
    })
  }

  redirectAdd = () => {
    this.props.history.push("/admin/addProjectInstance");
  }

  render() {
    const { ProjectInstanceList } = this.state;
   // console.log("Project Instance",ProjectInstanceList);
    const tableColumns = [
      {
        Header: 'Instance',
        accessor: 'InstanceName',
        id: 'InstanceName',
        sortable: true,
      },
      {
        Header: 'Front End Host',
        accessor: 'FrontEndIP',
        id: 'FrontEndIP',
        sortable: true,
      },
      {
        Header: 'API Host',
        accessor: 'APIIP',
        id: 'APIIP',
        sortable: true,
      },
      {
        Header: 'DB Host',
        accessor: 'DBIP',
        id: 'DBIP',
        sortable: true,
      },
      {
        Header: 'Description',
        accessor: 'Description',
        id: 'Description',
        sortable: true,
      },
      {
        Header: 'Action',
        id: 'action',
        sortable: false,
        className:"text-align-right",
        Cell: record => {
          return (
            <span>
              <Tooltip title="Edit" placement="bottom">
                <Button className="btn-icon" color="primary" onClick={() => this.edit(record)}><CreateIcon /></Button>
              </Tooltip>
            </span>
          )
        },
        filterable: false
      },
    ]
    return (
      <Card>
        <CardHeader>
        <Tooltip title="Add" placement="bottom">
          <Button type="button" color="primary" onClick={() => this.redirectAdd()}>Add Project Instance</Button>
          </Tooltip>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={ProjectInstanceList}
            filterable
            minRows={2}
            resizable={false}
            columns={tableColumns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
    )
  }
}
export default ProjectInstancelisting;