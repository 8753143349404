import React, { Component } from 'react'
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
// material ui icons
import Contacts from "@material-ui/icons/Contacts";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { CommonConfig } from '../../utils/constant';
import CardBody from "components/Card/CardBody.js";
import cogoToast from 'cogo-toast';
import api from '../../utils/apiClient';
import Icon from "@material-ui/core/Icon";
import Datetime from "react-datetime";
import moment from 'moment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from "@material-ui/core/InputLabel";
// style for this view
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Radio from '@material-ui/core/Radio';
import SimpleBackdrop from "../../utils/general";
import Autocomplete from '@material-ui/lab/Autocomplete';

const style = {
    ...customSelectStyle
};

class AddFederalLeave extends Component {
    constructor(props) {
        super(props)

        this.state = {
            Name: '',
            NameErr: '',
            NameHelperText: '',
            LeaveDate: '',
            LeaveDateErr: false,
            LeaveDateHelperText: '',
            DayOfTheWeek: '',
            Loading: false,

        }
    }

    componentDidMount(){
        console.log("typeee",this.props)
        if(this.props.history.location.state.type=='edit'){
            const { Name ,Date, DayOfTheWeek } = this.props.history.location.state.data
            this.setState({
                Name:Name,
                LeaveDate:moment(Date).format("YYYY-MM-DD HH:mm:ss"),
                DayOfTheWeek:DayOfTheWeek
            }, ()=> {
                console.log("stateeeeee",this.state)
            })
        }
    }

    handleChange = (e,type) => {
        if (type === 'Name') {
            if (e.target.value == "") {
                this.setState({
                    Name: e.target.value, 
                    NameErr: true, 
                    NameHelperText: 'Name of the leave is required' });
            } else {
                this.setState({ Name: e.target.value, NameErr: false, NameHelperText: '' });
            }
        }
        else {
            this.setState({
                LeaveDate : moment(e). format("YYYY-MM-DD HH:mm:ss"),
                DayOfTheWeek: moment(e). format("dddd"),
                LeaveDateErr: false,
                LeaveDateHelperText: '',
            }, ()=>{
                console.log("states",this.state)
            })
        }
    }

    validate = () => {
        let formIsvalid = true;
        const { Name ,LeaveDate } = this.state;
        if(CommonConfig.isEmpty(Name)){
            formIsvalid = false;
          this.setState({NameErr:true , NameHelperText:"Name of the leave is required"});
        }

        if(CommonConfig.isEmpty(LeaveDate)){
            formIsvalid = false;
          this.setState({LeaveDateErr:true , LeaveDateHelperText:"Start Date is required"});
        }

        return (formIsvalid);
    }

    handleSubmit = (e,type) => {

        e.preventDefault();
        if (this.validate()) {
            const { Name ,LeaveDate,DayOfTheWeek } = this.state;
            this.setState({ Loading: true });
            let data;
            data = {
                // ResourceID: CommonConfig.loginData().PersonID,
                UserID: CommonConfig.loginData().PersonID,
                Name: Name,
                LeaveDate: LeaveDate,
                DayOfTheWeek: DayOfTheWeek,
            }

            if(this.props.history.location.state.type=='edit'){
                const { editName ,editLeaveDate, editDayOfTheWeek } = this.props.history.location.state.data
                data.LeaveCalendarID = this.props.history.location.state.data.LeaveCalendarID
            }

            try {
                api.post("federalLeave/addUpdateFederalLeave", data).then(res => {     
                this.setState({ Loading:false });
                    if (res.success) {
                        cogoToast.success("Leave added successfully");
                        this.props.history.push("/admin/viewFederalLeave");
                    }       
                    else {
                        cogoToast.error("Something went wrong");
                    }
                }).catch(error => {
                    console.log("error", error);
                });
            }
            catch (err) {
                console.log("error", err);
            }
        }
    }

    render() {
        const { classes } = this.props;        
        return (
            <div>
                {this.state.Loading === true ? (
                    <div className="loading">
                        <SimpleBackdrop />
                    </div>
                ) : null
                }
                <GridContainer justify="center">
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <Contacts />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>{this.props.history.location.state.type ? 'Edit' : 'Add'} Leave</h4>
                            </CardHeader>
                            <CardBody>
                                <form>
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} md={4}>
                                            {/* <InputLabel >Name of the leave</InputLabel> */}
                                            <TextField success={this.state.NameErr === false}
                                                error={this.state.NameErr === true}
                                                value={this.state.Name}
                                                error={this.state.NameErr}
                                                helperText={this.state.NameHelperText}
                                                label="Name of the leave *"
                                                id="leaveName"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={(e) => this.handleChange(e, "Name")}
                                                inputProps={{
                                                    autoComplete: "off",
                                                }}
                                            />
                                            </GridItem>
                                            <GridItem xs={12} sm={4} md={4}>
                                            <Datetime
                                                dateFormat={"DD/MM/YYYY"}
                                                value={moment(this.state.LeaveDate)}
                                                timeFormat={false}
                                                selected={moment(this.state.LeaveDate)}
                                                isValidDate={this.validLeaveDate}
                                                onChange={(e, date) => this.handleChange(e, "LeaveDate")} closeOnSelect={true}
                                                renderInput={params => (
                                                    <TextField
                                                        error={this.state.LeaveDateErr}
                                                        helperText={this.state.LeaveDateHelperText}
                                                        {...params} label="Leave Date *" fullWidth disabled
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Icon>date_range</Icon>
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <div>{this.state.currentDay}</div>
                                                            ),
                                                            placeholder: "Leave Date *"
                                                        }}
                                                    />
                                                )}
                                            />
                                            </GridItem>
                                            <GridItem xs={12} sm={4} md={4}>
                                            <TextField
                                                value={this.state.DayOfTheWeek}
                                                label="Day of the week"
                                                id="dayOfTheWeek"
                                                disabled={true}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    autoComplete: "off",
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.formCategory} >
                                            </div>
                                            <div className={classes.center} style={{ display: "flex", justifyContent: "center" }}>
                                                {this.props.history.location.state ?
                                                    <Button color="rose" onClick={(e) => this.handleSubmit(e, "edit")}>
                                                        Save
                                                </Button>
                                                    :
                                                    <Button color="rose" onClick={(e) => this.handleSubmit(e, "add")}>
                                                        Save
                                                </Button>
                                                 } 
                                                <Button color="rose" onClick={(e) => this.props.history.go(-1)}>
                                                    Cancel
                                            </Button>
                                            </div>

                                        </GridItem>

                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {/* </div> */}



            </div>
        )
    }
}

AddFederalLeave.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(AddFederalLeave);
