import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// material ui icons
import Contacts from "@material-ui/icons/Contacts";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { CommonConfig } from "../../utils/constant";
import CardBody from "components/Card/CardBody.js";
import ImageUploader from "components/CustomUpload/PictureUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import cogoToast from 'cogo-toast';
import EditIcon from "@material-ui/icons/Edit";
import api from "../../utils/apiClient";
import { FormHelperText, InputLabel } from "@material-ui/core";
import ReactTable from "react-table";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DoneIcon from "@material-ui/icons/Done";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
// style for this view
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { Grid } from "@material-ui/core";
import { parse } from "date-fns";

const style = {
  ...customSelectStyle,
};

class addSalaryMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTaxExempt: "",
      isTaxExemptErr: false,
      isTaxExemptHelperText: "",

      EmployeeCode: "",
      EmployeeCodeErr: false,
      EmployeeCodeHelperText: "",

      BasicSalary: "",
      BasicSalaryErr: false,
      BasicSalaryHelperText: "",

      EmployeeName: "",
      EmployeeNeErr: false,
      EmployeeNameHelperText: "",

      Designation: "",
      DesignationErr: false,
      DesignationHelperText: "",
      DesignationList: [],

      Amountlimit: "",
      AmountlimitErr: false,
      AmountlimitHelperText: "",

      AllowanceAmountlimit: "",
      AllowanceAmountlimitErr: false,
      AllowanceAmountlimitHelperText: "",

      basicSalary: "",
      BasicSalaryErr: false,
      BasicSalaryHelperText: "",

      Reimbursement: "",
      ReimbursementErr: false,
      ReimbursementHelperText: "",

      AllowanceType: "",
      AllowanceTypeErr: false,
      AllowanceTypeHelperText: "",
      AllowanceTypeList: [],

      PaymentModeList: [],

      PaymentMode: "",
      payMentModeErr: false,
      paymentModeHelperText: "",

      AllowanceSummary: [],

      BankName: "",
      BankNameErr: false,
      BankNameHelperText: "",
      BankNameList: [],

      isTaxExempt: false,

      FixTDS: false,

      TDSAmount: "",
      TDSAmountErr: false,
      TDSAmountHelperText: "",

      IsESICApplicable: false,
      isPFApplication: false,
      isTDSApplication: false,
      isLeaveNotApplicable: false,

      isChecked: false,
      allowanceList: [],
      AllowanceTotal: 0,
    };
  }

  componentDidMount() {
    this.getDesignationList();
    this.getAllowaneTypeList();
    this.getPaymentMode();
    this.getBankName();
    this.getResourceDetails();
  }

  getResourceDetails = (ResourceID) => {
    console.log("data", ResourceID);
    let data;
    data = {
      ResourceID: this.props.location.state.id,
    };
    try {
      api
        .post("payroll/getResourceDetails", data)
        .then((res) => {
          console.log("res...", res);
          this.setState({
            ResourceID: res.data.ResourceID,
            EmployeeName: res.data.FirstName + " " + res.data.LastName,
            Designation: res.data.Designation,
            SalaryDate: res.data.SalaryDate,
            BasicSalary: res.data.BasicSalary,
          });
        })
        .catch((error) => {});
    } catch (err) {}
  };

  getPayrollList = () => {
    let data;
    data = {
      ResourceID: CommonConfig.loginData().PersonID,
    };
    try {
      api
        .post("payroll/getPayrollList", data)
        .then((res) => {
          this.setState({ PayrollList: res.data });
        })
        .catch((error) => {});
    } catch (err) {}
  };

  getDesignationList = () => {
    let data = {
      StringMapType: "DESIGNATION",
    };
    try {
      api
        .post("/stringmap/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ DesignationList: res.data });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {}
  };

  getAllowaneTypeList = () => {
    let data = {
      StringMapType: "ALLOWANCETYPE",
    };
    try {
      api
        .post("/stringmap/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ AllowanceTypeList: res.data });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {}
  };

  getPaymentMode = () => {
    let data = {
      StringMapType: "PAYMENTMODE",
    };
    try {
      api
        .post("/stringmap/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ PaymentModeList: res.data });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {}
  };

  getBankName = () => {
    let data = {
      StringMapType: "BANKNAME",
    };
    try {
      api
        .post("/stringmap/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ BankNameList: res.data });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {}
  };

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  selectChange = (e, type) => {
    if (type === "Designation") {
      this.setState({ Designation: e.target.value });
    }
    if (type === "AllowanceType") {
      this.setState({ AllowanceType: e.target.value });
    }
    if (type === "PaymentMode") {
      this.setState({ PaymentMode: e.target.value });
    }
    if (type === "BankName") {
      this.setState({ BankName: e.target.value });
    }
  };

  onChange = (e, type) => {
    if (type === "TDSAmount") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          TDSAmount: e.target.value,
          TDSAmountErr: true,
          TDSAmountHelperText: "Please enter TDSAmount",
        });
      } else {
        this.setState({
          TDSAmount: e.target.value,
          TDSAmountErr: false,
          TDSAmountHelperText: "",
        });
      }
    }
    if (type === "EmployeeCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          EmployeeCode: e.target.value,
          EmployeeCodeErr: true,
          EmployeeCodeHelperText: "Please enter EmployeeCode",
        });
      } else {
        this.setState({
          EmployeeCode: e.target.value,
          EmployeeCodeErr: false,
          EmployeeCodeHelperText: "",
        });
      }
    }
    if (type === "EmployeeName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          EmployeeName: e.target.value,
          EmployeeNameErr: true,
          EmployeeNameHelperText: "Please enter EmployeeName",
        });
      } else {
        this.setState({
          EmployeeName: e.target.value,
          EmployeeNameErr: false,
          EmployeeNameHelperText: "",
        });
      }
    }
    if (type === "Amountlimit") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Amountlimit: e.target.value,
          AmountlimitErr: true,
          AmountlimitHelperText: "Please enter Amountlimit",
        });
      } else {
        this.setState({
          Amountlimit: e.target.value,
          AmountlimitErr: false,
          AmountlimitHelperText: "",
        });
      }
    }
    if (type === "AllowanceAmountlimit") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          AllowanceAmountlimit: e.target.value,
          AllowanceAmountlimitErr: true,
          AllowanceAmountlimitHelperText: "Please enter Amount limit",
        });
      } else {
        this.setState({
          AllowanceAmountlimit: e.target.value,
          AllowanceAmountlimitErr: false,
          AllowanceAmountlimitHelperText: "",
        });
      }
    }
  };

  validate = () => {
    let Isvalid = true;
    const { EmployeeCode, EmployeeName, Amountlimit } = this.state;

    if (CommonConfig.isEmpty(EmployeeCode)) {
      Isvalid = false;
      this.setState({
        EmployeeCodeErr: true,
        EmployeeCodeHelperText: "Employee Code is required",
      });
    }
    if (CommonConfig.isEmpty(EmployeeName)) {
      Isvalid = false;
      this.setState({
        EmployeeNameErr: true,
        EmployeeNameHelperText: "Employee Name is required",
      });
    }
    if (CommonConfig.isEmpty(Amountlimit)) {
      Isvalid = false;
      this.setState({
        AmountlimitErr: true,
        AmountlimitHelperText: "Amount Limit is required",
      });
    }
    // if (CommonConfig.isEmpty(BasicSalary)) {
    //     Isvalid = false;
    //     this.setState({ BasicSalaryErr: true, BasicSalaryHelperText: "Basic Salary is required" });
    // }
    // if (CommonConfig.isEmpty(Reimbursement)) {
    //     Isvalid = false;
    //     this.setState({ ReimbursementErr: true, ReimbursementHelperText: "Reimbursement is required" });

    return Isvalid;
    // }
  };

  designationlist = () => {
    return this.state.DesignationList.map((content) => {
      return (
        <MenuItem value={content.StringMapKey}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  allowanceTypelist = () => {
    return this.state.AllowanceTypeList.map((content) => {
      return (
        <MenuItem value={content.StringMapKey}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  paymentModelist = () => {
    return this.state.PaymentModeList.map((content) => {
      return (
        <MenuItem value={content.StringMapKey}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  bankNamelist = () => {
    return this.state.BankNameList.map((content) => {
      return (
        <MenuItem value={content.StringMapKey}>
          {" "}
          {content.StringMapName}{" "}
        </MenuItem>
      );
    });
  };

  addAllowance = (e) => {
    const { AllowanceType, AllowanceAmountlimit, isTaxExempt } = this.state;
    if (AllowanceType && AllowanceAmountlimit) {
      var addAllowance = this.state.AllowanceSummary.filter(
        (x) => x.Status === "Active" && x.AllowanceType === AllowanceType
      );

      if (addAllowance.length === 0) {
        let data = {
          CreatedBy: CommonConfig.loginData().PersonID,
          AllowanceType: this.state.AllowanceType,
          Amountlimit: this.state.AllowanceAmountlimit,
          isTaxExempt: this.state.isTaxExempt ? 1 : 0,
          Index: this.state.AllowanceSummary.length + 45,
          IsEdit: false,
          Status: "Active",
        };
        this.setState({
          AllowanceSummary: [...this.state.AllowanceSummary, data],
          AllowanceType: "",
          AllowanceTypeErr: false,
          AllowanceTypeHelperText: "",
          AllowanceAmountlimit: "",
          AllowanceAmountlimitErr: false,
          AllowanceAmountlimitHelperText: "",
          isTaxExempt: false,
        });
      } else {
        cogoToast.error("Allowance Already exist");
      }
    } else {
      if (CommonConfig.isEmpty(AllowanceType)) {
        this.setState({
          AllowanceTypeErr: true,
          AllowanceTypeHelperText: "Please select Allowance Type",
        });
      }
      if (CommonConfig.isEmpty(AllowanceAmountlimit)) {
        this.setState({
          AllowanceAmountlimitErr: true,
          AllowanceAmountlimitHelperText: "Please Enter Amount limit",
        });
      }
    }
  };

  editAllowanceSummary = (idx) => {
    const editAllowance = [...this.state.AllowanceSummary];
    var Index = this.state.AllowanceSummary.findIndex((x) => x.Index === idx);
    if (Index !== -1) {
      editAllowance[Index]["IsEdit"] = true;
      this.setState({ AllowanceSummary: editAllowance });
    }
  };

  removeAllowanceSummary = (idx) => {
    const removeAllowance = [...this.state.AllowanceSummary];
    var Index = this.state.AllowanceSummary.findIndex((x) => x.Index === idx);
    if (Index !== -1) {
      removeAllowance[Index]["Status"] = "Inactive";
      this.setState({ AllowanceSummary: removeAllowance });
    }
  };

  searchTotalAllowance = (len, e) => {
    var sum = 0;
    for (let i = 0; i < len.length; i++) {
      sum = sum + Number(len[i]._original.Amountlimit);
    }
    this.setState({ AllowanceTotal: sum });
  };
  searchCheckProps = (e) => {
    if (e.sortedData.length !== 0) {
      var sum = 0;
      for (let i = 0; i < e.sortedData.length; i++) {
        sum = sum + Number(e.sortedData[i]._original.Amountlimit);
      }
      if (this.state.AllowanceTotal !== sum) {
        this.searchTotalAllowance(e.sortedData);
      }
    }
    return "";
  };

  Submit = () => {
    const {
      EmployeeCode,
      EmployeeName,
      AllowanceSummary,
      Amountlimit,
      Designation,
      FixTDS,
      PaymentMode,
      BankName,
      basicSalary,
      Reimbursement,
      IsESICApplicable,
      isPFApplication,
      isTDSApplication,
      isLeaveNotApplicable,
    } = this.state;
    if (this.validate()) {
      let data = {
        UserID: CommonConfig.loginData().PersonID,
        ResourceID: CommonConfig.loginData().PersonID,
        PayrollID: null,
        EmployeeCode: EmployeeCode,
        EmployeeName: EmployeeName,
        AmountLimit: Amountlimit,
        AllowanceList: AllowanceSummary.filter((x) => x.Status === "Active"),
        Designation: Designation,
        IsTDSFixed: FixTDS ? 1 : 0,
        PaymentMode: PaymentMode,
        BankName: BankName,
        BasicSalary: basicSalary,
        Reimbursements: Reimbursement,
        IsESICApplicable: IsESICApplicable ? 1 : 0,
        IsPFApplicable: isPFApplication ? 1 : 0,
        IsTDSApplicable: isTDSApplication ? 1 : 0,
        IsLeaveNotApplicable: isLeaveNotApplicable ? 1 : 0,
      };
      try {
        api
          .post("payroll/addUpdatePayRoll", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success("Salary added successfully");
              this.props.history.push("/admin/salaryListing");
            } else {
              cogoToast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  BackAllowance = () => {
    this.props.history.push("/admin/salaryListing");
  };
  AllowanceSummary = () => {
    return this.state.AllowanceSummary.filter((x) => x.Status === "Active").map(
      (content, index) => {
        return content.IsEdit == false ? (
          <GridContainer className="ml-auto">
            <GridItem xs={4}>
              <div className="payment-card">
                <div className="card-base border-primary">
                  <div className="d-flex justify-content-between">
                    <h5 className="fw-bold">Name{content.AccountNo}</h5>
                    <div className="list-action">
                      <button
                        type="button"
                        className="userProfile-wrapper"
                        onClick={() => this.editAllowanceSummary(content.Index)}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </button>
                      <button
                        type="button"
                        className="userProfile-wrapper"
                        onClick={() =>
                          this.removeAllowanceSummary(content.Index)
                        }
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                  <address>
                    <span></span>
                    <span>
                      <strong>Amount - </strong> {content.Amount}
                    </span>
                    {/* <span>BRANCH NAME</span> */}
                    <span>
                      <strong>isTaxExempt - </strong> {content.isTaxExempt}
                    </span>
                  </address>
                </div>
              </div>
            </GridItem>
            {/* <GridItem xs={3}>
                <Button onClick={() => this.removeBankDetails(content.Index)} color="primary"><DeleteIcon/></Button>
              </GridItem> */}
          </GridContainer>
        ) : (
          <GridContainer className="ml-auto">
            <GridItem xs={3}>
              <FormControl fullWidth label="Bank Name">
                <TextField
                  value={content.Name}
                  //   prefix={
                  //     <Icon
                  //       type="user"
                  //       style={{ color: "rgba(0,0,0,.25)" }}
                  //     />
                  //   }
                  label="Name"
                  onChange={(e) => {
                    let editAllowanceSummary = [...this.state.AllowanceSummary];
                    var Index = this.state.AllowanceSummary.findIndex(
                      (x) => x.Index === content.Index
                    );
                    if (Index !== -1) {
                      editAllowanceSummary[Index]["Name"] = e.target.value;
                      this.setState({ AllowanceSummary: editAllowanceSummary });
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <FormControl fullWidth label="Amount">
                <TextField
                  value={content.Amount}
                  //   prefix={
                  //     <Icon
                  //       type="user"
                  //       style={{ color: "rgba(0,0,0,.25)" }}
                  //     />
                  //   }
                  label="Amount"
                  onChange={(e) => {
                    const editAllowanceSummary = [
                      ...this.state.AllowanceSummary,
                    ];
                    var Index = this.state.AllowanceSummary.findIndex(
                      (x) => x.Index === content.Index
                    );
                    if (Index !== -1) {
                      editAllowanceSummary[Index]["Amount"] = e.target.value;
                      this.setState({ AllowanceSummary: editAllowanceSummary });
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            {/* <GridItem xs={3}>
              <FormControl fullWidth label="IFSC Code">
                <TextField
                  value={content.IFSCCode}
                  prefix={
                    <Icon
                      type="user"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  label="IFSC Code"
                  onChange={(e) => {
                    const editBank = [...this.state.viewBankList]
                    var Index = this.state.viewBankList.findIndex(x => x.Index === content.Index);
                    if (Index !== -1) {
                      editBank[Index]["IFSCCode"] = e.target.value;
                      this.setState({ viewBankList: editBank })
                    }
                  }}
                />
              </FormControl>
            </GridItem> */}

            <GridItem xs={3}>
              <Tooltip title="Update" placement="bottom">
                <Button
                  color="primary"
                  onClick={(e) => {
                    let editAllowanceSummary = [...this.state.AllowanceSummary];
                    var Index = this.state.AllowanceSummary.findIndex(
                      (x) => x.Index === content.Index
                    );
                    if (Index !== -1) {
                      if (
                        CommonConfig.isEmpty(content.Name) &&
                        CommonConfig.isEmpty(content.Amount) &&
                        CommonConfig.isEmpty(content.isTaxExempt)
                      ) {
                        cogoToast.error("Fields cannot be empty.");
                      } else {
                        editAllowanceSummary[Index]["IsEdit"] = false;
                        this.setState({
                          AllowanceSummary: editAllowanceSummary,
                        });
                      }
                    }
                  }}
                >
                  Update
                </Button>
              </Tooltip>
              <Tooltip title="Cancel" placement="bottom">
                <Button
                  color="primary"
                  onClick={(e) => {
                    let editAllowanceSummary = [...this.state.AllowanceSummary];
                    var Index = this.state.AllowanceSummary.findIndex(
                      (x) => x.Index === content.Index
                    );
                    if (Index !== -1) {
                      editAllowanceSummary[Index]["IsEdit"] = false;
                      this.setState({ AllowanceSummary: editAllowanceSummary });
                    }
                  }}
                >
                  Cancel
                </Button>
              </Tooltip>
            </GridItem>
          </GridContainer>
        );
      }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      isTaxExempt,
      EmployeeCode,
      EmployeeCodeErr,
      EmployeeCodeHelperText,
      EmployeeName,
      EmployeeNameErr,
      EmployeeNameHelperText,
      Designation,
      DesignationErr,
      DesignationHelperText,
      Amountlimit,
      AmountlimitErr,
      AmountlimitHelperText,
      BasicSalaryHelperText,
      AllowanceAmountlimit,
      AllowanceAmountlimitErr,
      AllowanceAmountlimitHelperText,
      BasicSalary,
      BasicSalaryErr,
      ReimbursementHelperText,
      ReimbursementErr,
      Reimbursement,
      AllowanceType,
      AllowanceTypeHelperText,
      AllowanceTypeErr,
      AllowanceSummary,
      PaymentMode,
      paymentModeHelperText,
      payMentModeErr,
      FixTDS,
      BankNameHelperText,
      BankName,
      BankNameErr,
      IsESICApplicable,
      IsLeaveNotAppicable,
      IsPFApplicable,
      IsTDSAppicable,
      TDSAmount,
      TDSAmountErr,
      TDSAmountHelperText,
    } = this.state;
    console.log("AllowanceSummary", AllowanceSummary);
    const tableColumns = [
      {
        Header: "Name",
        accessor: (data) => {
          return <span>{data.AllowanceType}</span>;
        },
        id: "Name",
        width: 700,
      },
      {
        Header: "Amount",
        accessor: (data) => {
          return <span>{data.Amountlimit}</span>;
        },
        id: "Amount",
      },
      {
        Header: "Is Tax Exempt",
        accessor: (data) => {
          return <span>{data.isTaxExempt ? "Yes" : "No"}</span>;
        },
        id: "isTaxExempt",
      },
      {
        Header: "Action",
        className: "text-align-right",
        accessor: "Action",
        Cell: (record) => {
          return (
            <span>
              <EditIcon
                onClick={(content) => this.editAllowanceSummary(content.Index)}
              />
              <DeleteIcon onClick={(Index) => this.removeAllowanceSummary()} />
            </span>
          );
        },
      },
    ];
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h4 style={{ color: "black" }}>Salary Master</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      onChange={this.onDrop}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          helperText={EmployeeCodeHelperText}
                          error={EmployeeCodeErr}
                          value={EmployeeCode}
                          label="Employee Code"
                          onChange={(e) => this.onChange(e, "EmployeeCode")}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          label="Amount Limit"
                          helperText={AmountlimitHelperText}
                          error={AmountlimitErr}
                          value={Amountlimit}
                          onChange={(e) => this.onChange(e, "Amountlimit")}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          disabled
                          helperText={EmployeeNameHelperText}
                          label="Employee Name"
                          error={EmployeeNameErr}
                          value={EmployeeName}
                          onChange={(e) => this.onChange(e, "EmployeeName")}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          disabled
                          label="Basic Salary"
                          helperText={BasicSalaryHelperText}
                          error={BasicSalaryErr}
                          value={BasicSalary}
                          onChange={(e) => this.onChange(e, "BasicSalary")}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl
                          error={DesignationErr}
                          fullWidth
                          label="Designation"
                        >
                          <InputLabel>Designation</InputLabel>
                          <Select
                            disabled
                            onChange={(e) =>
                              this.selectChange(e, "Designation")
                            }
                            value={Designation}
                          >
                            {this.designationlist()}
                          </Select>
                          <FormHelperText>
                            {DesignationHelperText}
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          label="Reimbursement Amount"
                          disabled
                          helperText={ReimbursementHelperText}
                          error={ReimbursementErr}
                          value={Reimbursement}
                          onChange={(e) =>
                            this.onChange(e, "ReimbursementAmount")
                          }
                        />
                      </GridItem>

                      <GridItem xs={12} sm={6} md={6}>
                        <label> Fix TDS </label>
                        <Checkbox
                          checked={FixTDS}
                          onChange={(e) =>
                            this.setState({ FixTDS: !this.state.FixTDS })
                          }
                          name="checked"
                        />
                      </GridItem>
                      {this.state.FixTDS && (
                        <GridItem xs={12} sm={6} md={6}>
                          <TextField
                            type="number"
                            label="TDS Amount"
                            helperText={TDSAmountHelperText}
                            error={TDSAmountErr}
                            value={TDSAmount}
                            onChange={(e) => this.onChange(e, "TDSAmount")}
                          />
                        </GridItem>
                      )}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h4 style={{ color: "black" }}>Allowance</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          error={AllowanceTypeErr}
                          fullWidth
                          label="AllowanceType"
                        >
                          <InputLabel>Allowance Type</InputLabel>
                          <Select
                            onChange={(e) =>
                              this.selectChange(e, "AllowanceType")
                            }
                            value={AllowanceType}
                          >
                            {this.allowanceTypelist()}
                          </Select>
                          <FormHelperText>
                            {AllowanceTypeHelperText}
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={2} md={2}>
                        <TextField
                          label="Amount Limit"
                          type="number"
                          helperText={AllowanceAmountlimitHelperText}
                          error={AllowanceAmountlimitErr}
                          value={AllowanceAmountlimit}
                          onChange={(e) =>
                            this.onChange(e, "AllowanceAmountlimit")
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={2} md={2}>
                        <Checkbox
                          checked={isTaxExempt}
                          onChange={(e) =>
                            this.setState({
                              isTaxExempt: !this.state.isTaxExempt,
                            })
                          }
                          name="checked"
                        />
                        <label> Is Tax Exempt </label>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <Button
                          variant="contained"
                          color="rose"
                          onClick={() => this.addAllowance()}
                        >
                          Add Allowance
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card>
                        <CardBody>
                          <ReactTable
                            data={AllowanceSummary}
                            minRows={5}
                            resizable={false}
                            columns={tableColumns}
                            defaultPageSize={10}
                            style={{ cursor: "pointer" }}
                            className="-striped -highlight"
                            pageText={
                              "Allowance Total : " +
                              parseFloat(this.state.AllowanceTotal).toFixed(2)
                            }
                            getPaginationProps={(record) =>
                              this.searchCheckProps(record)
                            }
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h4 style={{ color: "black" }}>Payment Details</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl
                          error={payMentModeErr}
                          fullWidth
                          label="PaymentMode"
                        >
                          <InputLabel>Payment Mode</InputLabel>
                          <Select
                            onChange={(e) =>
                              this.selectChange(e, "PaymentMode")
                            }
                            value={PaymentMode}
                          >
                            {this.paymentModelist()}
                          </Select>
                          <FormHelperText>
                            {paymentModeHelperText}
                          </FormHelperText>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl
                          error={BankNameErr}
                          fullWidth
                          label="BankName"
                        >
                          <InputLabel>Bank Name</InputLabel>
                          <Select
                            onChange={(e) => this.selectChange(e, "BankName")}
                            value={BankName}
                          >
                            {this.bankNamelist()}
                          </Select>
                          <FormHelperText>{BankNameHelperText}</FormHelperText>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h4 style={{ color: "black" }}>Employee Configuration</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <label> Is ESIC Applicable</label>
                    <Checkbox
                      checked={IsESICApplicable}
                      onChange={(e) =>
                        this.setState({
                          IsESICApplicable: !this.state.IsESICApplicable,
                        })
                      }
                      name="checked"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <label> Is PF Applicable</label>
                    <Checkbox
                      checked={IsPFApplicable}
                      onChange={(e) =>
                        this.setState({
                          IsPFApplicable: !this.state.IsPFApplicable,
                        })
                      }
                      name="checked"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <label> Is TDS Applicable</label>
                    <Checkbox
                      checked={IsTDSAppicable}
                      onChange={(e) =>
                        this.setState({
                          IsPFApplicable: !this.state.IsPFApplicable,
                        })
                      }
                      name="checked"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <label> Is Leave Not Applicable</label>
                    <Checkbox
                      checked={IsLeaveNotAppicable}
                      onChange={(e) =>
                        this.setState({
                          IsLeaveNotAppicable: !this.state.IsLeaveNotAppicable,
                        })
                      }
                      name="checked"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={6} md={6}>
                <Button
                  variant="contained"
                  color="rose"
                  onClick={() => this.Submit()}
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  color="rose"
                  onClick={() => this.BackAllowance()}
                >
                  Back
                </Button>
              </GridItem>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </div>
    );
  }
}

addSalaryMaster.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(addSalaryMaster);
