import React, { Component } from 'react';
import HeadsetMic from "@material-ui/icons/HeadsetMic";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputLabel } from '@material-ui/core';
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import LockIcon from '@material-ui/icons/Lock';
import TextField from "@material-ui/core/TextField";
import {CommonConfig} from '../../utils/constant';
import api from '../../utils/apiClient';
class EditServerConfig extends Component {
    constructor(props){
        super(props);
        this.state = {
          PanelName:"",
          panelnameErr:false,
          panelnameHelperText:"",
  
          Description:"",
          descriptionErr:false,
          descriptionHelperText:"",
          
          AccessURL:"",
          accessurlErr:false,
          accessurlHelperText:"",
          
          AccessURLRole:"",
          accessroleErr:false,
          accessroleHelperText:"",
          
          AccessURLUserName:"",
          accessusernameErr:false,
          accessusernameHelperText:"",
          
          AccessURLPassword:"",
          accesspasswordErr:false,
          accesspasswordHelperText:"",
          
          URL1:"",
          url1Err:false,
          url1HelperText:"",
  
          URL1Role:"",
          url1roleErr:false,
          url1roleHelperText:"",

          Project:"",
          ProjectErr:false,
          ProjectHelperText:"",
  
          URL1UserName:"",
          url1usernameErr:false,
          url1usernameHelperText:"",
  
          URL1Password:"",
          url1passwordErr:false,
          url1passwordHelperText:"",
  
          URL2:"",
          url2Err:false,
          url2HelperText:"",
  
          URL2Role:"",
          url2roleErr:false,
          url2roleHelperText:"",
  
          URL2UserName:"",
          url2usernameErr:false,
          url2usernameHelperText:"",
  
          URL2Password:"",
          url2passwordErr:false,
          url2passwordHelperText:"",

          ProjectList: [],

          Root:false,
          IsRDP:false,
          Accessvisible: false,
          URL1visible: false,
          URL2visible: false,
          loggedUserID:0,
        };
    }

  componentDidMount(){ 
    this.getEditData();
    this.getProjectList();
  }
  getProjectList = () => {
    try {
        api.get("tasks/getProjectList").then(res => {
            if (res.success) {
                this.setState({ ProjectList: res.data });
                var date = CommonConfig.isEmpty(this.state.DueDate) ? moment().format("DD/MM/YYYY") : this.state.DueDate;
                this.setState({ DueDate: date });
            }
            else {
                cogoToast.error("Something went wrong");
            }
        }).catch(err => {
            console.log(err)
        })
    } catch (err) {
    }
  }

  getEditData = () => {
    let data = {
        ServerConfigID : this.props.location.state.id
    };
    try{
        api.post("serverconfig/getServerconfigByID",data).then(res => {
            if(res.success){
                this.setState({
                PanelName : res.data[0].PanelName ,
                Description : res.data[0].Description  , 
                AccessURL : res.data[0].AccessURL , 
                AccessURLRole : res.data[0].AccessURLRole, 
                AccessURLUserName : res.data[0].AccessUserName , 
                AccessURLPassword : res.data[0].AccessPassword ,
                URL1 : res.data[0].URL1,
                URL1Password : res.data[0].URL1Password, 
                URL1UserName : res.data[0].URL1UserName, 
                URL1Role : res.data[0].URL1Role, 
                URL2 : res.data[0].URL2, 
                URL2Role : res.data[0].URL2Role, 
                URL2UserName : res.data[0].URL2UserName , 
                URL2Password : res.data[0].URL2Password, 
                Root : res.data[0].Root.data[0] === 1 ? true : false, 
                IsRDP : res.data[0].IsRDP.data[0] === 1 ? true : false
                });

            }else{
              cogoToast.error('Something went wrong')
            }

        }).catch(error => {
        })

    }
    catch(err){
    }
  }

  onChange = (e,type) => {
    if(type === "panelname"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({PanelName:e.target.value , panelnameErr:true , panelnameHelperText:"Please enter Panel Name"});
      }
      else{
        this.setState({PanelName:e.target.value, panelnameErr:false , panelnameHelperText:""});
      }
    }
    else if(type === "accessURL"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({AccessURL:e.target.value , accessurlErr:true , accessurlHelperText:"Please enter Access URL"});
      }
      else{
        this.setState({AccessURL:e.target.value , accessurlErr:false , accessurlHelperText:""});
      }

    }
    
    else if(type === "description"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({Description:e.target.value , descriptionErr:true , descriptionHelperText:"Please enter Description"});
      }
      else{
        this.setState({Description:e.target.value , descriptionHelperText:"" , descriptionErr:false});
      }

    }
    
    else if(type === "URL1"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL1:e.target.value , url1Err:true , url1HelperText:"Please enter URL1"});
      }
      else{
        this.setState({URL1:e.target.value , url1Err:false , url1HelperText:""});
      }

    }

    else if(type === "URL2"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL2:e.target.value , url2HelperText:"Please enter URL2" , url2Err:true});
      }
      else{
        this.setState({URL2:e.target.value , url2HelperText:"" , url2Err:false});
      }

    }

    else if(type === "AccessURLRole"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({AccessURLRole:e.target.value , accessroleHelperText:"Please enter URL Role" , accessroleErr:true});
      }
      else{
        this.setState({AccessURLRole:e.target.value , accessroleHelperText:"" , accessroleErr:false});
      }

    }
    
    else if(type === "AccessURLUserName"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({AccessURLUserName:e.target.value , accessusernameHelperText:"Please enter URL UserName" , accessusernameErr:true});
      }
      else{
        this.setState({AccessURLUserName:e.target.value , accessusernameHelperText:"" , accessusernameErr:false});
      }

    }
    
    else if(type === "AccessURLPassword"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({AccessURLPassword:e.target.value , accesspasswordHelperText:"Please enter URL Password" , accesspasswordErr:true});
      }
      else{
        this.setState({AccessURLPassword:e.target.value , accesspasswordHelperText:"" , accesspasswordErr:false});
      }

    }
    
    else if(type === "URL1Role"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL1Role:e.target.value , url1roleErr:true , url1HelperText:"Please enter URL Role"});
      }
      else{
        this.setState({URL1Role:e.target.value , url1roleErr:false , url1HelperText:""});
      }

    }
    
    else if(type === "URL1UserName"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL1UserName:e.target.value , url1usernameErr:true , url1usernameHelperText:"Please enter URL UserName"});
      }
      else{
        this.setState({URL1UserName:e.target.value , url1usernameErr:false , url1usernameHelperText:""});
      }

    }
    
    else if(type === "URL1Password"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL1Password:e.target.value , url1passwordHelperText:"Please enter URL Password" , url1passwordErr:true});
      }
      else{
        this.setState({URL1Password:e.target.value , url1passwordHelperText:"" , url1passwordErr:false});
      }

    }
    
    else if(type === "URL2UserName"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL2UserName:e.target.value , url2usernameErr:true , url2usernameHelperText:"Please enter URL UserName"});
      }
      else{
        this.setState({URL2UserName:e.target.value , url2usernameErr:false , url2usernameHelperText:""});
      }

    }
    
    else if(type === "URL2Role"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL2Role:e.target.value , url2roleErr:true , url2roleHelperText:"Please enter URL Role"});
      }
      else{
        this.setState({URL2Role:e.target.value , url2roleErr:false , url2roleHelperText:""});
      }

    }
    
    else if(type === "URL2Password"){
      if(CommonConfig.isEmpty(e.target.value)){
        this.setState({URL2Password:e.target.value , url2passwordHelperText:"Please enter URL Password" , url2passwordErr:true});
      }
      else{
        this.setState({URL2Password:e.target.value , url2passwordHelperText:"" , url2passwordErr:false});
      }

    }

  }
  projectlist = () => {
    return this.state.ProjectList.map(content => {
        return (
            <MenuItem value={content.ProjectID} > {content.ProjectName} </MenuItem>
        )
    })
  }

  checkboxChange = (e,type) => {
    if(type === "root"){
      this.setState({Root : e.target.checked});
    }
    else if(type === "isrdp"){
      this.setState({IsRDP : e.target.checked});
    }
  }

  validate = () => {
    const { PanelName ,Description , AccessURL , 
      AccessURLRole , AccessURLUserName , AccessURLPassword ,
      URL1 , URL1Password , URL1UserName , URL1Role, 
      URL2 , URL2Role , URL2UserName  , URL2Password  } = this.state;

      let IsValid = true;

    if(CommonConfig.isEmpty(PanelName)){
      IsValid = false;
      this.setState({panelnameHelperText:"Please enter Panel Name",panelnameErr:true});
    }

    if(CommonConfig.isEmpty(Description)){
      IsValid = false;
      this.setState({descriptionHelperText:"Please enter Description",descriptionErr:true});
    }

    if(CommonConfig.isEmpty(AccessURL)){
      IsValid = false;
      this.setState({accessurlHelperText:"Please enter Access URL",accessurlErr:true});
    }
    
    if(CommonConfig.isEmpty(AccessURLRole)){
      IsValid = false;
      this.setState({accessroleHelperText:"Please enter Access Role",accessroleErr:true});
    }
    
    if(CommonConfig.isEmpty(AccessURLUserName)){
      IsValid = false;
      this.setState({accessusernameHelperText:"Please enter Access URL UserName",accessusernameErr:true});
    }
    
    if(CommonConfig.isEmpty(AccessURLPassword)){
      IsValid = false;
      this.setState({accesspasswordHelperText:"Please enter Access URL Password",accesspasswordErr:true});
    }
    
    // if(CommonConfig.isEmpty(URL1Role)){
    //   IsValid = false;
    //   this.setState({url1roleHelperText:"Please enter URL Role",url1roleErr:true});
    // }
    
    // if(CommonConfig.isEmpty(URL1UserName)){
    //   IsValid = false;
    //   this.setState({url1usernameHelperText:"Please enter URL UserName",url1usernameErr:true});
    // }
    
    // if(CommonConfig.isEmpty(URL1Password)){
    //   IsValid = false;
    //   this.setState({url1passwordHelperText:"Please enter URL Password",url1passwordErr:true});
    // }
    
    // if(CommonConfig.isEmpty(URL2Role)){
    //   IsValid = false;
    //   this.setState({url2roleHelperText:"Please enter URL Role",url2roleErr:true});
    // }
    
    // if(CommonConfig.isEmpty(URL2UserName)){
    //   IsValid = false;
    //   this.setState({url2usernameHelperText:"Please enter URL UserName",url2usernameErr:true});
    // }
    
    // if(CommonConfig.isEmpty(URL2Password)){
    //   IsValid = false;
    //   this.setState({url2passwordHelperText:"Please enter URL Password",url2passwordErr:true});
    // }
    
    // if(CommonConfig.isEmpty(URL1)){
    //   IsValid = false;
    //   this.setState({url1HelperText:"Please enter URL1",url1Err:true});
    // }
    
    // if(CommonConfig.isEmpty(URL2)){
    //   IsValid = false;
    //   this.setState({url2HelperText:"Please enter URL2",url2Err:true});
    // }

    return IsValid;
  }

  submit = (e) => {
    const { PanelName ,Description , AccessURL , 
      AccessURLRole , AccessURLUserName , AccessURLPassword ,
      URL1 , URL1Password , URL1UserName , URL1Role , 
      URL2 , URL2Role , URL2UserName  , URL2Password , Root , IsRDP  } = this.state;
    if(this.validate()){
        
        let data = {
        PanelName:PanelName,
        Description:Description,
        AccessURL:AccessURL,
        AccessURLRole:AccessURLRole,
        AccessURLUserName:AccessURLUserName,
        AccessURLPassword:AccessURLPassword,
        URL1:URL1,
        URL1Password:URL1Password,
        URL1UserName:URL1UserName,
        URL1Role:URL1Role,
        URL2:URL2,
        URL2Role:URL2Role,
        URL2UserName:URL2UserName,
        URL2Password:URL2Password,
        Root:Root,
        IsRDP:IsRDP,
        UserID:CommonConfig.loginData().PersonID,
        ServerConfigID:this.props.location.state.id
        }
        try{
          api.post("serverconfig/addServerConfig",data).then(res => {
            if(res.success){
              cogoToast.success('Updated Successfully')
              
              this.props.history.push("/admin/serverconfigListing");
            }
            else{
              cogoToast.error('Something went wrong')
              
            }
          });
      }

      catch(err){
      }

    }
    else{
      cogoToast.error('Please correct error and resubmit the form')
    }
    
  }
  
    
  showModal = () => {
    this.setState({ Accessvisible: true });
  };

  handleOk = () => {
    this.setState({ Accessvisible: false });
  };

  handleCancel = () => {
    this.setState({ Accessvisible: false });
  };

  url1Model = () => {
    this.setState({ URL1visible: true });
  };

  url1Ok = () => {
    this.setState({ URL1visible: false });
  };

  url1Cancel = () => {
    this.setState({ URL1visible: false });
  };

  url2Model = () => {
    this.setState({ URL2visible: true });
  };

  url2Ok = () => {
    this.setState({ URL2visible: false });
  };

  url2Cancel = () => {
    this.setState({ URL2visible: false });
  };
  
  deleteServerConfig = () => {
    try{
      let data ={
        ServerConfigID : this.props.location.state.id
      }
      api.post("serverconfig/deleteServerConfigByID",data).then(res=>{
        if(res.success){
          cogoToast.success('Deleted Successfully')
          
          this.props.history.push("/admin/serverconfigListing");
        }
        else{
          cogoToast.error('Something went wrong')
          
        }
      }).catch(error => {
    });

    }
    catch(err){

    }
  }
  typeChange = (e,type) => {
      if (type === "Project") {
            this.setState({ Project: e, ProjectHelperText: "", ProjectErr: false });
        }
        if (type === "Project2") {
          this.setState({ Project: e, ProjectHelperText: "", ProjectErr: false });
      }
    }

  render(){
      const { PanelName ,Description , AccessURL , panelnameErr , descriptionErr , accessurlErr , accessroleErr , accessusernameErr ,  
        AccessURLRole , AccessURLUserName , AccessURLPassword  , Accessvisible , accesspasswordErr , url1Err , url2Err,
        URL1 , URL1Password , URL1UserName , URL1Role , URL1visible, descriptionHelperText , url1roleErr , url1usernameErr ,
        url1passwordErr  , url2passwordErr , url2usernameErr , accessurlHelperText , accessroleHelperText , accessusernameHelperText , 
        accesspasswordHelperText , url1HelperText , url1passwordHelperText , url1usernameHelperText , url1roleHelperText ,
        url2HelperText , url2passwordHelperText , url2usernameHelperText , url2roleHelperText , url2roleErr ,   
        URL2 , URL2Role , URL2UserName  , URL2visible, URL2Password , Root , IsRDP , panelnameHelperText, Project,ProjectErr,ProjectHelperText } = this.state;
        
      const projectlist = this.state.ProjectList.map((type) => {
          return { value: type.ProjectID, label: type.ProjectName };
         });
  
         return(
          <GridContainer className="UserList-outer">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader className="btn-right-outer" color="primary" icon>
                  <CardIcon color="primary">
                      <HeadsetMic />
                  </CardIcon>
                  <h4 className="margin-right-auto text-color-black">Edit Server Configuration</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}sm={12} md={12}>
                      <FormControlLabel
                          control={<Checkbox checked={Root} onChange={(e)=>this.checkboxChange(e,"root")} name="Root" />}
                          label="Root"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={IsRDP} onChange={(e)=>this.checkboxChange(e,"isrdp")} name="IsRDP" />}
                          label="IsRDP"
                        />  
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4}sm={4} md={4}>
                      <FormControl>
                        <TextField
                          helperText={panelnameHelperText} 
                          error={panelnameErr}
                          value={PanelName} 
                          label="Panel Name"
                          onChange={(e) => this.onChange(e,"panelname")}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={4}sm={4} md={4}>
                      <FormControl>
                        <TextField
                          helperText={descriptionHelperText} 
                          error={descriptionErr}
                          value={Description}
                          label="Description"
                          onChange={(e) => this.onChange(e,"description")}
                        />
                      </FormControl> 
                    </GridItem>   
                    <GridItem xs={3}sm={3} md={3}>         
                      <FormControl>
                        <TextField
                          helperText={accessurlHelperText} 
                          error={accessurlErr}
                          value={AccessURL}
                          label="Access URL"
                          onChange={(e) => this.onChange(e,"accessURL")}
                        />
                      </FormControl>
                    </GridItem>  
                    <GridItem xs={1}sm={1} md={1}>
                    <Tooltip title="Credentials" placement="bottom">
                        <Button className="btn-icon" color="primary"type="button" onClick={()=>this.showModal()}>
                          <LockIcon />
                        </Button></Tooltip>
                        <Dialog
                            open={Accessvisible}
                            title="Credentials"
                            onClose={()=>this.handleCancel()}
                          >
                          <DialogContent>
                            <FormControl>
                              <TextField
                                helperText={accessroleHelperText} 
                                error={accessroleErr}
                                value={AccessURLRole}
                                label="URL Role"
                                onChange={(e) => this.onChange(e,"AccessURLRole")}
                              />
                            </FormControl>      
                            <FormControl>
                                <TextField
                                  helperText={accessusernameHelperText} 
                                  error={accessusernameErr}
                                  value={AccessURLUserName}
                                  label="User Name"
                                  onChange={(e) => this.onChange(e,"AccessURLUserName")}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                  type="password"
                                  value={AccessURLPassword}
                                  helperText={accesspasswordHelperText} 
                                  error={accesspasswordErr}
                                  label="Password"
                                  onChange={(e) => this.onChange(e,"AccessURLPassword")}
                                  />
                            </FormControl>
                          </DialogContent>
                          <DialogActions>
                          <Tooltip title="Save" placement="bottom">
                            <Button
                                type="submit"
                                color="primary"
                                onClick={()=>this.handleOk()}
                              >
                                Save
                              </Button>
                            </Tooltip>
                              <Tooltip title="Back" placement="bottom">
                              <Button key="back" onClick={()=>this.handleCancel()}>
                                Back
                              </Button></Tooltip>
                          </DialogActions>
                        </Dialog>
                    </GridItem>
                    </GridContainer>
                    <GridContainer>
                    {this.state.IsRDP ? <> 
                      <GridItem xs={2} sm={2} md={2}>
                        <Autocomplete
                          options={projectlist}
                          id="URL1"
                          value={url1Err}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) => this.typeChange(value, "Project")}
                          renderInput={params => (
                          <TextField {...params} error={ProjectErr} helperText={ProjectHelperText}
                              label="URL1" />)}
                        />
                    </GridItem>
                    <GridItem xs={3}sm={3} md={3}>
                      <FormControl>
                        <TextField
                          helperText={url1HelperText} 
                          error={url1Err}
                          value={URL1}
                          label="URL1"
                          onChange={(e) => this.onChange(e,"URL1")}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={1}sm={1} md={1}>  
                    <Tooltip title="Credentials" placement="bottom">
                        <Button className="btn-icon" color="primary"color="primary" type="button" onClick={()=>this.url1Model()}>
                          <LockIcon />
                        </Button></Tooltip>
                        <Dialog
                            open={URL1visible}
                            title="Credentials"
                            onClose={()=>this.url1Cancel()}
                        >
                          <DialogContent>
                            <FormControl>
                              <TextField
                                helperText={url1roleHelperText} 
                                error={url1roleErr}
                                value={URL1Role}
                                label="URL Role"
                                onChange={(e) => this.onChange(e,"URL1Role")}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                helperText={url1usernameHelperText} 
                                error={url1usernameErr}
                                value={URL1UserName}
                                label="User Name"
                                onChange={(e) => this.onChange(e,"URL1UserName")}
                              />
                            </FormControl>
                            <FormControl>
                              <TextField
                                type="password"
                                helperText={url1passwordHelperText} 
                                error={url1passwordErr}
                                value={URL1Password}
                                label="Password"
                                onChange={(e) => this.onChange(e,"URL1Password")}
                             />
                            </FormControl>
                          </DialogContent>
                          <DialogActions>
                          <Tooltip title="Save" placement="bottom">
                            <Button
                              type="submit"
                              cplor="primary"
                              onClick={()=>this.url1Ok()}
                            >
                                  Save
                              </Button></Tooltip>
                              <Tooltip title="Back" placement="bottom">
                            <Button key="back" onClick={()=>this.url1Cancel()}>
                              Back
                            </Button></Tooltip>
                          </DialogActions>
                        </Dialog>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                        <Autocomplete
                          options={projectlist}
                          id="URL2"
                          value={Project}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, value) => this.typeChange(value, "Project1")}
                          renderInput={params => (
                          <TextField {...params} error={ProjectErr} helperText={ProjectHelperText}
                              label="URL2" />)}
                        />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>  
                      <FormControl>
                        <TextField
                          helperText={url2HelperText} 
                          error={url2Err}
                          value={URL2}
                          label="URL2"
                          onChange={(e) => this.onChange(e,"URL2")}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={1} sm={1} md={1}>  
                    <Tooltip title="Credentials" placement="bottom">
                        <Button className="btn-icon" color="primary" onClick={()=>this.url2Model()}>
                          <LockIcon />
                        </Button></Tooltip>
                        <Dialog
                            open={URL2visible}
                            title="Credentials"
                            onCancel={()=>this.url2Cancel()}
                          >
                            <DialogContent>
                              <FormControl>
                                    <TextField
                                    helperText={url2roleHelperText} 
                                    error={url2roleErr}
                                    value={URL2Role}
                                    label="URL Role"
                                    onChange={(e) => this.onChange(e,"URL2Role")}
                                    />
                                </FormControl>
                              <FormControl>
                                  <TextField
                                  helperText={url2usernameHelperText} 
                                  error={url2usernameErr}
                                  value={URL2UserName}
                                  label="User Name"
                                  onChange={(e) => this.onChange(e,"URL2UserName")}
                                  />
                              </FormControl>
                              <FormControl>
                                    <TextField
                                    type="password"
                                    helperText={url2passwordHelperText} 
                                    error={url2passwordErr}
                                    value={URL2Password}
                                    label="Password"
                                    onChange={(e) => this.onChange(e,"URL2Password")}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions> 
                            <Tooltip title="Save" placement="bottom"> 
                              <Button color="primary" key="submit" onClick={()=>this.url2Ok()}>
                                    Save
                              </Button></Tooltip>
                              <Tooltip title="Back" placement="bottom">
                              <Button key="back" onClick={this.url2Cancel}>
                                    Back
                              </Button></Tooltip>
                            </DialogActions>
                          </Dialog>
                    </GridItem>
                    </>: null}
                    <GridItem xs={12}sm={12} md={12}> 
                    <Tooltip title="Update" placement="bottom">
                      <Button color="primary"  type="submit" onClick={(e)=>this.submit(e)} className="login-form-button M-right-10">
                          Update Server Configuration
                      </Button></Tooltip>
                      <Tooltip title="Delete" placement="bottom">
                      <Button color="info"  type="submit" className="login-form-button danger-color" onClick={()=>this.deleteServerConfig()}>
                          Delete Server Configuration
                      </Button></Tooltip>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );
  }

}
export default EditServerConfig;


 