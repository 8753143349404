import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CreateIcon from "@material-ui/icons/Create";
import api from "../../utils/apiClient";
import Tooltip from "@material-ui/core/Tooltip";
import ReactTable from "react-table";
import { CommonConfig } from "../../utils/constant";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import cogoToast from 'cogo-toast';
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";
import SimpleBackdrop from "../../utils/general";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class overTimeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LeaveList: [],
      OTReferenceList: [],
      ApproveAccess: false,
      IsSelectAll: false,
      LeaveBalance: 12,
      DailyStatusList: [],
      filterVal: "",
      filterValErr: "",
      filterValhelperText: "",
      Resoource: "",
      StartDate: "",
      EndDate: "",
      selectedDate: new Date(),
      Editvisible: false,
      EditOt: false,
      EditReferenceList: false,
      ResourceID: "",
      resourceIDErr: "",
      resourceIDhelperText: "",
      FirstName: "",
      LastName: "",
      OTDate: "",
      OTDateErr: false,
      OTDatehelperText: "",
      OT: "",
      OTErr: false,
      OThelperText: "",
      EditOtData: "",
      isExpanded: false,
      OtHours: "",
      OtHoursErr: false,
      OtHourshelperText: "",
    };
  }

  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }

  componentDidMount() {
    let checkAccess = CommonConfig.getUserAccess("Leave");
    if (checkAccess) {
      if (checkAccess.AllAccess == 1) {
        this.setState({ ApproveAccess: true });
      } else {
        this.setState({ ApproveAccess: false });
      }
    }
    this.getLeaveList();
    this.getDailyStatusList();
  }

  sendState() {
    return this.state;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  isValidated() {
    return true;
  }

  getDailyStatusList = () => {
    try {
      api
        .get("dailystatus/getDailyStatusList")
        .then((res) => {
          if (res.success) {
            let obj = {
              FullName: "Select All",
              ResourceID: "",
            };
            var finalResourceList = [];

            res.data.map((r, i) => {
              res.data[i].FullName = `${r.FirstName} ${r.LastName}`;
            });
            finalResourceList.push(obj);
            for (let i = 0; i < res.data.length; i++) {
              finalResourceList.push(res.data[i]);
            }
            this.setState({
              DailyStatusList: finalResourceList,
              filterVal: this.state.ApproveAccess
                ? { value: "", label: "Select All" }
                : {
                  value: CommonConfig.loginData().PersonID,
                  label:
                    CommonConfig.loginData().FirstName +
                    " " +
                    CommonConfig.loginData().LastName,
                },
            });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  getLeaveList = () => {
    this.showLoader()
    var today = new Date();
    debugger;
    let data;
    let checkAccess = CommonConfig.getUserAccess("Leave");
    if (checkAccess) {
      if (checkAccess.AllAccess == 1) {
        data = {
          ResourceID: "",
          StartDate: startOfMonth(today),
          EndDate: endOfMonth(today),
        };
      } else {
        data = {
          ResourceID: CommonConfig.loginData().PersonID,
          StartDate: this.state.StartDate
            ? this.state.StartDate
            : startOfMonth(today),
          EndDate: this.state.EndDate ? this.state.EndDate : endOfMonth(today),
          // ResourceID: "",
          // StartDate: startOfMonth(today),
          // EndDate: endOfMonth(today),
        };
      }
    }


    try {
      api
        .post("leave/getOtListByResource", data)
        .then((res) => {
          this.hideLoader()
          this.setState({ LeaveList: res.data });
          if (res.success === true) {

            let current = 0;
            let balance = 12;

            //Reverse the array to count the balance from the earlier date
            res.data = res.data.reverse();
            res.data.map((r) => {
              r.leaveDays =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              r.leaveDays2 =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              current =
                r.HalfLeave.data[0] === 1
                  ? r.LeaveStatus !== "Approved"
                    ? 0
                    : 0.5
                  : r.LeaveStatus !== "Approved"
                    ? 0
                    : this.days_passed(r.LeaveStartDate, r.LeaveEndDate);
              balance = balance - current;
              r.leaveBalance = balance;
              return r;
            });
            //Now again reverse the list to it's original state
            let data = res.data.reverse();



          } else {
            this.setState({ LeaveList: [] });
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };

  handleCancel = () => {
    this.setState({
      OThelperText: "",
      OTDatehelperText: "",

    })
    this.setState({ Editvisible: false });
    this.setState({ EditOt: false });
    this.setState({ EditReferenceList: false });

  };

  handleOtDateChange = (date) => {
    this.setState({
      OTDate: moment(date, "DD/MM/YYYY")
        .format("YYYY-MM-DD HH:mm:ss")
        .toString(),
      OTDateErr: false,
      OTDatehelperText: "",
    });
  };

  validDate(current) {
    return current.day() !== 0 && current.isBefore(moment().subtract(0, 'day'));
  }

  handleResoureOnChange = (value) => {
    if (value !== null) {
      this.setState({
        filterVal: value,
        filterValErr: false,
        filterValhelperText: "",
      });
    }
  };

  OT = (value) => {

    this.setState({ Editvisible: true });
    // if (value !== null) {
    //   this.setState({ ResourceID: value });
    // }
    if (value !== null) {
      this.setState({
        ResourceID: value,
        resourceIDErr: false,
        resourceIDhelperText: "",
      });
    }
  };

  handleApproved = (record) => {
    this.setState({ EditOt: true });

    this.setState({ EditOtData: record.original })
  }

  onChangeOtHours = (e) => {

    this.setState({
      OtHours: e.target.value,
      OtHoursErr: false,
      OtHourshelperText: "",
    });
    // }
  };

  AddOTHours = () => {

    let Data = {
      ResourceID: this.state.EditOtData.ResourceID,
      OtBalance: this.state.OtHours ? this.state.OtHours : this.state.EditOtData.OTHours,
      IsModifyHours: 1,
      OtID: this.state.EditOtData.OtID,

    };

    try {
      api
        .post("leave/addUpdateOt", Data)
        .then((res) => {
          if (res.success) {
            this.setState({
              OtHours: "",
            })
            cogoToast.success("OT Verified Successfully.");
            this.search();
            this.setState({ EditOt: false });
          } else {
            cogoToast.error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (err) {
      console.log("error", err);
    }

  }


  onChange = (e) => {
    this.setState({
      OT: e.target.value,
      OTErr: false,
      OThelperText: "",
    });

  };
  validate = () => {
    let formIsvalid = true;
    const { ResourceID, OTDate, OT } = this.state;
    if (CommonConfig.isEmpty(ResourceID)) {
      formIsvalid = false;
      this.setState({
        resourceIDErr: true,
        resourceIDhelperText: "Resource is required.",
      });
    }

    if (CommonConfig.isEmpty(OTDate)) {
      formIsvalid = false;
      this.setState({
        OTDateErr: true,
        OTDatehelperText: "OT Date is required.",
      });
    }

    if (CommonConfig.isEmpty(OT)) {
      formIsvalid = false;
      this.setState({
        OTErr: true,
        OThelperText: "OT is required.",
      });
    }
    return formIsvalid;
  };


  AddOT = () => {
    if (this.validate()) {
      let Data = {
        ResourceID: this.state.ResourceID.value,
        OtBalance: this.state.OT,
        OtDate: this.state.OTDate,
      };
      try {
        api
          .post("leave/addUpdateOt", Data)
          .then((res) => {
            if (res.success) {
              this.setState({
                OT: "",
                OTDate: "",
              })
              cogoToast.success("Ot Added Successfully");
              this.search();
              this.setState({ Editvisible: false });
            } else {
              cogoToast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  edit = (record) => {
    this.props.history.push({
      pathname: "/admin/AddLeave",
      state: { id: record.original.LeaveID },
    });
  };
  Delete = (record) => {

    let data = {
      ResourceID: CommonConfig.loginData().PersonID,
      OtID: record.original.OtID,
    };
    try {
      api
        .post("leave/deleteOt", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success(`OT Removed Successfully.`);
            this.getLeaveList();
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };
  redirectAdd = () => {
    this.props.history.push("/admin/AddLeave");
  };


  handleExpanded = (row) => {
    this.showLoader()
    this.setState({ EditReferenceList: true });

    this.setState({ isExpanded: true })

    let data;
    data = {
      ReferenceID: row._original.ReferenceID,
    };
    try {
      api
        .post("leave/getOtListByReferenceID", data)
        .then((res) => {
          this.hideLoader()
          this.setState({ OTReferenceList: res.data });
        })
        .catch((error) => { });
    } catch (err) { }
  }

  handleApprovedLeave = (record, type) => {
    let data = {
      ResourceID: CommonConfig.loginData().PersonID,
      CurrentStatus: type,
      OtID: record.original.OtID,
    };
    try {
      api
        .post("leave/updateOtStatus", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success(`OT ` + type + ` Successfully`);
            this.search();
          }
        })
        .catch((error) => { });
    } catch (err) { }
  };
  handleResoureOnChange = (value) => {
    if (value !== null) {
      this.setState({ filterVal: value });
    }
  };

  handleMonthChange = (date) => {
    this.setState({
      selectedDate: startOfMonth(date),
      StartDate: startOfMonth(date),
      EndDate: endOfMonth(date),
    });
  };

  search = () => {
    this.showLoader()
    var today = new Date();
    let data;
    data = {
      ResourceID: this.state.filterVal.value,
      StartDate: this.state.StartDate
        ? this.state.StartDate
        : startOfMonth(today),
      EndDate: this.state.EndDate ? this.state.EndDate : endOfMonth(today),
    };
    try {
      api
        .post("leave/getOtListByResource", data)
        .then((res) => {
          this.hideLoader()
          this.setState({ LeaveList: res.data });
        })
        .catch((error) => { });
    } catch (err) { }
  };



  days_passed = (startdate, enddate) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(startdate);
    const secondDate = new Date(enddate);
    return Math.round(Math.abs((firstDate - secondDate) / oneDay) + 1);
  };

  render() {
    const {
      LeaveList,
      ApproveAccess,
      selectedDate,
      IsSelectAll,
      Editvisible,
      EditOt,
      EditReferenceList,
      OTDate,
      OT,
      OTHours,
    } = this.state;
    const Resource = this.state.DailyStatusList
      ? this.state.DailyStatusList.map((DailyStatus) => {
        return { value: DailyStatus.ResourceID, label: DailyStatus.FullName };
      })
      : [];
    var AlltableColumns = [
      {
        Header: "Expand",
        width: 100,
        Cell: ({ row }) => (
          <span>
            <button>
              <DoneIcon
                onClick={() => this.handleExpanded(row)}
              />
            </button>
          </span>
        ),
      },

      {
        Header: "Reference ID",
        width: 120,
        accessor: "ReferenceID",
        id: "ReferenceID",
        sortable: true,
      },
      {
        Header: "OT Date",
        accessor: (data) => {
          return (
            moment(data.OtDate).format("DD/MM/YYYY")
          );
        },
        id: "OtDate",
        width: 100,
        sortable: true,
      },
      {
        Header: "Resource",
        accessor: (data) => {
           return `${data.FirstName} ${data.LastName}`
        },
        id: "Day2",
        width: 150,
        sortable: true,
      },
      {
        Header: "Current Status",
        width: 120,
        accessor: "CurrentStatus",
        id: "CurrentStatus",
        sortable: true,
      },
      {
        Header: "OT Hours",
        width: 120,
        accessor: "OTHours",
        id: "OTHours",
        sortable: true,
      },
      {
        Header: "Action",
        width: 150,
        id: "action",
        sortable: false,
        className: "text-align-right",
        Cell: (record) => {
          return (
            <span>

              {record.original.CurrentStatus === "Pending" ? (
                <span>
                  <DoneIcon
                    onClick={() => this.handleApprovedLeave(record, "Approved")}
                  />
                  <CloseIcon
                    onClick={() => this.handleApprovedLeave(record, "Rejected")}
                  />
                  <Button
                  // className="btn-icon"
                  color="primary"
                  onClick={() => this.handleApproved(record)}
                >
                  <CreateIcon />
                </Button>
                  {/*<Button>
                    <CreateIcon
                      onClick={() => this.handleApproved(record)}
                    />
              </Button>*/}
                </span>
              ) : null}
            </span>
          );
        },
        filterable: false,
      },
    ];

    const tableColumns = [
      {
        Header: "Expand",
        width: 100,
        Cell: ({ row }) => (
          <span>
            <button>
              <DoneIcon
                onClick={() => this.handleExpanded(row)}
              />
            </button>
          </span>
        ),
      },
      {
        Header: "OT Date",
        accessor: (data) => {
          return (
            moment(data.OtDate).format("DD/MM/YYYY")
          );
        },
        id: "OtDate",
        width: 100,
        sortable: true,
      },
      {
        Header: "Resource",
        accessor: (data) => {
          return (
            <span>
              {data.FirstName} {data.LastName}
            </span>
          );
        },
        id: "Day2",
        width: 150,
        sortable: true,
      },
      {
        Header: "Current Status",
        width: 120,
        accessor: "CurrentStatus",
        id: "CurrentStatus",
        sortable: true,
      },
      {
        Header: "OT Hours",
        width: 120,
        accessor: "OTHours",
        id: "OTHours",
        sortable: true,
      },
      {
        Header: "Action",
        width: 150,
        id: "action",
        sortable: false,
        className: "text-align-right",
        Cell: (record) => {
          return (
            <span>
            </span>
          );
        },
        filterable: false,
      },
    ];


    const ReferenceColumns = [
      {
        Header: "OT Date",
        accessor: (data) => {
          return (
            moment(data.OtDate).format("DD/MM/YYYY")
          );
        },
        id: "OtDate",
        width: 100,
        sortable: true,
      },
      {
        Header: "Resource",
        accessor: (data) => {
          return (
            <span>
              {data.FirstName} {data.LastName}
            </span>
          );
        },
        id: "Day2",
        width: 150,
        sortable: true,
      },
      {
        Header: "Current Status",
        width: 120,
        accessor: "CurrentStatus",
        id: "CurrentStatus",
        sortable: true,
      },
      {
        Header: "OT Hours",
        width: 120,
        accessor: "OTHours",
        id: "OTHours",
        sortable: true,
      },
    ];



    if (!IsSelectAll) {
      var index = AlltableColumns.findIndex((x) => x.id == "Resource");
      if (index > -1) {
        AlltableColumns.splice(index, 1);
      }
    }

    return (
      <div>
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        <Dialog
          open={EditOt}
          onClose={() => this.handleCancel()}
          fullWidth
        >
          <DialogContent>
            <GridContainer>
              <GridItem xs={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    id="OTHours"
                    defaultValue={this.state.EditOtData.OTHours}
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    label="OT Hours"
                    onChange={(e) => this.onChangeOtHours(e)}
                  />
                  {this.state.OtHoursErr == true ? (
                    <FormHelperText style={{ color: "red" }}>
                      {this.state.OtHourshelperText}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Tooltip title="Update" placement="bottom">
              <Button
                type="submit"
                color="primary"
                onClick={(e) => this.AddOTHours(e)}
              >
                Update
              </Button>
            </Tooltip>
            <Tooltip title="Cancel" placement="bottom">
              <Button key="Cancel" onClick={() => this.handleCancel()}>
                Cancel
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>



        <Dialog
          open={Editvisible}
          onClose={() => this.handleCancel()}
          fullWidth
        >
          <DialogContent>
            <GridContainer>
              <GridItem xs={6}>
                <div className="search-resource">
                  <div className="with-icon">
                    <Autocomplete
                      options={Resource}
                      id="ResourceID"
                      value={this.state.ResourceID}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, value) => this.OT(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Resource*"
                        />
                      )}
                    />
                  </div>
                  <FormHelperText style={{ color: "red" }}>{this.state.resourceIDhelperText}</FormHelperText>
                </div>
              </GridItem>

              <GridItem xs={6}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    id="OT"
                    value={OT}
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    label="OT Hours*"
                    onChange={(e) => this.onChange(e)}
                  />
                  {this.state.OTErr == true ? (
                    <FormHelperText style={{ color: "red" }}>
                      {this.state.OThelperText}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </GridItem>

            </GridContainer>

            <GridContainer>
              <GridItem xs={6}>
                <FormControl fullWidth style={{ minHeight: "200px" }}>
                  <Datetime
                    dateFormat={"DD/MM/YYYY"}
                    timeFormat={false}
                    className="input-group"
                    isValidDate={this.validDate}
                    id="OTDate"
                    value=""
                    maxDate={new Date()}
                    inputProps={{ placeholder: "OT Date" }}
                    onChange={(date) => this.handleOtDateChange(date)}
                    closeOnSelect={true}
                    renderInput={(params) => (
                      <TextField {...params} label="OT Date*" />
                    )}
                  >
                  </Datetime>
                  <FormHelperText style={{ color: "red" }}>{this.state.OTDatehelperText}</FormHelperText>
                </FormControl>
              </GridItem>


            </GridContainer>
          </DialogContent>

          <DialogActions>
            <Tooltip title="Add" placement="bottom">
              <Button
                type="submit"
                color="primary"
                onClick={(e) => this.AddOT(e)}
              >
                Add
              </Button>
            </Tooltip>
            <Tooltip title="Cancel" placement="bottom">
              <Button key="Cancel" onClick={() => this.handleCancel()}>
                Cancel
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>

        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <div className="search-resource">
              <div className="with-icon">
                <Autocomplete
                  disabled={ApproveAccess ? false : true}
                  options={Resource}
                  id="Resource"
                  value={this.state.filterVal}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => this.handleResoureOnChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={this.state.ResourceErr}
                      helperText={this.state.ResourceHelperText}
                      label="Search Resource"
                    />
                  )}
                />
                <Tooltip title="Search">
                  <Button
                    color="primary"
                    justIcon
                    onClick={() => this.search()}
                  >
                    <SearchIcon />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <div className="search-resource">
              <div className="with-icon">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk={true}
                    variant="inline"
                    openTo="year"
                    views={["year", "month"]}
                    label="Select Year and Month"
                    value={selectedDate}
                    onChange={(e) => this.handleMonthChange(e)}
                  />
                </MuiPickersUtilsProvider>
                <Tooltip title="Search">
                  <Button
                    color="primary"
                    justIcon
                    onClick={() => this.search()}
                  >
                    <SearchIcon />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </GridItem>
          {ApproveAccess ? (
            <GridItem xs={12} sm={4} md={2}>
              <div className="search-resource">
                <div className="with-icon">
                  <Tooltip title="Add OT" placement="bottom">
                    <Button
                      color="primary"
                      title="Add OT"
                      onClick={() => this.OT()}
                      style={{ marginLeft: "10px" }}
                    >
                      Add OT
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </GridItem>

          ) : ""}

        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <ReactTable
                  data={LeaveList}
                  minRows={2}
                  resizable={false}
                  columns={ApproveAccess ? AlltableColumns : tableColumns}
                  defaultPageSize={10}
                  style={{ cursor: "pointer" }}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>



        <Dialog
          open={EditReferenceList}
          onClose={() => this.handleCancel()}
          fullWidth
        >
          <DialogContent>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <ReactTable
                      data={this.state.OTReferenceList ? this.state.OTReferenceList : ""}
                      minRows={2}
                      resizable={false}
                      columns={ReferenceColumns}
                      defaultPageSize={5}
                      style={{ cursor: "pointer" }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Tooltip title="Ok" placement="bottom">
              <Button key="Ok" onClick={() => this.handleCancel()}>
                Ok
              </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

overTimeList.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(overTimeList);
