import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CreateIcon from '@material-ui/icons/Create';
import api from '../../utils/apiClient';
import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from "react-table";
import { CommonConfig } from '../../utils/constant';
import moment from 'moment';
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import cogoToast from 'cogo-toast';
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import tableStyle from 'assets/jss/material-dashboard-pro-react/components/tableStyle';

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    }
};


class FederalListView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            arrOfLeave: [
            ]
        }
    }

    getLeaveList = () => {
        try {
            api.get("federalLeave/getFederalLeaveList").then(res => {
                console.log("response", res.data)
                if (res.success) {
                    //   let newState = {}
                    res.data.map(item => {
                        this.setState({
                            arrOfLeave: [...this.state.arrOfLeave, item]
                        })
                        console.log("state........", this.state.arrOfLeave)
                    })
                }
            }).catch(err => {
                console.log("error", err)
            })
        }
        catch (error) {
            console.log("err", error)
        }
    }

    redirectEdit = (data) => {
        this.props.history.push({
            pathname: "/admin/addFederalLeave",
            state: { data: data, type: "edit" }
        });
        console.log("props", this.props)
    }

    redirectAdd = () => {
        this.props.history.push({
            pathname: "/admin/addFederalLeave",
            state: { type: null }
        });
        console.log("props", this.props)
    }

    componentDidMount() {
        this.getLeaveList();
    }


    render() {
        const { arrOfLeave } = this.state

        console.log("data", arrOfLeave)
        var columns = [
            {
                Header: 'Name',
                accessor: 'Name',
            },
            {
                Header: 'Date',
                accessor: 'Date',
                Cell: (record) => {
                    return <span>{moment(record.original.Date).format("YYYY-MM-DD")}</span>;
                }
            },
            {
                Header: 'Day',
                accessor: 'DayOfTheWeek',
            },
            {
                Header: 'Action',
                width: 100,
                id: 'Action',

                accessor: data => {
                    return (
                        // <Button color="primary" onClick={() => this.editRowData()}>Add Resource</Button>
                        <Button color="primary" title="Edit " onClick={() => this.redirectEdit(data)} style={{ marginLeft: '10px' }}>
                            <i class="fas fa-edit"></i>
                        </Button>
                        // null
                    )

                }

            },
        ]

        return (
            <div>
                <Card>
                    <CardBody>
                        <GridContainer className="UserList-outer">
                            <GridItem xs={12} sm={4} md={4}>
                                <Tooltip title="Add" placement="bottom">
                                    {/* <Link to="/admin/AddFederalLeave"> */}
                                    <Button type="button" color="primary" onClick={() => this.redirectAdd()}>Add Leave</Button>
                                    {/* </Link> */}
                                </Tooltip>
                            </GridItem>
                        </GridContainer>
                        <ReactTable minRows={2} defaultPageSize={10}
                            resizable={false} columns={columns} data={arrOfLeave} className="-striped -highlight"></ReactTable>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

FederalListView.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(FederalListView);