import React, { Component } from 'react';
import PropTypes from "prop-types";
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReactTable from "react-table";
import cogoToast from 'cogo-toast';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import CardHeader from 'components/Card/CardHeader';
import MenuItem from '@material-ui/core/MenuItem';
import { FormHelperText, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import { FormatAlignLeft } from '@material-ui/icons';

class DailyStatus extends Component {
    constructor(props) {
        super(props);
        var width;
        if (window.innerWidth < 600) {
            width = "50%";
        } else {
            width = "50%";
        }
        this.state = {
            currentStep: 0,
            color: this.props.color,
            width: width,
            UserList: [],
            loggedUserID: 0,
            movingTabStyle: {
                transition: "transform 0s"
            },
            allStates: {},
            ListItem: 1,
            GroupList: [],
            moduleList: [],
            ReadAccess: "",
            WriteAccess: "",
            DeleteAccess: "",
            AllAccess: "",
            loggedUserID: 0,
            Designation: "",
            DesignationErr: false,
            DesignationHelpertext: "",
            DesignationList: [],
        };
    }

    componentDidMount() {
        // let checkAccess = CommonConfig.getUserAccess("Access Control");
        // if(window.location.pathname !== "/admin/userList"){
            this.getUserList();
            this.getDesignationList();
            this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
        // this.getUserModule(CommonConfig.loginData().PersonID);
        // }else{
        //     this.props.history.goBack(-1)
        // }
    }

    getUserModule = (UserGroupName) => {
        let data = {
            UserGroupName: UserGroupName
        }
        try {
            api.post("moduleMaster/getUserGroupModule", data).then(res => {
                if (res.success) {
                    if (res.Data.length > 0) {
                        this.setState({ moduleList: res.Data });
                        this.viewModuleList();
                    } else {
                        this.getModuleName();
                    }
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {

        }
    }

    getModuleName = () => {
        try {
            api.get("moduleMaster/getModuleName").then(res => {
                if (res.success) {
                    var moduleObject = {
                        "AllAccess": 0,
                        "DeleteAccess": 0,
                        "ReadAccess": 0,
                        "Status": "Active",
                        "WriteAccess": 0,
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i] = Object.assign(res.data[i], moduleObject)
                    }
                    this.setState({ moduleList: res.data });
                    this.viewModuleList();
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {

        }
    }

    getDesignationList = () => {
        let data = {
            StringMapType: "DESIGNATION"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ DesignationList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getUserList = () => {
        try {
            api.get("moduleMaster/getUserList").then(res => {
                this.setState({ UserList: res.data });
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    redirectEdit = (record) => {
        // console.log("record...",record);
        this.props.history.push({
            pathname: "/admin/userModule",
            state: {
                id: record.original.PersonID,
                PersonName: record.original.FirstName + " " + record.original.LastName
            }
        })

    }

    submit = (e) => {
        const { moduleList } = this.state;
        let data = {
            UserGroupName: this.state.Designation,
            UserID: CommonConfig.loginData().PersonID,
            moduleList: moduleList
        }
          try {
            api.post("moduleMaster/addUpdateAccessByGroup", data).then(res => {
              if (res.success) {
                cogoToast.success("User Module added successfully");
               window.location.reload()
              }
              else {
                cogoToast.error("Something went wrong");
              }
            }).catch(error => {
              console.log("error", error);
            });
          }
          catch (err) {
            console.log("err", err);
        }
    }

    checkboxChange = (e, idx, type) => {
        var checkedModule = this.state.moduleList;
        let cbVal = e.target.checked;
        if (type === "ReadAccess") {
            checkedModule[idx]["ReadAccess"] = (cbVal) ? 1 : 0;
        }
        if (type === "WriteAccess") {
            checkedModule[idx]["WriteAccess"] = (cbVal) ? 1 : 0;
        }
        if (type === "DeleteAccess") {
            checkedModule[idx]["DeleteAccess"] = (cbVal) ? 1 : 0;
        }
        if (type === "AllAccess") {
            checkedModule[idx]["AllAccess"] = (cbVal) ? 1 : 0;
        }
        this.setState({ moduleList: checkedModule });
    }

    viewModuleList = () => {
        return this.state.moduleList.filter(x => x.Status === 'Active').map((content, idx) => {
            return (
                <tr>
                    <td style={{ width: 180 }}>
                        {content.ModuleName}
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox  checked={content.ReadAccess} onChange={(e) => this.checkboxChange(e, idx, "ReadAccess")} name="ReadAccess" />
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox checked={content.WriteAccess} onChange={(e) => this.checkboxChange(e, idx, "WriteAccess")} name="WriteAccess" />
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox checked={content.DeleteAccess} onChange={(e) => this.checkboxChange(e, idx, "DeleteAccess")} name="DeleteAccess" />
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox checked={content.AllAccess} onChange={(e) => this.checkboxChange(e, idx, "AllAccess")} name="AllAccess" />
                    </td>
                </tr>

            )
        })
    }

    selectChange = (e, type) => {
        if (type === "Gender") {
            this.setState({
                Gender: e.target.value,
                GenderHelpertext: "",
                GenderErr: false,

            });
        }
        if (type === "Department") {
            this.setState({
                Department: e.target.value,
                DepartmentHelpertext: "",
                DepartmentErr: false,

            });
        }
        if (type === "Designation") {
            this.setState({
                Designation: e.target.value,
                DesignationHelpertext: "",
                DesignationErr: false,
            });
            this.getUserModule(e.target.value);
        }
        if (type === "AddressType") {
            this.setState({
                AddressType: e.target.value,
                AddressTypeHelpertext: "",
                AddressTypeErr: false,
            });
        }
        if (type === "Title") {
            this.setState({
                Title: e.target.value,
                TitleListHelpertext: "",
                TitleListErr: false,
            });
        }
        if (type === "Suffix") {
            this.setState({
                Suffix: e.target.value,
                SuffixListHelpertext: "",
                SuffixListErr: false,
            });
        }

    };

    designationlist = () => {
        return this.state.DesignationList.map((content) => {
            return (
                <MenuItem value={content.StringMapKey}>
                    {" "}
                    {content.StringMapName}{" "}
                </MenuItem>
            );
        });
    };

    render() {
        const { classes } = this.props;
        const { UserList, GroupList } = this.state;
        const tableColumns = [
            {
                Header: 'Name',
                className: "text-align-left",
                accessor: data => {
                    return data.FirstName + " " + data.LastName
                },
                filterMethod: (filter, row ) => {
                    let key =  row._original.FirstName && row._original.LastName ? 
                    row._original.FirstName + " " + row._original.LastName : '';
                    return (
                        row._original ? String(key).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                id: 'Name',
                width: 120,
                sortable: true,
            },
            {
                Header: 'User Name',
                accessor: 'UserName',
                filterMethod: (filter, row ) => {
                    let userName =  row._original.UserName;
                    return (
                        row._original ? String(userName).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                id: 'UserName',
                width: 200,
                sortable: true,
            },
            {
                Header: 'Email',
                className: "text-align-left",
                accessor: 'Email',
                filterMethod: (filter, row ) => {
                    let email =  row._original.Email;
                    return (
                        row._original ? String(email).toLowerCase().includes(filter.value.toLowerCase()) : row
                    )
                }, 
                id: 'Email',
                width: 188,
                sortable: true,
            },
            {
                Header: 'Mobile',
                className: "text-align-left",
                accessor: 'PhoneNumber',
                id: 'PhoneNumber',
                width: 95,
                sortable: true,
            },
            {
                className: "text-align-left",
                Header: 'Created On',
                width: 125,
                accessor: data => {
                    if (CommonConfig.isEmpty(data.CreatedOn)) {
                        return null;
                    }
                    else {
                        return moment(data.CreatedOn).format(CommonConfig.dateFormat.dateFirst);
                    }
                },
                id: 'CreatedOn',
                sortable: true,
            },
            {
                className: "text-align-left",
                Header: 'Action',
                id: 'action',
                width: 80,
                Cell: record => {
                    return (
                        <span>
                            <Tooltip title="Edit" placement="bottom">
                                <Button color="primary" onClick={() => this.redirectEdit(record)}><CreateIcon /></Button>
                            </Tooltip>
                        </span>
                    )
                },
                sortable: false,
                filterable: false
            },
        ]
        
        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader>
                            <div className={classes.wizardNavigation}>                               
                                <ul className={classes.nav}>
                                    <li
                                        className={classes.steps}
                                        style={{ width: this.state.width }}
                                    >
                                        <Button
                                            href="#pablo"
                                            className={classes.stepsAnchor}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ ListItem: 1 })
                                            }}
                                          
                                        >
                                            Group Wise
                                        </Button>
                                    </li>
                                    <li
                                        className={classes.steps}
                                        style={{ width: this.state.width }}
                                    >
                                        <Button
                                            href="#pablo"
                                            className={classes.stepsAnchor}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ ListItem: 2 })
                                            }}
                                        >
                                            User Wise
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </CardHeader>
                          <CardBody>
                            {this.state.ListItem == 1 ?
                                <GridContainer justify="center">
                                    <GridItem xs={6}>
                                        <FormControl error={this.state.DesignationErr} fullWidth label="Security Group">
                                            <InputLabel>Security Group</InputLabel>
                                            <Select
                                                onChange={(e) => this.selectChange(e, "Designation")}
                                                value={this.state.Designation}
                                            >
                                                {this.designationlist()}
                                            </Select>
                                            <FormHelperText>{this.state.DesignationHelpertext}</FormHelperText>
                                        </FormControl>
                                    </GridItem>
                                    {this.state.moduleList.length > 0 ?
                                    <>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControl>
                                            <table style={{ margin: 0, border: "solid" }}>
                                                <tr>
                                                    <th>
                                                        Module Name
                                                    </th>
                                                    <th>
                                                        Read Access
                                                        <Checkbox />
                                                    </th>
                                                    <th>
                                                        Write Access
                                                        <Checkbox />
                                                    </th>
                                                    <th>
                                                        Delete Access
                                                        <Checkbox />
                                                    </th>
                                                    <th>
                                                        All Access
                                                        <Checkbox />
                                                    </th>
                                                </tr>
                                                {this.viewModuleList()}
                                            </table>
                                        </FormControl>
                                    </GridItem>
                                    <GridContainer justify="center">
                                        <GridItem>
                                            <Tooltip title="Add" placement="bottom">
                                                <Button type="submit" color="primary" onClick={(e) => this.submit(e)}>Save</Button></Tooltip>
                                        </GridItem>
                                    </GridContainer>
                                    </>
                                :null}
                                </GridContainer> :
                                <ReactTable
                                    data={UserList}
                                    minRows={5}
                                    resizable={false}
                                    sortable={true}
                                    filterable={true}
                                    columns={tableColumns}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    defaultFilterMethod={CommonConfig.filterCaseInsensitive}
                                />
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

DailyStatus.propTypes = {
    classes: PropTypes.object.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            stepName: PropTypes.string.isRequired,
            stepComponent: PropTypes.object.isRequired,
            stepId: PropTypes.string.isRequired
        })
    ).isRequired,
    color: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose"
    ]),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    previousButtonClasses: PropTypes.string,
    previousButtonText: PropTypes.string,
    nextButtonClasses: PropTypes.string,
    nextButtonText: PropTypes.string,
    finishButtonClasses: PropTypes.string,
    finishButtonText: PropTypes.string,
    finishButtonClick: PropTypes.func,
    validate: PropTypes.bool
};
export default withStyles(wizardStyle)(DailyStatus);