import React, { Component } from 'react';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Tooltip from '@material-ui/core/Tooltip';
import TaglineLogo from 'assets/img/Cognisun-tagline.png';
import TextField from "@material-ui/core/TextField";
import cogoToast from 'cogo-toast';
import Button from "components/CustomButtons/Button.js";
import SimpleBackdrop from "../../utils/general";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Icon from "@material-ui/core/Icon";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(styles);
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: "",
      usernameErr: false,
      usernameHelperText: "",
      passwordHelperText: "",
      passwordErr: false,
      Password: "",
      // isChecked: false,      
    };
  }

  componentWillUnmount() {
    document.body.classList.remove('login-page');

  }

  componentDidMount() {
    document.body.classList.add('login-page');
    // if(localStorage.getItem('RememberMe')){
    //     const data = JSON.parse(localStorage.getItem('RememberMe'));
    //     this.setState({ UserName : data.Email, Password : data.Password, isChecked : true})
    //     localStorage.removeItem("RememberMe")
    // }

  }

  // onChangeCheckbox = event => {
  //   this.setState({
  //       isChecked: event.target.checked
  //   })
  // }

  validate = () => {
    let IsValid = true;

    if (CommonConfig.isEmpty(this.state.UserName)) {
      IsValid = false;
      this.setState({ usernameHelperText: "Please enter username", usernameErr: true });
    }

    else {
      IsValid = true;
      this.setState({ usernameErr: false, usernameHelperText: "" });
    }

    if (CommonConfig.isEmpty(this.state.Password)) {
      IsValid = false;
      this.setState({ passwordHelperText: "Please enter password", passwordErr: true });
    }
    else {
      IsValid = true;
      this.setState({ passwordErr: false, passwordHelperText: "" });
    }
    return IsValid;
  }


  onForgotPasswordClick = () => {
    this.props.history.push("/auth/forgot-password")
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.showLoader();

      let data = {
        Email: this.state.UserName,
        Password: this.state.Password,
      }
      try {
        api.post("login/userLogin", data).then(res => {
          this.hideLoader();
          if (res.success) {
            // if(this.state.isChecked){
            //   localStorage.setItem('RememberMe',JSON.stringify(data));
            // }
            localStorage.setItem('loginData', JSON.stringify(res.Data));
            localStorage.setItem('isAuthorized', res.success);
            this.props.history.push("/admin/dashboard");
          }
          else {
            cogoToast.error(res.message);
          }
        }).catch(err => {
        });
      }
      catch (error) {
      }

    }

    else {
      cogoToast.error("There were errors found in form.Please resolve and resubmit form");
    }

  }

  onChange = (e, type) => {
    if (type === "username") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ UserName: e.target.value, usernameErr: true, usernameHelperText: "Please enter username" });
      }
      else {
        this.setState({ UserName: e.target.value, usernameErr: false, usernameHelperText: "" });
      }
    }
    else if (type === "password") {

      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ Password: e.target.value, passwordErr: true, passwordHelperText: "Please enter password" });
      }
      else {
        this.setState({ Password: e.target.value, passwordErr: false, passwordHelperText: "" });
      }

    }
  }


  showLoader() {
    this.setState({ Loading: true });
  }

  hideLoader() {
    this.setState({ Loading: false });
  }

  render() {
    const { Email, UserName, Password, usernameErr, usernameHelperText, passwordErr, passwordHelperText, isChecked } = this.state;

    return (

      <div className="login-outer">
        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null
        }
        <form>
          <div className="login-box">
            <div className="login-welcome">
              <img src={TaglineLogo} />
              <p>Welcome to OMS.</p>
            </div>
            <div className="login-input">
              <FormControl fullWidth>
                <TextField
                  value={UserName}
                  label="User Name"
                  helperText={usernameHelperText}
                  error={usernameErr}
                  onChange={(e) => this.onChange(e, "username")}
                />
              </FormControl>
            </div>
            <div className="login-input">
              <FormControl fullWidth>
                <TextField
                  type="password"
                  label="Password"
                  helperText={passwordHelperText}
                  error={passwordErr}
                  value={Password}
                  onChange={(e) => this.onChange(e, "password")}
                />
                {/* <div className="login-input">
                      <input type="checkbox" checked={isChecked} name="lsRememberMe" onChange={this.onChangeCheckbox} />
                      <label> Remember me </label>
                      </div> */}
              </FormControl>
            </div>

            <div className="login-submit">
              {/* <a color="danger" onClick={() => this.onForgotPasswordClick()}>Forgot Password</a> */}
              <Tooltip title="Login" placement="bottom">
                <Button type="Submit" color="primary" onClick={(e) => this.onSubmit(e)}>LOGIN</Button>
              </Tooltip>

              &nbsp;&nbsp;
              <a  style={{ cursor: "pointer" }} onClick={() => this.onForgotPasswordClick()}>Forgot Password?</a>

            </div>

          </div>
        </form >

      </div >

    )
  }
}

export default LoginPage;