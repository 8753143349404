import React, { Component } from 'react';
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import cogoToast from 'cogo-toast';
import FormControl from '@material-ui/core/FormControl';
import CardIcon from "components/Card/CardIcon.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Checkbox from '@material-ui/core/Checkbox';
import Button from "components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';

class UserAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LoginID: "",
            ID: "",
            PersonName: "",
            moduleList: [],
            ReadAccess: "",
            WriteAccess: "",
            DeleteAccess: "",
            AllAccess: "",
            loggedUserID: 0,
        }
    }

    componentDidMount() {
        this.setState({
            loggedUserID: CommonConfig.loginData().PersonID,
            LoginID: this.props.location.state.id,
            PersonName: this.props.location.state.PersonName
        });
        this.getUserModule();
    }

    getUserModule = () => {
        let data = {
            UserID: this.props.location.state.id
        }
        try {
            api.post("moduleMaster/getUserModule", data).then(res => {
                if (res.success) {
                    this.setState({ moduleList: res.Data });
                    this.viewModuleList();
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {

        }
    }

    checkboxChange = (e, idx, type) => {
        var checkedModule = this.state.moduleList;
        let cbVal = e.target.checked;
        if (type === "ReadAccess") {
            checkedModule[idx]["ReadAccess"] = (cbVal) ? 1 : 0;
        }
        if (type === "WriteAccess") {
            checkedModule[idx]["WriteAccess"] = (cbVal) ? 1 : 0;
        }
        if (type === "DeleteAccess") {
            checkedModule[idx]["DeleteAccess"] = (cbVal) ? 1 : 0;
        }
        if (type === "AllAccess") {
            checkedModule[idx]["AllAccess"] = (cbVal) ? 1 : 0;
        }
        this.setState({ moduleList: checkedModule });
    }

    viewModuleList = () => {
        return this.state.moduleList.filter(x => x.Status === 'Active').map((content, idx) => {
            return (
                <tr>
                    <td style={{ width: 180 }}>
                        {content.ModuleName}
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox checked={content.ReadAccess} onChange={(e) => this.checkboxChange(e, idx, "ReadAccess")} name="ReadAccess" />
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox checked={content.WriteAccess} onChange={(e) => this.checkboxChange(e, idx, "WriteAccess")} name="WriteAccess" />
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox checked={content.DeleteAccess} onChange={(e) => this.checkboxChange(e, idx, "DeleteAccess")} name="DeleteAccess" />
                    </td>
                    <td style={{ paddingLeft: 27 }}>
                        <Checkbox checked={content.AllAccess} onChange={(e) => this.checkboxChange(e, idx, "AllAccess")} name="AllAccess" />
                    </td>
                </tr>

            )
        })
    }

    submit = (e) => {
        const { moduleList } = this.state;
        // if (this.validate()) {
        let data = {
            LoginID: this.state.LoginID,
            ID: CommonConfig.loginData().PersonID,
            UserID: CommonConfig.isEmpty(this.state.moduleList.UserID) == true ? null : this.state.moduleList.UserID,
            moduleList: moduleList
        }

        try {
            api.post("moduleMaster/addUpdateUserModule", data).then(res => {
                if (res.success) {
                    cogoToast.success("User Module added successfully");
                    this.props.history.push("/admin/userList");
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(error => {
                console.log("error", error);
            });
        }
        catch (err) {
            console.log("err", err);
        }
    }

    render() {

        return (
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader className="btn-right-outer" color="primary" icon>
                            <CardIcon color="primary">
                                <HeadsetMic />
                            </CardIcon>
                            <h4>Add User Access Module</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="center">
                                <GridItem>
                                    {/* {this.state.LoginID} */}
                                    {this.state.PersonName}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl>
                                        <table style={{ width: 580, margin: 0, border: "solid" }}>
                                            <tr>
                                                <th>
                                                    Module Name
                                                </th>
                                                <th>
                                                    Read Access
                                                    <Checkbox />
                                                </th>
                                                <th>
                                                    Write Access
                                                    <Checkbox />
                                                </th>
                                                <th>
                                                    Delete Access
                                                    <Checkbox />
                                                </th>
                                                <th>
                                                    All Access
                                                    <Checkbox />
                                                </th>
                                            </tr>

                                            {this.viewModuleList()}

                                        </table>

                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                                <GridItem>
                                    <Tooltip title="Save" placement="bottom">
                                        <Button type="submit" color="primary" onClick={(e) => this.submit(e)}>Save</Button></Tooltip>
                                </GridItem>
                            </GridContainer>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );

    }


}

export default UserAccess;