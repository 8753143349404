import Dashboard from "views/Dashboard/Dashboard.js";
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import changePassword from "components/Resource/changePassword.js";
import Reports from "components/Reports/Reports";
import CognisunQuickView from "components/CognisunQuickView/CognisunQuickView";
import timeSheetList from "components/Reports/timeSheetList";
import Payroll from "components/Payroll/Payroll.js";
import ServerConfig from "components/ServerConfig/serverconfiglisting";
import addServerConfig from "components/ServerConfig/addServerConfig";
import EditServerConfig from "components/ServerConfig/EditServerConfig";
import addProjectInstance from "components/ProjectInstance/addProjectInstance";
import EditProjectInstance from "components/ProjectInstance/EditProjectInstance";
import ProjectInstance from "components/ProjectInstance/projectinstancelisting";
import Client from "components/Client/clientlisting";
import addClient from "components/Client/addClient";
import EditClient from "components/Client/EditClient";
import addbasicResource from "components/Resource/addbasicResource";
import salaryListing from "components/salaryMaster/SalaryList"
import addSalaryMaster from "components/salaryMaster/addSalaryMaster";
import editSalaryMaster from "components/salaryMaster/editSalaryMaster";
import editbasicResource from "components/Resource/editbasicResource";
import resourceListing from "components/Resource/ResourceList";
import StringMap from "components/StringMap/addStringMap";
import ProjectManagement from "components/ProjectManagement/ProjectManagement";
import addProjectManagement from "components/ProjectManagement/addProjectManagement";
import editProjectManagement from "components/ProjectManagement/editProjectManagement";
import Tasks from "components/Tasks/Tasks";
// import TasksReports from "components/Tasks/TasksReports";
import addTasks from "components/Tasks/addTasks";
import AddLeave from "components/Leave/AddLeave";
import LeaveList from "components/Leave/LeaveList";
// import AddOT from "components/Leave/AddLeave";
import overTimeList from "components/OverTime/OverTimeList";
import LeaveCalendar from "components/LeaveCalendar/LeaveCalendar";
import editTasks from "components/Tasks/editTasks";
import viewTasks from "components/Tasks/viewTasks";
import projectDomainConfig from "components/ProjectDomainConfig/projectDominConfig";
import addProjectDomainConfig from "components/ProjectDomainConfig/addProjectDomainConfig";
import userList from "components/ModuleMaster/userList";
import moduleMaster from "components/ModuleMaster/moduleMaster";
import addModule from "components/ModuleMaster/addModule";
import userModule from "components/ModuleMaster/userModule";
import Profile from "components/Resource/Profile";

import addDaily from "components/DailyStatus/addDailyTasks";
import viewDaily from "components/DailyStatus/viewDailyTasks";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddFederalLeave from "components/FederalLeave/AddFederalLeave";
import FederalLeaveList from "components/FederalLeave/FederalLeaveList";
import FederalListView from "components/FederalLeave/FederalListView";
import PdfCommon from "components/Payroll/PdfCommon";
import ForgotPassword from "views/Pages/ForgotPassword.js";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    invisible: true,
    layout: "/admin",
  },
  {
    path: "/serverconfigListing",
    name: "Server Configuration",
    mini: "SC",
    invisible: true,
    component: ServerConfig,
    layout: "/admin",
  },
  {
    path: "/EditServerConfig",
    name: "Edit Server Configuration",
    mini: "SC",
    invisible: false,
    component: EditServerConfig,
    layout: "/admin",
  },
  {
    path: "/addServerConfig",
    name: "Add Server Configuration",
    mini: "SC",
    invisible: false,
    component: addServerConfig,
    layout: "/admin",
  },
  {
    path: "/ProjectInstanceListing",
    name: "Project Instance",
    mini: "PI",
    invisible: true,
    component: ProjectInstance,
    layout: "/admin",
  },
  {
    path: "/EditProjectInstance",
    name: "Edit Project Instance",
    mini: "PC",
    invisible: false,
    component: EditProjectInstance,
    layout: "/admin",
  },
  {
    path: "/addProjectInstance",
    name: "Add Project Instance",
    mini: "PC",
    invisible: false,
    component: addProjectInstance,
    layout: "/admin",
  },
  {
    path: "/addClient",
    name: "Add Client",
    mini: "CC",
    invisible: false,
    component: addClient,
    layout: "/admin",
  },
  {
    path: "/clientlisting",
    name: "Client",
    mini: "CC",
    invisible: true,
    component: Client,
    layout: "/admin",
  },
  {
    path: "/EditClient",
    name: "Edit Client",
    mini: "CC",
    invisible: false,
    component: EditClient,
    layout: "/admin",
  },
  {
    path: "/resourceListing",
    name: "Resource",
    mini: "RB",
    invisible: true,
    component: resourceListing,
    layout: "/admin",
  },
  {
    path: "/addbasicResource",
    name: "Add Basic Resource",
    mini: "RB",
    invisible: false,
    component: addbasicResource,
    layout: "/admin",
  },
  {
    path: "/salaryListing",
    name:"Salary Master",
    mini: "RB",
    invisible: false,
    component: salaryListing,
    layout: "/admin",
  },
  {
    path: "/overTimeList",
    name: "over TimeList",
    mini: "RB",
    invisible: false,
    component: overTimeList,
    layout: "/admin",
  },
  {
    path: "/editbasicResource",
    name: "Edit Basic Resource",
    mini: "RB",
    invisible: false,
    component: editbasicResource,
    layout: "/admin",
  },
  {
    path: "/ProjectManagement",
    name: "Project",
    mini: "PM",
    invisible: true,
    component: ProjectManagement,
    layout: "/admin",
  },
  {
    path: "/addProjectManagement",
    name: "Add Project",
    mini: "PM",
    invisible: false,
    component: addProjectManagement,
    layout: "/admin",
  },
  {
    path: "/editProjectManagement",
    name: "Edit Project",
    mini: "PM",
    invisible: false,
    component: editProjectManagement,
    layout: "/admin",
  },
  {
    path: "/login-page",
    name: "Login Page",
    invisible: false,
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    invisible: false,
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/register-page",
    name: "Register Page",
    invisible: true,
    component: RegisterPage,
    layout: "/auth",
  },
  {
    path: "/addStringMap",
    name: "Add String Map",
    mini: "SM",
    invisible: false,
    component: StringMap,
    layout: "/admin",
  },

  {
    path: "/editSalaryMaster",
    name: "Edit Salary",
    mini: "TK",
    invisible: false,
    component: editSalaryMaster,
    layout: "/admin",
  },

  {
    path: "/addSalaryMaster",
    name: "Add Salary",
    mini: "TK",
    invisible: false,
    component: addSalaryMaster,
    layout: "/admin",
  },


  {
    path: "/PdfCommon",
    name: "PdfCommon",
    mini: "TK",
    invisible: false,
    component: PdfCommon,
    layout: "/admin",
  },


  {
    path: "/AddLeave",
    name: "Add Leave",
    mini: "TK",
    invisible: false,
    component: AddLeave,
    layout: "/admin",
  },
  {
    path: "/LeaveCalendar",
    name: "Leave Calendar",
    mini: "TK",
    invisible: false,
    component: LeaveCalendar,
    layout: "/admin",
  },
  {
    path: "/LeaveList",
    name: "Leave List",
    mini: "CC",
    invisible: true,
    component: LeaveList,
    layout: "/admin",
  },
  {
    path: "/AddFederalLeave",
    name: "Add Federal Leave",
    // mini: "TK",
    invisible: false,
    component: AddFederalLeave,
    layout: "/admin",
  },
  {
    path: "/ViewFederalLeave",
    name: "View Federal Leave",
    mini: "TK",
    invisible: true,
    component: FederalListView,
    layout: "/admin",
  },
  {
    path: "/FederalLeaveList",
    name: "FederalLeaveList",
    // mini: "TK",
    invisible: true,
    component: FederalLeaveList,
    layout: "/admin",
  },
  {
    path: "/Tasks",
    name: "Task",
    mini: "TK",
    invisible: true,
    component: Tasks,
    layout: "/admin",
  },
  {
    path: "/viewTasks",
    name: "View Tasks",
    mini: "TK",
    invisible: false,
    component: viewTasks,
    layout: "/admin",
  },
  // {
  //   path: "/TasksReports",
  //   name: "TasksReports",
  //   mini: "TK",
  //   invisible: true,
  //   component: TasksReports,
  //   layout: "/admin"
  // },
  {
    path: "/addTasks",
    name: "Add Tasks",
    mini: "TK",
    invisible: false,
    component: addTasks,
    layout: "/admin",
  },
  {
    path: "/editTasks",
    name: "Edit Tasks",
    mini: "TK",
    invisible: false,
    component: editTasks,
    layout: "/admin",
  },
  {
    path: "/viewDailyTasks",
    name: "View Daily Status",
    mini: "DS",
    invisible: false,
    component: viewDaily,
    layout: "/admin",
  },
  {
    path: "/addDailyTasks",
    name: "Add Daily Tasks",
    mini: "DS",
    invisible: false,
    component: addDaily,
    layout: "/admin",
  },
  {
    path: "/projectdomainconfigListing",
    name: "Project Domain Config",
    mini: "PDC",
    invisible: true,
    component: projectDomainConfig,
    layout: "/admin",
  },
  {
    path: "/addProjectDomainConfig",
    name: "Add Project Domain Config",
    mini: "PDC",
    invisible: false,
    component: addProjectDomainConfig,
    layout: "/admin",
  },
  {
    path: "/userList",
    name: "Access Control",
    mini: "AC",
    invisible: true,
    component: userList,
    layout: "/admin",
  },
  {
    path: "/moduleMaster",
    name: "Module Master",
    mini: "AC",
    invisible: false,
    component: moduleMaster,
    layout: "/admin",
  },
  {
    path: "/addModule",
    name: "Add Module",
    mini: "AC",
    invisible: false,
    component: addModule,
    layout: "/admin",
  },
  {
    path: "/userModule",
    name: "User Module",
    mini: "AC",
    invisible: false,
    component: userModule,
    layout: "/admin",
  },
  {
    path: "/changePassword",
    name: "change Password ",
    mini: "AC",
    invisible: true,
    component: changePassword,
    layout: "/admin",
  },
  {
    path: "/Profile",
    name: "Profile",
    mini: "AC",
    invisible: false,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/Reports",
    name: "Reports ",
    mini: "AC",
    invisible: true,
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/timeSheetList",
    name: "TimeSheetList ",
    mini: "AC",
    invisible: true,
    component: timeSheetList,
    layout: "/admin",
  },
  // {
  //   path: "/salaryMaster",
  //   name: "SalaryMaster ",
  //   mini: "AC",
  //   invisible: true,
  //   component:salaryMaster,
  //   layout: "/admin"
  // },
  {
    path: "/Payroll",
    name: "Payroll",
    mini: "AC",
    invisible: true,
    component: Payroll,
    layout: "/admin",
  },
  {
    path: "/CognisunQuickView",
    name: "CognisunQuickView ",
    mini: "AC",
    invisible: true,
    component: CognisunQuickView,
    layout: "/admin",
  },
];
export default dashRoutes;
