export class CommonConfig{

    static isEmpty = function(value){
        if(value === undefined || value === null || value === ''){
            return true;
        }else{
            if(typeof value === 'string'){
                return value.trim() === "";
            }else{
                return false;
            }
        }
    }

    static dateFormat = {
        dateTime: 'MM/DD/YYYY hh:mm:ss A',
        dateOnly: 'MM/DD/YYYY',
        dateFirst: 'DD/MM/YYYY',
        forDatePicker: 'DD-MM-YYYY',
        yearOnly: 'YYYY',
        dbDateTime:'YYYY-MM-DD HH:mm:ss',
        timeOnly:'HH:mm'
    }

    static loginData = function() {
        if(localStorage.getItem('loginData'))
        {
            return JSON.parse(localStorage.getItem('loginData'));
        }
        else
        {
            return ""
        }
    }

    static getUserAccess = function(moduleName){
        if(localStorage.getItem('loginData'))
        {
            let data = JSON.parse(localStorage.getItem('loginData')).userModuleAccess;

            let moduleAccess = data.find(x => x.MenuKey === moduleName);

            return moduleAccess

        }else{
           return ""
        }
    }

    static filterCaseInsensitive = function(filter, row){
        const id = filter.pivotId || filter.id;
        const content = row[id];

        if (typeof content !== 'undefined') {
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }

        return true;
    }

    static RegExp ={
        number : /^[0-9\b]+$/,
        onlyNumber  : /[a-zA-Z~`!@#$%^&*()_+=-{}|:"<>?,;']+$/,
        onlyDecimal : /^[0-9]+(\.[0-9][0-9])?$/,
        phoneNumber : /^([0-9]+\s?)*$/g,
        email : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/ig,
        companyName : /[!@~`#$%^&*()_+\-=\]{};':"\\|,.<>?]/,
        zipCode : /^([a-zA-Z0-9]+\s?)*$/g,
        regExpNumber : /[0-9]/g,
        regExpUpperCase : /[A-Z]/g,
        regExpLowerCase : /[a-z]/g,
        phone :/^[0-9]{10}$/,
        regExpSpecialCharacter : /[!@#$%^&*(),.?":{}|<>]/g,
        aadharNo: /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/,
        PANno:/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    }


}