import React, { Component } from 'react';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CreateIcon from '@material-ui/icons/Create';
import api from '../../utils/apiClient';
import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.js";
class CognisunQuickView extends Component {
  constructor(props){
    super(props);
    this.state = {
      cognisunQuickViewList:[],
    }
  }

  componentDidMount(){
    this.getcognisunQuickViewList();
  }

  getcognisunQuickViewList = () => {
    try{
      api.get("client/cognisunQuickViewList").then(res =>{
        this.setState({clientList:res.data});
      }).catch(error => {
    })

    }
    catch(err){
    }
  } 

//   edit = (record) => {
//     this.props.history.push({
//       pathname:"/admin/EditClient",
//       state:{ id : record.original.ClientID }
//     })
//   }

//   redirectAdd = () => {
//     this.props.history.push("/admin/addClient");
//   }
  
  render() {
    const {cognisunQuickViewList} = this.state;
    const tableColumns = [
      {
        Header: 'Name',
        accessor: 'Name',
        id: 'Name',
        sortable:true,
      },
      {
        Header: 'Email',
        accessor: 'Email',
        id: 'Email',
        sortable:true,
      },
      {
        Header: 'Contact No',
        accessor: 'ContactNo',
        id: 'ContactNo',
        sortable:true,
      },
      {
        Header: 'Status',
        accessor: 'Status',

        id: 'Status',
        sortable:true,
      },
    //   {
    //     Header: 'Action',
    //     id: 'action',
    //     sortable: false,
    //     className:"text-align-right",
    //     Cell: record => {
    //       return (
    //         <span>
    //         <Tooltip title="Edit" placement="bottom">
    //         <Button className="btn-icon" justIcon color="primary" onClick={() => this.edit(record)}><CreateIcon /></Button>
    //         </Tooltip>
    //         </span>
    //         )  
    //   },
    //     filterable: false
    //   },
    ]
  return( 
    <div class="page-outer">
      {/* <div className="page-actions">
        <Tooltip title="Add" placement="bottom">
          <Button type="button" size="sm" color="primary" onClick={()=>this.redirectAdd()}>Add Client</Button>
        </Tooltip>
      </div> */}
      <div className="page-content">
        <div className="common-datatable">
          <ReactTable
            data={cognisunQuickViewList}
            filterable
            minRows = {2}
            resizable={false}
            columns={tableColumns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
      </div>
    </div>  
  )
  }
}
export default CognisunQuickView;