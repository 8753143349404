import React, { Component } from 'react';
import Add from "@material-ui/icons/Add";
// core components
import Icon from '@material-ui/core/Icon';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Select from '@material-ui/core/Select';
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import Datetime from "react-datetime";
import Chip from '@material-ui/core/Chip';
import cogoToast from 'cogo-toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SimpleBackdrop from "../../utils/general";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

class addTasks extends Component {

    constructor(props) {
        super(props);
        this.state = {

            Subject: "",
            SubjectErr: false,
            SubjectHelperText: "",

            EstimetedHours: "",
            EstimetedHoursErr: false,
            EstimetedHoursHelperText: "",

            Assignee: "",
            AssigneeErr: false,
            AssigneeHelperText: "",
            fixedOptions:[],
            Observer: [],
            ObserverErr: false,
            ObserverHelperText: "",
            PersonList: [],

            TaskStatus: "",
            TaskStatusErr: false,
            TaskStatusHelperText: "",

            TypeList: [],
            StatusList: [],
            PriorityList: [],
            CategoryList: [],

            TaskType: "",
            TaskTypeHelperText: "",
            TaskTypeErr: "",

            Priority: "",
            PriorityHelperText: "",
            PriorityErr: false,

            DueDate: moment(),
            DueDateErr: false,
            DueDateHelperText: "",

            Version: "",
            Category: "",
            Milestone: "",

            ProjectList: [],
            ProjectErr: false,
            ProjectHelperText: "",
            Project: "",

            Description: "",
            fileArray: [],
            ObserverArray: [],
            Milestonevisible: false,
            Versionvisible: false,
            Categoryvisible: false,
            loggedUserID: 0,
            Loading: false,
           
        };
    }

    componentDidMount() {
        this.setState({ loggedUserID: CommonConfig.loginData().PersonID });
        this.getPersonList();
        this.getProjectList();
        this.getTypeList();
        this.getStatusList();
        this.getPriorityList();
        this.getCategoryList();
        this.getDailyStatusList();
    }

    getDailyStatusList = (ProjectManager) => {
        try {
            api.get("dailystatus/getDailyStatusList").then(res => {
                if (res.success) {
                    var Obserarr = []
                    Obserarr.push({
                        value: res.data.filter(x => x.ResourceID == 1)[0].FirstName+' '+res.data.filter(x => x.ResourceID == 1)[0].LastName,
                        label: res.data.filter(x => x.ResourceID == 1)[0].ResourceID,
                    })
                    if(ProjectManager && ProjectManager.length>0 && !Obserarr.filter(x=>x.label == ProjectManager[0].ProjectManager).length>0){
                        Obserarr.push({
                            value: res.data.filter(x => x.ResourceID ==ProjectManager[0].ProjectManager)[0].FirstName+' '+res.data.filter(x => x.ResourceID ==ProjectManager[0].ProjectManager)[0].LastName,
                            label: res.data.filter(x => x.ResourceID ==ProjectManager[0].ProjectManager)[0].ResourceID,
                        })
                    }
                    this.setState({
                        ObserverArray: res.data,Observer:Obserarr,fixedOptions:Obserarr
                    });
                }
            }).catch(error => {
            })
        }
        catch (err) {
        }
    }

    getProjectList = () => {
        try {
            api.get("tasks/getProjectList").then(res => {
                if (res.success) {
                    this.setState({ ProjectList: res.data });
                    var date = CommonConfig.isEmpty(this.state.DueDate) ? moment().format("DD/MM/YYYY") : this.state.DueDate;
                    this.setState({ DueDate: date });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getTypeList = () => {
        let data = {
            StringMapType: "TASKTYPE"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ TypeList: res.data, TaskType: "TASK" });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getStatusList = () => {
        let data = {
            StringMapType: "TASKSTATUS"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ StatusList: res.data, TaskStatus: "OPEN" });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getPriorityList = () => {
        let data = {
            StringMapType: "PRIORITY"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ PriorityList: res.data, Priority: "NORMAL" });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getCategoryList = () => {
        let data = {
            StringMapType: "CATEGORY"
        }
        try {
            api.post("/stringmap/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ CategoryList: res.data });
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    getPersonList = () => {
        try {
            api.get("/tasks/getPersonList").then(res => {
                if (res.success) {
                    this.setState({ PersonList: res.data});
                }
                else {
                    cogoToast.error("Something went wrong");
                }
            }).catch(err => {
                console.log(err)
            })
        } catch (err) {
        }
    }

    diff_hours = (DueDate) => {
        // console.log("Due Date", DueDate)
        var dt1 = new Date().getTime();
        var dt2 = new Date(DueDate).getTime();
        var diff = (dt2 - dt1) / 1000;
        diff /= (60 * 60);
        this.setState({ EstimetedHours: Math.abs(Math.round(diff))});
    }

    onChange = (e, type) => {
        if (type === "Subject") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Subject: e.target.value, SubjectErr: true, SubjectHelperText: "Please enter Subject" });
            }
            else {
                this.setState({ Subject: e.target.value, SubjectErr: false, SubjectHelperText: "" });
            }
        }
        else if (type === "EstimetedHours") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ EstimetedHours: e.target.value, EstimetedHoursErr: true, EstimetedHoursHelperText: "Please enter Estimated Hours" });
            }
            else {
                this.setState({ EstimetedHours: e.target.value, EstimetedHoursErr: false, EstimetedHoursHelperText: "" });
            }
        }
        else if (type === "Description") {
            this.setState({ Description: e.target.value });
        }
        else if (type === "Milestone") {
            this.setState({ Milestone: e.target.value });
        }
        else if (type === "Version") {
            this.setState({ Version: e.target.value });
        }
        else if (type === "Category") {
            this.setState({ Category: e.target.value });
        }
    }

    redirectBack = () => {
        this.props.history.push("/admin/Tasks");
    };

    milestoneModel = () => {
        this.setState({ Milestonevisible: true });
    };

    milestoneCancel = () => {
        this.setState({ Milestonevisible: false });
    };

    versionModel = () => {
        this.setState({ Versionvisible: true });
    };

    versionCancel = () => {
        this.setState({ Versionvisible: false });
    };

    categoryModel = () => {
        this.setState({ Categoryvisible: true });
    };

    categoryCancel = () => {
        this.setState({ Categoryvisible: false });
    };

    personlist = () => {
        return this.state.PersonList.map(content => {
            return (
                <MenuItem value={content.ResourceID} > {content.FirstName + " " + content.LastName}  </MenuItem>
            )
        })
    }

    projectlist = () => {
        return this.state.ProjectList.map(content => {
            return (
                <MenuItem value={content.ProjectID} > {content.ProjectName} </MenuItem>
            )
        })
    }

    typelist = () => {
        return this.state.TypeList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    statuslist = () => {
        return this.state.StatusList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    prioritylist = () => {
        return this.state.PriorityList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName}  </MenuItem>
            )
        })
    }

    categorylist = () => {
        return this.state.CategoryList.map(content => {
            return (
                <MenuItem value={content.StringMapKey} > {content.StringMapName} </MenuItem>
            )
        })
    }

    handleDate = (date, type) => {
        if (type === "DueDate") {
            this.setState({ DueDate: date, DueDateErr: false, DueDateHelperText: "" });
            // this.diff_hours(date);
        }
    }

    validate = () => {
        const { Subject, Assignee, Observer, Priority, TaskStatus, TaskType, Version, DueDate, Project, EstimetedHours } = this.state;
        let IsValid = true;
        if (CommonConfig.isEmpty(Subject)) {
            IsValid = false;
            this.setState({ SubjectHelperText: "Please enter Subject", SubjectErr: true });
        }

        if (CommonConfig.isEmpty(EstimetedHours)) {
            IsValid = false;
            this.setState({ EstimetedHoursHelperText: "Please enter Estimated Hours", EstimetedHoursErr: true });
        }
        
        // if (!Assignee.length>0) {
        //     IsValid = false;
        //     this.setState({ AssigneeHelperText: "Please enter Assignee", AssigneeErr: true });
        // }
        if (CommonConfig.isEmpty(Assignee)) {
            IsValid = false;
            this.setState({ AssigneeHelperText: "Please enter Assignee", AssigneeErr: true });
        }

        if (!Observer.length>0) {
            IsValid = false;
            this.setState({ ObserverHelperText: "Please select Observer", ObserverErr: true });
        }

        if (CommonConfig.isEmpty(TaskStatus)) {
            IsValid = false;
            this.setState({ TaskStatusHelperText: "Please enter Task Status", TaskStatusErr: true });
        }

        if (CommonConfig.isEmpty(Priority)) {
            IsValid = false;
            this.setState({ PriorityHelperText: "Please enter Priority", PriorityErr: true });
        }

        if (CommonConfig.isEmpty(TaskType)) {
            IsValid = false;
            this.setState({ TaskTypeHelperText: "Please enter Issue Type", TaskTypeErr: true });
        }

        if (CommonConfig.isEmpty(DueDate)) {
            IsValid = false;
            this.setState({ DueDateHelperText: "Please enter Due Date", DueDateErr: true });
        }

        if (CommonConfig.isEmpty(Project)) {
            IsValid = false;
            this.setState({ ProjectHelperText: "Please enter Project", ProjectErr: true });
        }

        return IsValid;
    }

    submit = (e) => {
        const { Subject, Assignee, Observer, TaskStatus, EstimetedHours, TaskType, Priority, Category, Version, Milestone, DueDate, Description, Project } = this.state;
        if (this.validate()) {
            this.setState({ Loading: true });
            let data = {
                UserID: CommonConfig.loginData().PersonID,
                Subject: Subject,
                EstimetedHours: EstimetedHours,
                TaskStatus: TaskStatus,
                TaskType: TaskType,
                Assignee: Assignee.value,
                Observer: Observer.map(x=>{return x.label}),
                Category: Category,
                Priority: Priority,
                Version: CommonConfig.isEmpty(Version) == true ? 0 : Version,
                Milestone: CommonConfig.isEmpty(Milestone) == true ? 0 : Milestone,
                DueDate: CommonConfig.isEmpty(DueDate) !== true ? moment(DueDate).endOf('day').format("YYYY-MM-DD HH:mm:ss").toString() : moment().endOf('day').format("YYYY-MM-DD HH:mm:ss").toString(),
                Description: Description,
                ProjectID: Project.value,
                TaskID: null
            }
            
            try {
                api.post("tasks/addUpdateTask", data).then(res => {
                    this.setState({ Loading: false });
                    if (res.success) {
                        cogoToast.success("Task added successfully");
                        this.props.history.push("/admin/Tasks");
                        
                    }
                    else {
                        cogoToast.error("Something went wrong");
                    }
                }).catch(error => {
                    console.log("error", error);
                });
            }
            catch (err) {
                console.log("error", err);
            }
        }
        else {
            cogoToast.error("Please correct error and resubmit the form");
        }
    }

    getAsssigneeByProject = (ProjectID) => {
        let data = {
            ProjectID : ProjectID
        }
        api.post("projectManagement/getAssigneeByProjectID",data).then(res => {
            // console.log("res.......",res);
            if(res.success){
                this.setState({PersonList : res.data.Assignee})
                this.getDailyStatusList(res.data.ProjectManager)
            }
        }).catch(error => {
            console.log("error....",error);
        })
        
    }
    typeChange = (e,type) => {
        // var fil = e.filter((option) => this.state.fixedOptions.indexOf(option))
        // var array = [ ...this.state.fixedOptions,fil[0]]
        if (type === "Assignee") {
            this.setState({ Assignee: e, AssigneeHelperText: "", AssigneeErr: false });
        }
        else if (type === "Observer") {
            this.setState({ Observer: [
                ...this.state.fixedOptions,
                ...e.filter((option) => this.state.fixedOptions.indexOf(option) === -1)
            ], ObserverHelperText: "", ObserverErr: false });
        }
        else if (type === "Project") {
            this.setState({ Project: e, ProjectHelperText: "", ProjectErr: false });
            if(e !== null) {
                this.getAsssigneeByProject(e.value);
            }
            
        }
    }

    selectChange = (e, type) => {

        if (type === "Version") {
            this.setState({ Version: e.target.value });
        }
        else if (type === "TaskStatus") {
            this.setState({ TaskStatus: e.target.value, TaskStatusHelperText: "", TaskStatusErr: false });
        }
        else if (type === "Milestone") {
            this.setState({ Milestone: e.target.value });
        }
        else if (type === "Priority") {
            this.setState({ Priority: e.target.value, PriorityHelperText: "", PriorityErr: false });
        }
        else if (type === "TaskType") {
            this.setState({ TaskType: e.target.value, TaskTypeHelperText: "", TaskTypeErr: false });
        }
        else if (type === "Category") {
            this.setState({ Category: e.target.value })
        }
    }

    render() {
        const { Subject, SubjectErr, SubjectHelperText, EstimetedHours, EstimetedHoursHelperText, EstimetedHoursErr, Milestonevisible, Categoryvisible, Versionvisible, Priority, PriorityHelperText, PriorityErr, TaskType, TaskTypeErr, TaskTypeHelperText, DueDate, DueDateHelperText, DueDateErr, Assignee, AssigneeErr, AssigneeHelperText, Observer, ObserverErr, ObserverHelperText, Category, TaskStatus, TaskStatusErr, TaskStatusHelperText, Version, Milestone, Description, Project, ProjectHelperText, ProjectErr } = this.state;
        console.log("Assignee",this.state.Observer)
        const projectlist = this.state.ProjectList.map((type) => {
            return { value: type.ProjectID, label: type.ProjectName };
        });

        const personList = this.state.PersonList.map(type => {
            return {value : type.AssigneeID , label : type.FirstName + ' '+ type.LastName}
        })

       let ObserverArray = this.state.ObserverArray.length > 0 ? this.state.ObserverArray.filter(x=>!this.state.Observer.map(t=>{return t.label}).includes(x.ResourceID)).map(DailyStatus => {return { value: DailyStatus.FirstName + ' ' + DailyStatus.LastName, label: DailyStatus.ResourceID }
        }) : []

        // ObserverArray = ObserverArray.filter(x=>x.label != this.state.Observer.map(t=>{return t.label}))
        // console.log("personList....",ObserverArray.filter(x=>x.label != this.state.Observer.map(t=>{return t.label})));
        return (<div>
            {this.state.Loading === true ? (
                <div className="loading">
                <SimpleBackdrop />
                </div>
            ) : null
            }
            <GridContainer className="UserList-outer">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader className="btn-right-outer" color="primary" icon>
                            <h4 className="margin-right-auto text-color-black">Add Task</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <Autocomplete
                                            options={projectlist}
                                            id="Project"
                                            value={Project}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, value) => this.typeChange(value, "Project")}
                                            renderInput={params => (
                                                <TextField {...params} error={ProjectErr} helperText={ProjectHelperText}
                                                    label="Project" />)}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={TaskTypeErr}>
                                        <InputLabel>Issue Type</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "TaskType")} value={TaskType}>
                                            {this.typelist()}
                                        </Select>
                                        <FormHelperText>{TaskTypeHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <FormControl fullWidth label="Subject">
                                        <TextField
                                            helperText={SubjectHelperText}
                                            error={SubjectErr}
                                            value={Subject}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Subject"
                                            onChange={(e) => this.onChange(e, "Subject")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <FormControl fullWidth>
                                        <TextareaAutosize
                                            value={Description}
                                            placeholder="Description"
                                            rowsMin={5}
                                            cols={90}
                                            label="Description"
                                            onChange={(e) => this.onChange(e, "Description")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={TaskStatusErr}>
                                        <InputLabel>Task Status</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "TaskStatus")} value={TaskStatus} >
                                            {this.statuslist()}
                                        </Select>
                                        <FormHelperText>{TaskStatusHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <Autocomplete
                                        options={personList}
                                        id="DailyStatus"
                                        value={Assignee}
                                        getOptionLabel={(option) => option.label}
                                        // getOptionLabel={option => option.ResourceID ? option.FirstName + " " + option.LastName : option}
                                        onChange={(event, value) => this.typeChange(value, "Assignee")}
                                        renderInput={params => (
                                            <TextField {...params} error={AssigneeErr} helperText={AssigneeHelperText}
                                                label="Assignee" />)}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <Autocomplete
                                        multiple
                                        options={ObserverArray}
                                        id="DailyStatus"
                                        value={Observer}
                                        getOptionLabel={option => option.label ? option.value : option}
                                        onChange={(event, value) => this.typeChange(value, "Observer")}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                              <Chip
                                                label={option.label ? option.value : option.label}
                                                {...getTagProps({ index })}
                                                disabled={this.state.fixedOptions.indexOf(option) !== -1}
                                              />
                                            ))
                                          }
                                        renderInput={params => (
                                            <TextField {...params} error={ObserverErr} helperText={ObserverHelperText}
                                                label="Observer" />)}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth error={PriorityErr}>
                                        <InputLabel>Priority</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Priority")} value={Priority} >
                                            {this.prioritylist()}
                                        </Select>
                                        <FormHelperText>{PriorityHelperText}</FormHelperText>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Milestone</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Milestone")} value={Milestone} >
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">3</MenuItem>
                                        </Select>
                                        {/* <Button justIcon round color="primary" onClick={() => this.milestoneModel()}><Add /></Button>
                                        <Dialog
                                            open={Milestonevisible}
                                            title="Credentials"
                                            onClose={() => this.milestoneCancel()}>
                                            <DialogContent>
                                                <FormControl>
                                                    <TextField
                                                        value={Milestone}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        label="Milestone"
                                                        onChange={(e) => this.onChange(e, "Milestone")}
                                                    />
                                                    <Button color="primary" onClick={() => this.milestoneCancel()}>Add</Button>
                                                    <Button color="primary" onClick={() => this.milestoneCancel()}>Cancel</Button>
                                                </FormControl>
                                            </DialogContent>
                                        </Dialog> */}
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Category</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Category")} value={Category} >
                                            {this.categorylist()}
                                        </Select>
                                        {/* <Button justIcon round color="primary" onClick={() => this.categoryModel()}><Add /></Button>
                                        <Dialog
                                            open={Categoryvisible}
                                            title="Credentials"
                                            onClose={() => this.categoryCancel()}>
                                            <DialogContent>
                                                <FormControl>
                                                    <TextField
                                                        value={Category}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        label="Category"
                                                        onChange={(e) => this.onChange(e, "Category")}
                                                    />
                                                    <Button color="primary" onClick={() => this.categoryCancel()}>Add</Button>
                                                    <Button color="primary" onClick={() => this.categoryCancel()}>Cancel</Button>
                                                </FormControl>
                                            </DialogContent>
                                        </Dialog> */}
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Version</InputLabel>
                                        <Select onChange={(e) => this.selectChange(e, "Version")} value={Version} >
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">3</MenuItem>
                                        </Select>
                                        {/* <Button justIcon round color="primary" onClick={() => this.versionModel()}><Add /></Button>
                                        <Dialog
                                            open={Versionvisible}
                                            title="Credentials"
                                            onClose={() => this.versionCancel()}>
                                            <DialogContent>
                                                <FormControl>
                                                    <TextField
                                                        value={Version}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        label="Version"
                                                        onChange={(e) => this.onChange(e, "Version")}
                                                    />
                                                    <Button color="primary" onClick={() => this.versionCancel()}>Add</Button>
                                                    <Button color="primary" onClick={() => this.versionCancel()}>Cancel</Button>
                                                </FormControl>
                                            </DialogContent>
                                        </Dialog> */}
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            dateFormat={"DD/MM/YYYY"}
                                            timeFormat={false}
                                            className="input-group"
                                            value={DueDate}
                                            //selected={DueDate}
                                            inputProps={{ placeholder: "Due Date" }}
                                            onChange={(date) => this.handleDate(date, "DueDate")}
                                            closeOnSelect={true}
                                            renderInput={params => (
                                                <TextField
                                                    helperText={DueDateHelperText}
                                                    error={DueDateErr}
                                                    {...params} label="Due Date" margin="normal" />)} />
                                        <Icon className="date-icon tp-slam">date_range</Icon>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <FormControl fullWidth label="EstimetedHours">
                                        <TextField
                                            helperText={EstimetedHoursHelperText}
                                            error={EstimetedHoursErr}
                                            value={EstimetedHours}
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            label="Estimated Hours"
                                            onChange={(e) => this.onChange(e, "EstimetedHours")}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <Tooltip title="Save" placement="bottom">
                                <Button type="submit" color="primary" onClick={(e) => this.submit(e)}>Save</Button></Tooltip>
                            <Tooltip title="Back" placement="bottom">
                                <Button type="submit" key="back" onClick={() => this.redirectBack()}>Back</Button></Tooltip>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
        );
       
    }
}

export default addTasks;