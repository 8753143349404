import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import { useState, useEffect } from 'react';
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import Logo from 'assets/img/cognisun-logo.png';

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { CommonConfig } from '../../utils/constant';
import api from '../../utils/apiClient';
import Datetime from "react-datetime";
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import cogoToast from 'cogo-toast';
import { Menu } from "@material-ui/core";
import { apiBase } from "../../utils/config";
import defaultImage from "assets/img/default-avatar.png";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

  let history = useHistory();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [ObserverDetail, setObserverDetail] = React.useState([]);
  var [VisibleArray, setVisibleArray] = React.useState([]);
  const [filterVal, setfilterVal] = React.useState('');
  const [filterData, setfilterData] = React.useState([]);
  const [DailyStatusList, setDailyStatusList] = React.useState([]);
  const [UserImg1 , setUserImg1] =  React.useState({});
  const [imagePreviewUrl , setimagePreviewUrl] =  React.useState({defaultImage});
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  Number.prototype.padLeft = function (base, chr) {
    var len = (String(base || 10).length - String(this).length) + 1;
    return len > 0 ? new Array(len).join(chr || '0') + this : this;
  }
  useEffect(() => {
    // if (count == false) {
    getObserverNotification();
    getDailyStatusList();
    getEditData();
    // }
  }, [])

  const getEditData = () => {
    let data = {
      ResourceID: CommonConfig.loginData().PersonID,
    };
    if (data.ResourceID !== "") {
      try {
        api.post("resource/getResourceByID", data).then(res => {
          if (res.success) {
            setUserImg1(
             res.data.UserImg ? res.data.UserImg : '',
            );
          } else {
            cogoToast.error('Something went wrong');
          }
        }).catch(error => {
          console.log(error);
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      // Handle the case when ResourceID is empty
    }
  }

  const getObserverNotification = () => {
    let data = {
      // TaskID: record.original.TaskID,
      ResourceID: CommonConfig.loginData().PersonID
    }
    try {
      api.post("dailystatus/getObserverNotification", data).then(res => {
        if (res.success) {
          var taskHrs = []
          var VisibleArray = []
          for (let i = 0; i < res.data.length; i++) {

            taskHrs.push({
              StartDate: res.data[i].TaskStatusDate,
              Day: moment(res.data[i].TaskStatusDate).startOf("day").format('dddd'),
              PercentageComplete: res.data[i].PercentageCompleted,
              Devloper: res.data[i].FirstName + " " + res.data[i].LastName,
              ResourceID: res.data[i].ResourceID,
              Hours: res.data[i].HrsToday,
              Note: res.data[i].Notes,
              Status: res.data[i].Status,
              TaskStatus: res.data[i].TaskStatus,
              NotificationStatus: res.data[i].NotificationStatus,
              Project: res.data[i].ProjectName,
              Subject: res.data[i].Subject,
              TaskID: res.data[i].TaskID,
              DailyTaskID: res.data[i].DailyTaskID,

            })
            VisibleArray.push({
              Visible: false,
              DailyTaskID: res.data[i].DailyTaskID,
            })
          }
          setObserverDetail(taskHrs);
          setVisibleArray(VisibleArray);
        } else {
          cogoToast.error('Something went wrong');
        }
      })
    }
    catch {

    }
  }

  const updateObserverNotification = (Data) => {
    let data = {
      // TaskID: record.original.TaskID,
      ObserverID: CommonConfig.loginData().PersonID,
      DailyTaskID: Data
    }
    try {

      api.post("dailystatus/updateObserverNotification", data).then(res => {
        if (res.success) {
          getObserverNotification();
        } else {
          cogoToast.error('Something went wrong');
        }
      })
    }
    catch {

    }
  }

  const getDailyStatusList = () => {
    try {
      api.get("dailystatus/getDailyStatusList").then(res => {
        if (res.success) {
          let obj = {
            FullName: "Select All",
            ResourceID: ""
          }
          var finalResourceList = []

          res.data.map((r, i) => {
            res.data[i].FullName = `${r.FirstName} ${r.LastName}`
          });
          finalResourceList.push(obj)
          for (let i = 0; i < res.data.length; i++) {
            finalResourceList.push(
              res.data[i]
            )
          }
          setDailyStatusList(finalResourceList)
          setfilterVal({
            value: CommonConfig.loginData().PersonID, label: CommonConfig.loginData().FirstName + ' ' + CommonConfig.loginData().LastName
          })
        }
      }).catch(error => {
      })
    }
    catch (err) {
    }
  }

  const DailyStatus = DailyStatusList.map(DailyStatus => {
    return { value: DailyStatus.ResourceID, label: DailyStatus.FullName }
  });

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }

    // var Data = ObserverDetail.filter(x=>x.NotificationStatus != 'Inactive');
    // if(Data.length>0){
    //   updateObserverNotification(Data);
    // }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
    const navBarProfile = true; // Update the navBarProfile value dynamically for profile component
    history.push({
      pathname: "/admin/Profile",
      state: { editid: CommonConfig.loginData().PersonID, navBarProfile }
    });
  };
  
  const handleLogout = () => {
    localStorage.clear();
    history.push("/login-page");
  }
  const redirectChangePassword = () => {
    setOpenProfile(null);
    history.push({
      pathname:"/admin/changePassword",
    })
  };
  //   history.push("/admin/changePassword");
  //   // handleCloseProfile();
  // }
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const days_passed = (date) => {
    console.log(date)
    // var current = new Date();
    // var previous = new Date(date.getFullYear(), 0, 1);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date();
    const secondDate = new Date(date);

    return Math.round(Math.abs((firstDate - secondDate) / oneDay));

    // return Math.ceil((current - previous + 1) / 86400000);
  }
  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  const handleChange = (value, type) => {

    if (value !== null) {
      setfilterVal(value)
      var filterData = ObserverDetail.filter(x => x.ResourceID == value.value)
      setfilterData(filterData);
    } else {
      setfilterVal('')
      setfilterData([]);
    }
  }

  const Visible = (idx, type) => {

    var Index = VisibleArray.findIndex(x => x.DailyTaskID == idx);
    VisibleArray[Index].Visible = type == "visible" ? true : false
    setVisibleArray(VisibleArray)
  }

  const Data = filterData.length > 0 ? filterData : ObserverDetail;
  const list = () => {
    return <div className="observer-sidebar">
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}><h3>Notification</h3></GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <div className="search-resource">

            <Autocomplete
              options={DailyStatus}
              id="DailyStatus"
              value={filterVal}
              getOptionLabel={option => option.label}
              onChange={(event, value) => handleChange(value, "Resource")}
              renderInput={params => (
                <TextField {...params}
                />)}
            />

          </div>
        </GridItem>
      </GridContainer>
      <div className="notification-inner">
        <ul>
          {Data.map((x, idx) => {
            return (
              <li onClick={() => updateObserverNotification(x.DailyTaskID)} className={x.NotificationStatus == "Inactive" ? "notification-inactive notification" : "notification-active notification"} >
                <div className="notification-list__link">
                  <div className="notification-list__icon">
                    <img src={Logo} className="image image--circle -x-large" />
                  </div>
                  <div className="notification-list__info">
                    <div className="notification-list__summary">
                      <span className="key">{x.Project}</span>
                      <span className="notification-task">{x.Subject.length > 30 ? x.Subject.substring(0,30) + "..." : x.Subject}</span>
                      <a className="notification-link" onClick={() => {
                        history.push({
                          pathname: "/admin/viewTasks",
                          state: { id: x.TaskID }
                        })
                        setOpenNotification(null);
                      }}><VisibilityIcon /></a>
                    </div>
                    <div className="notification-list__title">
                      <span>{x.Devloper + " changed status " }{x.Note.length > 30 ? x.Note.substring(0,30) + "..." : x.Note}</span>
                    </div>
                  </div>
                  <div className="notification-list__meta">
                    <abbr className="notification-list__time js-notification-time" title={x.StartDate}>{days_passed(new Date(x.StartDate)) == 0 ? "Today" + " | " + formatAMPM(new Date(x.StartDate)) : days_passed(new Date(x.StartDate)) + " day ago "}</abbr>
                    <span className={"badge badge-" + x.TaskStatus.split(" ").join("").toLowerCase()}>{x.TaskStatus}</span>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>;
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <VisibilityIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />

          <span className={classes.notifications}>{ObserverDetail.filter(x => x.NotificationStatus != 'Inactive').length}</span>

          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : "Notification"}
            </span>
          </Hidden>

        </Button>
        {openNotification ?
          <ClickAwayListener onClickAway={handleCloseNotification}>
            {list()}
          </ClickAwayListener>
          : null}
        {/* <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">

                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> */}


        {/* <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
          {list("right")}
        </Drawer> */}
      </div>


      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
        {UserImg1 ? (
          <img src={ apiBase + UserImg1} 
          className={classes.headerLinksSvg + " " + (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)}
          width="32"
          height="32"
          style={{ borderRadius: "500px" }}
           />
        ) : (
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          )}
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">

                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Profile"}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={redirectChangePassword}
                      className={dropdownItem}
                    >Change Password
                    </MenuItem>
                    <MenuItem
                      onClick={handleLogout}
                      className={dropdownItem}
                    >Log out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
